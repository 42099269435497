import styled from 'styled-components';

export const UserBarContainer = styled.div`
  width: 100%;
  margin-top: 0px;
  display: flex;

  @media screen and (max-width: 1024px) {
    margin-top: 20px;
  }

  @media screen and (max-width: 480px) {
    margin-top: 10px;
  }
`;

export const LittleGrayBar = styled.div`
  background-color: #e2e2e2;
  width: 5%;
`;

export const LittleRedBar = styled.div`
  background-color: red;
  width: 1%;

  @media screen and (max-width: 480px) {
    width: 2%;
  }
`;

export const TextContainer = styled.div`
  background-color: #e2e2e2;
  width: 94%;
  display: flex;
  justify-content: space-between;
  padding: 7px 0 7px 50px;

  @media screen and (max-width: 1024px) {
    padding: 7px 0 7px 20px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    padding: 7px 15px;
  }

  @media screen and (max-width: 480px) {
    width: 94%;
  }
`;

export const TextRow = styled.div`
  @media screen and (min-width: 769px) {
    margin: 7px 0;
    display: flex;
    justify-content: space-between;
  }
`;
export const FirstSection = styled.div`
  @media screen and (min-width: 769px) {
    width: 65%;
  }
`;
export const SecondSection = styled.div`
  @media screen and (min-width: 769px) {
    width: 35%;
  }
`;

export const DataContiner = styled.div`
  @media screen and (min-width: 769px) {
    display: flex;
    margin: 7px 0;
  }
`;

export const Text = styled.span`
  color: red;
  font-size: 19px;

  @media screen and (max-width: 480px) {
    font-size: 15px;
  }
`;

export const Span = styled.span`
  font-size: 19px;

  @media screen and (max-width: 480px) {
    font-size: 15px;
  }
`;

export const Ciclo = styled.span`
  margin-right: 22px;
`;

export const NameText = styled.p`
  margin: 0;
  font-size: 18px;

  @media screen and (max-width: 768px) {
    margin-top: 0;
    margin-bottom: 6px;
  }

  @media screen and (max-width: 480px) {
    margin-top: 0;
    font-size: 14px;
  }
`;

export const ModalidadText = styled.p`
  margin: 0 50px 0 0;
  font-size: 18px;

  @media screen and (max-width: 768px) {
    margin: 0;
    margin-bottom: 6px;
  }
  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

export const MatriculaText = styled.p`
  margin: 0 50px 0 0;
  font-size: 18px;

  @media screen and (max-width: 480px) {
    margin-top: 6px;
    margin: 0;
    font-size: 14px;
  }
`;

export const DateText = styled.p`
  margin: 0 50px 0 0;
  font-size: 18px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    display: block;
    justify-content: unset;
    margin: 0;
    margin-top: 6px;
  }

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;
