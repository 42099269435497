import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ArrowRight,ArrowLeft} from '@material-ui/icons/';
import Checkpoint from './Checkpoint';

import {
  StepperContainer,
  ItemContainer,
  Button,
  ProgressBarContainer,
  Bar,
  ImageContainer,
  Image,
  PercentText,
} from './styles';

import pointer from '../../../../images/progress_pointer.png';

export default function  Progress({
  canContinue,
  questionIndex,
  agrupadores,
  setQuestionIndex,
  setAnswerData,
  enviar,
  clearAnswers,
  setEvalInfo,
  reactivos,
  answerData,
  setTipoActual,
  salir,
  respuestasActuales,
  presentacion
}){
  const history = "useHistory()";

  const token = window.localStorage.getItem('token_eval');

  const now = parseInt((questionIndex / reactivos.length) * 100);

  const { matches } = window.matchMedia('(min-width: 769px)');

  let agrupadorActual;

  agrupadores.forEach((agrupador) => {
    if (agrupador.reactivos.includes(reactivos[questionIndex])) {
      agrupadorActual = agrupador.orden;
    }
  });

  let agrupadorSiguiente;

  agrupadores.forEach((agrupador) => {
    if (agrupador.reactivos.includes(reactivos[questionIndex + 1])) {
      agrupadorSiguiente = agrupador.orden;
    }
  });

  const sendAnswers = async () => {
    try {
      await fetch(
        'http://159.203.83.182/evaluacion-alumno/enviarRespuesta.php',
        {
          mode: 'cors',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            tk: token,
          },
          body: JSON.stringify(answerData),
        },
      );
      setAnswerData({});
    } catch (error) {
      console.log(error);
    }
  };

  if (questionIndex >= reactivos.length) canContinue = true;
  const handleNext = () => {
    if(presentacion){
      setTipoActual(reactivos[questionIndex].tipo);
      return
    }
    if (!canContinue) {      
      toast.error('Para continuar se debe responder');
    } else {
      clearAnswers([]);
      if (enviar) {
        salir()
      } else {
        if (questionIndex + 1 < reactivos.length) {
          setQuestionIndex(questionIndex + 1);
          
          setQuestionIndex(questionIndex + 1);
          if (agrupadorActual !== agrupadorSiguiente) {
            setTipoActual('presentacion');
          } else {
            setTipoActual(reactivos[questionIndex + 1].tipo);
          }
        } else {
          setQuestionIndex(questionIndex + 1);
          setTipoActual('fin');
        }
      }
    }
  };

  const goBack = () => {
    if (questionIndex > 0) {
      setQuestionIndex(questionIndex - 1);
      setTipoActual(reactivos[questionIndex - 1].tipo);
    }else{
      salir()
    }
  };

  return (
    <StepperContainer>
      <ItemContainer>
        <p>Anterior</p>
        <Button onClick={goBack}>
          <ArrowLeft style={{fontSize:'30'}}/>
        </Button>
      </ItemContainer>
      <ProgressBarContainer>
        <Bar percent={`${now}%`}>
          <ImageContainer show={matches && now > 0}>
            <Image src={pointer} alt='pointer' />
            <PercentText now={now}>{now}%</PercentText>
          </ImageContainer>
        </Bar>
        <Checkpoint number={0} percent={0} />
        {agrupadores.map((agrupador, i) => (
          <Checkpoint
            agrupadores={agrupadores}
            key={i}
            number={i + 1}
            percent={(agrupador.reactivos.length / reactivos.length) * 100}
          />
        ))}
      </ProgressBarContainer>
      <ItemContainer>
        <p>{enviar ? 'Enviar' : 'Siguiente'}</p>
        <Button onClick={handleNext}>
          <ArrowRight style={{fontSize:'30'}}/>
        </Button>
      </ItemContainer>
    </StepperContainer>
  );
};

