import React from 'react'
import PropTypes from 'prop-types'
import Modal from '@material-ui/core/Modal'
import CircularProgress from '@material-ui/core/CircularProgress'
import Close from '@material-ui/icons/Close'

import './style.css'

function NewModal(props) {
  const {
    open,
    instrumento,
    title,
    handleClose,
    height,
    width,
    loading,
    children,
    canDelete,
    onDelete,
    handleSubmit,
    noFooter,
    textoDelete,
    inactiva,
    downloadFigpo,
    visualizarPublico,
    noAceptar,
    copiar,
    showCopyButton,
    showCheckbox,
    activeCheckbox,
    handleChangeActiva,
  } = props
  return (
    <Modal open={open}>
      <div
        className={instrumento ? 'NewModal-Instrumento' : 'NewModal'}
        style={width && { maxWidth: width }}
      >
        <h3>
          {title}
          <Close className='close-modal' onClick={handleClose}>
            x
          </Close>
        </h3>
        <div className='NewModal-container' style={{ height: height }}>
          {loading ? (
            <div style={{ textAlign: 'center', paddingTop: 30 }}>
              <CircularProgress size={50} />
            </div>
          ) : (
            <>
              {children}
              {noFooter ? null : (
                <div className='NewModal-footer'>
                  {canDelete ? (
                    <>
                      <button
                        onClick={onDelete}
                        disabled={inactiva}
                        className={
                          inactiva ? 'disabled-button' : 'delete-button-modal'
                        }
                      >
                        {textoDelete ? textoDelete : 'Eliminar'}
                      </button>
                      {showCheckbox && (
                        <div className='activa-container'>
                          <label htmlFor='checkbox-activa'>Activa</label>
                          <input
                            type='checkbox'
                            name='Activa'
                            id='checkbox-activa'
                            checked={activeCheckbox}
                            onChange={handleChangeActiva}
                          />
                        </div>
                      )}
                    </>
                  ) : null}
                  <button className='gray-btn' onClick={handleClose}>
                    Cancelar
                  </button>
                  {noAceptar ? null : (
                    <button className='black-btn' onClick={handleSubmit}>
                      Aceptar
                    </button>
                  )}
                  {title === 'Editar Evaluacion' ? (
                    <button onClick={downloadFigpo} className='figpo-button'>
                      Descargar figpo
                    </button>
                  ) : null}
                  {visualizarPublico ? (
                    <button
                      onClick={visualizarPublico}
                      className='figpo-button-2'
                    >
                      Visualizar público
                    </button>
                  ) : null}
                  {showCopyButton ? (
                    <button className='copiar-button' onClick={copiar}>
                      Copiar Evaluacion Integral
                    </button>
                  ) : null}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

NewModal.propTypes = {
  open: PropTypes.bool,
  instrumento: PropTypes.bool,
  title: PropTypes.string,
  handleClose: PropTypes.func,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  children: PropTypes.element,
  canDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  handleSubmit: PropTypes.func,
  noFooter: PropTypes.bool,
  textoDelete: PropTypes.string,
  inactiva: PropTypes.bool,
  downloadFigpo: PropTypes.func,
  copiar: PropTypes.func,
  visualizarPublico: PropTypes.func,
  noAceptar: PropTypes.bool,
}

export default NewModal
