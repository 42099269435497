import React from 'react'
import PropTypes from 'prop-types'

import './style.css'

function ToggableButton(props) {
  return (
    <div
      className='Toggable-Button'
      onClick={props.onClick}
      style={props.style}
      disabled={props.disabled}
    >
      {props.yes ? (
        <div className='Toggable-Yes'>
          <span>Si</span>
          <div className='Circle-Toggable-Yes'></div>
        </div>
      ) : (
        <div className='Toggable-No'>
          <div className='Circle-Toggable-No'></div>
          <span>No</span>
        </div>
      )}
    </div>
  )
}

ToggableButton.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.any,
  disabled: PropTypes.bool,
  yes: PropTypes.bool,
  children: PropTypes.element,
}

export default ToggableButton
