import React, { useRef, useState } from 'react';
import { SquareCheckbox } from '../SquareCheckbox';
import { withStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import { TD, FirstOptionsText } from './styles';

export default function TableRow({
  id,
  asignatura,
  docente,
  handleClick,
  answerData,
  reactivos,
  questionIndex,
  tipoEvaluacion
}){

  const respuestaFiltrada = answerData.respuestas.filter(
    (respuesta) =>
      respuesta.id_asignacion === id &&
      reactivos[questionIndex].id === respuesta.id_reactivo,
  );

  return (
    <tr>
    {
      tipoEvaluacion==='Opinion_Estudiantil' ?
      <>
        <TD
          odd={id % 2 !== 0}
          style={{ borderRight: '2px solid #b1b1b1', width: '30px' }}>
          <FirstOptionsText>{asignatura}</FirstOptionsText>
        </TD>
        <TD style={{ paddingLeft: '11px' }} odd={id % 2 !== 0}>
          <FirstOptionsText>{docente}</FirstOptionsText>
        </TD>
      </>:null
    }
      {reactivos[questionIndex].opciones.map((option, i) => {
        const handleChange = () => {
          handleClick(option.nombre, option.valor);
        };

        const isChecked = (opcion)=>{
          let checado=false
          answerData.respuestas.forEach(respuesta=>{
            if(opcion.nombre===respuesta.respuesta) checado=true
          })

          return checado
        } 

        return (
          <TD
            onClick={handleChange}
            key={option.id}
            odd={id % 2 !== 0}
            dark={i % 2 === 0}>
            <SquareCheckbox
              checked={
                respuestaFiltrada.length > 0 &&
                isChecked(option)
              }
              size='14px'
            />
          </TD>
        );
      })}
    </tr>
  );
};

