export const setUser = (payload) => ({
  type: 'SET_USER',
  payload
});
export const setIsLoged = (payload) => ({
  type: 'SET_IS_LOGED',
  payload
});

export const setVersion = (payload) => ({
  type: 'SET_VERSION',
  payload
});

export const setUrlScriptAyuda = (payload) => ({
  type: 'SET_URL_SCRIPT_AYUDA',
  payload
});

export const setTokenKey = (payload) => ({
	type: 'SET_TOKEN_KEY',
	payload
})

export const setCampus = (payload) => ({
	type: 'SET_CAMPUS',
	payload
})

export const setAllPermises = (payload) => ({
  type: 'SET_ALL_PERMISES',
  payload
})