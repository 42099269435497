import Grid from '@material-ui/core/Grid'
import { Checkbox } from '@material-ui/core'
const SimpleCheck = ({ isActive, label, onClick }) => {
  return (
    <Grid
      container
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        '&:hover': {
          bgcolor: '#DEEBFF',
        },
        width: '100%',
      }}
      onClick={onClick}
    >
      <Grid item>
        <label style={{ cursor: 'pointer' }}>{label}:</label>
      </Grid>
      <Grid item>
        <Checkbox style={{ color: 'black' }} size='small' checked={isActive} />
      </Grid>
    </Grid>
  )
}

export default SimpleCheck
