import React, { useState } from 'react';
import { TD, FirstOptionsText, Bar, Radius, Fill } from './styles';

export default function TableRow({
  handleClick,
  id,
  asignatura,
  docente,
  reactivos,
  questionIndex,
  dark,
  answerData,
  tipoEvaluacion
}){
  const respuestaFiltrada = answerData.respuestas.filter(
    (respuesta) =>
      respuesta.id_asignacion === id &&
      reactivos[questionIndex].id === respuesta.id_reactivo &&
      respuesta.tipo === 'intervalo',
  );
  const handleChange = (res, value) => {
    handleClick(res, value);
  };

  return (
    <tr>
    {
      tipoEvaluacion==='Opinion_Estudiantil' ?
      <>
        <TD
          odd={id % 2 !== 0}
          style={{ borderRight: '2px solid #b1b1b1', width: '30px' }}>
          <FirstOptionsText>{asignatura}</FirstOptionsText>
        </TD>
        <TD style={{ paddingLeft: '11px' }} odd={id % 2 !== 0}>
          <FirstOptionsText>{docente}</FirstOptionsText>
        </TD>
      </>:null
    }
      {reactivos[questionIndex].opciones.map(({ id, nombre, valor }, i) => (
        <TD
          onClick={() => handleChange(nombre, valor)}
          cursor='pointer'
          key={id}
          odd={i % 2 === 0}
          dark={dark}>
          <Radius>
            <Fill
              selected={
                respuestaFiltrada.length > 0 &&
                respuestaFiltrada[0].valor_respuesta >= valor
              }
            />
          </Radius>
            {reactivos[questionIndex].opciones.length > i + 1 && (
              <Bar
                selected={
                  respuestaFiltrada.length > 0 &&
                  respuestaFiltrada[0].valor_respuesta > valor
                }
              />
            )}
        </TD>
      ))}
    </tr>
  );
};