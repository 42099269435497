import React from 'react';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Progress from '../components/Progress';
import Button from '../components/Button';

const Container = styled.div`
  margin: 3rem;
  @media screen and (min-width: 481px) and (max-width: 768px) {
    margin: 2rem;
  }
  @media screen and (max-width: 480px) {
    margin: 3rem 1rem;
  }
`;

const Title = styled.p`
  font-size: 25px;
  text-align: center;
  @media screen and (max-width: 480px) {
    font-size: 22px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 5rem 0 0;
  @media screen and (min-width: 481px) and (max-width: 768px) {
    margin: 4rem 0 0;
  }
  @media screen and (max-width: 480px) {
    margin: 3rem 0 0;
  }
`;

const ButtonSiguiente = styled.button`
  width: 340px;
  height: 42px;
  background-color: #191919;
  border-radius: 40px;
  font-size: 22px;
  color: white;
  cursor: pointer;
  border: none;
  @media screen and (max-width: 480px) {
    width: 300px;
    height: 50px;
    font-size: 20px;
  }
`;

const ControlsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #fff;

  @media screen and (max-width: 480px) {
    margin-bottom: 25px;
    padding-bottom: 15px;
    position: fixed;
  }
`;

const Controls = styled.div`
  width: 100%;

  @media screen and (max-width: 480px) {
    width: 91%;
    margin: 0 1rem;
  }
`;

export default function Presentacion({ 
	agrupadores,
	reactivos,
	canContinue,
	step,
	questionIndex,
	setQuestionIndex,
	clearAnswers,
	salir,
	answerData,
	setTipoActual,
}){
  let name = '';

  agrupadores.forEach((agrupador) => {
    if (agrupador.reactivos.includes(reactivos[questionIndex])) {
      name = agrupador.nombre;
    }
  });

  const handleClick = () => {
  	console.log("siguiente")
    setTipoActual(reactivos[questionIndex].tipo);
  };

  const matches = useMediaQuery('(min-width: 481px)');

  return (
    <>
      <Container>
        <Title>{name}</Title>
        <ButtonContainer>
          <ButtonSiguiente onClick={handleClick}>Siguiente</ButtonSiguiente>
        </ButtonContainer>
      </Container>

      <ControlsWrapper>
	      <Controls>
	        <Progress
	          agrupadores={agrupadores}
	          reactivos={reactivos}
	          canContinue={true}
	          step={questionIndex + 1}
	          questionIndex={questionIndex}
	          setQuestionIndex={setQuestionIndex}
	          clearAnswers={clearAnswers}
	          salir={salir}
	          answerData={answerData}
	          setTipoActual={setTipoActual}
            presentacion={true}
	        />
	        <Button  salir={salir}/>
	      </Controls>
      </ControlsWrapper>
    </>
  );
};