import React from 'react';
import {
  UserBarContainer,
  LittleGrayBar,
  LittleRedBar,
  TextRow,
  FirstSection,
  SecondSection,
  TextContainer,
  DataContiner,
  Text,
  DateText,
  Ciclo,
  NameText,
  ModalidadText,
  MatriculaText,
  Span,
} from './styles';

export default function UserInfoComponent({ user, evalInfo }){
  return (
    <UserBarContainer>
      <LittleGrayBar></LittleGrayBar>
      <LittleRedBar></LittleRedBar>
      <TextContainer>
        <FirstSection>
          <TextRow>
            <NameText>
              <Text>Nombre Alumno: </Text>
              <Span>

              </Span>
            </NameText>
          </TextRow>
          <DataContiner>
            <ModalidadText>
              <Text>Modalidad: </Text>
              <Span>Bachillerato Semestral</Span>
            </ModalidadText>
            <MatriculaText>
              <Text>Matrícula: </Text>
              <Span>230003456</Span>
            </MatriculaText>
            <DateText>
              <Ciclo>
                <Text>Ciclo: </Text>
                <Span>02</Span>
              </Ciclo>
              <Span>
                <Text>Año: </Text>
                <Span>2020</Span>
              </Span>
            </DateText>
          </DataContiner>
        </FirstSection>
        {evalInfo ? (
          <SecondSection>
            <TextRow>
              <NameText>
                <Text>Folio: </Text>
                <Span>{evalInfo.folio}</Span>
              </NameText>
              <ModalidadText>
                <Text>Clave: </Text>
                <Span>{evalInfo.clave}</Span>
              </ModalidadText>
            </TextRow>
            <DataContiner>
              <MatriculaText>
                <Text>Evaluacion: </Text>
                <Span>{evalInfo.tipo_evaluacion}</Span>
              </MatriculaText>
            </DataContiner>
          </SecondSection>
        ) : null}
      </TextContainer>
    </UserBarContainer>
  );
};

