import React from "react";

const CampusIcon = () => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 172 175" enable-background="new 0 0 172 175" space="preserve">
            <image id="image0" width="172" height="175" x="0" y="0"
                href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKwAAACvCAQAAADKrP39AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
                AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZ
                cwAACxIAAAsSAdLdfvwAAAAHdElNRQflAgMXBiSRGCq9AAAFbElEQVR42u2dzXHjNhiGX2dy2Jkc
                zNx8yZiHnclRSgXLrSBOBat0oFSwcgfqIEoHSgdyB1Qq4HZAZXLMDHNYx+MfEARJvfpA8H10sUHI
                H/AYA/Ij+HPVQDD4xroBqSKxJCSWhMSSkFgSEktCYklILAmJJSGxJCSWhMSSkFgSEktCYklILAmJ
                JSGxJL61bkAgBQoANXaorZsSxtUk1rx2+PT40xHFNNROYSoonrQCC2ytmxPGFKaCuxe/fUIV+L0K
                e7vRPYWp4IAPA795QoHSptFTmAqGc42DVei0xQLXKGwCpy4WWNqETV/s3iZs6mIfgo8hzkzqYtdW
                gdMWe291sJW22CM2dsFTFruyDJ6yWFNSFruzDJ6y2IXmWBafrfKu1MXC7uxt6mI/ILcJnLpY6OwW
                i8ombOpiT1anutMWe7KaCKaxmPia+8B6plchTFHsxroBIaQ9FRgisSQklsQUxN68+j2zblAI8Ytd
                4cdXJYcpqI1d7Aq/vylbTEFt3GJdWoFJqI1ZbJtWYAJq4xXr0wpErzZWsV1agcjVxik2RCsQtdoY
                xYZqBSJWG5/YPlqBaNWOuVQ+x/rsq6A3b9KBEP7BEf+etR01tuNOkQ8Xu8IW12ftTGz8MeYipaFi
                c5SJawWA34Yvnw8VO/xOlilxwnLoYuSwndfdLLQC15cdsRmqGUwD//PLsLsYhozY1HdaL9kNO5jr
                L/b5na1z4HrY4mXfqSBDiVvrvl6cj/2PafuO2PUMtQ66hLmf2CU+W/fRhNv+00G/qaDEwrqPZvzU
                79amPiN2PWOtvS9hDh+x80hiffRKcMPFziOJ9dErwQ2dCuaSxProleCGjdh5JbE+ghPcsBE7ryTW
                R3CCGyJ2bkmsj+AEt3sqmGcS6yMowe0esfNMYn3sQip1iZ1rEusjKMHtmgrmnMT66Exw/SN23kms
                j21XBd+IVRLroyPB9YlVEuujI8FtnwqUxPrpOKJtH7F7/Gzd9uj5vv3Ox7YRm0lrAHftm9rELq3b
                PAny9k1tYg/WbZ4EZfum9p3Xn9atngCH9k3tYjc4Wbc7cu59N+23iy1R4MG67dHyBb8OPdz6So4c
                wEpnZB85Yg2g7l4K73oQRIUKZk8CipA6dLce380diSCxJCSWhMSSkFgSEkviXM/dOmIPYNl5TuyE
                PSpkuLvQ2m94vAccABRnOwvdhHw2jY+6KZ5q5s3BU3PbZE811w2fzbM++OKVzfKp3rIpPTUPQb7Q
                nEFs/axRXz/7gG6iQXNH1rp+FW/VqjV7US/zqL2g2NWb2rmzXuX4uzuiVpcC97+8eFOvGC92/M5r
                96akcp5y3AZ993zsAsuOjiT1gOPY8GPFus9/lYFlB/CoAuPVzm+7S3swr8Ot+nKh5iX2gkgsCYkl
                IbEkJJaExJKQWBISS2LsacPMuYKbO8qWF+5ZaDx3D7Kx4cPuTNzoFo9HHkIvBdBUQEJiSUgsCYkl
                IbEkJJaExJKQWBISS2L8YuKV4+N6+d5HZ00eofHcPRh9k4BGLAmJJSGxJCSWhMSSGHui+8Z52c47
                R9n2wi/fDY333tmD92PDjxX7znmh7hdH2aWfLhMa7ztnD+qx4TUVkJBYEhJLQmJJSCyJsWJve5TG
                SdajtAcasSQkloTEkpBYEhJLQmJJ+K82zLDBEkA+qQMoJjWOCHprrU9shr/wg3VPoqXjVRO+qWAl
                rR7W/s0+sZl126Mm92/2iS2t2x41pX+zf+e106P3WvgbC/8rqLruQVj6nuo7WyrsuxZvhr+tXnhR
                gkBCYklILAmJJSGxJCSWhMSSkFgSEktCYklILAmJJSGxJCSWhMSSkFgSEktCYklILAmJJSGxJP4D
                n+P8N4cL2kQAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjEtMDItMDNUMjM6MDY6MzYrMDM6MDDomPUi
                AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIxLTAyLTAzVDIzOjA2OjM2KzAzOjAwmcVNngAAAABJRU5E
                rkJggg==" />
        </svg>
    )
}

export default CampusIcon;