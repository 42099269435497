/* eslint-disable no-loop-func */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from '@material-ui/core'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Edit from '@material-ui/icons/Edit'
import Save from '@material-ui/icons/Save'
import CircularProgress from '@material-ui/core/CircularProgress'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import moment from 'moment'
import Select from 'react-select'

import { postRequest } from '../../utils/requester'
import Can from '../../utils/can'
import './index.css'

function DataSelectTable(props) {
  const data = props.data
  const [orderBy, setOrderBy] = useState({ col: 0, asc: true })
  const [rowsPerPage, setRowsPerPage] = useState(data.length)
  const [pageActual, setPageActual] = useState(0)
  const [resize, setResize] = useState(false)
  const tableRef = useRef(null)
  const [editableCel, setEditableCel] = useState([])
  const [loadingCel, setLoadingCel] = useState([])
  const [menuToShow, setMenuToShow] = useState(null)
  const [filterOptions, setFilterOptions] = useState([])
  const [selectedFilters, setSelectedFilters] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [isFilterActive, setIsFilterActive] = useState(false)

  useEffect(() => {
    setFilteredData(data)
  }, [data])

  let i = 0

  if (orderBy.asc && !props.dobleTabla) {
    filteredData.sort((a, b) => {
      if (a[orderBy.col] > b[orderBy.col]) {
        return 1
      }
      if (a[orderBy.col] < b[orderBy.col]) {
        return -1
      }
      return 0
    })
  } else if (!props.dobleTabla) {
    filteredData.sort((a, b) => {
      if (a[orderBy.col] < b[orderBy.col]) {
        return 1
      }
      if (a[orderBy.col] > b[orderBy.col]) {
        return -1
      }
      return 0
    })
  }

  useEffect(() => {
    setPageActual(0)
  }, [data])

  useEffect(() => {
    if (props.paginate) {
      let height = tableRef.current.clientHeight
      let newRows = Math.trunc(height / 35)
      setRowsPerPage(newRows)
    } else {
      setRowsPerPage(filteredData.length)
    }
  }, [filteredData, props.paginate, resize])

  useEffect(() => {
    window.onresize = function onresize() {
      setResize(!resize)
    }
  })

  useEffect(() => {
    const options = []

    filteredData.forEach((item) => {
      item.forEach((option, j) => {
        // Crear indice en caso de que no exista
        if (!options[j]) {
          options[j] = []
        }
        // Solo insertar elementos que sean diferente de null o un string vacio y que no existan en el array
        if (option && !options[j].includes(option)) {
          options[j].push(option)
        }
      })
    })

    const parsedOptions = []

    options.forEach((item, i) => {
      item.forEach((option) => {
        if (!parsedOptions[i]) {
          parsedOptions[i] = []
        }
        if (option) {
          parsedOptions[i].push({ label: option, value: option })
        }
      })
    })
    if (parsedOptions.length > 0) {
      setFilterOptions(parsedOptions)
    }
  }, [filteredData])

  async function modificarCelda(id, campo, valor, celda) {
    setLoadingCel([...loadingCel, celda])
    let response = await postRequest(
      '/estructura/participacion_academica/editar',
      {
        id: id,
        campo: campo,
        valor: valor,
      }
    )
    if (response.s === 'OK') {
      let eliminarLoadingCel = loadingCel.filter((editable) => {
        return editable !== celda
      })
      let myData = [...data]
      let [fila, columna] = celda.split(',')
      fila = parseInt(fila)
      columna = parseInt(columna)
      myData[fila][columna] = valor
      setLoadingCel(eliminarLoadingCel)
    } else {
      let eliminarLoadingCel = loadingCel.filter((editable) => {
        return editable !== celda
      })
      setLoadingCel(eliminarLoadingCel)
    }
  }

  function getRows() {
    let rows = []
    let maxLength =
      pageActual < Math.ceil(filteredData.length / rowsPerPage) - 1
        ? rowsPerPage * pageActual + rowsPerPage
        : filteredData.length
    if (filteredData.length > 0) {
      for (i = rowsPerPage * pageActual; i < maxLength; i++) {
        rows.push(
          ////////Genera un key aleatorio con 100000000 como valor maximo////////
          <TableRow key={Math.round(Math.random() * 100000000)}>
            {filteredData[i].map((cell, j) =>
              j < props.headers.length ? (
                props.numerosColumnasConSelect.includes(j) ||
                props.numerosColumnasconInput.includes(j) ? (
                  <TableCell
                    className={
                      j === props.headers.indexOf(props.resaltada)
                        ? 'ResaltarRow'
                        : ''
                    }
                    style={{ position: 'relative' }}
                    key={Math.round(Math.random() * 100000000)}
                  >
                    {editableCel.includes(i + ',' + j) ? (
                      <>
                        {props.numerosColumnasConSelect.includes(j) ? (
                          <>
                            <input
                              style={{ display: 'none' }}
                              id={'input-' + i + '-' + j}
                            />
                            <Select
                              options={props.listaOpciones[j]}
                              className='select-to-selected-table'
                              classNamePrefix='select-search'
                              id={'input-' + i + '-' + j}
                              name={'input-' + i}
                              onChange={(values, e) => {
                                let value = ''
                                if (props.multiples.includes(j)) {
                                  values.forEach((seleccionado, k) => {
                                    value = value + seleccionado.value
                                    if (k !== values.length - 1) {
                                      value = value + ','
                                    }
                                  })
                                } else {
                                  value = values.value
                                }
                                let fila = parseInt(e.name.split('-')[1])
                                document.getElementById(
                                  'input-' + fila + '-' + j
                                ).value = value
                              }}
                              isMulti={props.multiples.includes(j)}
                            />
                          </>
                        ) : (
                          <input
                            maxlength='30'
                            defaultValue={cell}
                            id={'input-' + i + '-' + j}
                            onChange={(e) => {
                              if (e.target.value.length > 30) {
                                e.preventDefault()
                              }
                            }}
                          />
                        )}
                      </>
                    ) : (
                      <>{cell}</>
                    )}
                    {loadingCel.includes(i + ',' + j) ? (
                      <>
                        <CircularProgress
                          className='icon-edit-save-cell'
                          size={20}
                          width={20}
                        />
                      </>
                    ) : (
                      <Can I='edit' a={props.permiso}>
                        <button
                          className='icon-edit-save-cell'
                          value={i}
                          onClick={(e) => {
                            if (
                              editableCel.includes(
                                parseInt(e.target.value) + ',' + j
                              )
                            ) {
                              let fila = parseInt(e.target.value)
                              let myId = data[fila][data[fila].length - 1]
                              let campoAEditar = props.campos[j]
                              let inputEditado = document.getElementById(
                                'input-' + fila + '-' + j
                              )
                              let valorNuevoCampo = inputEditado.value
                              let eliminarEditableCel = editableCel.filter(
                                (editable) => {
                                  return (
                                    editable !==
                                    parseInt(e.target.value) + ',' + j
                                  )
                                }
                              )
                              setEditableCel([...eliminarEditableCel])

                              modificarCelda(
                                myId,
                                campoAEditar,
                                valorNuevoCampo,
                                fila + ',' + j
                              )
                            } else {
                              setEditableCel([
                                ...editableCel,
                                parseInt(e.target.value) + ',' + j,
                              ])
                            }
                          }}
                        >
                          {editableCel.includes(i + ',' + j) ? (
                            <Save onClick={() => {}} size={10} />
                          ) : (
                            <Edit onClick={() => {}} size={5} />
                          )}
                        </button>
                      </Can>
                    )}
                  </TableCell>
                ) : (
                  <TableCell key={Math.round(Math.random() * 100000000)}>
                    {cell}
                  </TableCell>
                )
              ) : null
            )}
          </TableRow>
        )
      }
    } else {
      return null
    }
    return rows
  }

  function getPaginate() {
    let buttons = []
    buttons.push(
      <button
        key={Math.round(Math.random() * 10000)}
        onClick={() => {
          if (pageActual > 0) {
            setPageActual(pageActual - 1)
          }
        }}
      >
        Anterior
      </button>
    )

    if (Math.ceil(data.length / rowsPerPage) > 6) {
      if (pageActual <= 2) {
        for (i = 0; i < 3; i++) {
          buttons.push(
            <button
              onClick={setPageActual.bind(this, i)}
              className={pageActual === i ? 'active' : null}
            >
              {i + 1}
            </button>
          )
        }
        buttons.push(<button>...</button>)
      } else if (pageActual >= Math.ceil(data.length / rowsPerPage) - 3) {
        buttons.push(<button>...</button>)
        for (
          i = Math.ceil(data.length / rowsPerPage) - 3;
          i < Math.ceil(data.length / rowsPerPage);
          i++
        ) {
          buttons.push(
            <button
              onClick={setPageActual.bind(this, i)}
              className={pageActual === i ? 'active' : null}
            >
              {i + 1}
            </button>
          )
        }
      } else {
        buttons.push(<button>...</button>)
        for (i = pageActual - 2; i < pageActual + 3; i++) {
          buttons.push(
            <button
              onClick={setPageActual.bind(this, i)}
              className={pageActual === i ? 'active' : null}
            >
              {i + 1}
            </button>
          )
        }
        buttons.push(<button>...</button>)
      }
    } else {
      for (i = 0; i < Math.ceil(data.length / rowsPerPage); i++) {
        buttons.push(
          <button
            key={Math.round(Math.random() * 10000)}
            onClick={setPageActual.bind(this, i)}
            className={pageActual === i ? 'active' : null}
          >
            {i + 1}
          </button>
        )
      }
    }
    buttons.push(
      <button
        key={Math.round(Math.random() * 10000)}
        onClick={() => {
          if (pageActual < data.length / rowsPerPage - 1) {
            setPageActual(pageActual + 1)
          }
        }}
      >
        Siguiente
      </button>
    )

    return (
      <div
        className='contenedor-buttons'
        style={{ position: 'relative', bottom: '10px' }}
      >
        <button
          key={Math.round(Math.random() * 10000)}
          onClick={() => {
            if (pageActual > 0) {
              setPageActual(0)
            }
          }}
        >
          Inicio
        </button>
        {buttons}
        <button
          key={Math.round(Math.random() * 10000)}
          onClick={() => {
            if (pageActual < data.length / rowsPerPage - 1) {
              setPageActual(Math.floor(data.length / rowsPerPage))
            }
          }}
        >
          Fin
        </button>
      </div>
    )
  }

  function downloadReporte() {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    let dataDownload = []
    data.forEach((reg) => {
      let myReg = {}
      props.headers.forEach((header, i) => {
        myReg[header] = reg[i]
      })
      dataDownload.push(myReg)
    })

    const ws = XLSX.utils.json_to_sheet(dataDownload.map((row) => row))
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data2 = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(
      data2,
      `Reporte ${props.title} ${moment().format('YYYY-MM-DD_HH:mm')}.xlsx`
    )
  }

  const handleClick = (headerNumber) => {
    if (menuToShow === headerNumber) {
      setMenuToShow()
    } else {
      setMenuToShow(headerNumber)
    }
  }

  const handleApplyFilters = () => {
    if (selectedFilters.length > 0) {
      setIsFilterActive(true)

      let filtered = []

      selectedFilters.forEach((filter) => {
        filteredData.forEach((row) => {
          if (filter.value === row[filter.index]) {
            filtered = [...filtered, row]
          }
        })
      })
      setFilteredData(filtered)

      setSelectedFilters([])

      return
    }
    setFilteredData(data)
  }

  const handleDeleteFilters = () => {
    setFilteredData(data)
    setIsFilterActive(false)
  }

  return (
    <>
      <div
        ref={tableRef}
        style={
          props.paginate
            ? {
                height: 'calc(100% - 50px)',
                overflow: 'auto',
                position: 'relative',
                marginBottom: '10px',
              }
            : { height: '100%', overflow: 'auto', position: 'relative' }
        }
      >
        {props.loading ? (
          <></>
        ) : (
          <>
            <button
              onClick={downloadReporte}
              className='descargar-excel-selectedtable'
            >
              Descargar Excel
            </button>
            <button
              style={{ backgroundColor: '#1c1c21', left: '480px' }}
              onClick={handleApplyFilters}
              className='descargar-excel-selectedtable'
            >
              Aplicar Filtros
            </button>
            {isFilterActive && (
              <button
                className='descargar-excel-selectedtable'
                onClick={handleDeleteFilters}
                style={{ backgroundColor: '#1c1c21', left: '655px' }}
              >
                Borrar Filtros
              </button>
            )}
          </>
        )}

        <Table stickyHeader className='table-dash'>
          <TableHead className={props.dobleTabla ? 'doble-tabla' : null}>
            <TableRow>
              {props.headers.map((header, h) => {
                return (
                  <TableCell
                    key={header}
                    style={{ position: 'relative', height: '48px' }}
                    className={
                      header === props.resaltada
                        ? 'HeadCell HeadResltado'
                        : 'HeadCell'
                    }
                    col={i++}
                    onClick={(e) => {
                      if (!props.dobleTabla) {
                        let value = parseInt(e.target.getAttribute('col'))
                        if (value === orderBy.col) {
                          setOrderBy({ col: orderBy.col, asc: !orderBy.asc })
                        } else {
                          setOrderBy({ col: value, asc: true })
                        }
                      }
                    }}
                  >
                    {header}
                    {props.dobleTabla ? null : (
                      <>
                        <div className='HeadArrows'>
                          <ArrowDropUpIcon
                            style={
                              orderBy.col === i - 1 && orderBy.asc
                                ? { color: 'red' }
                                : null
                            }
                          />
                          <ArrowDropDownIcon
                            style={
                              orderBy.col === i - 1 && !orderBy.asc
                                ? { color: 'red' }
                                : null
                            }
                          />
                          <div>
                            <ArrowDropDownIcon onClick={() => handleClick(h)} />
                          </div>
                        </div>
                        <div
                          style={{
                            width: '100%',
                            backgroundColor: '#fff',
                            position: 'absolute',
                            left: '0',
                            top: '57px',
                            display: menuToShow === h ? 'block' : 'none',
                          }}
                        >
                          <Select
                            className='black-text'
                            options={filterOptions[h]}
                            onChange={(values, e) => {
                              if (e.action === 'remove-value') {
                                const filteredValues = selectedFilters.filter(
                                  (item) => item.value !== e.removedValue.value
                                )
                                setSelectedFilters(filteredValues)
                              } else {
                                values.forEach((value) => {
                                  value.index = h
                                  setSelectedFilters([
                                    ...selectedFilters,
                                    value,
                                  ])
                                })
                              }
                            }}
                            value={selectedFilters
                              .filter((item) => item.index === h)
                              .map((item) => ({
                                label: item.label,
                                value: item.value,
                              }))}
                            isMulti
                          />
                        </div>
                      </>
                    )}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          {props.loading ? null : <TableBody>{getRows()}</TableBody>}
        </Table>
        {props.loading ? (
          <div
            style={{
              textAlign: 'center',
              marginTop: '10px',
              position: 'absolute',
              width: '100%',
            }}
          >
            <CircularProgress size={50} />
          </div>
        ) : null}
        {data.length === 0 && !props.loading ? (
          <p
            style={{ textAlign: 'center', width: '100%', position: 'absolute' }}
          >
            No hay registros que mostrar.
          </p>
        ) : null}
      </div>
      {props.paginate ? getPaginate() : null}
    </>
  )
}

DataSelectTable.propTypes = {
  data: PropTypes.any,
  dobleTabla: PropTypes.bool,
  paginate: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  numerosColumnasConSelect: PropTypes.arrayOf(PropTypes.number),
  numerosColumnasconInput: PropTypes.arrayOf(PropTypes.number),
  listaOpciones: PropTypes.arrayOf(PropTypes.any),
  multiples: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.string,
  resaltada: PropTypes.string,
  campos: PropTypes.arrayOf(PropTypes.string),
}

export default DataSelectTable
