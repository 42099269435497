import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import Progress from '../components/Progress';
import Button from '../components/Button';
import IntervaloMobile from '../components/IntervaloMobile';

const Container = styled.div`
  margin: 0;
  @media screen and (max-width: 480px) {
    margin: 0;
  }
`;
const Name = styled.p`
  font-size: 24px;
  text-align: center;
`;
const Controls = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;

  @media screen and (max-width: 480px) {
    position: fixed;
    width: 91%;
    margin: 0 1rem;
  }
`;

export default function Intervalo({
  loading,
  agrupadores,
  asignaciones,
  questionIndex,
  setQuestionIndex,
  reactivos,
  rangeSelectionDesktop,
  setRangeSelectionDesktop,
  rangeSelection,
  setRangeSelection,
  answerData,
  setAnswerData,
  clearAnswers,
  type,
  setTipoActual,
  salir,
  tipoEvaluacion
}){
  const question = reactivos[questionIndex].pregunta;
  let name;
  let orden;
  agrupadores.forEach((agrupador) => {
    if (agrupador.reactivos.includes(reactivos[questionIndex])) {
      name = agrupador.nombre;
      orden = agrupador.orden;
    }
  });

  const respuestasActuales = answerData.respuestas.filter(
    (respuesta) =>
      respuesta.id_reactivo === reactivos[questionIndex].id &&
      respuesta.tipo === 'intervalo',
  );
  return (
    <>
      {
        loading ?
          <div style={{ textAlign: 'center', paddingTop: 30 }}>
            <CircularProgress size={50} />
          </div>
        :
        <Container>
          <Name>{`${orden}.${name}`}</Name>
          <p>{`${orden}.${reactivos[questionIndex].orden} ${question}`}</p>
          <IntervaloMobile 
            tipoEvaluacion={tipoEvaluacion}
            reactivos={reactivos}
            questionIndex={questionIndex}
            asignaciones={asignaciones}
            agrupadores={agrupadores}
            answerData={answerData}
            setAnswerData={setAnswerData}
          />
        </Container>
      }
      <Controls>
        <Progress 
          agrupadores={agrupadores}
          reactivos={reactivos}
          respuestasActuales={respuestasActuales}
          canContinue={true}
          step={questionIndex + 1}
          questionIndex={questionIndex}
          setQuestionIndex={setQuestionIndex}
          clearAnswers={clearAnswers}
          salir={salir}
          setTipoActual={setTipoActual}
         />
        <Button onClick={salir}/>
      </Controls>
    </>
  );
};


