import React, { useState, useEffect, useRef, useMemo } from 'react'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import { toast } from 'react-toastify'

import NewModal from '../../Components/Modal/NewModal'
import Despliegue from '../../Components/BotonDespliegue'
import Contenedor from '../../Components/Contenedor/Contenedor'
import SubContenedor from '../../Components/Contenedor/SubContenedor'
import PageTitle from '../../Components/PageTitle'
import EvaluacionCoordinador from './EvaluacionCoordinador'
import InfoIcon from '../../images/InfoIcon'
import { getRequest, postRequest } from '../../utils/requester'

import './style.css'

const EvaluacionesDocentes = () => {
  const searchInput = useRef(null)
  const tableModal = useRef(null)
  const [loading, setLoading] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [
    loadingEvaluacionCoordinador,
    setLoadingEvaluacionCoordinador,
  ] = useState(false)
  const [modalImageIsOpen, setModalImageIsOpen] = useState(false)
  const [imageUrl, setImageUrl] = useState('')
  const [modalEvaluacionCoordinador, setModalEvaluacionCoordinador] = useState(
    false
  )
  const [modalEditable, setModalEditable] = useState(true)
  const [detalleEvaluacion, setDetalleevaluacion] = useState({
    dimension: {},
    calendario: {},
  })
  const [loadingRevisionDocumentos, setLoadingRevisionDocumentos] = useState(
    false
  )
  const [
    detalleEvaluacionInstrumento,
    setDetalleEvaluacionInstrumento,
  ] = useState({
    agrupadores: [],
    coordinadores: [],
    publico: '',
  })
  const [evaluacionesGlobales, setEvaluacionesGlobales] = useState([])
  const [nivelPeriodo, setNivelPeriodo] = useState({})
  const [
    evaluacionesActualesDesplegadas,
    setEvaluacionesDocentesDesplegadas,
  ] = useState([])
  const [modalDetails, setModalDetails] = useState({})
  const [modalTableHeaders, setModalTableHeaders] = useState([])
  const [modalTableItems, setModalTableItems] = useState([])
  const [selectOptions, setSelectOptions] = useState([])
  const [search, setSearch] = useState([])
  const [headersTablaArchivo, setHeadersTablaArchivo] = useState([])
  const [dataTablaArchivo, setDataTablaArchivo] = useState([])
  const [modalArchivo, setModalArchivo] = useState(false)
  const [regId, setRegID] = useState('')
  const [archivosEstructura, setArchivosEstructura] = useState([])
  const [actualDataRefresh, setActualDataRefresh] = useState({})
  const [updated, setUpdated] = useState(false)

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    modalTableHeaders.forEach((header) => {
      if (header.nombre === 'Cumplimiento' && header.opciones.length > 0) {
        let myOpciones = []
        Object.keys(header.opciones_key_value).forEach((key) => {
          myOpciones.push([key, header.opciones_key_value[key]])
        })
        setSelectOptions(myOpciones)
      }
    })
  }, [modalTableHeaders])

  const getData = async () => {
    try {
      setLoading(true)
      const response = await getRequest('/evaluacion/coordinador')
      setLoading(false)
      if (response.s === 'OK') {
        setEvaluacionesGlobales(response.d.evaluaciones_globales)
        setNivelPeriodo(response.d['nivel-periodo'])
      }
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  const getModalData = async (
    tipo,
    id_dimension,
    id_calendar,
    id_integral_evaluation,
    id_instrumento
  ) => {
    setActualDataRefresh({
      tipo,
      id_dimension,
      id_calendar,
      id_integral_evaluation,
      id_instrumento,
    })
    try {
      setLoadingModal(true)
      setLoadingEvaluacionCoordinador(true)
      const response = await getRequest(
        `/evaluacion/coordinador/detalle?tipo=${tipo}&id_dimension=${id_dimension}&id_calendar=${id_calendar}&id_integral_evaluation=${id_integral_evaluation}&id_instrumento=${id_instrumento}`
      )
      setLoadingModal(false)

      if (id_instrumento) {
        if (response.s === 'OK') {
          setDetalleEvaluacionInstrumento(response.d.instrumento)
          response.d.id_evaluacion = id_dimension
          setDetalleevaluacion(response.d)
          setLoadingEvaluacionCoordinador(false)
        } else {
          setModalEvaluacionCoordinador(false)
          setLoadingEvaluacionCoordinador(false)
        }
      } else {
        if (response.s === 'OK') {
          setModalDetails(response.d.detalle)
        } else {
          setModalIsOpen(false)
          setModalEvaluacionCoordinador(false)
          setLoadingEvaluacionCoordinador(false)
        }
      }
      let editable = false
      if ('elementos' in response.d)
        response.d.elementos.forEach((elemento) => {
          editable = elemento.editable ? true : editable
        })
      setModalEditable(editable)
      setModalTableHeaders(
        response.d.elementos.sort((a, b) => a.order - b.order)
      )
      setModalTableItems(response.d.elementos_registrados)
    } catch (error) {
      setLoadingModal(false)
      console.error(error)
    }
  }

  const handleOpenModal = (
    tipo,
    id_dimension,
    id_calendar,
    id_integral_evaluation,
    id_instrumento
  ) => {
    setSearch('')
    getModalData(
      tipo,
      id_dimension,
      id_calendar,
      id_integral_evaluation,
      id_instrumento
    )

    id_instrumento ? setModalEvaluacionCoordinador(true) : setModalIsOpen(true)
  }

  const handleOpenModalImage = (url) => {
    setImageUrl(url)
    setModalImageIsOpen(true)
  }

  async function enviarRespuestaEvaluacionNivelPeriodo(respuestas) {
    let body = {
      tipo: 'nivel-periodo',
      id_evaluacion: detalleEvaluacion.id_evaluacion,
      id_calendario: detalleEvaluacion.calendario.id,
      id_dimension: detalleEvaluacion.dimension.id,
      id_evaluacion_integral: detalleEvaluacion.evaluacion_integral.id,
      id_instrumento: detalleEvaluacion.instrumento.id,
      elementos_registrados: respuestas,
    }
    setLoadingEvaluacionCoordinador(true)
    try {
      let response = await postRequest('/evaluacion/coordinador/editar', body)
      if (response.s === 'OK') {
        getData()
        toast.success(response.m)
        setLoadingEvaluacionCoordinador(false)
        setModalEvaluacionCoordinador(false)
      } else {
        setLoadingEvaluacionCoordinador(false)
        setModalEvaluacionCoordinador(false)
      }
    } catch (error) {
      console.error(error)
      setLoadingEvaluacionCoordinador(false)
    }
  }

  const handleSearch = () => {
    setSearch(searchInput.current.value)
  }

  const handleEdit = async () => {
    let elementosRegistrados = []

    const selects = [...modalTableItems]

    selects.forEach((item) => {
      elementosRegistrados = [
        ...elementosRegistrados,
        {
          id_docente: item.id ? Number(item.id) : '',
          Cumplimiento: item.Cumplimiento,
        },
      ]
    })

    elementosRegistrados = elementosRegistrados.filter(
      (elemento) =>
        elemento.Cumplimiento !== 'null' && elemento.Cumplimiento !== ''
    )

    const body = {
      id_evaluacion: modalDetails.id,
      elementos_registrados: elementosRegistrados,
      id_calendario: modalDetails.id_calendario,
      id_dimension: modalDetails.id_dimension,
      id_evaluacion_integral: modalDetails.id_evaluacion_integral,
      id_instrumento: modalDetails.id_instrumento,
      tipo: modalDetails.tipo,
    }

    try {
      setLoadingModal(true)
      const response = await postRequest('/evaluacion/coordinador/editar', body)
      if (response.s === 'OK') {
        getData()
        toast.success(response.m)
        setModalIsOpen(false)
      }
      setLoadingModal(false)
    } catch (error) {
      setLoadingModal(false)
      console.error(error)
    }
  }

  const filteredData = useMemo(
    () =>
      modalTableItems.filter((row) => {
        if (JSON.stringify(row).toLowerCase().includes(search.toLowerCase())) {
          return true
        }
        return false
      }),
    [search, modalTableItems]
  )

  function handleOpenModalArchivos(archivos, id) {
    let myData = []
    if (archivos.length > 0) {
      let myHeaders = []
      Object.keys(archivos[0]).forEach((key) => {
        if (key === 'id' || key === 'status') {
        } else {
          myHeaders.push(key)
        }
      })

      archivos.forEach((archivo) => {
        let myReg = []
        myHeaders.forEach((header) => {
          myReg.push(archivo[header])
        })
        myReg.push(archivo.status)
        myData.push(myReg)
      })
      myHeaders.push('Acciones')
      setHeadersTablaArchivo(myHeaders)
    }
    setRegID(id)
    setArchivosEstructura(archivos)
    setDataTablaArchivo(myData)
    setModalArchivo(true)
  }

  async function saveRevisionDocumentos() {
    let registrosCambiados = []
    dataTablaArchivo.forEach((reg, i) => {
      if (reg[reg.length - 1] !== '') {
        registrosCambiados.push({
          id_documento: archivosEstructura[i].id,
          status: reg[reg.length - 1],
          id_docente: regId,
        })
      }
    })
    if (registrosCambiados.length === 0) {
      toast.error('No se puede enviar datos vacios')
      return
    }
    setLoadingRevisionDocumentos(true)
    try {
      let response = await postRequest(
        '/evaluacion/coordinador/carga_documentos/editar',
        {
          elementos: registrosCambiados,
          id_calendario: modalDetails.id_calendario,
          id_dimension: modalDetails.id_dimension,
          id_evaluacion_integral: modalDetails.id_evaluacion_integral,
        }
      )
      if (response.s === 'OK') {
        toast.success(response.m)
        setLoadingRevisionDocumentos(false)
        setModalArchivo(false)
        setUpdated(true)
        console.log(actualDataRefresh)
        getModalData(
          actualDataRefresh.tipo,
          actualDataRefresh.id_dimension,
          actualDataRefresh.id_calendar,
          actualDataRefresh.id_integral_evaluation,
          actualDataRefresh.id_instrumento
        )
      } else {
        setLoadingRevisionDocumentos(false)
      }
    } catch (error) {
      console.error(error)
      toast.error('Error cambiando documentos')
      setLoadingRevisionDocumentos(false)
    }
  }

  const getCellBackgroud = (index1, index2) => {
    if (index1 % 2 === 0) {
      if (index2 % 2 === 0) {
        return '#ebebeb'
      } else {
        return '#fff'
      }
    } else {
      if (index2 % 2 === 0) {
        return '#dbdbdb'
      } else {
        return '#ebebeb'
      }
    }
  }

  return (
    <>
      <Contenedor contenedorPadre>
        <Grid style={{ height: '100%' }} container>
          <Grid item xs={12}>
            <SubContenedor title='Dimensiónes Generales'>
              <div style={{ height: 'calc(100vh - 194px)', overflow: 'auto' }}>
                {loading ? (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <CircularProgress size={50} />
                  </div>
                ) : (
                  <>
                    <div
                      style={{
                        width: '85%',
                        margin: 'auto',
                      }}
                    >
                      {evaluacionesGlobales.map((evaluacion) => (
                        <div
                          key={evaluacion.id}
                          style={{
                            display: 'grid',
                            gridTemplateColumns: '3fr 1fr 1fr',
                            width: '100%',
                            margin: '20px 0',
                          }}
                        >
                          <span
                            onClick={() =>
                              handleOpenModal(
                                'global',
                                evaluacion.id_dimension,
                                evaluacion.id_calendar,
                                evaluacion.id_integral_evaluation,
                                evaluacion.id_instrumento
                              )
                            }
                            className='Actualizacion-Profesional'
                          >
                            {evaluacion.nombre}
                          </span>
                          <div className='progress-bar-container'>
                            <span style={{ color: 'grey', fontSize: '17px' }}>
                              {evaluacion.porcentaje_avance[0].nombre}
                            </span>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '3px',
                              }}
                            >
                              <div className='progress-bar'>
                                <div
                                  className='progress'
                                  style={{
                                    width: `${
                                      100 *
                                      (evaluacion.porcentaje_avance[0].valor /
                                        evaluacion.porcentaje_avance[0].total)
                                    }%`,
                                  }}
                                ></div>
                              </div>
                              <span className='percent'>
                                {evaluacion.porcentaje_avance[0].valor}
                                {evaluacion.porcentaje_avance[0].tipo ===
                                'porcentaje'
                                  ? '%'
                                  : ''}
                              </span>
                            </div>
                          </div>
                          <div
                            className='progress-bar-container'
                            style={{ marginLeft: '25px' }}
                          >
                            <span style={{ color: 'grey', fontSize: '17px' }}>
                              {evaluacion.porcentaje_avance[1].nombre}
                            </span>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '3px',
                              }}
                            >
                              <div className='progress-bar'>
                                <div
                                  className='progress'
                                  style={{
                                    width: `${
                                      100 *
                                      (evaluacion.porcentaje_avance[1].valor /
                                        evaluacion.porcentaje_avance[1].total)
                                    }%`,
                                  }}
                                ></div>
                              </div>
                              <span className='percent'>
                                {evaluacion.porcentaje_avance[1].valor}
                                {evaluacion.porcentaje_avance[1].tipo ===
                                'porcentaje'
                                  ? '%'
                                  : ''}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <PageTitle title='Dimensiónes por Periodo' width='unset' />
                    {Object.entries(nivelPeriodo).map((reg, i) => {
                      const name = reg[0]
                      const data = reg[1]
                      const titles = Object.keys(data)

                      return (
                        <Despliegue
                          key={i}
                          size='medio'
                          height={600}
                          desplegada={evaluacionesActualesDesplegadas.includes(
                            name
                          )}
                          nombre={name}
                          open={() => {
                            if (
                              evaluacionesActualesDesplegadas.includes(name)
                            ) {
                              let nuevosDesplegados = evaluacionesActualesDesplegadas.filter(
                                (nivel) => {
                                  return name !== nivel
                                }
                              )
                              setEvaluacionesDocentesDesplegadas([
                                ...nuevosDesplegados,
                              ])
                            } else {
                              setEvaluacionesDocentesDesplegadas([
                                ...evaluacionesActualesDesplegadas,
                                name,
                              ])
                            }
                          }}
                        >
                          <div>
                            {titles.map((title) => (
                              <>
                                <div className='section-title-container'>
                                  <span className='section-title'>
                                    {title.toUpperCase()}
                                  </span>
                                </div>
                                {data[`${title}`].map((item) => (
                                  <div
                                    key={item.id}
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <p className='registro-materia-resultados'>
                                      <span
                                        onClick={() =>
                                          handleOpenModal(
                                            'nivel-periodo',
                                            item.id_dimension,
                                            item.id_calendar,
                                            item.id_integral_evaluation,
                                            item.id_instrumento
                                          )
                                        }
                                        className='Actualizacion-Profesional'
                                      >
                                        {item.nombre}
                                      </span>
                                      <span
                                        onClick={() =>
                                          handleOpenModal(
                                            'nivel-periodo',
                                            item.id_dimension,
                                            item.id_calendar,
                                            item.id_integral_evaluation,
                                            item.id_instrumento
                                          )
                                        }
                                        className='estatus-actualizacion'
                                      ></span>
                                      <InfoIcon
                                        width={16}
                                        onClick={() =>
                                          handleOpenModalImage(
                                            item.url_img_rubrica
                                          )
                                        }
                                      />
                                    </p>
                                    <div className='progress-bar-container'>
                                      <span
                                        style={{
                                          color: 'grey',
                                          fontSize: '17px',
                                        }}
                                      >
                                        {item.porcentaje_avance[0].nombre}
                                      </span>
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          marginTop: '3px',
                                        }}
                                      >
                                        <div className='progress-bar'>
                                          <div
                                            className='progress'
                                            style={{
                                              width: `${
                                                100 *
                                                (item.porcentaje_avance[0]
                                                  .valor /
                                                  item.porcentaje_avance[0]
                                                    .total)
                                              }%`,
                                            }}
                                          ></div>
                                        </div>
                                        <span className='percent'>
                                          {item.porcentaje_avance[0].valor}
                                          {item.porcentaje_avance[0].tipo ===
                                          'porcentaje'
                                            ? '%'
                                            : ''}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </>
                            ))}
                          </div>
                        </Despliegue>
                      )
                    })}
                  </>
                )}
              </div>
            </SubContenedor>
          </Grid>
        </Grid>
      </Contenedor>
      <NewModal
        title={modalDetails.nombre}
        open={modalIsOpen}
        handleClose={() => {
          setModalIsOpen(false)
          updated && getData()
        }}
        handleSubmit={handleEdit}
        loading={loadingModal}
        noSaveBtn={!modalEditable}
        width='80vw'
      >
        <div style={{ paddingTop: '7px' }}>
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: '#eee',
              display: 'grid',
            }}
          >
            <p key='0' style={{ marginLeft: '30px' }}>
              <span
                style={{ color: 'gray', fontWeight: 600, marginRight: '5px' }}
              >
                Descripción:
              </span>
              <span style={{ fontWeight: 600 }}>
                {modalDetails.descripcion}
              </span>
            </p>
          </div>
        </div>
        <div style={{ margin: '20px 10px', height: '80%' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <input
                  ref={searchInput}
                  value={search}
                  onChange={handleSearch}
                  type='text'
                  placeholder='BUSCAR'
                  className='input-busqueda'
                />
              </div>
              <div
                style={{
                  maxHeight: '275px',
                  overflowY: 'scroll',
                  marginBottom: '25px',
                }}
              >
                <table
                  ref={tableModal}
                  style={{ borderCollapse: 'collapse', width: '100%' }}
                >
                  <thead>
                    <tr>
                      {modalTableHeaders.map((header, i) => (
                        <th
                          key={header.id}
                          style={{
                            backgroundColor:
                              i % 2 === 0 ? '#c7c7c7' : '#d2d2d2',
                            padding: '17px 40px',
                          }}
                        >
                          {header.nombre === 'Cumplimiento'
                            ? 'Estatus'
                            : header.nombre}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((thisItem, i) => (
                      <tr key={i}>
                        {modalTableHeaders.map((header, j) => {
                          if (header.tipo_dato === 'select') {
                            return (
                              <td
                                style={{
                                  backgroundColor: getCellBackgroud(i, j),
                                  padding: '4px',
                                  fontWeight: '600',
                                }}
                              >
                                <select
                                  id={thisItem.id}
                                  defaultValue={thisItem[header.nombre]}
                                  value={thisItem[header.nombre]}
                                  onChange={(e) => {
                                    let myData = [...modalTableItems]
                                    myData.find(
                                      (item) => item.id === thisItem.id
                                    )[header.nombre] = e.target.value
                                    setModalTableItems(myData)
                                  }}
                                  className='select-cumplimiento'
                                  style={{
                                    borderRadius: '10px',
                                    padding: '0 5px',
                                  }}
                                >
                                  {selectOptions.map((option, i) => {
                                    if (thisItem.Cumplimiento === option) {
                                      return (
                                        <option
                                          selected
                                          key={i}
                                          value={option[0]}
                                          disabled={option[1] === ''}
                                        >
                                          {option[1]}
                                        </option>
                                      )
                                    } else {
                                      return (
                                        <option
                                          key={i}
                                          disabled={option[1] === ''}
                                          value={option[0]}
                                        >
                                          {option[1]}
                                        </option>
                                      )
                                    }
                                  })}
                                </select>
                              </td>
                            )
                          } else if (header.tipo_dato === 'button') {
                            return (
                              <td
                                style={{
                                  backgroundColor: getCellBackgroud(i, j),
                                  padding: '4px',
                                  fontWeight: '600',
                                  textAlign: 'center',
                                }}
                              >
                                <button
                                  onClick={() => {
                                    handleOpenModalArchivos(
                                      thisItem.archivos,
                                      thisItem.id
                                    )
                                  }}
                                  className='NewButton'
                                  style={{
                                    fontSize: 12,
                                    textAlign: 'center',
                                    margin: 'auto',
                                  }}
                                >
                                  Ver Archivos
                                </button>
                              </td>
                            )
                          } else {
                            return (
                              <td
                                style={{
                                  backgroundColor: getCellBackgroud(i, j),
                                  padding: '4px',
                                  fontWeight: '600',
                                }}
                              >
                                {header.nombre === 'Cumplimiento'
                                  ? thisItem.archivos.every(
                                      ({ status }) => status !== ''
                                    )
                                    ? 'Completo'
                                    : 'Pendiente'
                                  : thisItem[`${header.nombre}`]}
                              </td>
                            )
                          }
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Grid>
          </Grid>
        </div>
      </NewModal>

      <NewModal
        title={'Revisar documentos'}
        open={modalArchivo}
        handleClose={() => {
          setModalArchivo(false)
        }}
        handleSubmit={() => {
          saveRevisionDocumentos()
        }}
        loading={loadingRevisionDocumentos}
        width='90%'
      >
        <div style={{ paddingTop: '7px' }}>
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: '#eee',
              display: 'grid',
            }}
          >
            <p key='0' style={{ marginLeft: '30px' }}>
              <span
                style={{ color: 'gray', fontWeight: 600, marginRight: '5px' }}
              >
                Descripción:
              </span>
              <span style={{ fontWeight: 600 }}>
                {modalDetails.descripcion}
              </span>
            </p>
          </div>
        </div>
        <div style={{ margin: '20px 10px', height: '80%' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              ></div>
              <div
                style={{
                  height: '300px',
                  overflowY: 'scroll',
                  marginBottom: '25px',
                }}
              >
                <table style={{ borderCollapse: 'collapse', margin: '0 auto' }}>
                  <thead>
                    <tr>
                      {headersTablaArchivo.map((header, i) => (
                        <th
                          key={i}
                          style={{
                            backgroundColor:
                              i % 2 === 0 ? '#c7c7c7' : '#d2d2d2',
                            padding: '17px 40px',
                          }}
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {dataTablaArchivo.map((thisItem, i) => (
                      <tr key={i}>
                        {headersTablaArchivo.map((_header, j) => {
                          if (j === headersTablaArchivo.length - 1) {
                            return (
                              <td
                                style={{
                                  backgroundColor: getCellBackgroud(i, j),
                                  padding: '4px',
                                  fontWeight: '600',
                                }}
                              >
                                <select
                                  defaultValue={thisItem[j]}
                                  onChange={(e) => {
                                    let myData = [...dataTablaArchivo]
                                    myData[i][j] = e.target.value
                                    setDataTablaArchivo(myData)
                                  }}
                                  className='select-cumplimiento'
                                  style={{
                                    borderRadius: '10px',
                                    padding: '0 5px',
                                  }}
                                >
                                  <option value='Aceptado'>Aceptado</option>
                                  <option value='Rechazado'>Rechazado</option>
                                  <option disabled value=''></option>
                                </select>
                              </td>
                            )
                          } else {
                            return (
                              <td
                                style={{
                                  backgroundColor: getCellBackgroud(i, j),
                                  padding: '4px',
                                  fontWeight: '600',
                                }}
                              >
                                {String(thisItem[j]).includes('http') ? (
                                  <button
                                    className='delete-reg-eval'
                                    onClick={() => {
                                      window.open(thisItem[j])
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    Descargar
                                  </button>
                                ) : (
                                  thisItem[j]
                                )}
                              </td>
                            )
                          }
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Grid>
          </Grid>
        </div>
      </NewModal>

      <NewModal
        title='Información Rúbrica'
        open={modalImageIsOpen}
        handleClose={() => {
          setModalImageIsOpen(false)
        }}
        noSaveBtn
        width='50%'
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            overflowY: 'scroll',
            padding: '70px 10px 90px',
          }}
        >
          <img style={{ width: '100%' }} src={imageUrl} alt='imagen rubrica' />
        </div>
      </NewModal>
      <EvaluacionCoordinador
        enviarRespuestaEvaluacionNivelPeriodo={
          enviarRespuestaEvaluacionNivelPeriodo
        }
        detalleEvaluacionInstrumento={detalleEvaluacionInstrumento}
        detalleEvaluacion={detalleEvaluacion}
        modalEvaluacionCoordinador={modalEvaluacionCoordinador}
        setModalEvaluacionCoordinador={setModalEvaluacionCoordinador}
        loadingEvaluacionCoordinador={loadingEvaluacionCoordinador}
      />
    </>
  )
}

export default EvaluacionesDocentes
