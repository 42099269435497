/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import LinearProgress from '@material-ui/core/LinearProgress'
import CircularProgress from '@material-ui/core/CircularProgress'
import { toast } from 'react-toastify'

import Contenedor from '../../Components/Contenedor/Contenedor'
import MultiSelect from '../../Components/MultiSelect'
import { getRequest, postRequest } from '../../utils/requester'
import './style.css'

function DetalleInstrumento({ listaCampus }) {
  const [periodo, setPeriodo] = useState({})
  const [year, setYear] = useState({})
  const [ciclo, setCiclo] = useState({})
  const [campus] = useState({})
  const [nivel, setNivel] = useState({})
  const [tipoProfesor, setTipoProfesor] = useState({})
  const [loading, setLoading] = useState(false)
  const [generado, setGenerado] = useState(false)
  const [years, setYears] = useState([])
  const [campusOptions, setCampusOptions] = useState([])
  const [periodoOptions, setPeriodoOptions] = useState([])
  const [nivelOptions, setNivelOptions] = useState([])
  const [procesandoReporte, setProcesandoReporte] = useState(false)
  const [urlDownload, setURLDownload] = useState('')
  const [progreso, setProgreso] = useState(0)
  const [quantityCampus, setQuantityCampus] = useState(0)
  const [valueCampus, setValueCampus] = useState('')
  const [tipoPeriodo, setTipoPeriodo] = useState({
    label: 'Abierto',
    value: 'abierto',
  })
  const [periodos, setPeriodos] = useState([])
  const [calendarios, setCalendarios] = useState([])
  const [calendario, setCalendario] = useState({})
  const [estatus, setEstatus] = useState({
    label: 'Incompleto',
    value: 'incompleto',
  })
  const [loadingPeriodos, setLoadingPeriodos] = useState(false)
  const [loadingCalendarios, setLoadingCalendarios] = useState(false)

  useEffect(() => {
    //reloadList()
    getNivelOptions()

    const lista = listaCampus
      .sort((a, b) => {
        if (a.nombre < b.nombre) {
          return -1
        }
        if (a.nombre > b.nombre) {
          return 1
        }
        return 0
      })
      .map(({ nombre, id }) => ({ label: nombre, value: String(id) }))

    lista.unshift({
      label: 'TODOS',
      value: '0',
    })

    setCampusOptions(lista)
  }, [])

  useEffect(() => {
    const myOpcionesYears = []
    const actualYear = new Date().getFullYear()
    for (let i = actualYear - 4; i <= actualYear; i++) {
      myOpcionesYears.push({
        label: String(i),
        value: String(i),
      })
    }
    setYears(myOpcionesYears)
    setYear({
      label: String(actualYear),
      value: String(actualYear),
    })
  }, [])

  useEffect(() => {
    if (year.value !== undefined) {
      getPeriodos()
    }
  }, [year])

  const getPeriodoOptions = async () => {
    setLoading(true)
    try {
      const response = await getRequest(
        `/calendario/periodo?year=${year.value}`
      )
      if (response.s === 'OK') {
        const periodos = response.d.map((periodo) => ({
          label: periodo,
          value: periodo,
        }))
        periodos.unshift({
          label: '',
          value: undefined,
        })
        setPeriodoOptions(periodos)
        setLoading(false)
      } else {
        setPeriodoOptions([])
        setLoading(false)
      }
    } catch (error) {
      setPeriodoOptions([])
      console.error(error)
      setLoading(false)
    }
  }

  const getNivelOptions = async () => {
    setLoading(true)
    try {
      const response = await getRequest('/calendario/nivel')
      if (response.s === 'OK') {
        const niveles = response.d.map(({ nombre }) => ({
          label: nombre,
          value: nombre,
        }))
        niveles.unshift({
          label: '',
          value: undefined,
        })
        setNivelOptions(niveles)
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  async function getCalendarios() {
    setLoadingCalendarios(true)
    let url = ''
    if(tipoPeriodo.value === 'abierto'){
      url = '/calendario?periodo=' +
      periodo.value +
      '&tipo=' +
      tipoPeriodo.value
    }else{
      url = '/calendario?year=' +
      year.value +
      '&periodo=' +
      periodo.value +
      '&tipo=' +
      tipoPeriodo.value

    }
    const response = await getRequest(url)
    if (response.s === 'OK') {
      const myPeriodos = []
      response.d.forEach((reg) => {
        myPeriodos.push({
          value: reg.name,
          label: reg.name,
        })
      })
      setCalendario({})
      setCalendarios(myPeriodos)
      setLoadingCalendarios(false)
    } else {
      setCalendario({})
      setCalendarios([])
      setLoadingCalendarios(false)
    }
  }

  useEffect(() => {
    if (periodo.value !== undefined) {
      getCalendarios()
    }
  }, [periodo])


  useEffect(() => {
    if(tipoPeriodo.value === 'abierto'){
      getPeriodos()
    }else{
      getPeriodos()
    }
  }, [tipoPeriodo])


  const cicloOptions = [
    {
      label: 'C1',
      value: 'C1',
    },
    {
      label: 'C2',
      value: 'C2',
    },
    {
      label: 'C3',
      value: 'C3',
    },
  ]

  const tipoProfesorOptions = [
    {
      label: '',
      value: undefined,
    },
    {
      label: 'PTC',
      value: 'PTC',
    },
    {
      label: 'ASI',
      value: 'ASI',
    },
    {
      label: 'FAC',
      value: 'FAC',
    },
  ]

  async function getReporte() {
    setLoading(true)
    setGenerado(true)
    try {
      const response = await postRequest('/reportes/detalle_instrumento', {
        campus: valueCampus.split(',').map((id) => parseInt(id)),
        ciclo: ciclo.value,
        year: parseInt(year.value),
        periodo: periodo.value,
        calendario: calendario.value,
        accion: 'generar',
      })
      if (response.s === 'OK') {
        setLoading(false)
        progresGenerando(response.d.id_rastreo, '', 0, 100, 1, 0)
        setProcesandoReporte(true)
        toast.success('Generando reporte')
      } else {
        setLoading(false)
      }
    } catch (e) {
      toast.error('Error procesando reporte')
      setLoading(false)
      console.log(e)
    }
  }

  function progresGenerando(id, id_job, _myProgreso, _timer, vez, time) {
    setTimeout(function () {
      postRequest('/reportes/detalle_instrumento', {
        campus: valueCampus.split(',').map((id) => parseInt(id)),
        ciclo: ciclo.value,
        year: parseInt(year.value),
        periodo: periodo.value,
        nivel: nivel.value,
        tipo_profesor: tipoProfesor.value,
        accion: 'verificar',
        id_location: parseInt(id),
        id_job: parseInt(id_job === '' ? null : parseInt(id_job)),
        calendario: calendario.value,
      }).then((response) => {
        if (response.s === 'OK') {
          if (response.d.result === 'SUCCESS') {
            toast.success('Reporte obtenido correctamente')
            setURLDownload(response.d.url)
            setProcesandoReporte(false)
            setProgreso(0)
          } else if (response.d.result === 'FAILURE') {
            setProcesandoReporte(false)
            toast.error('Error al generar reporte')
            setProgreso(0)
            return
          } else {
            let duracion = 10000
            if (isNaN(response.d.estimatedDuration)) {
              vez = 0
              duracion = 2000
            }
            let actualProgreso =
              vez < 1 ? 0 : ((10 * vez) / response.d.estimatedDuration) * 100

            setProgreso(actualProgreso < 100 ? actualProgreso : 100)
            progresGenerando(
              id,
              response.d.id_job,
              actualProgreso,
              response.d.estimatedDuration,
              vez + 1,
              duracion
            )
          }
        } else {
        }
      })
    }, time)
  }

  const tipoPeriodoOptions = [
    {
      label: 'Abierto',
      value: 'abierto',
    },
    {
      label: 'Cerrado',
      value: 'cerrado',
    },
  ]
  const estatusOptions = [
    {
      label: 'Completo',
      value: 'completo',
    },
    {
      label: 'Incompleto',
      value: 'incompleto',
    },
  ]

  async function getPeriodos() {
    setLoadingPeriodos(true)
    let url = ""
    if(tipoPeriodo.value === 'abierto'){
      url = "/calendario/periodo?status=abierto"
    }else{
      url = '/calendario/periodo?year=' + year.value
    }
    const response = await getRequest(url);
    if (response.s === 'OK') {
      const myPeriodos = []
      response.d.forEach((reg) => {
        myPeriodos.push({
          value: reg,
          label: reg,
        })
      })
      setPeriodos(myPeriodos)
      setLoadingPeriodos(false)
    } else {
      setPeriodos([])
      setPeriodo({})
      setLoadingPeriodos(false)
    }
  }

  return (
    <Contenedor title='Detalle Instrumento'>
      <div className='filtros-tabla-completa' style={{ float: 'left' }}>
        <div className='sub-filtro'>
          <p>FILTROS</p>
        </div>
        <div className='sub-filtro'>
          <p>Campus(*):</p>
          <MultiSelect
            fromUser={false}
            loading={false}
            options={campusOptions}
            setCantidad={setQuantityCampus}
            cantidad={quantityCampus}
            onSet={setValueCampus}
            selecteds={valueCampus}
            value={campus}
          />
        </div>
        <div className='sub-filtro'>
          <p>Tipo Periodo:</p>
          <Select
            options={tipoPeriodoOptions}
            className='select-periodo'
            classNamePrefix='select-search'
            value={tipoPeriodo}
            onChange={setTipoPeriodo}
          />
        </div>
        {tipoPeriodo.value === 'cerrado' ? (
          <>
            <div className='sub-filtro'>
              <p>Año:</p>
              <Select
                options={years}
                style={{ width: '50px' }}
                className='select-periodo small-bar'
                classNamePrefix='select-search'
                value={year}
                onChange={setYear}
              />
            </div>
            <div className='sub-filtro'>
              <p>Ciclo:</p>
              <Select
                options={cicloOptions}
                style={{ width: '50px' }}
                className='select-periodo small-bar'
                classNamePrefix='select-search'
                value={ciclo}
                onChange={setCiclo}
              />
            </div>
            <div className='sub-filtro'>
              <p>Periodo:</p>
              {loadingPeriodos ? (
                <CircularProgress />
              ) : (
                <Select
                  options={periodos}
                  className='select-periodo'
                  classNamePrefix='select-search'
                  value={periodo}
                  onChange={setPeriodo}
                />
              )}
            </div>
            <div className='sub-filtro'>
              <p>Calendario:</p>
              {loadingCalendarios ? (
                <CircularProgress />
              ) : (
                <Select
                  options={calendarios}
                  className='select-periodo'
                  classNamePrefix='select-search'
                  value={calendario}
                  onChange={setCalendario}
                />
              )}
            </div>
          </>
        ) : (
          <>
            <div className='sub-filtro'>
              <p>Periodo:</p>
              {loadingPeriodos ? (
                <CircularProgress />
              ) : (
                <Select
                  options={periodos}
                  className='select-periodo'
                  classNamePrefix='select-search'
                  value={periodo}
                  onChange={setPeriodo}
                />
              )}
            </div>
            <div className='sub-filtro'>
              <p>Calendario:</p>
              {loadingCalendarios ? (
                <CircularProgress />
              ) : (
                <Select
                  options={calendarios}
                  className='select-periodo'
                  classNamePrefix='select-search'
                  value={calendario}
                  onChange={setCalendario}
                />
              )}
            </div>
          </>
        )}

        <div className='sub-filtro'>
          <button
            className='NewButton'
            onClick={loading ? () => {} : getReporte}
          >
            Generar
          </button>
        </div>
      </div>
      <div className='opciones-bar' style={{ marginTop: '120px' }}></div>
      <div style={{ height: 'calc(100% - 202px)' }}>
        {generado ? (
          <div style={{ height: '100%' }}>
            {loading ? (
              <div style={{ marginLeft: '50%', marginTop: '90px' }}>
                <CircularProgress />
              </div>
            ) : procesandoReporte ? (
              <>
                <div style={{ textAlign: 'center' }}>
                  <p style={{ textAlign: 'center', fontSize: '22px' }}>
                    Generando reporte
                  </p>
                  <CircularProgress />
                </div>
                <div id='barra-progreso'>
                  <LinearProgress variant='determinate' value={progreso} />
                </div>
              </>
            ) : (
              <>
                {urlDownload === '' ? (
                  <>
                    <p style={{ textAlign: 'center', fontSize: '22px' }}>
                      No se ha generado reporte
                    </p>
                  </>
                ) : (
                  <button
                    onClick={() => {
                      window.open(urlDownload)
                    }}
                    style={{ margin: 'auto' }}
                    className='NewButton'
                  >
                    Descargar excel
                  </button>
                )}
              </>
            )}
          </div>
        ) : (
          <p style={{ textAlign: 'center', fontSize: '22px' }}>
            Aplica los filtros para generar el Reporte
          </p>
        )}
      </div>
    </Contenedor>
  )
}

const mapStateToProps = (state) => ({
  listaCampus: state.listaCampus,
})

export default connect(mapStateToProps)(DetalleInstrumento)
