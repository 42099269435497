/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import Grid from '@material-ui/core/Grid'

export default function Permisos({
  changePermiso,
  valoresPermisos,
  selectAll,
  deselectAll,
}) {
  return (
    <>
      <Grid container id='contenedor-lista-permisos'>
        <Grid xs={12}>
          <button
            className='contenedor-lista-permisos__button'
            onClick={deselectAll}
          >
            Deseleccionar todos
          </button>
          <button
            className='contenedor-lista-permisos__button'
            onClick={selectAll}
          >
            Seleccionar todos
          </button>
        </Grid>
        {valoresPermisos.map((reg_tipo) => (
          <div className='permisos-contenerdor'>
            <div className='permisos-contenerdor__info'>
              <h1
                className='view-title'
                style={{ width: '94%', margin: '12px 0' }}
              >
                {Object.keys(reg_tipo)}
              </h1>
              <div className='los-permisos'>
                {reg_tipo[Object.keys(reg_tipo)].map((reg, j) => {
                  return (
                    <>
                      <label className='permisos-contenerdor__info_subtitle'>
                        {reg.label ? reg.label : reg.permiso.replace('_', ' ')}:
                      </label>
                      <Grid xs={12} container className='los-permisos'>
                        {reg.permisos &&
                          reg.permisos.map((permiso) => (
                            <>
                              {
                                // reg.permiso.split('_')[0] == 'reportes' && (permiso !== 'leer' && permiso != 'todos') ?
                                // 	null :
                                <>
                                  <input
                                    className='permisos-contenerdor__input'
                                    checked={
                                      reg[permiso] === '1' ? true : false
                                    }
                                    disabled={
                                      permiso !== 'leer' &&
                                      !(reg['leer'] === '1' ? true : false) &&
                                      permiso !== 'todos'
                                    }
                                    onChange={(e) => {
                                      changePermiso(
                                        Object.keys(reg_tipo),
                                        j,
                                        permiso,
                                        e.target.checked
                                      )
                                    }}
                                    type='checkbox'
                                  />
                                  {permiso}
                                </>
                              }
                            </>
                          ))}
                      </Grid>
                    </>
                  )
                })}
              </div>
            </div>
          </div>
        ))}
      </Grid>
    </>
  )
}
