/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { toast } from 'react-toastify'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

import Despliegue from '../../Components/BotonDespliegue'
import Contenedor from '../../Components/Contenedor/Contenedor'
import SubContenedor from '../../Components/Contenedor/SubContenedor'
import DetalleAsignacion from './DetalleAsignacion'
import DetalleIntegral from './DetalleIntegral'
import EvaluacionCoordinador from './EvaluacionCoordinador'
import ActualizacionProfesional from './ActualizacionProfesional'
import { getRequest, postRequest } from '../../utils/requester'

import './style.css'

const MisEvaluaciones = () => {
  const [, setFiltroYear] = useState('')
  const [filtroCiclo, setFiltroCiclo] = useState('')
  const [resultadosActuales, setResultadosActuales] = useState([])
  const [resultadosHistoricos, setResultadosHistoricos] = useState([])
  const [evaluacionesActuales, setEvaluacionesActuales] = useState({
    resultados_globales: [],
    resultados_nivel_periodo: [],
  })
  const [modalDetalleAsignacion, setModalDetalleAsignacion] = useState(false)
  const [loadingResultadosActuales, setLoadingResultadosActuales] =
    useState(false)
  const [loadingResultadosHistoricos, setLoadingResultadosHistoricos] =
    useState(false)
  const [loadingEvaluacionesActuales, setLoadingEvaluacionesActuales] =
    useState(false)
  const [loadingEvaluacionGlobal, setLoadingEvaluacionGlobal] = useState(false)
  const [years, setYears] = useState([])
  const [year, setYear] = useState({})
  const [loadingDetalleAsignacion, setLoadingDetalleAsignacion] =
    useState(false)
  const [detalleAsignacion, setDetalleAsignacion] = useState({
    detalle: {
      resultados: {},
    },
    dimensiones: [],
  })
  const [modalDetalleIntegral, setModalDetalleIntegral] = useState(false)
  const [loadingDetalleIntegral, setLoadingDetalleIntegral] = useState(false)
  const [detalleIntegral, setDetalleIntegral] = useState({
    detalle: {
      resultados: {},
    },
    dimensiones: [],
    resultado_integral: {},
  })
  const [modalActualizacionProfesional, setModalActualizacionProfesional] =
    useState(false)
  const [nivelesActualesDesplegados, setNivelesActualesDesplegados] = useState(
    []
  )
  const [nivelesHistoricosDesplegados, setNivelesHistoricosDesplegados] =
    useState([])
  const [evaluacionesActualesDesplegadas, setEvaluacionesActualesDesplegadas] =
    useState([])
  const [modalEvaluacionCoordinador, setModalEvaluacionCoordinador] =
    useState(false)
  const [loadingEvaluacionCoordinador, setLoadingEvaluacionCoordinador] =
    useState(false)
  const [detalleEvaluacionInstrumento, setDetalleEvaluacionInstrumento] =
    useState({
      agrupadores: [],
      coordinadores: [],
      publico: '',
    })
  const [detalleEvaluacion, setDetalleevaluacion] = useState({
    dimension: {},
    calendario: {},
  })
  const [deleteados, setDeletados] = useState([])
  const [detalleEvaluacionGlobal, setDetalleEvaluacionGlobal] = useState({
    elementos: [],
    elementos_registrados: [],
    headers: [],
    data: [],
  })
  const [materiaIntegralId, setMaterialIntegralId] = useState('')
  const [resultadosHistoricosExist, setResultadosHistoricosExist] =
    useState(true)
  const [resultadosActualesExist, setResultadosActualesExist] = useState(true)
  const [hideFirmarButton, setHideFirmarButton] = useState(false)
  const [nivelActual, setNivelActual] = useState('')

  useEffect(() => {
    const myOpcionesYears = []
    const actualYear = new Date().getFullYear()
    for (let i = actualYear - 3; i <= actualYear; i++) {
      myOpcionesYears.push({
        label: String(i),
        value: String(i),
      })
    }
    setYears(myOpcionesYears)
    setYear({
      label: String(actualYear),
      value: String(actualYear),
    })
    loadData()
  }, [])

  useEffect(() => {
    const myDeleteados = []
    detalleEvaluacionGlobal.data.forEach((reg) => {
      if (
        deleteados.includes(parseInt(reg[reg.length - 1])) ||
        deleteados.includes(reg[reg.length - 1])
      ) {
      } else {
        myDeleteados.push(reg)
      }
    })
    setDetalleEvaluacionGlobal({
      ...detalleEvaluacionGlobal,
      data: myDeleteados,
    })
  }, [deleteados])

  const valoresCiclos = [
    { label: 'C1', value: 'C1' },
    { label: 'C2', value: 'C2' },
    { label: 'C3', value: 'C3' },
  ]

  const token = localStorage.getItem('token_sieda')

  async function loadData() {
    setLoadingEvaluacionesActuales(true)
    setLoadingResultadosActuales(true)

    try {
      const response = await getRequest('/evaluacion/docente')
      if (response.s === 'OK') {
        const myResultadosActuales = []
        Object.keys(response.d.resultados_actuales).forEach((key) => {
          myResultadosActuales.push({
            nivel: key,
            materias: response.d.resultados_actuales[key],
          })
        })

        const myEvaluacionesActualesNivelPeriodo = {}
        const myEvaluacionesActualesGlobal = []
        response.d.evaluaciones_actuales.forEach((evaluacion) => {
          if (evaluacion.tipo === 'global') {
            myEvaluacionesActualesGlobal.push(evaluacion)
          } else {
            if (
              myEvaluacionesActualesNivelPeriodo[
                evaluacion['nivel-periodo']
              ] === undefined
            ) {
              myEvaluacionesActualesNivelPeriodo[evaluacion['nivel-periodo']] =
                [evaluacion]
            } else {
              myEvaluacionesActualesNivelPeriodo[
                evaluacion['nivel-periodo']
              ].push(evaluacion)
            }
          }
        })

        const myEvaluacionesActualesNivelPeriodoArray = []
        Object.keys(myEvaluacionesActualesNivelPeriodo).forEach((key) => {
          myEvaluacionesActualesNivelPeriodoArray.push({
            nivel: key,
            materias: myEvaluacionesActualesNivelPeriodo[key],
          })
        })

        setEvaluacionesActuales({
          resultados_globales: myEvaluacionesActualesGlobal,
          resultados_nivel_periodo: myEvaluacionesActualesNivelPeriodoArray,
        })

        if (myResultadosActuales.length > 0) {
          setResultadosActualesExist(true)
        } else {
          setResultadosActualesExist(false)
        }
        setResultadosActuales(myResultadosActuales)
      } else {
      }
      setLoadingEvaluacionesActuales(false)
      setLoadingResultadosActuales(false)
    } catch (error) {
      console.error(error)
      toast.error('Error request')
      setLoadingEvaluacionesActuales(false)
      setLoadingResultadosActuales(false)
    }
  }

  const getResultadosHistoricos = async () => {
    if (!filtroCiclo.value) {
      toast.error('Se debe seleccionar ciclo')
      return
    }
    setNivelesHistoricosDesplegados([])
    setLoadingResultadosHistoricos(true)
    setResultadosHistoricos([])

    try {
      const response = await getRequest(
        '/evaluacion/docente/resultados_historicos?ciclo=' +
          filtroCiclo.value +
          '&year=' +
          year.value
      )
      if (response.s === 'OK') {
        const myResultadosHistoricos = []
        Object.keys(response.d).forEach((key) => {
          myResultadosHistoricos.push({
            nivel: key,
            materias: response.d[key],
          })
        })
        if (myResultadosHistoricos.length > 0) {
          setResultadosHistoricosExist(true)
        } else {
          setResultadosHistoricosExist(false)
        }

        setResultadosHistoricos(myResultadosHistoricos)
      }

      setLoadingResultadosHistoricos(false)
    } catch (error) {
      console.error(error)
      toast.error('Error request')
      setLoadingResultadosHistoricos(false)
    }
  }

  function handleChangeFiltroYear({ value }) {
    console.log(value)
    setYear({
      label: value,
      value: value,
    })
  }

  function handleChangeFiltroCiclo({ value }) {
    setFiltroCiclo({
      label: value,
      value: value,
    })
  }

  async function handleOpenModalDetalleAsignacion(id_materia) {
    setModalDetalleAsignacion(true)
    setLoadingDetalleAsignacion(true)
    try {
      const response = await getRequest(
        '/reportes/resultados_asignacion?id=' + id_materia
      )
      if (response.s === 'OK') {
        let dimensioensFormato = []
        Object.keys(response.d.detalle_dimensiones).forEach((keyDimension) => {
          let resultados_dimension = []
          response.d.detalle_dimensiones[keyDimension].forEach((dimension) => {
            let resultado_evaluacion = [
              dimension.resultado_escala_5,
            ]
            resultados_dimension.push({
              nombre: dimension.nombre,
              resultado: resultado_evaluacion,
            })
          })
          dimensioensFormato.push({
            nombre_dimension: keyDimension,
            resultados: resultados_dimension,
          })
        })

        dimensioensFormato.sort((a, b) => {
          if (a.nombre_dimension < b.nombre_dimension) {
            return -1
          }
          return 0
        })

        setDetalleAsignacion({
          ...detalleAsignacion,
          dimensiones: dimensioensFormato,
          detalle: response.d,
        })
        setLoadingDetalleAsignacion(false)
      } else {
        setModalDetalleAsignacion(false)
        setLoadingDetalleAsignacion(false)
      }
    } catch (e) {
      console.log(e)
      setModalDetalleAsignacion(false)
      setLoadingDetalleAsignacion(false)
      toast.error('Error obteniendo detalle de asignación')
    }
  }

  async function handleOpenModalDetalleIntegral(id_materia) {
    if (id_materia) {
      setMaterialIntegralId(id_materia)
    } else {
      id_materia = materiaIntegralId
    }
    setModalDetalleIntegral(true)
    setLoadingDetalleIntegral(true)
    try {
      const response = await getRequest(
        '/reportes/integral_calendario_docente?calendario=' + id_materia
      )
      if (response.s === 'OK') {
        let dimensioensFormato = []
        Object.keys(response.d.detalle_dimensiones).forEach((keyDimension) => {
          let resultados_dimension = []
          response.d.detalle_dimensiones[keyDimension].forEach((dimension) => {
            let resultado_evaluacion = [dimension.resultados.integral]
            resultados_dimension.push({
              nombre: dimension.nombre,
              resultado: resultado_evaluacion,
            })
          })
          dimensioensFormato.push({
            nombre_dimension: keyDimension,
            resultados: resultados_dimension,
          })
        })

        setDetalleIntegral({
          ...detalleIntegral,
          dimensiones: dimensioensFormato,
          detalle: response.d,
        })
        setLoadingDetalleIntegral(false)
      } else {
        setLoadingDetalleIntegral(false)
        setModalDetalleIntegral(false)
      }
    } catch (e) {
      console.log(e)
      setLoadingDetalleIntegral(false)
      setModalDetalleIntegral(false)
      toast.error('Error obteniendo detalle de asignación')
    }
  }

  async function handleOpenModalEvaluacionCoordinador(
    id_calendario,
    id_dimension,
    id_evaluacion_integral,
    id_instrumento
  ) {
    setModalEvaluacionCoordinador(true)
    setLoadingEvaluacionCoordinador(true)

    try {
      const response = await getRequest(
        '/evaluacion/docente/detalle?tipo=nivel-periodo&id_calendario=' +
          id_calendario +
          '&id_dimension=' +
          id_dimension +
          '&id_evaluacion_integral=' +
          id_evaluacion_integral +
          '&id_instrumento=' +
          id_instrumento
      )
      if (response.s === 'OK') {
        setDetalleEvaluacionInstrumento(response.d.instrumento)
        response.d.id_evaluacion = id_dimension
        setDetalleevaluacion(response.d)
        setLoadingEvaluacionCoordinador(false)
      } else {
        setModalEvaluacionCoordinador(false)
        setLoadingEvaluacionCoordinador(false)
      }
    } catch (error) {
      console.error(error)
      setModalEvaluacionCoordinador(false)
      setLoadingEvaluacionCoordinador(false)
    }
  }

  async function handleOpenMOdalEvaluacionGlobal(
    id_calendario,
    id_dimension,
    id_evaluacion_integral,
    id_instrumento
  ) {
    setModalActualizacionProfesional(true)
    setLoadingEvaluacionGlobal(true)
    try {
      const response = await getRequest(
        '/evaluacion/docente/detalle?tipo=global&id_calendario=' +
          id_calendario +
          '&id_dimension=' +
          id_dimension +
          '&id_evaluacion_integral=' +
          id_evaluacion_integral +
          '&id_instrumento=' +
          id_instrumento
      )

      if (response.s === 'OK') {
        const myHeaders = []
        response.d.dimension.elementos.forEach((elemento) => {
          myHeaders.push(elemento.nombre)
        })
        response.d.dimension.headers = myHeaders

        const myData = []
        myHeaders.push('status')
        response.d.dimension.elementos_registrados.forEach((elemento, i) => {
          const myReg = []
          myHeaders.forEach((header) => {
            if (String(elemento[header]).includes('http')) {
              myReg.push(elemento[header])
            } else {
              myReg.push(elemento[header])
            }
          })
          myReg.push(
            <button
              className='delete-reg-eval'
              onClick={() => {
                deleteElement(i)
              }}
              disabled={elemento['status'] === 'Aceptado'}
              style={
                elemento['status'] === 'Aceptado'
                  ? { backgroundColor: 'grey', cursor: 'not-allowed' }
                  : {}
              }
            >
              Eliminar
            </button>
          )
          myReg.push(elemento.id)
          myReg.push(i)
          myData.push(myReg)
        })
        myHeaders.push('Acciones')
        response.d.dimension.data = myData
        response.d.dimension.calendario = response.d.calendario
        response.d.dimension.evaluacion_integral =
          response.d.evaluacion_integral
        setDetalleEvaluacionGlobal(response.d.dimension)
        setLoadingEvaluacionGlobal(false)
      } else {
        setModalActualizacionProfesional(false)
        setLoadingEvaluacionGlobal(false)
      }
    } catch (error) {
      console.error(error)
      setModalActualizacionProfesional(false)
      setLoadingEvaluacionGlobal(false)
    }
  }

  function deleteElement(elemento) {
    setDeletados([parseInt(elemento)])
  }

  async function postFirma() {
    setLoadingDetalleIntegral(true)
    const resulto_integral = JSON.parse(JSON.stringify(detalleIntegral.detalle))
    delete resulto_integral['estatus']
    delete resulto_integral['fecha_hora_firma']
    const body = {
      calendario: nivelActual,
      resulto_integral,
    }
    try {
      const response = await postRequest(
        '/reportes/integral_calendario_docente/firmar',
        body
      )

      if (response.s === 'OK') {
        toast.success(response.m)
      }
      setLoadingDetalleIntegral(false)
    } catch (error) {
      setLoadingDetalleIntegral(false)
      console.error(error)
    }
  }

  function firmarDetalle(afterFirma) {
    /*firma de detalle con ws*/
    afterFirma(false)
    postFirma().then(() => {
      handleOpenModalDetalleIntegral()
      loadData()
      getResultadosHistoricos()
    })
  }

  async function enviarRespuestaEvaluacionGlobal() {
    const formatoRegistros = []
    detalleEvaluacionGlobal.data.forEach((reg) => {
      const myRegistro = {}
      detalleEvaluacionGlobal.headers.forEach((header, i) => {
        if (header !== 'Acciones') {
          myRegistro[header] = reg[i]
        }
      })
      myRegistro.id = reg[reg.length - 2]
      formatoRegistros.push(myRegistro)
    })

    const body = {
      tipo: 'global',
      id_calendario: detalleEvaluacionGlobal.calendario.id,
      id_dimension: detalleEvaluacionGlobal.id,
      id_evaluacion_integral: detalleEvaluacionGlobal.evaluacion_integral.id,
      elementos_registrados: formatoRegistros,
    }
    setLoadingEvaluacionGlobal(true)
    try {
      const response = await postRequest('/evaluacion/docente/editar', body)
      if (response.s === 'OK') {
        loadData()
        toast.success(response.m)
        setLoadingEvaluacionGlobal(false)
        setModalActualizacionProfesional(false)
      } else {
        setLoadingEvaluacionGlobal(false)
        setModalActualizacionProfesional(false)
      }
    } catch (error) {
      console.error(error)
      setLoadingEvaluacionGlobal(false)
    }
  }

  async function enviarRespuestaEvaluacionNivelPeriodo(respuestas) {
    const body = {
      tipo: 'nivel-periodo',
      id_evaluacion: detalleEvaluacion.id_evaluacion,
      id_calendario: detalleEvaluacion.calendario.id,
      id_dimension: detalleEvaluacion.dimension.id,
      id_evaluacion_integral: detalleEvaluacion.evaluacion_integral.id,
      id_instrumento: detalleEvaluacion.instrumento.id,
      elementos_registrados: respuestas,
    }
    setLoadingEvaluacionCoordinador(true)
    try {
      const response = await postRequest('/evaluacion/docente/editar', body)
      if (response.s === 'OK') {
        loadData()
        toast.success(response.m)
        setLoadingEvaluacionCoordinador(false)
        setModalEvaluacionCoordinador(false)
      } else {
        setLoadingEvaluacionCoordinador(false)
        setModalEvaluacionCoordinador(false)
      }
    } catch (error) {
      console.error(error)
      setLoadingEvaluacionCoordinador(false)
    }
  }

  const customStyles = {
    valueContainer: (provided, _state) => ({
      ...provided,
      justifyContent: 'center',
    }),
    option: (provided, _state) => ({
      ...provided,
      color: '#616b74',
      fontSize: 14,
      fontWeight: 400,
      padding: '5px 25px',
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      userSelect: 'none',
      backgroundColor: '#fff',
      borderTop: '1px solid #ccc',
    }),
    control: (provided, _state) => ({
      ...provided,
      minHeight: 0,
      height: '25px',
      borderRadius: 15,
      fontSize: 11,
      backgroundColor: '#f9f9f9',
      padding: 0,
    }),
    placeholder: (provided, _state) => ({
      ...provided,
      padding: 0,
      fontSize: 11,
      fontWeight: 400,
    }),
    menu: (provided, _state) => ({
      ...provided,
      borderRadius: 0,
    }),
    input: (provided, _state) => ({
      ...provided,
      padding: 0,
      fontSize: 11,
      fontWeight: 400,
      borderRadius: 0,
    }),
    singleValue: (provided, _state) => ({
      ...provided,
      top: '50%',
    }),
  }

  return (
    <>
      <Contenedor contenedorPadre>
        <Grid container>
          <Grid item xs={6} className='contenedorPadre-izquierdo'>
            <SubContenedor title='Resultados Actuales'>
              {loadingResultadosActuales ? (
                <div style={{ textAlign: 'center' }}>
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {!resultadosActualesExist && (
                    <p
                      style={{
                        marginLeft: '50px',
                        color: '#7E7D82',
                        fontWeight: 'bold',
                        marginTop: 0,
                      }}
                    >
                      No se encontraron resultados
                    </p>
                  )}
                  {resultadosActuales.map((reg, i) => (
                    <Despliegue
                      key={i}
                      size='medio'
                      height={150}
                      desplegada={nivelesActualesDesplegados.includes(
                        reg.nivel
                      )}
                      nombre={reg.nivel}
                      open={() => {
                        if (nivelesActualesDesplegados.includes(reg.nivel)) {
                          const nuevosDesplegados =
                            nivelesActualesDesplegados.filter((nivel) => {
                              return reg.nivel !== nivel
                            })
                          setNivelesActualesDesplegados([...nuevosDesplegados])
                        } else {
                          setNivelesActualesDesplegados([
                            ...nivelesActualesDesplegados,
                            reg.nivel,
                          ])
                        }
                      }}
                    >
                      {reg.materias.map((materia) => (
                        <p
                          key={materia.id}
                          onClick={() => {
                            setHideFirmarButton(true)
                            if (materia.nombre === 'Integral') {
                              handleOpenModalDetalleIntegral(materia.id)
                            } else {
                              handleOpenModalDetalleAsignacion(materia.id)
                            }
                          }}
                          className='registro-materia-resultados'
                        >
                          <span>{materia.crn}</span>
                          <span>{materia.nombre}</span>
                          <span
                            style={{
                              color:
                                materia.status === 'firmado' ? 'green' : 'red',
                            }}
                          >
                            {materia.status}
                          </span>
                        </p>
                      ))}
                    </Despliegue>
                  ))}
                </>
              )}
            </SubContenedor>
            <SubContenedor noScroll title='Resultados Historicos'>
              <Grid container>
                <Grid xs={1}></Grid>
                <Grid xs={1}>Filtros:</Grid>
                <Grid xs={3}>
                  <Select
                    options={years}
                    value={year}
                    styles={customStyles}
                    name='etiqueta'
                    placeholder='Año...'
                    className='select-search-box'
                    onChange={handleChangeFiltroYear}
                  />
                </Grid>
                <div
                  style={{
                    width: '20px',
                  }}
                ></div>
                <Grid xs={3}>
                  <Select
                    options={valoresCiclos}
                    value={filtroCiclo}
                    styles={customStyles}
                    name='etiqueta'
                    placeholder='Ciclo...'
                    className='select-search-box'
                    onChange={handleChangeFiltroCiclo}
                  />
                </Grid>
                <div style={{ maxWidth: '20%', flexBasis: '20%' }}>
                  <button
                    style={{
                      width: '100%',
                      fontSize: '18px',
                      padding: '2px 20px',
                    }}
                    className='search-button'
                    onClick={getResultadosHistoricos}
                  >
                    Buscar
                  </button>
                </div>
              </Grid>
              {loadingResultadosHistoricos ? (
                <div style={{ textAlign: 'center' }}>
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {!resultadosHistoricosExist && (
                    <p
                      style={{
                        marginLeft: '50px',
                        color: '#7E7D82',
                        fontWeight: 'bold',
                        marginTop: 0,
                      }}
                    >
                      No se encontraron resultados
                    </p>
                  )}
                  <div style={{ overflow: 'scroll', height: '93%' }}>
                    {resultadosHistoricos.map((reg, i) => (
                      <Despliegue
                        key={i}
                        size='medio'
                        height={150}
                        desplegada={nivelesHistoricosDesplegados.includes(
                          reg.nivel
                        )}
                        nombre={reg.nivel}
                        open={() => {
                          if (
                            nivelesHistoricosDesplegados.includes(reg.nivel)
                          ) {
                            const nuevosDesplegados =
                              nivelesHistoricosDesplegados.filter((nivel) => {
                                return reg.nivel !== nivel
                              })
                            setNivelesHistoricosDesplegados([
                              ...nuevosDesplegados,
                            ])
                          } else {
                            setNivelesHistoricosDesplegados([
                              ...nivelesHistoricosDesplegados,
                              reg.nivel,
                            ])
                          }
                        }}
                      >
                        {reg.materias.map((materia) => (
                          <>
                            <p
                              key={materia.id}
                              onClick={() => {
                                setHideFirmarButton(false)
                                if (materia.nombre === 'Integral') {
                                  setNivelActual(reg.nivel)
                                  handleOpenModalDetalleIntegral(materia.id)
                                } else {
                                  handleOpenModalDetalleAsignacion(materia.id)
                                }
                              }}
                              className='registro-materia-resultados'
                            >
                              <span>{materia.clave}</span>
                              <span>{materia.nombre}</span>
                              <span
                                style={{
                                  color:
                                    materia.status === 'firmada'
                                      ? 'green'
                                      : 'rgb(246,129,0)',
                                }}
                                className='estatus-actualizacion'
                              >
                                {'   '}
                                {materia.status}
                              </span>
                            </p>
                          </>
                        ))}
                      </Despliegue>
                    ))}
                  </div>
                </>
              )}
            </SubContenedor>
          </Grid>
          <Grid item xs={6} className='contenedorPadre-derecho'>
            <SubContenedor title='Evaluaciones Actuales'>
              <div className='subcontainer' style={{ overflow: 'auto' }}>
                {loadingEvaluacionesActuales ? (
                  <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    <div>
                      {evaluacionesActuales.resultados_globales.map(
                        (resultado, i) => {
                          return (
                            <p
                              key={i}
                              style={{ marginLeft: '50px' }}
                              onClick={() => {
                                handleOpenMOdalEvaluacionGlobal(
                                  resultado.id_calendario,
                                  resultado.id_dimension,
                                  resultado.id_evaluacion_integral,
                                  resultado.id_instrumento
                                )
                              }}
                            >
                              <span className='Actualizacion-Profesional'>
                                {resultado.nombre}
                              </span>
                              <span
                                style={{
                                  color:
                                    resultado.status === 'documentos revisados'
                                      ? 'green'
                                      : resultado.status ===
                                        'documentos en revisión'
                                      ? 'rgb(246,129,0)'
                                      : resultado.status === 'sin documentos'
                                      ? '#ffa51e'
                                      : 'red',
                                }}
                                className='estatus-actualizacion'
                              >
                                {'   '}
                                {resultado.status}
                              </span>
                            </p>
                          )
                        }
                      )}
                    </div>
                    {evaluacionesActuales.resultados_nivel_periodo.map(
                      (reg, i) => (
                        <Despliegue
                          key={i}
                          size='medio'
                          height={150}
                          desplegada={evaluacionesActualesDesplegadas.includes(
                            reg.nivel
                          )}
                          nombre={reg.nivel}
                          open={() => {
                            if (
                              evaluacionesActualesDesplegadas.includes(
                                reg.nivel
                              )
                            ) {
                              const nuevosDesplegados =
                                evaluacionesActualesDesplegadas.filter(
                                  (nivel) => {
                                    return reg.nivel !== nivel
                                  }
                                )
                              setEvaluacionesActualesDesplegadas([
                                ...nuevosDesplegados,
                              ])
                            } else {
                              setEvaluacionesActualesDesplegadas([
                                ...evaluacionesActualesDesplegadas,
                                reg.nivel,
                              ])
                            }
                          }}
                        >
                          {reg.materias.map((materia) => (
                            <p
                              key={materia.id}
                              onClick={() => {
                                handleOpenModalEvaluacionCoordinador(
                                  materia.id_calendario,
                                  materia.id_dimension,
                                  materia.id_evaluacion_integral,
                                  materia.id_instrumento
                                )
                              }}
                              className='registro-materia-resultados'
                            >
                              <span className='Actualizacion-Profesional'>
                                {materia.nombre}
                              </span>
                              <span
                                style={{
                                  color:
                                    materia.status === 'contestada' && 'green',
                                }}
                                className='estatus-actualizacion'
                              >
                                {materia.status}
                              </span>
                            </p>
                          ))}
                        </Despliegue>
                      )
                    )}
                  </>
                )}
              </div>
            </SubContenedor>
          </Grid>
        </Grid>
      </Contenedor>
      <DetalleAsignacion
        detalleAsignacion={detalleAsignacion}
        modalDetalleAsignacion={modalDetalleAsignacion}
        setModalDetalleAsignacion={setModalDetalleAsignacion}
        loadingDetalleAsignacion={loadingDetalleAsignacion}
      />
      <DetalleIntegral
        detalleIntegral={detalleIntegral}
        modalDetalleIntegral={modalDetalleIntegral}
        setModalDetalleIntegral={setModalDetalleIntegral}
        loadingDetalleIntegral={loadingDetalleIntegral}
        firmarDetalle={firmarDetalle}
        hideButton={hideFirmarButton}
      />
      <ActualizacionProfesional
        detalleEvaluacionGlobal={detalleEvaluacionGlobal}
        modalActualizacionProfesional={modalActualizacionProfesional}
        setModalActualizacionProfesional={setModalActualizacionProfesional}
        loadingActualizacionProfesional={loadingEvaluacionGlobal}
        setDetalleEvaluacionGlobal={setDetalleEvaluacionGlobal}
        deleteElement={deleteElement}
        enviarRespuestaEvaluacionGlobal={enviarRespuestaEvaluacionGlobal}
      />
      <EvaluacionCoordinador
        enviarRespuestaEvaluacionNivelPeriodo={
          enviarRespuestaEvaluacionNivelPeriodo
        }
        detalleEvaluacionInstrumento={detalleEvaluacionInstrumento}
        detalleEvaluacion={detalleEvaluacion}
        modalEvaluacionCoordinador={modalEvaluacionCoordinador}
        setModalEvaluacionCoordinador={setModalEvaluacionCoordinador}
        loadingEvaluacionCoordinador={loadingEvaluacionCoordinador}
      />
    </>
  )
}

export default MisEvaluaciones
