/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'

import DataTable from '../../Components/DataTables'
import EditButton from '../../Components/Botones/EditButton'
import { getRequest } from '../../utils/requester'

export default function Cuestionarios(props) {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [tab] = useState(1)

  const headers = ['Folio', 'Clave', 'Peso', 'Reactivos', 'Acciones']

  function select(option) {
    console.log(option)
    props.setInstrumento(option)
    props.setModal(false)
  }

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true)
        let response = await getRequest(`/instrumento`)
        if (response.s === 'OK' && response.d) {
          let newData = []
          response.d.forEach((cuestionario) => {
            cuestionario.id_instrumento = cuestionario.id
            cuestionario.clave_instrumento = cuestionario.clave
            newData.push([
              cuestionario.id_instrumento,
              cuestionario.clave_instrumento,
              cuestionario.peso_instrumento,
              cuestionario.total_reactivos,
              <EditButton onClick={() => select(cuestionario)}>
                Seleccionar
              </EditButton>,
            ])
          })
          setData(newData)
          setLoading(false)
        } else {
          setData([])
          setLoading(false)
        }
      } catch (error) {
        console.log(error)
        setLoading(false)
        props.setModal(false)
      }
    }
    fetchData()
  }, [tab])

  return (
    <div className='contenedor-tabs-cuestionarios'>
      <h4>Instrumentos</h4>
      <div className='cuestionarios-container'>
        <DataTable headers={headers} data={data} loading={loading} paginate />
      </div>
    </div>
  )
}
