import React from 'react';
import styled from 'styled-components';
import ButtonExit from '../components/Button';

const InstruccionesContainer = styled.div`
  margin: 3rem;
  @media screen and (max-width: 480px) {
    margin: 3rem 1rem;
  }
`;

const Text = styled.p`
  font-size: 21px;
  @media screen and (max-width: 480px) {
    font-size: 18px;
    text-align: center;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 5rem 0 0;
  @media screen and (max-width: 480px) {
    margin: 3rem 0 0;
  }
`;

const Button = styled.button`
  width: 410px;
  height: 42px;
  background-color: #191919;
  border-radius: 40px;
  font-size: 22px;
  color: white;
  cursor: pointer;
  border: none;
  @media screen and (max-width: 480px) {
    width: 300px;
    height: 50px;
    font-size: 20px;
  }
`;

export default function Instrucciones({
  match,
  aceptarInstrucciones,
  salir
}){

  const onClick = () => {
      aceptarInstrucciones()
  };

  let text;
  let buttonText;

  switch (match) {
    case 'Taller_Laboratorio':
      text = (
        <Text>
          Estás a punto de iniciar la evaluación Taller/Laboratorio Quimica II
          <br />
          Enseguida te aparecerán las preguntas que deberás ir contestando una a
          una para concluir la evaluación.
        </Text>
      );

      buttonText = 'Taller/Laboratorio';
      /*
      setSelectionOptionsDesktop([
        {
          id: 1,
          laboratorio: 'A002',
        },
        {
          id: 2,
          laboratorio: 'A003',
        },
        {
          id: 3,
          laboratorio: 'A004',
        },
        {
          id: 4,
          laboratorio: 'A005',
        },
      ]);
      */
      break;
    
    case 'Opinion_Estudiantil':
      text = (
        <Text>
          Es momento de Evaluar a tus docentes
          <br />
          Enseguida encontrarás una serie preguntas que deberás contestar por
          cada docente que te ha impartido materias durante el ciclo escolar
        </Text>
      );

      buttonText = 'Docente';
      /*
      setSelectionOptionsDesktop([]);
      */
      break;

    case 'Tipo_ENE':
      text = (
        <Text>
          Estás a punto de iniciar el Examen Nacional ciclo 02 Año 2020
          <br />
          Enseguida te aparecerán las preguntas que deberás ir contestando una a
          una para concluir la evaluación.
        </Text>
      );

      buttonText = 'Tipo ENE';
      /*
      setSelectionOptionsDesktop([
        {
          id: 1,
          laboratorio: '3x+5y',
        },
        {
          id: 2,
          laboratorio: '6x+5y',
        },
        {
          id: 3,
          laboratorio: '6x+15y',
        },
        {
          id: 4,
          laboratorio: '6x+15xy',
        },
      ]);
      */
      break;

    default:
      text = '';
      buttonText = '';
      /*
      setSelectionOptionsDesktop([]);
      */
      break;
  }
  return (
    <>
      <InstruccionesContainer>
        {text}
        <ButtonContainer>
          <Button onClick={onClick}>Iniciar evaluación {buttonText}</Button>
        </ButtonContainer>
      </InstruccionesContainer>
      <ButtonExit onClick={salir}/>
    </>
  );
};
