/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Select from 'react-select'

import Contenedor from '../../Components/Contenedor/Contenedor'
import NewButton from '../../Components/Botones/NewButton'
import EditButton from '../../Components/Botones/EditButton'
import DataTable from '../../Components/DataTables'
import NewModal from '../../Components/Modal/NewModal'
/*import Can from '../../Utils/Funciones/can';*/
import { getRequest, postRequest } from '../../utils/requester'
import { toast } from 'react-toastify'
import SeleccionPregunta from './SeleccionPregunta'
import RespCortaPregunta from './RespCortaPregunta'
import CasillasPregunta from './CasillasPregunta'
import EscalaPregunta from './EscalaPregunta'

import './styles.css'

function Reactivos() {
  const headers = ['Folio', 'Nombre', 'Etiqueta', 'Acciones']
  const [data, setData] = useState([])
  const [dataF, setDataF] = useState([])
  const [tableLoading, setTableLoading] = useState(true)
  const [openNuevoReactivo, setOpenNuevoReactivo] = useState(false)

  const [openEditarReactivo, setOpenEditarReactivo] = useState(false)

  const [loadingModal, setLoadingModal] = useState(false)
  const [reactivoId, setReactivoId] = useState('')
  const [opcionesEtiquetas, setOpcionesEtiquetas] = useState([])

  const [etiqueta, setEtiqueta] = useState('')
  const [, setEtiquetaDefault] = useState({})
  const [tipoPreguntaS, setTipoPregunta] = useState('Selección')
  const [tipoPreguntaSEditar, setTipoPreguntaEditar] = useState({})
  const [nombre, setNombre] = useState('')

  const [respuestaCorta, setRespuestaCorta] = useState({})

  const [seleccionSimple, setSeleccionSimple] = useState({})

  const [escalaLineal, setEscalaLineal] = useState({})

  const [seleccionMultiple, setSeleccionMultiple] = useState({})

  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteModalLoading, setDeleteModalLoading] = useState(false)
  const loadTable = async () => {
    try {
      setTableLoading(true)
      let response = await getRequest('/instrumento/reactivo')
      let newData = []
      if (response.d) {
        response.d.forEach((reactivo) => {
          newData.push([
            reactivo.id_reactivo,
            reactivo.nombre_reactivo,
            reactivo.etiqueta,
            <EditButton
              onClick={() =>
                handleOpenModalEditarReactivo(reactivo.id_reactivo)
              }
            >
              Editar
            </EditButton>,
          ])
        })
        setTableLoading(false)
      }
      setData(newData)
      setDataF(newData)
    } catch (error) {
      setTableLoading(false)
      console.error(error)
    }
  }

  useEffect(() => {
    getEtiquetas()
  }, [])
  useEffect(() => {
    if (opcionesEtiquetas.length > 0) {
      loadTable()
    }
  }, [opcionesEtiquetas])

  function search(e) {
    let text = e.target.value.toLowerCase()
    let result = data.filter((row) => {
      if (JSON.stringify(row).toLowerCase().includes(text)) {
        return true
      }
      return false
    })
    setDataF(result)
  }

  const handleOpenModalEditarReactivo = async (id) => {
    console.log(opcionesEtiquetas)
    setLoadingModal(true)
    setOpenEditarReactivo(true)

    try {
      /*
      const response = await getRequest('/instrumento/etiqueta');
      const data = response.d;

      let etiquetas = [];
      data.forEach((etiqueta) => {
        etiquetas.push({
          label: etiqueta.nombre,
          value: String(etiqueta.id),
        });
      });
      setOpcionesEtiquetas(etiquetas);
      */

      setReactivoId(id)

      const responseReactivo = await getRequest(
        '/instrumento/reactivo?id=' + id
      )
      if (responseReactivo.s === 'OK') {
        responseReactivo.d = responseReactivo.d[0]
        setNombre(responseReactivo.d.nombre_reactivo)
        setEtiqueta({
          label: responseReactivo.d.etiqueta,
          value: responseReactivo.d.etiqueta,
        })
        setEtiquetaDefault({
          label: responseReactivo.d.etiqueta,
          value: responseReactivo.d.etiqueta,
        })

        switch (responseReactivo.d.tipo_reactivo) {
          case 'respuesta_corta':
            setTipoPreguntaEditar({
              label: 'Respuesta corta',
              value: 'respuesta_corta',
            })
            setRespuestaCorta({
              pregunta: responseReactivo.d.pregunta_reactivo,
              respuesta: responseReactivo.d.opciones[0].nombre_opcion,
              valor: responseReactivo.d.opciones[0].valor_opcion,
            })
            break
          case 'seleccion':
            setTipoPreguntaEditar({
              label: 'Selección',
              value: 'seleccion',
            })
            let opcionesSimple = []
            responseReactivo.d.opciones.forEach((opcion) => {
              opcionesSimple.push({
                id: opcion.id_opcion,
                option: opcion.nombre_opcion,
                value: opcion.valor_opcion,
                check: false,
              })
            })
            setSeleccionSimple({
              pregunta: responseReactivo.d.pregunta_reactivo,
              opciones: opcionesSimple,
            })

            break
          case 'casillas_verificacion':
            setTipoPreguntaEditar({
              label: 'Casillas de verificacion',
              value: 'casillas_verificacion',
            })
            let opcionesMultiple = []
            responseReactivo.d.opciones.forEach((opcion) => {
              opcionesMultiple.push({
                id: opcion.id_opcion,
                option: opcion.nombre_opcion,
                value: opcion.valor_opcion,
                check: false,
              })
            })
            setSeleccionMultiple({
              pregunta: responseReactivo.d.pregunta_reactivo,
              opciones: opcionesMultiple,
            })
            break
          case 'escala_lineal':
            setTipoPreguntaEditar({
              label: 'Escala lineal',
              value: 'escala_lineal',
            })
            setEscalaLineal({
              pregunta: responseReactivo.d.pregunta_reactivo,
              inicio: responseReactivo.d.opciones[0].valor_opcion,
              fin: responseReactivo.d.opciones[
                responseReactivo.d.opciones.length - 1
              ].valor_opcion,
              etiquetaInicio: responseReactivo.d.opciones[0].nombre_opcion,
              etiquetaFin:
                responseReactivo.d.opciones[
                  responseReactivo.d.opciones.length - 1
                ].nombre_opcion,
              valueInicio: responseReactivo.d.opciones[0].valor_opcion,
              valueFin:
                responseReactivo.d.opciones[
                  responseReactivo.d.opciones.length - 1
                ].valor_opcion,
            })
            break
          default:
            break
        }

        setLoadingModal(false)
      }
    } catch (error) {
      console.error(error)
      setLoadingModal(false)
      setOpenEditarReactivo(false)
    }
  }

  async function getEtiquetas() {
    const response = await getRequest('/instrumento/etiqueta')
    const data = response.d
    if (response.s === 'OK') {
      let etiquetas = []
      data.forEach((etiqueta) => {
        etiquetas.push({
          label: etiqueta.nombre,
          value: String(etiqueta.id),
        })
      })
      setOpcionesEtiquetas(etiquetas)
      setOpcionesEtiquetas(etiquetas)
    }
  }

  const handleOpenModalNuevoReactivo = async () => {
    setLoadingModal(true)
    setOpenNuevoReactivo(true)

    setNombre('')
    setTipoPregunta(tipoPregunta[0])

    setSeleccionSimple({
      pregunta: '',
      opciones: [{ id: 0, check: false, option: 'Opcion ' + 1, value: '' }],
    })

    setSeleccionMultiple({
      pregunta: '',
      opciones: [{ id: 0, check: false, option: 'Opcion ' + 1, value: '' }],
    })

    setRespuestaCorta({
      pregunta: '',
      respuesta: '',
      valor: '',
    })
    try {
      /*
      const response = await getRequest('/instrumento/etiqueta');
      const data = response.d;

      let etiquetas = [];
      data.forEach((etiqueta) => {
        etiquetas.push({
          label: etiqueta.nombre,
          value: String(etiqueta.id),
        });
      });
      setOpcionesEtiquetas(etiquetas);
      setEtiqueta(etiquetas[0].value);
      */
      setLoadingModal(false)
    } catch (error) {
      console.error(error)
      setLoadingModal(false)
      setOpenNuevoReactivo(false)
    }
  }

  const handleCloseModalNuevoReactivo = () => {
    setLoadingModal(false)
    setOpenEditarReactivo(false)
    setOpenNuevoReactivo(false)
  }

  const tipoPregunta = [
    {
      label: 'Selección',
      name: 'Selección',
      value: 'seleccion',
    },
    {
      label: 'Respuesta corta',
      name: 'Respuesta corta',
      value: 'respuesta_corta',
    },
    {
      label: 'Casillas de verificacion',
      name: 'Casillas de verificacion',
      value: 'casillas_verificacion',
    },
    {
      label: 'Escala lineal',
      name: 'Escala lineal',
      value: 'escala_lineal',
    },
  ]

  const tipoPreguntaEditar = [
    {
      label: 'Selección',
      value: 'seleccion',
    },
    {
      label: 'Respuesta corta',
      value: 'respuesta_corta',
    },
    {
      label: 'Casillas de verificacion',
      value: 'casillas_verificacion',
    },
    {
      label: 'Escala lineal',
      value: 'escala_lineal',
    },
  ]

  const handleChangeTipoPregunta = (e) => {
    setTipoPregunta({
      label: e.value,
      value: e.value,
    })
  }

  const handleChangeTipoPreguntaEditar = (e) => {
    setTipoPreguntaEditar({
      label: e.value,
      value: e.value,
    })
  }

  const handleNuevoReactivo = async () => {
    let data = {
      nombre_reactivo: nombre,
      id_etiqueta: parseInt(etiqueta.value),
    }
    switch (tipoPreguntaS.value) {
      case 'seleccion':
        let preguntaS = seleccionSimple.pregunta
        let valores = seleccionSimple.opciones
        let opcionesToSend = []
        valores.forEach((opcion) => {
          opcionesToSend.push({
            nombre_opcion: opcion.option,
            valor_opcion: parseFloat(opcion.value),
          })
        })
        data.pregunta_reactivo = preguntaS
        data.tipo_reactivo = 'seleccion'
        data.opciones = opcionesToSend
        break

      case 'respuesta_corta':
        let { pregunta, respuesta, valor } = respuestaCorta
        const opciones = [
          {
            respuesta,
            valor: parseFloat(valor),
          },
        ]
        data.pregunta_reactivo = pregunta
        data.tipo_reactivo = 'respuesta_corta'
        data.ociones = opciones
        break

      case 'casillas_verificacion':
        let preguntaM = seleccionMultiple.pregunta
        let valoresM = seleccionMultiple.opciones
        let opcionesToSendM = []
        valoresM.forEach((opcion) => {
          opcionesToSendM.push({
            nombre_opcion: opcion.option,
            valor_opcion: parseFloat(opcion.value),
          })
        })
        data.pregunta_reactivo = preguntaM
        data.tipo_reactivo = 'casillas_verificacion'
        data.opciones = opcionesToSendM
        break

      case 'escala_lineal':
        let preguntaRC = escalaLineal.pregunta
        let intervalo = []
        let inicio = parseInt(escalaLineal.inicio)
        let fin = parseInt(escalaLineal.fin)
        let valorInicio = escalaLineal.valueInicio
        let valorFin = escalaLineal.valueFin
        for (let i = inicio; i <= fin; i++) {
          if (i === inicio) {
            intervalo.push({
              nombre_opcion: escalaLineal.etiquetaInicio,
              valor_opcion: valorInicio,
              valor_intervalo: i,
            })
          } else if (i === fin) {
            intervalo.push({
              nombre_opcion: escalaLineal.etiquetaFin,
              valor_opcion: valorFin,
              valor_intervalo: i,
            })
          } else {
            intervalo.push({
              nombre_opcion: 'a',
              valor_opcion: parseInt(valorInicio * (i + 1 - inicio)),
              valor_intervalo: i,
            })
          }
        }

        data.tipo_reactivo = 'escala_lineal'
        data.pregunta_reactivo = preguntaRC
        data.opciones = intervalo
        break
      default:
        break
    }

    console.log(data)
    if (validarReactivo(data)) {
      try {
        setLoadingModal(true)
        const response = await postRequest('/instrumento/reactivo/nuevo', data)
        if (response.s === 'OK') {
          setLoadingModal(false)
          setOpenNuevoReactivo(false)
          loadTable()
        }
        setLoadingModal(false)
      } catch (error) {
        console.error(error)
        setLoadingModal(false)
      }
    }
  }

  const handleEditarReactivo = async () => {
    let data = {
      id: reactivoId,
      nombre_reactivo: nombre,
      id_etiqueta: etiqueta,
      status: 1,
    }

    switch (tipoPreguntaSEditar.value) {
      case 'seleccion':
        let preguntaS = seleccionSimple.pregunta
        let valores = seleccionSimple.opciones ? seleccionSimple.opciones : []
        let opcionesToSend = []
        valores.forEach((opcion) => {
          opcionesToSend.push({
            nombre_opcion: opcion.option,
            valor_opcion: parseFloat(opcion.value),
          })
        })
        data.pregunta_reactivo = preguntaS
        data.tipo = 'seleccion simple'
        data.opciones = opcionesToSend
        break

      case 'respuesta_corta':
        let { pregunta, respuesta, valor } = respuestaCorta
        const opciones = [
          {
            nombre_opcion: respuesta,
            valor_opcion: parseFloat(valor),
          },
        ]
        data.pregunta_reactivo = pregunta
        data.tipo = 'abierta'
        data.opciones = opciones
        break

      case 'casillas_verificacion':
        let preguntaM = seleccionMultiple.pregunta
        let valoresM = seleccionMultiple.opciones
        let opcionesToSendM = []
        valoresM.forEach((opcion) => {
          opcionesToSendM.push({
            nombre_opcion: opcion.option,
            valor_opcion: parseFloat(opcion.value),
          })
        })
        data.pregunta_reactivo = preguntaM
        data.tipo = 'seleccion multiple'
        data.opciones = opcionesToSendM
        break

      case 'escala_lineal':
        let preguntaRC = escalaLineal.pregunta
        let intervalo = []
        let inicio = parseInt(escalaLineal.inicio)
        let fin = parseInt(escalaLineal.fin)
        let valorInicio = escalaLineal.valueInicio
        let valorFin = escalaLineal.valueFin
        for (let i = inicio; i <= fin; i++) {
          if (i === inicio) {
            intervalo.push({
              nombre_opcion: escalaLineal.etiquetaInicio,
              valor_opcion: parseFloat(valorInicio),
              valor_intervalo: i,
            })
          } else if (i === fin) {
            intervalo.push({
              nombre_opcion: escalaLineal.etiquetaFin,
              valor_opcion: parseFloat(valorFin),
              valor_intervalo: i,
            })
          } else {
            intervalo.push({
              nombre_opcion: '',
              valor_opcion: parseFloat(
                (valorInicio * (i + 1 - inicio)).toFixed(2)
              ),
              valor_intervalo: i,
            })
          }
        }

        data.tipo = 'intervalo'
        data.pregunta_reactivo = preguntaRC
        data.opciones = intervalo
        break
      default:
        break
    }
    if (validarReactivo(data)) {
      try {
        setLoadingModal(true)
        const response = await postRequest('/instrumento/reactivo/editar', data)
        if (response.s === 'OK') {
          toast.success(response.m)
          setLoadingModal(false)
          setOpenEditarReactivo(false)
          loadTable()
        }
        setLoadingModal(false)
      } catch (error) {
        console.error(error)
        setLoadingModal(false)
      }
    }
  }

  function validarReactivo(body) {
    let valido = true
    if (body.nombre_reactivo === '') {
      document.getElementById('lable-nombre').classList.add('error')
      valido = false
    } else {
      document.getElementById('lable-nombre').classList.remove('error')
    }

    if (body.id_etiqueta === undefined) {
      document.getElementById('lable-etiqueta').classList.add('error')
      valido = false
    } else {
      document.getElementById('lable-etiqueta').classList.remove('error')
    }
    if (body.pregunta_reactivo === '' || body.pregunta_reactivo === undefined) {
      toast.error('Debe ingresar una pregunta')
      valido = false
    }
    if (
      body.tipo_reactivo === 'seleccion' ||
      body.tipo_reactivo === 'casillas_verificacion'
    ) {
      if (body.opciones.length === 0) {
        toast.error('Debe ingresar opciones')
        valido = false
      } else {
        let nombres = []
        body.opciones.forEach((myOpcion) => {
          if (
            myOpcion.nombre_opcion === '' ||
            myOpcion.valor_opcion === '' ||
            isNaN(myOpcion.valor_opcion)
          ) {
            toast.error('Todas las opciones deben tener nombre y valor')
            valido = false
          } else {
            nombres.push(myOpcion.nombre_opcion)
          }
        })
        let number = 0
        for (let i = 0; i < nombres.length; i++) {
          for (let j = 0; j < nombres.length; j++) {
            if (nombres[i] == nombres[j]) {
              number = number + 1
            }
          }
          if (number > 1) {
            toast.error('No se pueden repetir opciones: ' + nombres[i])
            i = nombres.length
            valido = false
          }
          number = 0
        }
      }
    } else if (body.tipo_reactivo === 'respuesta_corta') {
      if (
        body.ociones[0].respuesta === undefined ||
        body.ociones[0].respuesta === '' ||
        body.ociones[0].valor_opcion === undefined ||
        isNaN(body.ociones[0].valor_opcion) ||
        body.ociones[0].valor_opcion === ''
      ) {
        toast.error('Debe ingresar respuesta con valor')
        valido = false
      }
    } else if (body.tipo_reactivo === 'escala_lineal') {
      if (
        body.opciones[0].nombre_opcion === '' ||
        body.opciones[0].nombre_opcion === undefined ||
        body.opciones[body.opciones.length - 1].nombre_opcion === '' ||
        body.opciones[body.opciones.length - 1].nombre_opcion === undefined
      ) {
        toast.error('Debe etiquetas a valores inicial y final')
        valido = false
      } else if (
        body.opciones[0].nombre_opcion ===
        body.opciones[body.opciones.length - 1].nombre_opcion
      ) {
        toast.error('Etiqueta de inicio y final deben ser distintas')
        valido = false
      }

      if (
        body.opciones[0].valor_opcion === '' ||
        isNaN(body.opciones[0].valor_opcion)
      ) {
        toast.error('Debe ingresar valor incial')
        valido = false
      }
    }
    return valido
  }

  const handleDeleteReactivo = async () => {
    let data = {
      id: reactivoId,
      nombre_reactivo: nombre,
      id_etiqueta: etiqueta,
      status: 0,
    }

    switch (tipoPreguntaSEditar.value) {
      case 'seleccion':
        let preguntaS = seleccionSimple.pregunta
        let valores = seleccionSimple.opciones ? seleccionSimple.opciones : []
        let opcionesToSend = []
        valores.forEach((opcion) => {
          opcionesToSend.push({
            nombre_opcion: opcion.option,
            valor_opcion: parseFloat(opcion.value),
          })
        })
        data.pregunta_reactivo = preguntaS
        data.tipo = 'seleccion simple'
        data.opciones = opcionesToSend
        break

      case 'respuesta_corta':
        let { pregunta, respuesta, valor } = respuestaCorta
        const opciones = [
          {
            nombre_opcion: respuesta,
            valor_opcion: parseFloat(valor),
          },
        ]
        data.pregunta_reactivo = pregunta
        data.tipo = 'abierta'
        data.opciones = opciones
        break

      case 'casillas_verificacion':
        let preguntaM = seleccionMultiple.pregunta
        let valoresM = seleccionMultiple.opciones
        let opcionesToSendM = []
        valoresM.forEach((opcion) => {
          opcionesToSendM.push({
            nombre_opcion: opcion.option,
            valor_opcion: parseFloat(opcion.value),
          })
        })
        data.pregunta_reactivo = preguntaM
        data.tipo = 'seleccion multiple'
        data.opciones = opcionesToSendM
        break

      case 'escala_lineal':
        let preguntaRC = escalaLineal.pregunta
        let intervalo = []
        let inicio = parseInt(escalaLineal.inicio)
        let fin = parseInt(escalaLineal.fin)
        let valorInicio = escalaLineal.valueInicio
        let valorFin = escalaLineal.valueFin
        for (let i = inicio; i <= fin; i++) {
          if (i === inicio) {
            intervalo.push({
              nombre_opcion: escalaLineal.etiquetaInicio,
              valor_opcion: parseFloat(valorInicio),
              valor_intervalo: i,
            })
          } else if (i === fin) {
            intervalo.push({
              nombre_opcion: escalaLineal.etiquetaFin,
              valor_opcion: parseFloat(valorFin),
              valor_intervalo: i,
            })
          } else {
            intervalo.push({
              nombre_opcion: '',
              valor_opcion: parseFloat(
                (valorInicio * (i + 1 - inicio)).toFixed(2)
              ),
              valor_intervalo: i,
            })
          }
        }

        data.tipo = 'intervalo'
        data.pregunta_reactivo = preguntaRC
        data.opciones = intervalo
        break
      default:
        break
    }
    if (validarReactivo(data)) {
      try {
        setLoadingModal(true)
        setDeleteModalLoading(true)
        const response = await postRequest('/instrumento/reactivo/editar', data)
        if (response.s === 'OK') {
          setDeleteModalLoading(false)
          loadTable()
          setOpenEditarReactivo(false)
          setDeleteModal(false)
        }
        setDeleteModalLoading(false)
        setDeleteModal(false)
      } catch (error) {
        console.error(error)
        setDeleteModalLoading(false)
        setDeleteModal(false)
      }
    }
  }

  const customStyles = {
    option: (provided, _state) => ({
      ...provided,
      color: '#616b74',
      fontSize: 14,
      fontWeight: 400,
      padding: '5px 25px',
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      userSelect: 'none',
      backgroundColor: '#fff',
      borderTop: '1px solid #ccc',
    }),
    control: (provided, _state) => ({
      ...provided,
      minHeight: 0,
      height: '25px',
      borderRadius: 15,
      fontSize: 11,
      backgroundColor: '#f9f9f9',
      padding: 0,
    }),
    placeholder: (provided, _state) => ({
      ...provided,
      padding: 0,
      fontSize: 11,
      fontWeight: 400,
    }),
    menu: (provided, _state) => ({
      ...provided,
      borderRadius: 0,
    }),
    input: (provided, _state) => ({
      ...provided,
      padding: 0,
      fontSize: 11,
      fontWeight: 400,
      borderRadius: 0,
    }),
    singleValue: (provided, _state) => ({
      ...provided,
      top: '50%',
    }),
  }

  const disabledStyle = {
    option: (provided, _state) => ({
      ...provided,
      color: '#616b74',
      fontSize: 14,
      fontWeight: 400,
      padding: '5px 25px',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      userSelect: 'none',
      backgroundColor: '#fff',
      borderTop: '1px solid #ccc',
    }),
    control: (provided, _state) => ({
      ...provided,
      minHeight: 0,
      height: '25px',
      borderRadius: 15,
      fontSize: 11,
      backgroundColor: '#e8e5e5',
      padding: 0,
    }),
    menu: (provided, _state) => ({
      ...provided,
      display: 'none',
    }),
    placeholder: (provided, _state) => ({
      ...provided,
      padding: 0,
      fontSize: 11,
      fontWeight: 400,
    }),
    input: (provided, _state) => ({
      ...provided,
      padding: 0,
      fontSize: 11,
      fontWeight: 400,
      borderRadius: 0,
    }),
    singleValue: (provided, _state) => ({
      ...provided,
      top: '43%',
    }),
  }

  return (
    <Contenedor title='Reactivos'>
      <div className='opciones-bar'>
        <div className='sub-filtro'>
          <NewButton onClick={handleOpenModalNuevoReactivo} />
        </div>
        <div style={{ float: 'right' }}>
          <input
            type='search'
            placeholder='Buscar'
            className='SearchInput-tabla-completa SearchInput-Usuarios'
            onChange={search}
          />
        </div>
      </div>

      <div style={{ height: 'calc(100% - 70px)' }}>
        <DataTable
          headers={headers}
          data={dataF}
          loading={tableLoading}
          paginate
        />
      </div>
      <NewModal
        height={500}
        width={900}
        title='Nuevo Reactivo'
        open={openNuevoReactivo}
        handleClose={handleCloseModalNuevoReactivo}
        loading={loadingModal}
        handleSubmit={handleNuevoReactivo}
      >
        <div className='nuevoReactivoForm'>
          <Grid container>
            <Grid item xs={12} md={4}>
              <label id='lable-nombre'>Nombre (*)</label>
              <input
                placeholder='Ingresar nombre'
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <label id='lable-etiqueta'>Etiqueta (*)</label>
              <Select
                options={opcionesEtiquetas}
                value={etiqueta}
                styles={customStyles}
                name='etiqueta'
                placeholder='Selección...'
                className='select-search-box'
                onChange={setEtiqueta}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <label id='lable-tipo-pregunta'>Tipo pregunta (*)</label>
              <Select
                options={tipoPregunta}
                value={tipoPreguntaS}
                styles={customStyles}
                name='tipo-pregunta'
                placeholder='Selección...'
                className='select-search-box'
                onChange={handleChangeTipoPregunta}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <div className='contenedor-enunciado'>
                {tipoPreguntaS.value === 'seleccion' ? (
                  <SeleccionPregunta
                    setSeleccionSimple={setSeleccionSimple}
                    seleccionSimple={seleccionSimple}
                  />
                ) : tipoPreguntaS.value === 'respuesta_corta' ? (
                  <RespCortaPregunta
                    setRespuestaCorta={setRespuestaCorta}
                    respuestaCorta={respuestaCorta}
                  />
                ) : tipoPreguntaS.value === 'casillas_verificacion' ? (
                  <CasillasPregunta
                    setSeleccionMultiple={setSeleccionMultiple}
                    seleccionMultiple={seleccionMultiple}
                  />
                ) : (
                  <EscalaPregunta setEscalaLineal={setEscalaLineal} />
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </NewModal>
      <NewModal
        height={500}
        title='Editar Reactivo'
        canDelete
        onDelete={() => setDeleteModal(true)}
        open={openEditarReactivo}
        handleClose={handleCloseModalNuevoReactivo}
        loading={loadingModal}
        handleSubmit={handleEditarReactivo}
        permiso='objetos_instrumento_reactivos'
      >
        <div className='editarReactivoForm'>
          <Grid container>
            <Grid item xs={12} md={1}>
              <label>Folio</label>
              <input
                value={reactivoId}
                disabled
                style={{ backgroundColor: '#dcdbdb' }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <label id='lable-nombre'>Nombre (*)</label>
              <input
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                placeholder='Ingresar nombre'
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <label id='lable-etiqueta'>Etiqueta (*)</label>
              <input
                value={etiqueta.label}
                onChange={(e) => setNombre(e.target.value)}
                placeholder='Ingresar nombre'
                disabled
                style={{ backgroundColor: '#dcdbdb' }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <label id='lable-tipo-pregunta'>Tipo pregunta (*)</label>
              <Select
                onChange={handleChangeTipoPreguntaEditar}
                options={tipoPreguntaEditar}
                value={tipoPreguntaSEditar}
                styles={disabledStyle}
                name='tipo-pregunta'
                placeholder='Selección...'
                className='select-search-box'
                isSearchable={false}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <div className='contenedor-enunciado'>
                {tipoPreguntaSEditar.value === 'seleccion' ? (
                  <SeleccionPregunta
                    setSeleccionSimple={setSeleccionSimple}
                    seleccionSimple={seleccionSimple}
                  />
                ) : tipoPreguntaSEditar.value === 'respuesta_corta' ? (
                  <RespCortaPregunta
                    setRespuestaCorta={setRespuestaCorta}
                    respuestaCorta={respuestaCorta}
                  />
                ) : tipoPreguntaSEditar.value === 'casillas_verificacion' ? (
                  <CasillasPregunta
                    setSeleccionMultiple={setSeleccionMultiple}
                    seleccionMultiple={seleccionMultiple}
                  />
                ) : (
                  <EscalaPregunta
                    setEscalaLineal={setEscalaLineal}
                    escalaLineal={escalaLineal}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </NewModal>
      <NewModal
        open={deleteModal}
        title='Confirmar'
        height={120}
        loading={deleteModalLoading}
        handleClose={() => setDeleteModal(false)}
        handleSubmit={handleDeleteReactivo}
      >
        <div className='delete-confirm'>
          <h2 style={{ textAlign: 'center' }}>
            ¿Está seguro de que desea eliminar este reactivo?
          </h2>
        </div>
      </NewModal>
    </Contenedor>
  )
}

export default Reactivos
