/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import Select from 'react-select'
import { toast } from 'react-toastify'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import moment from 'moment'

import Contenedor from '../../Components/Contenedor/Contenedor'
import DataTable from '../../Components/DraggableTable'
import ProgresBar from '../../Components/Progress/ProgresBar'
import { getRequest, postRequest } from '../../utils/requester'
import './style.css'

function CreadoEstructuras({ listaCampus }) {
  const [periodo, setPeriodo] = useState({})
  const [calendario, setCalendario] = useState({})
  const [filtroRegistro, setFiltroRegistro] = useState({})
  const [dataSubFiltrada, setDataSubFiltrada] = useState([])
  const [dataFiltrada, setDataFiltrada] = useState([])
  const [data, setData] = useState([])
  const [completados, setCompletados] = useState(0)
  const [periodos, setPeriodos] = useState([])
  const [calendarios, setCalendarios] = useState([])
  const [loadingPeriodos, setLoadingPeriodos] = useState(false)
  const [loadingReporte, setLoadingReporte] = useState(false)

  const [loadingCalendarios, setLoadingCalendarios] = useState(false)

  const [coordinadores] = useState([])
  const [, setCoordinadoresFiltrados] = useState([])
  const [opcionesCampus, setOpcionesCampus] = useState([])
  const [campus, setCampus] = useState({})
  const [reporte, setReporte] = useState(false)

  const [year, setYear] = useState({})
  const [years, setYears] = useState([])
  const [tipo, setTipo] = useState({
    label: 'Abierto',
    value: 'abierto',
  })

  const [loadingGuardar, setLoadingGuardar] = useState(false)
  const [refreshBusqueda, setRefreshBusqueda] = useState(false)
  const [dataSinEditar, setDataSinEditar] = useState([])

  const [filtroRegistros] = useState([
    {
      value: 'Todos',
      label: 'Todos',
    },
    {
      value: 'Sin Coordinador',
      label: 'Sin Coordinador',
    },
    {
      value: 'Con Coordinador',
      label: 'Con Coordinador',
    },
  ])

  useEffect(() => {
    const myOpcionesYears = []
    const actualYear = new Date().getFullYear()
    const myOpcionesCampus = []
    for (let i = actualYear - 4; i <= actualYear; i++) {
      myOpcionesYears.push({
        label: String(i),
        value: String(i),
      })
    }
    setYears(myOpcionesYears)
    setYear({
      label: String(actualYear),
      value: String(actualYear),
    })

    if (listaCampus) {
      listaCampus.forEach((reg) => {
        myOpcionesCampus.push({
          label: reg.nombre,
          value: String(reg.id),
        })
      })
    }

    myOpcionesCampus.sort((a, b) => {
      var labelA = a.label.toUpperCase()
      var labelB = b.label.toUpperCase()
      if (labelA < labelB) {
        return -1
      }
      if (labelA > labelB) {
        return 1
      }

      return 0
    })

    setDataSinEditar([])
    setOpcionesCampus(myOpcionesCampus)
  }, [])

  useEffect(() => {
    if (year !== undefined && campus !== undefined) {
      if (year.value !== undefined && campus.value !== undefined) {
        getPeriodos()
      }
    }
  }, [year, campus])

  useEffect(() => {
    if (year !== undefined && periodo !== undefined && tipo !== undefined) {
      if (
        year.value !== undefined &&
        periodo.value !== undefined &&
        tipo.value !== undefined
      ) {
        getCalendarios()
      }
    }
  }, [periodo, tipo, year])

  useEffect(() => {
    const result = []
    data.forEach((reg) => {
      if (filtroRegistro.value === 'Sin Coordinador' && reg[0] === '') {
        result.push(reg)
      } else if (filtroRegistro.value === 'Con Coordinador' && reg[0] !== '') {
        result.push(reg)
      } else if (filtroRegistro.value === 'Todos') {
        result.push(reg)
      }
    })
    setDataSubFiltrada(result)
    setDataFiltrada(result)
  }, [filtroRegistro])

  function actualizarBarra() {
    let conCoordinador = 0
    data.forEach((reg) => {
      if (reg[0] !== '') {
        conCoordinador += 1
      }
    })
    setCompletados((conCoordinador / data.length) * 100)
  }

  async function getPeriodos() {
    setLoadingPeriodos(true)
    const response = await getRequest(
      '/calendario/periodo?year=' + year.value + '&campus=' + campus.value
    )
    if (response.s === 'OK') {
      const myPeriodos = []
      response.d.forEach((reg) => {
        myPeriodos.push({
          value: reg,
          label: reg,
        })
      })
      setPeriodo({})
      setCalendario({})
      setPeriodos(myPeriodos)
      setLoadingPeriodos(false)
    } else {
      setLoadingPeriodos(false)
    }
  }

  async function getCalendarios() {
    setLoadingCalendarios(true)
    const response = await getRequest(
      '/calendario?year=' +
        year.value +
        '&id_campus=' +
        campus.value +
        '&periodo=' +
        periodo.value +
        '&tipo=' +
        tipo.value
    )
    if (response.s === 'OK') {
      const myPeriodos = []
      response.d.forEach((reg) => {
        myPeriodos.push({
          value: reg.name,
          label: reg.name,
        })
      })
      setCalendario({})
      setCalendarios(myPeriodos)
      setLoadingCalendarios(false)
    } else {
      setLoadingCalendarios(false)
    }
  }

  async function getReporte() {
    if (campus.value === undefined || periodo.value === undefined) {
      toast.error('Debe seleccionar Campus y Calendario Evaluaciones')
      return
    }
    if (calendario.value === undefined) {
      toast.error('Debe seleccionar calendario')
      return
    }
    setRefreshBusqueda(!refreshBusqueda)
    setReporte(true)
    setLoadingReporte(true)

    const response = await getRequest(
      '/estructura?calendario=' + calendario.value + '&campus=' + campus.value
    )
    if (response.s === 'OK') {
      const myData = []
      let conCoordinador = 0
      const mydataSinEditar = []
      response.d.forEach((reg) => {
        conCoordinador += reg['Clave_coordinador'] === '' ? 0 : 1
        myData.push([
          reg['Clave_coordinador'],
          reg.Coordinador,
          reg.Ciclo,
          reg.Periodo,
          reg.Campus,
          reg.CRN,
          reg['Clave_materia'],
          reg.Materia,
          reg['Clave_docente'],
          reg['Nombre_docente'],
          reg['Tipo_profesor'],
          reg['Modalidad'],
          reg.Nivel,
          reg['Parte_periodo'],
          reg.Tipo,
          reg.id_coordinador,
          reg.id_estructura,
        ])
        mydataSinEditar.push([
          reg['Clave_coordinador'],
          reg.Coordinador,
          reg.Ciclo,
          reg.Periodo,
          reg.Campus,
          reg.CRN,
          reg['Clave_materia'],
          reg.Materia,
          reg['Clave_docente'],
          reg['Nombre_docente'],
          reg['Tipo_profesor'],
          reg['Modalidad'],
          reg.Nivel,
          reg.Tipo,
          reg.id_coordinador,
          reg.id_estructura,
        ])
      })
      mydataSinEditar.sort((a, b) => {
        if (a[0] > b[0]) {
          return 1
        }
        if (a[0] < b[0]) {
          return -1
        }
        return 0
      })
      myData.sort((a, b) => {
        if (a[0] > b[0]) {
          return 1
        }
        if (a[0] < b[0]) {
          return -1
        }
        return 0
      })
      setDataSinEditar(mydataSinEditar)
      setData(myData)
      setDataFiltrada(myData)
      setDataSubFiltrada(myData)
      setCompletados((conCoordinador / response.d.length) * 100)
      setLoadingReporte(false)
    } else {
      setLoadingReporte(false)
    }
  }

  const headers = [
    'Clave Coordinador',
    'Coordinador',
    'Ciclo',
    'Periodo',
    'Campus',
    'CRN',
    'Clave materia',
    'Materia',
    'Clave Docente',
    'Nombre Docente',
    'Tipo Profesor',
    'Modalidad',
    'Nivel',
    'Parte periodo',
    'Tipo',
  ]

  function filtrarCoordinadores(e) {
    const text = e.target.value.toLowerCase()
    const result = []
    coordinadores.forEach((reg) => {
      if (reg.clave.toLowerCase().includes(text)) {
        result.push(reg)
      }
    })
    setCoordinadoresFiltrados([])
  }

  function search(e) {
    const text = e.target.value.toLowerCase()
    const result = []
    dataSubFiltrada.forEach((reg) => {
      let contiene = false
      reg.forEach((cel) => {
        if (String(cel).toLowerCase().includes(text)) {
          contiene = true
        }
      })
      if (contiene) {
        result.push(reg)
      }
    })
    setDataFiltrada(result)
  }

  function setearDataCompleta(nueva) {
    setData(nueva)
  }

  async function guardarEstructura(registrosToSave) {
    setLoadingGuardar(true)
    setLoadingReporte(true)

    const response = await postRequest('/estructura/guardar', registrosToSave)
    if (response.s === 'OK') {
      setLoadingGuardar(false)
      setDataSinEditar(data)
      setRefreshBusqueda(!refreshBusqueda)
      toast.success(response.m)
      getReporte()
    } else {
      setLoadingReporte(false)
      setLoadingGuardar(false)
    }
  }

  function cancelarEdicion() {
    const myData = []
    dataSinEditar.forEach((reg) => {
      const myReg = []
      reg.forEach((reg2) => {
        myReg.push(reg2)
      })
      myData.push(myReg)
    })
    setData(myData)
    setDataFiltrada(myData)
  }

  function downloadReporte() {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const dataDownload = []
    data.forEach((reg) => {
      dataDownload.push({
        [headers[0]]: reg[0],
        [headers[1]]: reg[1],
        [headers[2]]: reg[2],
        [headers[3]]: reg[3],
        [headers[4]]: reg[4],
        [headers[5]]: reg[5],
        [headers[6]]: reg[6],
        [headers[7]]: reg[7],
        [headers[8]]: reg[8],
        [headers[9]]: reg[9],
        [headers[10]]: reg[10],
        [headers[11]]: reg[11],
        [headers[13]]: reg[13],
      })
    })

    const ws = XLSX.utils.json_to_sheet(dataDownload.map((row) => row))
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data2 = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(
      data2,
      `Reporte Creado de Estructuras ${moment().format(
        'YYYY-MM-DD_HH:mm'
      )}.xlsx`
    )
  }

  const tipoOptions = [
    {
      label: 'Abierto',
      value: 'abierto',
    },
    {
      label: 'Historico',
      value: 'historico',
    },
  ]

  return (
    <Contenedor title='Creado de Estructuras'>
      <div className='filtros-tabla-completa' style={{ float: 'left' }}>
        <div className='sub-filtro'>
          <p>Campus:</p>
          <Select
            options={opcionesCampus}
            className='select-periodo'
            classNamePrefix='select-search'
            value={campus}
            onChange={setCampus}
          />
        </div>
        <div className='sub-filtro'>
          <p>Tipo:</p>
          <Select
            options={tipoOptions}
            className='select-periodo'
            classNamePrefix='select-search'
            value={tipo}
            onChange={setTipo}
          />
        </div>
        <div className='sub-filtro'>
          <p>Seleccionar Año:</p>
          <Select
            style={{ width: '50px' }}
            options={years}
            className='select-periodo small-bar'
            classNamePrefix='select-search'
            value={year}
            onChange={setYear}
          />
        </div>
        <div className='sub-filtro'>
          <p>Periodo:</p>
          {loadingPeriodos ? (
            <div className='circle-loading' style={{ textAlign: 'center' }}>
              <CircularProgress size={25} />
            </div>
          ) : (
            <Select
              options={periodos}
              className='select-periodo small-bar'
              classNamePrefix='select-search'
              value={periodo}
              onChange={setPeriodo}
            />
          )}
        </div>
        <div className='sub-filtro'>
          <p>Calendarios Evaluaciones:</p>
          {loadingCalendarios ? (
            <div className='circle-loading' style={{ textAlign: 'center' }}>
              <CircularProgress size={25} />
            </div>
          ) : (
            <Select
              options={calendarios}
              className='select-periodo'
              classNamePrefix='select-search'
              value={calendario}
              onChange={setCalendario}
            />
          )}
        </div>
        <div className='sub-filtro'>
          {reporte && !loadingReporte ? (
            <>
              <p>Mostrar Registros:</p>
              <Select
                options={filtroRegistros}
                className='select-periodo'
                classNamePrefix='select-search'
                value={filtroRegistro}
                onChange={setFiltroRegistro}
              />
            </>
          ) : null}
        </div>
        <div className='sub-filtro'>
          <button className='NewButton' onClick={getReporte}>
            Buscar
          </button>
        </div>
        <div className='sub-filtro'>
          {reporte && !loadingReporte ? (
            <button className='ButtonXlsx' onClick={downloadReporte}>
              Descargar Excel
            </button>
          ) : null}
        </div>
      </div>
      <div className='progreso-estructuras2'>
        {reporte && !loadingReporte ? (
          <>
            <p>Completados: {completados.toFixed(2)} %</p>
            <ProgresBar variant='determinate' value={completados} />
          </>
        ) : null}
      </div>
      <div style={{ float: 'right' }}>
        {reporte && !loadingReporte ? (
          <>
            <input
              type='search'
              placeholder='Buscar'
              className='SearchInput-tabla-completa SearchInput search-creado-estrucutras2'
              onChange={search}
            />
          </>
        ) : null}
      </div>
      <div className='contenedor-reporte-creado-estructuras2'>
        {reporte ? (
          <DataTable
            sinAncho={[2, 3, 4, 5, 11, 12]}
            cancelarEdicion={cancelarEdicion}
            refreshBusqueda={refreshBusqueda}
            loadingGuardar={loadingGuardar}
            guardarEstructura={guardarEstructura}
            actualizarBarra={actualizarBarra}
            dataCompleta={data}
            valueCampus={campus.value}
            setearDataCompleta={setearDataCompleta}
            filtrarCoordinadores={filtrarCoordinadores}
            headers={headers}
            data={dataFiltrada}
            loading={loadingReporte}
          />
        ) : null}
      </div>
      <div style={{ width: '100%', textAlign: 'center' }}></div>
    </Contenedor>
  )
}

const mapStateToProps = (state) => {
  return {
    listaCampus: state.listaCampus,
  }
}

export default connect(mapStateToProps)(CreadoEstructuras)
