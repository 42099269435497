/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import NewModal from '../../Components/Modal/NewModal'

const getCharsLength = (...words) => {
  return words.join('-').length
}

function calcLines(nombre, nss, materia) {
  const charsLen = getCharsLength(nss, nombre, materia)
  const lines = Math.floor(charsLen / MAX_CHARS_PER_LINE) + 1
  return lines
}

const MAX_CHARS_PER_LINE = 30

export default function EvaluacionCoordinador({
  modalEvaluacionCoordinador,
  setModalEvaluacionCoordinador,
  loadingEvaluacionCoordinador,
  detalleEvaluacionInstrumento,
  detalleEvaluacion,
  enviarRespuestaEvaluacionNivelPeriodo,
}) {
  const [respuestasReactivos, setRespuestasReactivos] = useState({})
  useEffect(() => {
    let myRespuestasReactivos = {}
    detalleEvaluacionInstrumento.agrupadores.forEach((agrupador) => {
      agrupador.reactivos.forEach((reactivo) => {
        myRespuestasReactivos[String(reactivo.id)] = []
      })
    })

    if (detalleEvaluacionInstrumento.pubico) {
      detalleEvaluacionInstrumento[detalleEvaluacionInstrumento.pubico].forEach(
        (reg) => {
          reg.respuestas.forEach((response) => {
            if (response.id_result != null)
              myRespuestasReactivos[String(response.id_reactive)].push({
                id_coordinador: reg.id_coordinador,
                id_agrupador: response.id_reactive_group,
                id_opcion: response.id_reactive_option,
                id_reactivo: response.id_reactive,
                id_assignment_teacher: reg.id_assignment_teacher,
                respuesta_corta: response.respuesta_corta,
              })
          })
        }
      )
    }

    setRespuestasReactivos(myRespuestasReactivos)
  }, [loadingEvaluacionCoordinador])

  function handleSubmit() {
    let myRespuestas = []
    Object.keys(respuestasReactivos).forEach((reactivo) => {
      respuestasReactivos[reactivo].forEach((respuesta) => {
        myRespuestas.push(respuesta)
      })
    })

    enviarRespuestaEvaluacionNivelPeriodo(myRespuestas)
  }

  function handleChangeRespuestaReactivo(nombre_reactivo, valores) {
    setRespuestasReactivos({
      ...respuestasReactivos,
      [nombre_reactivo]: valores,
    })
  }

  return (
    <NewModal
      loading={loadingEvaluacionCoordinador}
      title='Responder Evaluación'
      open={modalEvaluacionCoordinador}
      handleClose={() => {
        setModalEvaluacionCoordinador(false)
      }}
      height={650}
      width={'80%'}
      handleSubmit={handleSubmit}
      buttonAcceptText='Enviar'
      noSaveBtn={
        detalleEvaluacion.dimension.criterio_evaluacion ===
        'Instrumento Externo'
      }
    >
      <div style={{ paddingTop: '7px' }}>
        <div
          style={{
            width: '100%',
            height: '150px',
            backgroundColor: '#eee',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <p style={{ margin: '0 0 8px 20px' }}>
            <span
              style={{ color: 'gray', fontWeight: 600, marginRight: '5px' }}
            >
              FOLIO CALENDARIO:
            </span>
            <span style={{ fontWeight: 600, marginRight: '60px' }}>
              {detalleEvaluacion?.calendario?.id}
            </span>
            <span
              style={{ color: 'gray', fontWeight: 600, marginRight: '5px' }}
            >
              CICLO:
            </span>
            <span style={{ fontWeight: 600, marginRight: '60px' }}>
              {detalleEvaluacion?.calendario?.ciclo}
            </span>
            <span
              style={{ color: 'gray', fontWeight: 600, marginRight: '5px' }}
            >
              NIVEL:
            </span>
            <span style={{ fontWeight: 600, marginRight: '60px' }}>
              {detalleEvaluacion?.calendario?.nivel}
            </span>
            <span
              style={{ color: 'gray', fontWeight: 600, marginRight: '5px' }}
            >
              PERIODO:
            </span>
            <span style={{ fontWeight: 600, marginRight: '60px' }}>
              {detalleEvaluacion?.calendario?.periodo}
            </span>
          </p>
          <p style={{ margin: '8px 0 8px 20px' }}>
            <span
              style={{ color: 'gray', fontWeight: 600, marginRight: '5px' }}
            >
              NOMBRE DE DIMENSIÓN:
            </span>
            <span style={{ fontWeight: 600, marginRight: '60px' }}>
              {detalleEvaluacion.dimension.nombre}
            </span>
            <span
              style={{ color: 'gray', fontWeight: 600, marginRight: '5px' }}
            >
              TIPO DE DIMENSIÓN:
            </span>
            <span style={{ fontWeight: 600, marginRight: '60px' }}>
              {detalleEvaluacion.dimension.tipo_dimension}
            </span>
            <span
              style={{ color: 'gray', fontWeight: 600, marginRight: '5px' }}
            >
              PERIODO DE EVALUACIÓN:
            </span>
            <span style={{ fontWeight: 600, marginRight: '60px' }}>
              {detalleEvaluacion.calendario.nombre}
            </span>
          </p>

          <p style={{ margin: '8px 0 0 20px' }}>
            <span
              style={{ color: 'gray', fontWeight: 600, marginRight: '5px' }}
            >
              DESCRIPCIÓN INSTRUMENTO:
            </span>
            <span style={{ fontWeight: 600 }}>
              {detalleEvaluacionInstrumento.description}
            </span>
          </p>
        </div>
      </div>
      <div
        style={{
          margin: '20px 10px 0',
          overflow: 'auto',
          overflowX: 'hidden',
          minHeight: '180px',
        }}
      >
        <Grid container spacing={1}>
          {detalleEvaluacion.dimension.criterio_evaluacion ===
          'Instrumento Externo' ? (
            <>
              <Grid style={{ marginTop: 120 }} item xs={12}>
                <button
                  onClick={() => {
                    window.open(
                      detalleEvaluacionInstrumento.link_instrumento_externo
                    )
                  }}
                  style={{ margin: 'auto' }}
                  className='ButtonXlsx'
                >
                  Iniciar Evaluación
                </button>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                {detalleEvaluacionInstrumento.agrupadores.map((agrupador) => (
                  <>
                    <h2>{agrupador.nombre}</h2>

                    {agrupador.reactivos.map((reactivo) =>
                      reactivo.tipo === 'seleccion' ? (
                        <PreguntaSeleccionSimple
                          docentes={
                            detalleEvaluacionInstrumento[
                              detalleEvaluacionInstrumento.pubico
                            ]
                          }
                          id_resultado_option={reactivo.id_resultado_option}
                          pregunta={reactivo.pregunta}
                          opciones={reactivo.opcions}
                          id_reactivo={reactivo.id}
                          id_agrupador={agrupador.id}
                          id_instrumento={detalleEvaluacionInstrumento.id}
                          publico={detalleEvaluacionInstrumento.pubico}
                          handleChangeRespuestaReactivo={
                            handleChangeRespuestaReactivo
                          }
                        />
                      ) : reactivo.tipo === 'seleccion multiple' ? (
                        <PreguntaSeleccionMultiple
                          docentes={
                            detalleEvaluacionInstrumento[
                              detalleEvaluacionInstrumento.pubico
                            ]
                          }
                          pregunta={reactivo.pregunta}
                          opciones={reactivo.opcions}
                          id_reactivo={reactivo.id}
                          id_agrupador={agrupador.id}
                          id_instrumento={detalleEvaluacionInstrumento.id}
                          publico={detalleEvaluacionInstrumento.pubico}
                          handleChangeRespuestaReactivo={
                            handleChangeRespuestaReactivo
                          }
                        />
                      ) : reactivo.tipo === 'respuesta_corta' ? (
                        <PreguntaRespuestaCorta
                          pregunta={reactivo.pregunta}
                          docentes={
                            detalleEvaluacionInstrumento[
                              detalleEvaluacionInstrumento.pubico
                            ]
                          }
                          id_reactivo={reactivo.id}
                          id_agrupador={agrupador.id}
                          id_instrumento={detalleEvaluacionInstrumento.id}
                          publico={detalleEvaluacionInstrumento.pubico}
                          handleChangeRespuestaReactivo={
                            handleChangeRespuestaReactivo
                          }
                        />
                      ) : (
                        <PreguntaIntervalo
                          id_resultado_option={reactivo.id_resultado_option}
                          pregunta={reactivo.pregunta}
                          opciones={reactivo.opcions}
                          docentes={
                            detalleEvaluacionInstrumento[
                              detalleEvaluacionInstrumento.pubico
                            ]
                          }
                          id_reactivo={reactivo.id}
                          id_agrupador={agrupador.id}
                          id_instrumento={detalleEvaluacionInstrumento.id}
                          publico={detalleEvaluacionInstrumento.pubico}
                          handleChangeRespuestaReactivo={
                            handleChangeRespuestaReactivo
                          }
                        />
                      )
                    )}
                  </>
                ))}
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </NewModal>
  )
}

function PreguntaRespuestaCorta({
  pregunta,
  docentes,
  id_reactivo,
  id_agrupador,
  handleChangeRespuestaReactivo,
  publico,
}) {
  const [respuestas, setRespuestas] = useState([])
  useEffect(() => {
    let misRespuestas = []
    docentes.forEach((docente) => {
      let misRespuestasDocente = ''
      docente.respuestas.forEach((response) => {
        if (
          id_reactivo === response.id_reactive &&
          id_agrupador === response.id_reactive_group
        ) {
          misRespuestasDocente = response.respuesta_corta
        }
      })
      misRespuestas.push(misRespuestasDocente)
    })
    setRespuestas(misRespuestas)
  }, [])
  return (
    <div className='section'>
      {pregunta.split('\n').map((parrafo) => (
        <p style={{ margin: '0 0 12px 0' }}>{parrafo}</p>
      ))}

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className='lista-coordinadores coordinadores-respuesta-corta'>
          <label>{publico}</label>
          {docentes.map((docente, i) => (
            <>
              <div
                style={{ marginTop: 44 * (i + 1) + 20 }}
                className='divisor-docentes respuesta-corta-divisor'
              ></div>
              <span title={docente.nombre}>
                {docente.nss}-{docente.nombre}-{docente.materia}
              </span>
            </>
          ))}
        </div>

        <div className='respuestas'>
          <label>Respuestas</label>
          {docentes.map((_docente, i) => (
            <input
              value={respuestas[i]}
              onChange={(e) => {
                let myRespuestas = [...respuestas]
                myRespuestas[i] = e.target.value
                let estructuraRespuestaReactivo = []
                docentes.map((regDocente, k) => {
                  estructuraRespuestaReactivo.push({
                    id_coordinador: regDocente.id,
                    id_assignment_structure: regDocente.id_assignment_structure,
                    id_agrupador: id_agrupador,
                    id_reactivo: id_reactivo,
                    id_opcion: '',
                    respuesta_corta: myRespuestas[k],
                  })
                })
                handleChangeRespuestaReactivo(
                  id_reactivo,
                  estructuraRespuestaReactivo
                )

                setRespuestas(myRespuestas)
              }}
              style={{ marginTop: '12px' }}
              type='text'
              name='respuesta'
              placeholder='Escribe tu respuesta'
              className='input-respuesta'
            />
          ))}
        </div>
      </div>
    </div>
  )
}
function PreguntaSeleccionMultiple({
  pregunta,
  opciones,
  docentes,
  id_reactivo,
  id_agrupador,
  handleChangeRespuestaReactivo,
  publico,
}) {
  const [respuestas, setRespuestas] = useState([])
  const [linesDocentes, setLinesDocentes] = useState([])
  const [margenesSeparadores, setMargenesSeparadores] = useState([])
  useEffect(() => {
    let misRespuestas = []
    let myLines = []
    let myMargenesSeparadores = []
    docentes.forEach((docente, i) => {
      let calculoLinea = calcLines(docente.nss, docente.nombre, docente.materia)
      myLines.push(calculoLinea)
      if (i == 0) {
        myMargenesSeparadores.push(51)
      } else {
        myMargenesSeparadores.push(
          (myLines[i - 1] > 0 ? 57 : 33) + myMargenesSeparadores[i - 1]
        )
      }
      let misRespuestasDocente = []
      opciones.forEach((opcion) => {
        let opcionSeleccionada = false
        docente.respuestas.forEach((response) => {
          if (
            response.id_reactive === id_reactivo &&
            response.id_reactive_group === id_agrupador &&
            response.id_reactive_option === opcion.id
          ) {
            opcionSeleccionada = true
          }
        })
        misRespuestasDocente.push(opcionSeleccionada)
      })
      misRespuestas.push(misRespuestasDocente)
    })
    setMargenesSeparadores(myMargenesSeparadores)
    setLinesDocentes(myLines)
    setRespuestas(misRespuestas)
  }, [])

  return (
    <div className='section'>
      {pregunta.split('\n').map((parrafo) => (
        <p style={{ margin: '0 0 12px 0' }}>{parrafo}</p>
      ))}

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className='lista-coordinadores'>
          <label>{publico}</label>
          {docentes.map(({ nombre, nss, materia }, i) => {
            return (
              <>
                <div
                  style={
                    linesDocentes[i] > 0
                      ? { marginTop: margenesSeparadores[i], height: 50 }
                      : { marginTop: margenesSeparadores[i], height: 25 }
                  }
                  className='divisor-docentes'
                ></div>
                {linesDocentes[i] > 0 ? (
                  <>
                    {linesDocentes[i] > 1 ? (
                      <>
                        <span>
                          {nss}-{materia}
                        </span>
                        <span style={{ marginTop: 5 }} title={nombre}>
                          {nombre}
                        </span>
                      </>
                    ) : (
                      <>
                        <span>{nss}</span>
                        <span style={{ marginTop: 5 }} title={nombre}>
                          {nombre}-{materia}
                        </span>
                      </>
                    )}
                  </>
                ) : (
                  <span title={nombre}>
                    {nss}-{nombre}-{materia}
                  </span>
                )}
              </>
            )
          })}
        </div>
        {respuestas.length > 0
          ? opciones.map((opcion, i) => (
              <div className='option'>
                <label>{opcion.nombre}</label>
                {docentes.map((_docente, j) => (
                  <label
                    class='custom-radio-checkbox'
                    style={
                      linesDocentes[j] > 0
                        ? { marginTop: '17px' }
                        : { marginTop: '8.5px', height: '25px' }
                    }
                  >
                    <input
                      checked={respuestas[j][i]}
                      onChange={() => {}}
                      onClick={() => {
                        let misRespuestas = []
                        respuestas.forEach((respuesta) => {
                          let misRegistros = []
                          respuesta.forEach((registro) => {
                            misRegistros.push(registro)
                          })
                          misRespuestas.push(misRegistros)
                        })
                        for (let k = 0; k <= opciones.length; k++) {
                          if (k == i) {
                            misRespuestas[j][k] = !misRespuestas[j][k]
                          }
                        }
                        let estructuraRespuestaReactivo = []
                        opciones.forEach((opcionRespuesta, i) => {
                          docentes.map((regDocente, k) => {
                            if (misRespuestas[k][i]) {
                              estructuraRespuestaReactivo.push({
                                id_coordinador: regDocente.id,
                                id_assignment_structure:
                                  regDocente.id_assignment_structure,
                                id_agrupador: id_agrupador,
                                id_reactivo: id_reactivo,
                                id_opcion: opcionRespuesta.id,
                                respuesta_corta: '',
                              })
                            }
                          })
                        })
                        handleChangeRespuestaReactivo(
                          id_reactivo,
                          estructuraRespuestaReactivo
                        )
                        setRespuestas(misRespuestas)
                      }}
                      type='checkbox'
                    />
                  </label>
                ))}
              </div>
            ))
          : null}
      </div>
    </div>
  )
}
function PreguntaSeleccionSimple({
  pregunta,
  opciones,
  docentes,
  id_reactivo,
  id_agrupador,
  handleChangeRespuestaReactivo,
  publico,
}) {
  const [respuestas, setRespuestas] = useState([])
  const [linesDocentes, setLinesDocentes] = useState([])
  const [margenesSeparadores, setMargenesSeparadores] = useState([])
  useEffect(() => {
    let misRespuestas = []
    let myLines = []
    let myMargenesSeparadores = []
    docentes.forEach((docente, i) => {
      let calculoLinea = calcLines(docente.nss, docente.nombre, docente.materia)
      myLines.push(calculoLinea)
      if (i == 0) {
        myMargenesSeparadores.push(51)
      } else {
        myMargenesSeparadores.push(
          (myLines[i - 1] > 0 ? 57 : 33) + myMargenesSeparadores[i - 1]
        )
      }
      let misRespuestasDocente = []
      opciones.forEach((opcion) => {
        let opcionSeleccionada = false
        docente.respuestas.forEach((response) => {
          if (
            response.id_reactive === id_reactivo &&
            response.id_reactive_group === id_agrupador &&
            response.id_reactive_option === opcion.id
          ) {
            opcionSeleccionada = true
          }
        })
        misRespuestasDocente.push(opcionSeleccionada)
      })
      misRespuestas.push(misRespuestasDocente)
    })
    setMargenesSeparadores(myMargenesSeparadores)
    setLinesDocentes(myLines)
    setRespuestas(misRespuestas)
  }, [])
  return (
    <div className='section'>
      {pregunta.split('\n').map((parrafo) => (
        <p style={{ margin: '0 0 12px 0' }}>{parrafo}</p>
      ))}

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className='lista-coordinadores'>
          <label>{publico}</label>
          {docentes.map(({ nombre, nss, materia }, i) => {
            return (
              <>
                <div
                  style={
                    linesDocentes[i] > 0
                      ? { marginTop: margenesSeparadores[i], height: 50 }
                      : { marginTop: margenesSeparadores[i], height: 25 }
                  }
                  className='divisor-docentes'
                ></div>
                {linesDocentes[i] > 0 ? (
                  <>
                    {linesDocentes[i] > 1 ? (
                      <>
                        <span>
                          {nss}-{materia}
                        </span>
                        <span style={{ marginTop: 5 }} title={nombre}>
                          {nombre}
                        </span>
                      </>
                    ) : (
                      <>
                        <span>{nss}</span>
                        <span style={{ marginTop: 5 }} title={nombre}>
                          {nombre}-{materia}
                        </span>
                      </>
                    )}
                  </>
                ) : (
                  <span title={nombre}>
                    {nss}-{nombre}-{materia}
                  </span>
                )}
              </>
            )
          })}
        </div>
        {respuestas.length > 0
          ? opciones.map((opcion, i) => (
              <div className='option'>
                <label>{opcion.nombre}</label>
                {docentes.map((_docente, j) => {
                  return (
                    <>
                      <label
                        class='custom-radio-checkbox'
                        style={
                          linesDocentes[j] > 0
                            ? { marginTop: '17px' }
                            : { marginTop: '8.5px', height: '25px' }
                        }
                      >
                        <input
                          id='id_12334'
                          checked={respuestas[j][i]}
                          onChange={() => {}}
                          onClick={() => {
                            let misRespuestas = []
                            respuestas.forEach((respuesta) => {
                              let misRegistros = []
                              respuesta.forEach((registro) => {
                                misRegistros.push(registro)
                              })
                              misRespuestas.push(misRegistros)
                            })
                            for (let k = 0; k <= opciones.length; k++) {
                              misRespuestas[j][k] = false
                              if (k == i) {
                                misRespuestas[j][k] = true
                              }
                            }
                            let estructuraRespuestaReactivo = []
                            opciones.forEach((opcionRespuesta, i) => {
                              docentes.map((regDocente, k) => {
                                if (misRespuestas[k][i]) {
                                  estructuraRespuestaReactivo.push({
                                    id_coordinador: regDocente.id_coordinador,
                                    id_assignment_teacher:
                                      regDocente.id_assignment_teacher,
                                    id_agrupador: id_agrupador,
                                    id_reactivo: id_reactivo,
                                    id_opcion: opcionRespuesta.id,
                                    respuesta_corta: '',
                                  })
                                }
                              })
                            })
                            handleChangeRespuestaReactivo(
                              id_reactivo,
                              estructuraRespuestaReactivo
                            )
                            setRespuestas(misRespuestas)
                          }}
                          type='radio'
                        />
                      </label>
                    </>
                  )
                })}
              </div>
            ))
          : null}
      </div>
    </div>
  )
}

function PreguntaIntervalo({
  pregunta,
  docentes,
  opciones,
  id_reactivo,
  id_agrupador,
  handleChangeRespuestaReactivo,
  publico,
}) {
  const [respuestas, setRespuestas] = useState([])
  const [linesDocentes, setLinesDocentes] = useState([])
  const [margenesSeparadores, setMargenesSeparadores] = useState([])
  useEffect(() => {
    let misRespuestas = []
    let myLines = []
    let myMargenesSeparadores = []
    docentes.forEach((docente, i) => {
      let calculoLinea = calcLines(docente.nss, docente.nombre, docente.materia)
      myLines.push(calculoLinea)
      if (i == 0) {
        myMargenesSeparadores.push(51)
      } else {
        myMargenesSeparadores.push(
          (myLines[i - 1] > 0 ? 57 : 33) + myMargenesSeparadores[i - 1]
        )
      }

      let misRespuestasDocente = []
      opciones.forEach((opcion) => {
        let opcionSeleccionada = false
        docente.respuestas.forEach((response) => {
          if (
            response.id_reactive === id_reactivo &&
            response.id_reactive_group === id_agrupador &&
            response.id_reactive_option === opcion.id
          ) {
            misRespuestasDocente.forEach((_, j) => {
              misRespuestasDocente[j] = true
            })
            opcionSeleccionada = true
          }
        })
        misRespuestasDocente.push(opcionSeleccionada)
      })
      misRespuestas.push(misRespuestasDocente)
    })
    setMargenesSeparadores(myMargenesSeparadores)
    setLinesDocentes(myLines)
    setRespuestas(misRespuestas)
  }, [])

  return (
    <>
      <div className='section'>
        {pregunta.split('\n').map((parrafo) => (
          <p style={{ margin: '0 0 12px 0' }}>{parrafo}</p>
        ))}

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className='lista-coordinadores'>
            <label>{publico}</label>
            {docentes.map(({ nombre, nss, materia }, i) => {
              return (
                <>
                  <div
                    style={
                      linesDocentes[i] > 0
                        ? { marginTop: margenesSeparadores[i], height: 50 }
                        : { marginTop: margenesSeparadores[i], height: 25 }
                    }
                    className='divisor-docentes'
                  ></div>
                  {linesDocentes[i] > 0 ? (
                    <>
                      {linesDocentes[i] > 1 ? (
                        <>
                          <span>
                            {nss}-{materia}
                          </span>
                          <span style={{ marginTop: 5 }} title={nombre}>
                            {nombre}
                          </span>
                        </>
                      ) : (
                        <>
                          <span>{nss}</span>
                          <span style={{ marginTop: 5 }} title={nombre}>
                            {nombre}-{materia}
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <span title={nombre}>
                      {nss}-{nombre}-{materia}
                    </span>
                  )}
                </>
              )
            })}
          </div>
          {opciones.map((opcion, i) => (
            <div className='option option-escala' style={{ width: '100%' }}>
              <label style={{ marginBottom: '5px' }}>{opcion.nombre}</label>
              {respuestas.length > 0
                ? docentes.map((_docente, j) => (
                    <>
                      <label
                        class='custom-radio-checkbox'
                        style={
                          linesDocentes[j] > 0
                            ? { marginTop: '17px' }
                            : { marginTop: '8.5px', height: '25px' }
                        }
                      >
                        {i === 0 ? (
                          <></>
                        ) : respuestas[j][i] ? (
                          <div
                            style={
                              linesDocentes[j] > 0
                                ? { marginTop: '12px' }
                                : { marginTop: '12px' }
                            }
                            className='escala-seleccionada'
                          ></div>
                        ) : null}
                        <input
                          checked={respuestas[j][i]}
                          onChange={() => {}}
                          onClick={() => {
                            let misRespuestas = []
                            respuestas.forEach((respuesta) => {
                              let misRegistros = []
                              respuesta.forEach((registro) => {
                                misRegistros.push(registro)
                              })
                              misRespuestas.push(misRegistros)
                            })
                            for (let k = 0; k <= i; k++) {
                              misRespuestas[j][k] = true
                            }
                            for (let k = i + 1; k <= opciones.length; k++) {
                              misRespuestas[j][k] = false
                            }
                            let estructuraRespuestaReactivo = []
                            opciones.forEach((opcionRespuesta, i) => {
                              docentes.map((regDocente, k) => {
                                if (misRespuestas[k][i]) {
                                  estructuraRespuestaReactivo.push({
                                    id_coordinador: regDocente.id_coordinador,
                                    id_assignment_teacher:
                                      regDocente.id_assignment_teacher,
                                    id_agrupador: id_agrupador,
                                    id_reactivo: id_reactivo,
                                    id_opcion: opcionRespuesta.id,
                                    respuesta_corta: '',
                                  })
                                }
                              })
                            })

                            estructuraRespuestaReactivo = [
                              estructuraRespuestaReactivo[
                                estructuraRespuestaReactivo.length - 1
                              ],
                            ]
                            handleChangeRespuestaReactivo(
                              id_reactivo,
                              estructuraRespuestaReactivo
                            )
                            setRespuestas(misRespuestas)
                          }}
                          type='radio'
                        />
                      </label>
                    </>
                  ))
                : null}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
