import React from 'react';
import { TD, FirstOptionsText, Input } from './styles';

export default function TableRow({
  id,
  asignatura,
  docente,
  abiertaOptionsSelected,
  setSelectedOptionsAbierta,
  tipoEvaluacion
}){

  return (
    <tr>
      {
        tipoEvaluacion==='Opinion_Estudiantil' ?
        <>
        <TD
          odd={id % 2 !== 0}
          style={{ borderRight: '2px solid #b1b1b1', width: '30px' }}>
          <FirstOptionsText>{asignatura}</FirstOptionsText>
        </TD>
        <TD
          style={{ paddingLeft: '11px', borderRight: '2px solid #b1b1b1' }}
          odd={id % 2 !== 0}>
          <FirstOptionsText>{docente}</FirstOptionsText>
        </TD>
        </>
        :null
      }
      <TD odd={id % 2 !== 0} style={{ padding: 0 }}>
        <Input
          type='text'
          name='respuesta'
          placeholder='Escribe tu respuesta...'
          
        />
      </TD>
    </tr>
  );
};
