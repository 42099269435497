/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'

import MenuIcon from '../../images/MenuIcon'
import MisEvaluacionesIcon from '../../images/MisEvaluacionesIcon'
import EvaluacionesDocenteIcon from '../../images/EvaluacionesDocenteIcon'
import ReportesIcon from '../../images/ReportesIcon'
import EstructuraIcon from '../../images/EstructuraIcon'
import UsuariosIcon from '../../images/UsuariosIcon'
import ObjetosInstrumentoIcon from '../../images/ObjetosInstrumentoIcon'
import ObjetosDimensionesIcon from '../../images/ObjetosDimensionesIcon'
import CalendarioIcon from '../../images/CalendarioIcon'
import CampusIcon from '../../images/CampusIcon'
import Footer from '../Footer/Footer'
import Can from '../../utils/can'
import './styles.css'

function Menu({ permisos }) {
  let { pathname } = useLocation()
  const [reportes, setReportes] = useState(false)
  const [avances, setAvances] = useState(false)
  const [resultados, setResultados] = useState(false)
  const [estructura, setEstructura] = useState(false)
  const [cargaManual, setCargaManual] = useState(false)
  const [objetos, setObjetos] = useState(false)
  const [dimensiones, setDimensiones] = useState(false)
  const [calendario, setCalendario] = useState(false)

  const newPermisos = permisos.reduce(
    (obj, item) => ({
      ...obj,
      [item.funcionalidad]: item,
    }),
    {}
  )

  function plegar(desplegar, valor) {
    setReportes(false)
    setAvances(false)
    setResultados(false)
    setEstructura(false)
    setCargaManual(false)
    setObjetos(false)
    setDimensiones(false)
    setCalendario(false)
    desplegar(valor)
  }
  //<Can I='read' a='usuario'></Can>
  return (
    <>
      <aside>
        <ul>
          <li className='menu-title'>
            <MenuIcon />
            Menú
          </li>
          <Can I='read' a='mis_evaluaciones'>
            <li
              className={pathname === '/mis-evaluaciones' ? 'active' : null}
              style={{ position: 'relative' }}
              onClick={() => plegar(() => {}, '')}
            >
              <Link to='/mis-evaluaciones'>
                <MisEvaluacionesIcon /> Mis evaluaciones
              </Link>
            </li>
          </Can>

          <Can I='read' a='evaluaciones_docente'>
            <li
              className={
                pathname === '/evaluaciones-docentes' ? 'active' : null
              }
              style={{ position: 'relative' }}
              onClick={() => plegar(() => {}, '')}
            >
              <Link to='/evaluaciones-docentes'>
                <EvaluacionesDocenteIcon /> Evaluación de mis docentes
              </Link>
            </li>
          </Can>

          <Can I='read' a='reportes_avance'>
            <li
              className={
                '/' + pathname.split('/')[1] === '/reportes' ||
                reportes ||
                avances ||
                resultados
                  ? 'active'
                  : null
              }
              style={{ position: 'relative' }}
            >
              <a href='#' onClick={() => plegar(setReportes, !reportes)}>
                <ReportesIcon /> Reporte
              </a>
              {reportes || avances || resultados ? (
                <div className='reportes-tab'>
                  <a
                    href='#'
                    className={avances ? 'active' : null}
                    style={
                      avances
                        ? {
                            backgroundColor: '#c6c6c5',
                            color: '#1b1c20',
                            borderLeft: '5px solid #e72400',
                          }
                        : {}
                    }
                    onClick={() => plegar(setAvances, !avances)}
                  >
                    Reportes de Avance
                  </a>
                  {avances && (
                    <>
                      <Can I='read' a='reportes_avance_participacion_global'>
                        <Link
                          to='/reportes/participacion-global'
                          className={
                            pathname === '/reportes/participacion-global'
                              ? 'active'
                              : null
                          }
                          style={{ marginLeft: '35px' }}
                        >
                          Participación Global
                        </Link>
                      </Can>

                      <Can
                        I='read'
                        a='reportes_avance_participacion_coordinador'
                      >
                        <Link
                          to='/reportes/participacion-coordinador'
                          className={
                            pathname === '/reportes/participacion-coordinador'
                              ? 'active'
                              : null
                          }
                          style={{ marginLeft: '35px' }}
                        >
                          Participación por Coordinador
                        </Link>
                      </Can>

                      <Can I='read' a='reportes_avance_participacion_docente'>
                        <Link
                          to='/reportes/participacion-docente'
                          className={
                            pathname === '/reportes/participacion-docente'
                              ? 'active'
                              : null
                          }
                          style={{ marginLeft: '35px' }}
                        >
                          Participación por Docente
                        </Link>
                      </Can>
                    </>
                  )}

                  <a
                    href='#'
                    className={resultados ? 'active' : null}
                    style={
                      resultados
                        ? {
                            backgroundColor: '#c6c6c5',
                            color: '#1b1c20',
                            borderLeft: '5px solid #e72400',
                          }
                        : {}
                    }
                    onClick={() => plegar(setResultados, !resultados)}
                  >
                    Reportes de Resultados
                  </a>
                  {resultados && (
                    <>
                      <Can
                        I='read'
                        a='reportes_avance_integral_periodo_docente'
                      >
                        <Link
                          to='/reportes/integral-periodo-docente'
                          className={
                            pathname === '/reportes/integral-periodo-docente'
                              ? 'active'
                              : null
                          }
                          style={{ marginLeft: '35px' }}
                        >
                          Integral por Periodo
                        </Link>
                      </Can>

                      <Can
                        I='read'
                        a='reportes_avance_integral_periodo_docente'
                      >
                        <Link
                          to='/reportes/integral-dimension'
                          className={
                            pathname === '/reportes/integral-dimension'
                              ? 'active'
                              : null
                          }
                          style={{ marginLeft: '35px' }}
                        >
                          Integral por Dimensión
                        </Link>
                      </Can>

                      <Can I='read' a='reportes_avance_reporte_CRN_docente'>
                        <Link
                          to='/reportes/crn-docente'
                          className={
                            pathname === '/reportes/crn-docente'
                              ? 'active'
                              : null
                          }
                          style={{ marginLeft: '35px' }}
                        >
                          Detalle por CRN
                        </Link>
                      </Can>

                      <Can I='read' a='reportes_avance_CRN_docente_dimension'>
                        <Link
                          to='/reportes/crn-docnete-domenciones'
                          className={
                            pathname === '/reportes/crn-docnete-domenciones'
                              ? 'active'
                              : null
                          }
                          style={{ marginLeft: '35px' }}
                        >
                          Detalle por Dimensión
                        </Link>
                      </Can>

                      <Can I='read' a='reportes_avance_CRN_docente_dimension'>
                        <Link
                          to='/reportes/detalle-instrumento'
                          className={
                            pathname === '/reportes/detalle-instrumento'
                              ? 'active'
                              : null
                          }
                          style={{ marginLeft: '35px' }}
                        >
                          Detalle Instrumento
                        </Link>
                      </Can>

                      <Can I='read' a='reportes_avance_asignaciones_FIGPO'>
                        <Link
                          to='/reportes/asignaciones-figpo'
                          className={
                            pathname === '/reportes/asignaciones-figpo'
                              ? 'active'
                              : null
                          }
                          style={{ marginLeft: '35px' }}
                        >
                          Asignaciones Figpo
                        </Link>
                      </Can>
                    </>
                  )}
                </div>
              ) : null}
            </li>
          </Can>

          <Can I='read' a='estructura'>
            <li
              className={
                '/' + pathname.split('/')[1] === '/estructa' || estructura
                  ? 'active'
                  : null
              }
              style={{ position: 'relative' }}
            >
              <a href='#' onClick={() => plegar(setEstructura, !estructura)}>
                <EstructuraIcon /> Estructura
              </a>
              {estructura ? (
                <div className='reportes-tab'>
                  <Can I='read' a='estructura_seguimiento_academicos'>
                    <Link
                      to='/estructura/participacion-academicos'
                      className={
                        pathname === '/estructura/participacion-academicos'
                          ? 'active'
                          : null
                      }
                    >
                      Seguimiento Academicos
                    </Link>
                  </Can>

                  <Can I='read' a='estructura_creado_estructura'>
                    <Link
                      to='/estructura/creado-de-estructura'
                      className={
                        pathname === '/estructura/creado-de-estructura'
                          ? 'active'
                          : null
                      }
                    >
                      Creado de estructura
                    </Link>
                  </Can>

                  <Can I='read' a='estructura_reporte_avance'>
                    <Link
                      to='/estructura/reporte-avance'
                      className={
                        pathname === '/estructura/reporte-avance'
                          ? 'active'
                          : null
                      }
                    >
                      Reporte avance de estructuras
                    </Link>
                  </Can>
                </div>
              ) : null}
            </li>
          </Can>
          <Can I='read' a='usuarios'>
            <li
              className={pathname === '/usuarios' ? 'active' : null}
              style={{ position: 'relative' }}
              onClick={() => plegar(() => {}, '')}
            >
              <Link to='/usuarios'>
                <UsuariosIcon />
                Usuarios
              </Link>
            </li>
          </Can>

          {newPermisos['carga_manual_resultados_profesor'].leer === 1 ||
          newPermisos['carga_manual_resultados_CRN'].leer === 1 ? (
            <li
              className={
                '/' + pathname.split('/')[1] === '/carga-manual' || cargaManual
                  ? 'active'
                  : null
              }
              style={{ position: 'relative' }}
            >
              <a href='#' onClick={() => plegar(setCargaManual, !cargaManual)}>
                <ObjetosInstrumentoIcon />
                Carga Manual de Resultados
              </a>
              {cargaManual ? (
                <div className='reportes-tab'>
                  {newPermisos['carga_manual_resultados_profesor'].leer ===
                    1 && (
                    <Link
                      to='/carga-manual/profesor'
                      className={
                        pathname === '/carga-manual/profesor' ? 'active' : null
                      }
                    >
                      Carga por Profesor
                    </Link>
                  )}

                  {newPermisos['carga_manual_resultados_CRN'].leer === 1 && (
                    <Link
                      to='/carga-manual/crn'
                      className={
                        pathname === '/carga-manual/crn' ? 'active' : null
                      }
                    >
                      Carga por CRN
                    </Link>
                  )}
                </div>
              ) : null}
            </li>
          ) : null}

          <Can I='read' a='objetos_instrumento'>
            <li
              className={
                '/' + pathname.split('/')[1] === '/objetos' || objetos
                  ? 'active'
                  : null
              }
              style={{ position: 'relative' }}
            >
              <a href='#' onClick={() => plegar(setObjetos, !objetos)}>
                <ObjetosInstrumentoIcon />
                Objetos de Instrumento
              </a>
              {objetos ? (
                <div className='reportes-tab'>
                  <Can I='read' a='objetos_instrumento_etiquetas'>
                    <Link
                      to='/objetos/etiquetas'
                      className={
                        pathname === '/objetos/etiquetas' ? 'active' : null
                      }
                    >
                      Etiquetas
                    </Link>
                  </Can>

                  <Can I='read' a='objetos_instrumento_reactivos'>
                    <Link
                      to='/objetos/reactivos'
                      className={
                        pathname === '/objetos/reactivos' ? 'active' : null
                      }
                    >
                      Reactivos
                    </Link>
                  </Can>

                  <Can I='read' a='objetos_instrumento_instrumento'>
                    <Link
                      to='/objetos/instrumentos'
                      className={
                        pathname === '/objetos/instrumentos' ? 'active' : null
                      }
                    >
                      Instrumentos
                    </Link>
                  </Can>
                </div>
              ) : null}
            </li>
          </Can>
          <Can I='read' a='objetos_dimensiones'>
            <li
              className={
                '/' + pathname.split('/')[1] === '/dimensiones' || dimensiones
                  ? 'active'
                  : null
              }
              style={{ position: 'relative' }}
            >
              <a href='#' onClick={() => plegar(setDimensiones, !dimensiones)}>
                <ObjetosDimensionesIcon />
                Objetos de Dimensiones
              </a>
              {dimensiones ? (
                <div className='reportes-tab'>
                  <Can I='read' a='objetos_dimensiones_categorias_evaluacion'>
                    <Link
                      to='/dimensiones/categoria-evaluacion'
                      className={
                        pathname === '/dimensiones/categoria-evaluacion'
                          ? 'active'
                          : null
                      }
                    >
                      Categoria de Evaluación
                    </Link>
                  </Can>

                  <Can I='read' a='objetos_dimensiones_dimensiones'>
                    <Link
                      to='/dimensiones/dimensiones'
                      className={
                        pathname === '/dimensiones/dimensiones'
                          ? 'active'
                          : null
                      }
                    >
                      Dimensiones
                    </Link>
                  </Can>

                  <Can I='read' a='objetos_dimensiones_evaluacion_integral'>
                    <Link
                      to='/dimensiones/evaluacion_integral'
                      className={
                        pathname === '/dimensiones/evaluacion_integral'
                          ? 'active'
                          : null
                      }
                    >
                      Evaluación Integral
                    </Link>
                  </Can>
                </div>
              ) : null}
            </li>
          </Can>
          <Can I='read' a='campus'>
            <li
              className={pathname === '/campus' ? 'active' : null}
              style={{ position: 'relative' }}
              onClick={() => plegar(() => {}, '')}
            >
              <Link to='/campus'>
                <CampusIcon />
                Campus
              </Link>
            </li>
          </Can>
          <Can I='read' a='calendario'>
            <li
              className={
                '/' + pathname.split('/')[1] === '/calendario' || calendario
                  ? 'active'
                  : null
              }
              style={{ position: 'relative' }}
            >
              <a href='#' onClick={() => plegar(setCalendario, !calendario)}>
                <CalendarioIcon />
                Calendario
              </a>
              {calendario ? (
                <div className='reportes-tab'>
                  <Can I='read' a='calendario_ciclo-periodo'>
                    <Link
                      to='/calendario/creado-cicloPeriodo'
                      className={
                        pathname === '/calendario/creado-cicloPeriodo'
                          ? 'active'
                          : null
                      }
                    >
                      Relación Ciclo-Periodo
                    </Link>
                  </Can>

                  <Can I='read' a='calendario_plantilla-evaluaciones'>
                    <Link
                      to='/calendario/creado-plantillaEvaluaciones'
                      className={
                        pathname === '/calendario/creado-plantillaEvaluaciones'
                          ? 'active'
                          : null
                      }
                    >
                      Relación Partes Periodo
                    </Link>
                  </Can>

                  <Can I='read' a='calendario_evaluacion-año'>
                    <Link
                      to='/calendario/creado-evaluacion'
                      className={
                        pathname === '/calendario/creado-evaluacion'
                          ? 'active'
                          : null
                      }
                    >
                      Apertura de Calendario
                    </Link>
                  </Can>
                </div>
              ) : null}
            </li>
          </Can>
        </ul>
      </aside>

      <Footer />
    </>
  )
}

const mapStateToProps = (state) => ({
  permisos: state.permisos,
})

export default connect(mapStateToProps)(Menu)
