import styled from 'styled-components';

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  @media screen and (min-width: 769px) {
    margin-bottom: 2em;
  }
`;

export const TH = styled.th`
  height: 55px;
  width: 20px;
  background-color: ${({ dark }) => (dark ? '#E2E2E2' : 'white')};
  font-size: 13px;
  font-weight: 400;
  text-align: left;

  @media screen and (min-width: 769px) {
    height: 35px;
    padding-left: 7px;
  }
`;

export const TD = styled.td`
  text-align: center;
  background-color: ${({ odd, dark }) => {
    if (odd && dark) {
      return '#C2C2C2';
    } else if (odd && !dark) {
      return '#DFDFDF';
    } else if (!odd && dark) {
      return '#DEDEDE';
    } else {
      return '#FFFFFF';
    }
  }};

  @media screen and (min-width: 769px) {
    padding-left: 10px;
  }
`;

export const OptionsText = styled.p`
  font-size: 13px;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-weight: 400;
  margin: 0 auto;
  height: 100%;
  text-align: left;
  padding: 7px 0 0;
`;

export const FirstOptionsText = styled.p`
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  margin: 6px 0;

  @media screen and (min-width: 769px) {
    font-size: 16px;
    margin: 0;
  }
`;

export const Input = styled.input`
  width: 93%;
  height: 2em;
  background-color: inherit;
  border: none;
  color: gray;

  &:placeholder-shown {
    color: gray;
  }
`;
