import React from 'react'
import PropTypes from 'prop-types'

import PageTitle from '../PageTitle'
import './index.css'

function Contenedor(props) {
  return (
    <main>
      {props.title ? <PageTitle title={props.title} /> : null}
      <div
        className={props.contenedorPadre ? 'contenedor-padre' : 'contenedor'}
      >
        {props.children}
      </div>
    </main>
  )
}

Contenedor.propTypes = {
  title: PropTypes.string,
  contenedorPadre: PropTypes.bool,
  children: PropTypes.element,
}

export default Contenedor
