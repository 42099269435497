/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import EditButton from '../../Components/Botones/EditButton'
import DataTable from '../../Components/DataTables'
import NewModal from '../../Components/Modal/NewModal'
import { getRequest, postRequest } from '../../utils/requester'
import ToggableButton from '../../Components/Botones/ToggableButton'
import Select from 'react-select'
import Cancel from '@material-ui/icons/Cancel'
import { toast } from 'react-toastify'
/*import Can from '../../Utils/Funciones/can'*/

function Catalogo() {
  const [dataInicial, setDataInicial] = useState([])
  const [dataFiltradas, setDataFiltradas] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingModalEditar, setLoadingModalEditar] = useState(false)
  const [modalEditarIsOpen, setModalEditarIsOpen] = useState(false)
  const [editar, setEditar] = useState({ options: [], type: {} })
  const [, setDeleteModal] = useState(false)

  const opcionesVisibilidad = [
    {
      label: 'Seguimiento Estándares',
      value: 'seguimiento estandares',
    },
    {
      label: 'Seguimiento Plantilla Académica',
      value: 'seguimiento plantilla academica',
    },
  ]

  const opcionesTipo = [
    {
      label: 'Selección unica',
      value: 'selección unica',
    },
    {
      label: 'Selección multiple',
      value: 'multi selección',
    },
    {
      label: 'Texto',
      value: 'texto',
    },
  ]

  const openEditModal = async (reg) => {
    let misValoresVisibilidad = []
    if (reg.show_on_estandares) {
      misValoresVisibilidad.push({
        label: 'Seguimiento Estándares',
        value: 'seguimiento estandares',
      })
    }
    if (reg.show_on_plantilla) {
      misValoresVisibilidad.push({
        label: 'Seguimiento Plantilla Académica',
        value: 'seguimiento plantilla academica',
      })
    }
    reg.visibilidad = misValoresVisibilidad
    let tipoMenu = {}
    opcionesTipo.forEach((opcion) => {
      if (opcion.value === reg.type) {
        tipoMenu = opcion
      }
    })
    reg.type = tipoMenu
    setEditar(reg)
    setModalEditarIsOpen(true)
  }

  const handleEditar = async () => {
    if (!editar.visibilidad) {
      toast.error('Se debe seleccionar visibilidad')
      return
    }
    setLoadingModalEditar(true)
    try {
      let opcionesBody = ''

      editar.options.forEach((opc, i) => {
        opcionesBody += opc
        if (i != editar.options.length - 1) {
          opcionesBody += ','
        }
      })

      let visibilidadBody = []
      editar.visibilidad.forEach((visible) => {
        visibilidadBody.push(visible.value)
      })

      let body = {
        id: editar.id,
        nombre: editar.name,
        opciones: editar.type.value == 'texto' ? '' : opcionesBody,
        visibilidad: visibilidadBody,
        status: editar.status ? 1 : 0,
        tipo: editar.type.value,
      }

      let editarResponse = await postRequest(
        '/estructura/participacion_academica/catalogo/editar',
        body
      )

      if (editarResponse.s === 'OK') {
        setModalEditarIsOpen(false)
        setLoadingModalEditar(false)
        reloadList()
      }
      setLoadingModalEditar(false)
    } catch (error) {
      console.error(error)
      setLoadingModalEditar(false)
    }
  }

  const testHeader = ['Folio', 'Nombre', 'Acciones']
  const customStyles = {
    option: (provided, _state) => ({
      ...provided,
      color: '#616b74',
      fontSize: 14,
      fontWeight: 400,
      padding: '5px 25px',
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      userSelect: 'none',
      backgroundColor: '#fff',
      borderTop: '1px solid #ccc',
    }),
    control: (provided, _state) => ({
      ...provided,
      minHeight: 0,
      height: '25px',
      borderRadius: 15,
      fontSize: 11,
      backgroundColor: '#f9f9f9',
      padding: 0,
    }),
    placeholder: (provided, _state) => ({
      ...provided,
      padding: 0,
      fontSize: 11,
      fontWeight: 400,
    }),
    menu: (provided, _state) => ({
      ...provided,
      borderRadius: 0,
    }),
    input: (provided, _state) => ({
      ...provided,
      padding: 0,
      fontSize: 11,
      fontWeight: 400,
      borderRadius: 0,
    }),
    singleValue: (provided, _state) => ({
      ...provided,
      top: '50%',
    }),
  }
  ////////// Filtrar resultados segun el input de busqueda //////////
  const search = (e) => {
    let text = e.target.value.toLowerCase()

    let result = dataInicial.filter((row) => {
      if (row[0].toString().toLowerCase().includes(text)) return true
      else if (row[1].toLowerCase().includes(text)) return true
      return false
    })
    setDataFiltradas(result)
  }

  const reloadList = async () => {
    setLoading(true)
    try {
      //const responseEtiqueta = await getRequest('/etiqueta');
      const response = await getRequest(
        '/estructura/participacion_academica/catalogo'
      )
      if (response.s === 'OK') {
        let data = []
        response.d.forEach((reg) => {
          data.push([
            reg.id,
            reg.name,
            <EditButton onClick={() => openEditModal(reg)}>Editar</EditButton>,
          ])
        })
        setDataInicial(data)
        setDataFiltradas(data)
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    reloadList()
  }, [])

  return (
    <>
      <div className='opciones-bar'>
        <div style={{ float: 'right', marginTop: '30px' }}>
          <input
            type='search'
            placeholder='Buscar'
            className='SearchInput-tabla-completa SearchInput-Usuarios'
            onChange={search}
          />
        </div>
      </div>
      <div style={{ height: 'calc(100% - 70px)' }}>
        <DataTable
          headers={testHeader}
          data={dataFiltradas}
          loading={loading}
          paginate
        />
      </div>
      <NewModal
        title='Editar Catalogo'
        open={modalEditarIsOpen}
        handleClose={() => setModalEditarIsOpen(false)}
        height={600}
        width={600}
        loading={loadingModalEditar}
        handleSubmit={() => handleEditar(editar)}
        onDelete={() => setDeleteModal(true)}
        permiso='estructura_seguimiento_academicos_catalogos'
      >
        <div className='editar-catalogo-from'>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <label id='tag-nombre'>ID:</label>
            </Grid>
            <Grid item xs={10}>
              <input
                disabled
                placeholder='Ingresa Nombre'
                value={editar.id}
                style={{ textAlign: 'center' }}
              />
            </Grid>
            <Grid item xs={2}>
              <label id='tag-nombre'>Nombre:</label>
            </Grid>
            <Grid item xs={10}>
              <input
                disabled
                placeholder='Ingresa Nombre'
                value={editar.name}
                style={{ textAlign: 'center' }}
              />
            </Grid>
            <Grid item xs={2}>
              <label id='tag-tipo'>Tipo:</label>
            </Grid>
            <Grid item xs={10}>
              <Select
                value={editar.type}
                onChange={(valores) => {
                  setEditar({
                    ...editar,
                    type: valores,
                  })
                }}
                style={customStyles}
                options={opcionesTipo}
                className='select-search-box'
                classNamePrefix='select-search'
              />
            </Grid>
            <Grid item xs={2}>
              <label id='tag-descripcion'>Opciones:</label>
            </Grid>
            <Grid item xs={10}>
              <div className='contenedor-opciones-descripcion-catalogo'>
                {editar.type.value === 'texto' ? (
                  <></>
                ) : (
                  editar.options.map((opcion, i) => (
                    <div className='contenedor-una-opcion'>
                      <input
                        disabled={editar.type.value == 'texto'}
                        value={opcion}
                        onChange={(e) => {
                          if (!e.target.value.includes(',')) {
                            let misOpciones = [...editar.options]
                            misOpciones[i] = e.target.value
                            setEditar({
                              ...editar,
                              options: misOpciones,
                            })
                          }
                        }}
                      />
                      <button
                        disabled={editar.type.value == 'texto'}
                        onClick={() => {
                          let eliminarOpcionArray = editar.options.filter(
                            (opc, j) => {
                              return j != i
                            }
                          )
                          setEditar({ ...editar, options: eliminarOpcionArray })
                        }}
                        className='eliminar-opcion-descripcion'
                      >
                        <Cancel />
                      </button>
                    </div>
                  ))
                )}
                {editar.type.value === 'texto' ? (
                  <></>
                ) : (
                  <button
                    disabled={editar.type.value == 'texto'}
                    onClick={() => {
                      setEditar({ ...editar, options: [...editar.options, ''] })
                    }}
                  >
                    Añadir Opción
                  </button>
                )}
              </div>
            </Grid>

            <Grid item xs={2}>
              <label id='tag-visibilidad'>Visibilidad:</label>
            </Grid>
            <Grid item xs={10}>
              <Select
                value={editar.visibilidad}
                onChange={(valores) => {
                  setEditar({
                    ...editar,
                    visibilidad: valores,
                  })
                }}
                style={customStyles}
                options={opcionesVisibilidad}
                className='select-search-box'
                classNamePrefix='select-search'
                isMulti
              />
            </Grid>
            <Grid item xs={2}>
              <label id='tag-descripcion'>Activo:</label>
            </Grid>
            <Grid item xs={10}>
              <ToggableButton
                yes={editar.status}
                onClick={() => {
                  setEditar({
                    ...editar,
                    status: !editar.status,
                  })
                }}
              />
            </Grid>
          </Grid>
        </div>
      </NewModal>
    </>
  )
}

export default Catalogo
