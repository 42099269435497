/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Grid, CircularProgress } from '@material-ui/core'
import Select from 'react-select'
import Reorder from '@material-ui/icons/Reorder'

import Contenedor from '../../Components/Contenedor/Contenedor'
import NewButton from '../../Components/Botones/NewButton'
import EditButton from '../../Components/Botones/EditButton'
import DataTable from '../../Components/DataTables'
import NewModal from '../../Components/NewModal'
import { getRequest, postRequest } from '../../utils/requester'
import SearchIcon from '../../images/SearchIcon'
import CancelIcon from '@material-ui/icons/Cancel'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { toast } from 'react-toastify'
import Visualizar from './Visualizar'
import Descripcion from './Descripcion'
import { EditorState, ContentState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
//import Can from '../../Utils/Funciones/can';
import './style.css'

export default function Instrumentos() {
  const headers = [
    'Folio',
    'Clave',
    'Total Reactivos',
    'Total Peso',
    'Nombre',
    'Acciones',
  ]
  const [tab1Data, setTab1Data] = useState([])
  const [tab1FilteredData, setTab1FilteredData] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [openNuevoInstrumento, setONI] = useState(false)
  const [openEditarInstrumento, setOEI] = useState(false)
  const [loadingModal, setLModal] = useState(false)
  const [loadingModalEtiquetas, setLModalEtiquetas] = useState(false)

  const [etiqueta, setEtiqueta] = useState('')
  const [opcionesEtiquetas, setOpcionesEtiquetas] = useState([])

  const [preguntasNuevasF, setPreguntasNuevaF] = useState([])
  const [preguntasFiltradas, setPreguntasFiltradas] = useState([])

  const [misId, newId] = useState(1)
  const [contenidoNuevoAgrupador, setContenidoNuevoAgrupador] = useState([])
  const [agrupadores, setAgrupadores] = useState([])
  const [nombreInstrumento, setNombreInstrumento] = useState('')
  const [nuevoInstrumentoNombre, setNuevoInstrumentoNombre] = useState('')
  const [nuevoInstrumentoClave, setNuevoInstrumentoClave] = useState('')
  const [, setNuevoInstrumentoDescripcion] = useState('')
  const [, setNuevoInstrumentoReactivos] = useState('')
  const [reactivosEliminar, setReactivosEliminar] = useState([])
  const [editarReactivoData, setEditarReactivoData] = useState({})

  const [claveInstrumento, setClaveInstrumento] = useState('')
  const [, setDescripcionInstrumento] = useState('')
  const [buscarEtiquetas, setBuscarEtiquetas] = useState('')

  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteModalLoading, setDeleteModalLoading] = useState(false)

  const [refresh, setRefresh] = useState(false)

  const [visualizar, setVisualizar] = useState(false)

  const [modalDescripcion, setModalDescripcion] = useState(false)
  const [, setEditorState] = useState(EditorState.createEmpty())
  const [valueHTML, setValueHTML] = useState('')

  useEffect(() => {
    loadTable()
  }, [])

  const allowDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const drop = (e) => {
    e.stopPropagation()
    e.preventDefault()
    let reactivo = e.dataTransfer.getData('id_reactivo')

    ////// Clonar reactivo de la lista y añadirlo al agrupador //////
    var reactivoCopiado = document.getElementById(reactivo).cloneNode(true)
    reactivoCopiado.id = `cloned${reactivoCopiado.id}`
    e.target.appendChild(reactivoCopiado)

    const idAgrupador = parseInt(e.currentTarget.id.split('-')[1])

    ////// Obtener ID del reactivo a insertar //////
    let idReactivo = document.getElementById(reactivo).getAttribute('id')
    idReactivo = parseInt(idReactivo.split('-')[1])

    let pregunta = preguntasFiltradas.filter(
      (pregunta) => pregunta.id === idReactivo
    )

    ////// Eliminar reactivo de la lista despues de añadirlo a un agrupador //////
    const preguntaEliminar = preguntasFiltradas.indexOf(pregunta[0])
    let preguntasFiltradasActualizar = []
    preguntasFiltradas.forEach((pregunta) =>
      preguntasFiltradasActualizar.push(pregunta)
    )
    if (preguntaEliminar > -1) {
      preguntasFiltradasActualizar.splice(preguntaEliminar, 1)
    }
    setPreguntasFiltradas(preguntasFiltradasActualizar)

    ////// Identificar a que agrupador se le desea añadir el reactivo (Modal Nuevo) //////
    const agrupador = contenidoNuevoAgrupador.filter(
      (agrupador) => agrupador.numero === idAgrupador
    )[0]

    ////// Identificar a que agrupador se le desea añadir el reactivo (Modal Editar) //////
    const agrupadorEdit = agrupadores.filter(
      (agrupador) => agrupador.numero === idAgrupador
    )[0]

    ////// Identificar si el agrupador es del modal nuevo o editar //////
    if (agrupador === undefined) {
      let new_valor_reactivo = 0
      if (agrupadorEdit.aleatorio == '1') {
        new_valor_reactivo =
          agrupadorEdit.total_reactivos / agrupadorEdit.total_aleatorio
        new_valor_reactivo = isNaN(new_valor_reactivo)
          ? 0
          : parseFloat(new_valor_reactivo).toFixed(2)
      }
      agrupadorEdit.reactivos.push({
        ...pregunta[0],
        valor_reactivo: agrupadorEdit.aleatorio == '1' ? new_valor_reactivo : 0,
      })
      let dataMas = []
      agrupadores.forEach((reg) => {
        if (reg.numero === idAgrupador) {
          dataMas.push(agrupadorEdit)
        } else {
          dataMas.push(reg)
        }
      })
      setAgrupadores(dataMas)
    } else {
      let new_valor_reactivo = 0
      if (agrupador.aleatorio == '1') {
        new_valor_reactivo =
          agrupador.total_reactivos / agrupador.total_aleatorio
        new_valor_reactivo = isNaN(new_valor_reactivo)
          ? 0
          : parseFloat(new_valor_reactivo).toFixed(2)
      }
      agrupador.reactivos.push({
        ...pregunta[0],
        valor_reactivo: agrupador.aleatorio == '1' ? new_valor_reactivo : 0,
      })
      let dataMas = []
      contenidoNuevoAgrupador.forEach((reg) => {
        if (reg.numero === idAgrupador) {
          dataMas.push(agrupador)
        } else {
          dataMas.push(reg)
        }
      })
      setContenidoNuevoAgrupador(dataMas)
    }
    changeCantidadReactivos()
    changePesoReactivos()
  }

  const drag = (e) => {
    e.dataTransfer.setData('id_reactivo', e.target.id)

    const idReactivo = parseInt(e.target.id.split('-')[1])

    ////// Añadir reactivo seleccionado a la lista de reactivos a eliminar //////
    if (e.currentTarget.parentNode.id === 'tabla-preguntas') {
      setReactivosEliminar([...reactivosEliminar, idReactivo])
    }
  }

  // *---------- Permitir drop sobre algun reactivo de algun agrupador ----------* //
  const appendSibling = (e) => {
    e.stopPropagation()
    let reactivo = e.dataTransfer.getData('id_reactivo')
    e.currentTarget.insertAdjacentElement(
      'beforebegin',
      document.getElementById(reactivo)
    )

    const idAgrupador = parseInt(
      e.currentTarget.parentNode.parentNode.id.split('-')[1]
    )

    let idReactivo = document.getElementById(reactivo).getAttribute('id')
    idReactivo = parseInt(idReactivo.split('-')[1])
    const nombreReactivo = document
      .getElementById(reactivo)
      .getAttribute('nombre')
    const preguntaReactivo = document
      .getElementById(reactivo)
      .getAttribute('pregunta')

    const dataReactivo = {
      id: idReactivo,
      numero: idReactivo,
      nombre: nombreReactivo,
      pregunta: preguntaReactivo,
      valor_reactivo: 0,
    }

    const agrupador = contenidoNuevoAgrupador.filter(
      (agrupador) => agrupador.numero === idAgrupador
    )[0]
    const exists = agrupador.reactivos.filter(
      (reactivo) => reactivo.id === idReactivo
    )[0]
    if (exists === undefined) {
      agrupador.reactivos.push(dataReactivo)
    }
  }

  const handleChangeValorAgrupador = (value, idAgrupador) => {
    let idInputValorAgrupador = idAgrupador + 'i'
    idAgrupador = parseInt(idAgrupador.split('-')[1])
    ////// Identificar a que agrupador se le desea añadir el reactivo (Modal Nuevo) //////
    const agrupador = contenidoNuevoAgrupador.filter(
      (agrupador) => agrupador.numero === idAgrupador
    )[0]

    ////// Identificar a que agrupador se le desea añadir el reactivo (Modal Editar) //////
    const agrupadorEdit = agrupadores.filter(
      (agrupador) => agrupador.numero === idAgrupador
    )[0]

    document.getElementById(idInputValorAgrupador).value = value

    if (agrupador === undefined) {
      if (agrupadorEdit.aleatorio == '1') {
        for (let i = 0; i < agrupadorEdit.reactivos.length; i++) {
          let new_valor_reactivo =
            value === '' || value == '0' ? 0 : parseInt(value)
          let total_aleatorio =
            agrupadorEdit.total_aleatorio == '0' ||
            agrupadorEdit.total_aleatorio == ''
              ? 0
              : parseInt(agrupadorEdit.total_aleatorio)
          let dividido =
            new_valor_reactivo === 0
              ? ''
              : total_aleatorio === 0 || isNaN(total_aleatorio)
              ? ''
              : new_valor_reactivo / total_aleatorio

          agrupadorEdit.reactivos[i].valor_reactivo = isNaN(dividido)
            ? dividido
            : parseFloat(dividido).toFixed(2)
          document.getElementById('agrupador-' + idAgrupador + 'i' + i).value =
            isNaN(dividido) ? dividido : parseFloat(dividido).toFixed(2)
        }
      }
      agrupadorEdit.total_reactivos = value === '' ? '' : parseInt(value)
      let myValue = 0
      agrupadores.forEach((reg) => {
        myValue =
          myValue +
          (reg.total_reactivos === '' ? 0 : parseInt(reg.total_reactivos))
      })
      document.getElementById('peso_total').value = parseInt(myValue)
    } else {
      if (agrupador.aleatorio == '1') {
        for (let i = 0; i < agrupador.reactivos.length; i++) {
          let new_valor_reactivo =
            value === '' || value == '0' ? 0 : parseInt(value)
          let total_aleatorio =
            agrupador.total_aleatorio == '0' || agrupador.total_aleatorio == ''
              ? 0
              : parseInt(agrupador.total_aleatorio)
          let dividido =
            new_valor_reactivo === 0
              ? ''
              : total_aleatorio === 0 || isNaN(total_aleatorio)
              ? ''
              : new_valor_reactivo / total_aleatorio

          agrupador.reactivos[i].valor_reactivo = isNaN(dividido)
            ? dividido
            : parseFloat(dividido).toFixed(2)
          document.getElementById('agrupador-' + idAgrupador + 'i' + i).value =
            isNaN(dividido) ? dividido : parseFloat(dividido).toFixed(2)
        }
      }
      agrupador.total_reactivos = value === '' ? '' : parseInt(value)
      let myValue = 0
      contenidoNuevoAgrupador.forEach((reg) => {
        myValue =
          myValue +
          (reg.total_reactivos === '' ? 0 : parseInt(reg.total_reactivos))
      })
      document.getElementById('peso_total').value = parseInt(myValue)
    }
  }

  const handleChangeTotalReactivo = (value, idAgrupador) => {
    let idInputValorAgrupador = idAgrupador + 'ii'
    idAgrupador = parseInt(idAgrupador.split('-')[1])
    ////// Identificar a que agrupador se le desea añadir el reactivo (Modal Nuevo) //////
    const agrupador = contenidoNuevoAgrupador.filter(
      (agrupador) => agrupador.numero === idAgrupador
    )[0]

    ////// Identificar a que agrupador se le desea añadir el reactivo (Modal Editar) //////
    const agrupadorEdit = agrupadores.filter(
      (agrupador) => agrupador.numero === idAgrupador
    )[0]

    document.getElementById(idInputValorAgrupador).value = value

    if (agrupador === undefined) {
      agrupadorEdit.total_aleatorio = value === '' ? '' : parseInt(value)
      if (value != '' || value != 0 || value != '0') {
        for (let i = 0; i < agrupadorEdit.reactivos.length; i++) {
          let new_valor_reactivo = agrupadorEdit.total_reactivos / value
          agrupadorEdit.reactivos[i].valor_reactivo = isNaN(new_valor_reactivo)
            ? ''
            : parseFloat(new_valor_reactivo).toFixed(2)
          agrupadorEdit.reactivos[i].valor = isNaN(new_valor_reactivo)
            ? ''
            : parseFloat(new_valor_reactivo).toFixed(2)
          document.getElementById('agrupador-' + idAgrupador + 'i' + i).value =
            isNaN(new_valor_reactivo)
              ? ''
              : parseFloat(new_valor_reactivo).toFixed(2)
        }
      }
    } else {
      agrupador.total_aleatorio = value === '' ? '' : parseInt(value)
      if (value != '' || value != 0 || value != '0') {
        for (let i = 0; i < agrupador.reactivos.length; i++) {
          let new_valor_reactivo = agrupador.total_reactivos / value
          agrupador.reactivos[i].valor_reactivo = isNaN(new_valor_reactivo)
            ? ''
            : parseFloat(new_valor_reactivo).toFixed(2)
          agrupador.reactivos[i].valor = isNaN(new_valor_reactivo)
            ? ''
            : parseFloat(new_valor_reactivo).toFixed(2)
          document.getElementById('agrupador-' + idAgrupador + 'i' + i).value =
            isNaN(new_valor_reactivo)
              ? ''
              : parseFloat(new_valor_reactivo).toFixed(2)
        }
      }
    }
  }

  const handleChangeNombreAgrupador = (value, idAgrupador) => {
    idAgrupador = parseInt(idAgrupador.split('-')[1])

    ////// Identificar a que agrupador se le desea añadir el reactivo (Modal Nuevo) //////
    const agrupador = contenidoNuevoAgrupador.filter(
      (agrupador) => agrupador.numero === idAgrupador
    )[0]

    ////// Identificar a que agrupador se le desea añadir el reactivo (Modal Editar) //////
    const agrupadorEdit = agrupadores.filter(
      (agrupador) => agrupador.numero === idAgrupador
    )[0]

    if (agrupador === undefined) {
      agrupadorEdit.nombre = value
    } else {
      agrupador.nombre = value
    }
  }

  const handleChangeCheckedAleatorio = (value, idAgrupador) => {
    let idInputCheckAleatorio = idAgrupador + 'c'
    let saveAgrupador = idAgrupador
    let valueNuevoTotalAgrupador = 0
    idAgrupador = parseInt(idAgrupador.split('-')[1])

    ////// Identificar a que agrupador se le desea añadir el reactivo (Modal Nuevo) //////
    const agrupador = contenidoNuevoAgrupador.filter(
      (agrupador) => agrupador.numero === idAgrupador
    )[0]

    ////// Identificar a que agrupador se le desea añadir el reactivo (Modal Editar) //////
    const agrupadorEdit = agrupadores.filter(
      (agrupador) => agrupador.numero === idAgrupador
    )[0]

    document.getElementById(idInputCheckAleatorio).checked = value

    if (agrupador === undefined) {
      agrupadorEdit.aleatorio = value ? '1' : '0'
      if (!value) {
        agrupadorEdit.reactivos.forEach((reactivo) => {
          if (!isNaN(reactivo.valor_reactivo)) {
            valueNuevoTotalAgrupador += parseInt(reactivo.valor_reactivo)
          }
        })
        handleChangeValorAgrupador(valueNuevoTotalAgrupador, saveAgrupador)
      } else {
        for (let i = 0; i < agrupadorEdit.reactivos.length; i++) {
          agrupadorEdit.reactivos[i].valor_reactivo =
            agrupadorEdit.total_reactivos
          document.getElementById('agrupador-' + idAgrupador + 'i' + i).value =
            agrupadorEdit.total_reactivos
        }

        agrupadores.forEach((editAgrupador) => {
          if (editAgrupador.numero === idAgrupador) {
            editAgrupador = agrupadorEdit
          }
        })
      }
    } else {
      agrupador.aleatorio = value ? '1' : '0'
      if (!value) {
        agrupador.reactivos.forEach((reactivo) => {
          if (!isNaN(reactivo.valor_reactivo)) {
            valueNuevoTotalAgrupador += parseFloat(reactivo.valor_reactivo)
          }
        })
        handleChangeValorAgrupador(valueNuevoTotalAgrupador, saveAgrupador)
      } else {
        for (let i = 0; i < agrupador.reactivos.length; i++) {
          agrupador.reactivos[i].valor_reactivo = agrupador.total_reactivos
          document.getElementById('agrupador-' + idAgrupador + 'i' + i).value =
            agrupador.total_reactivos
        }
        contenidoNuevoAgrupador.forEach((editAgrupador) => {
          if (editAgrupador.numero === idAgrupador) {
            editAgrupador = agrupador
          }
        })
      }
    }
    changeCantidadReactivos()
  }

  const changeCantidadReactivos = () => {
    if (openNuevoInstrumento) {
      let valueReactivo = 0
      contenidoNuevoAgrupador.forEach((agrupador) => {
        if (agrupador.aleatorio == '1') {
          valueReactivo = valueReactivo + 1
        } else {
          valueReactivo = valueReactivo + agrupador.reactivos.length
        }
      })
      document.getElementById('reactivos_total').value = valueReactivo
    } else {
      let valueReactivo = 0
      agrupadores.forEach((agrupador) => {
        if (agrupador.aleatorio == '1') {
          valueReactivo = valueReactivo + 1
        } else {
          valueReactivo = valueReactivo + agrupador.reactivos.length
        }
      })
      document.getElementById('reactivos_total').value = valueReactivo
    }
  }

  const changePesoReactivos = () => {
    if (openNuevoInstrumento) {
      let myValue = 0
      contenidoNuevoAgrupador.forEach((reg) => {
        myValue =
          myValue +
          (reg.total_reactivos === '' ? 0 : parseInt(reg.total_reactivos))
      })
      document.getElementById('peso_total').value = myValue
    } else {
      let myValue = 0
      agrupadores.forEach((reg) => {
        myValue =
          myValue +
          (reg.total_reactivos === '' ? 0 : parseInt(reg.total_reactivos))
      })
      document.getElementById('peso_total').value = myValue
    }
  }

  const handleChangeValorReactivo = (value, idAgrupador, index) => {
    let idInputValorReactivo = idAgrupador + 'i' + index
    let valueNuevoTotalAgrupador = 0
    let saveAgrupador = idAgrupador
    idAgrupador = parseInt(idAgrupador.split('-')[1])
    ////// Identificar a que agrupador se le desea añadir el reactivo (Modal Nuevo) //////
    const agrupador = contenidoNuevoAgrupador.filter(
      (agrupador) => agrupador.numero === idAgrupador
    )[0]

    ////// Identificar a que agrupador se le desea añadir el reactivo (Modal Editar) //////
    const agrupadorEdit = agrupadores.filter(
      (agrupador) => agrupador.numero === idAgrupador
    )[0]

    document.getElementById(idInputValorReactivo).value = value

    if (agrupador === undefined) {
      agrupadorEdit.reactivos[index].valor_reactivo = parseInt(value)
      agrupadorEdit.reactivos[index].valor = parseInt(value)

      agrupadorEdit.reactivos.forEach((reactivo) => {
        if (!isNaN(reactivo.valor_reactivo)) {
          valueNuevoTotalAgrupador += reactivo.valor_reactivo
        }
      })
      handleChangeValorAgrupador(valueNuevoTotalAgrupador, saveAgrupador)
    } else {
      agrupador.reactivos[index].valor_reactivo = parseInt(value)
      agrupador.reactivos[index].valor = parseInt(value)

      agrupador.reactivos.forEach((reactivo) => {
        if (!isNaN(reactivo.valor_reactivo)) {
          valueNuevoTotalAgrupador += reactivo.valor_reactivo
        }
      })
      handleChangeValorAgrupador(valueNuevoTotalAgrupador, saveAgrupador)
    }
  }

  function deleteReactivo(reactivo, index, reactIndex) {
    handleChangeValorReactivo(0, 'agrupador-' + (index + 1), reactIndex)
    if (openNuevoInstrumento) {
      if (reactivo.etiqueta === etiqueta) {
        let new_preguntas = [...preguntasFiltradas]
        new_preguntas.push(reactivo)
        setPreguntasFiltradas(new_preguntas)
      } else {
        let new_preguntas = [...preguntasFiltradas]
        setPreguntasFiltradas(new_preguntas)
      }
      contenidoNuevoAgrupador[index].reactivos.splice(reactIndex, 1)
    } else {
      if (reactivo.etiqueta === etiqueta) {
        let new_preguntas = [...preguntasFiltradas]
        new_preguntas.push(reactivo)
        setPreguntasFiltradas(new_preguntas)
      } else {
        let new_preguntas = [...preguntasFiltradas]
        setPreguntasFiltradas(new_preguntas)
      }
      agrupadores[index].reactivos.splice(reactIndex, 1)
    }
    changeCantidadReactivos()
  }

  function deleteAgrupador(index) {
    if (openNuevoInstrumento) {
      let new_preguntas = [...preguntasFiltradas]
      for (
        let i = contenidoNuevoAgrupador[index].reactivos.length - 1;
        i >= 0;
        i--
      ) {
        let reactivo = contenidoNuevoAgrupador[index].reactivos[i]
        if (reactivo.etiqueta === etiqueta) {
          new_preguntas.push(reactivo)
        }
      }
      setPreguntasFiltradas(new_preguntas)
      contenidoNuevoAgrupador.splice(index, 1)
      let number = 1
      contenidoNuevoAgrupador.forEach((reg) => {
        reg.numero = number
        number = number + 1
      })

      changeCantidadReactivos()
      changePesoReactivos()
    } else {
      let new_preguntas = [...preguntasFiltradas]
      for (let i = agrupadores[index].reactivos.length - 1; i >= 0; i--) {
        let reactivo = agrupadores[index].reactivos[i]
        if (reactivo.etiqueta === etiqueta) {
          new_preguntas.push(reactivo)
        }
      }
      setPreguntasFiltradas(new_preguntas)
      agrupadores.splice(index, 1)
      let number = 1
      agrupadores.forEach((reg) => {
        reg.numero = number
        number = number + 1
      })

      changeCantidadReactivos()
      changePesoReactivos()
    }
  }

  function handleChangePositionPregunta(idAgrupador, posicion, cambio) {
    let numeroAgrupador = parseInt(idAgrupador.split('-')[1])
    ////// Identificar a que agrupador se le desea añadir el reactivo (Modal Nuevo) //////
    const agrupador = contenidoNuevoAgrupador.filter(
      (agrupador) => agrupador.numero === numeroAgrupador
    )[0]

    const agrupadorEdit = agrupadores.filter(
      (agrupador) => agrupador.numero === numeroAgrupador
    )[0]

    if (agrupador === undefined) {
      let auxCambio = agrupadorEdit.reactivos[posicion + cambio]
      agrupadorEdit.reactivos[posicion + cambio] =
        agrupadorEdit.reactivos[posicion]
      agrupadorEdit.reactivos[posicion] = auxCambio
      setRefresh(!refresh)
    } else {
      let auxCambio = agrupador.reactivos[posicion + cambio]
      agrupador.reactivos[posicion + cambio] = agrupador.reactivos[posicion]
      agrupador.reactivos[posicion] = auxCambio
      setRefresh(!refresh)
    }
  }

  function Agrupador({
    reactivos,
    id,
    index,
    valorAgrupador,
    valorAleatorio,
    tipo,
    nombre,
    valorTotalAleatorio,
  }) {
    const [aleatorioAgrupador, setAleatorioAgrupador] = useState(
      valorAleatorio == '1' ? true : false
    )
    useEffect(() => {
      document.getElementById(id + 'i').value = valorAgrupador
      if (aleatorioAgrupador) {
        document.getElementById(id + 'ii').value =
          valorTotalAleatorio === undefined ? 0 : valorTotalAleatorio
      }
      document.getElementById(id + 'c').checked =
        valorAleatorio == '1' ? true : false
      document.getElementById(id + 'n').value = nombre
      console.log(nombre)
      for (let i = 0; i < reactivos.length; i++) {
        document.getElementById(id + 'i' + i).value =
          reactivos[i].valor_reactivo
      }
    }, [])

    return (
      <>
        <div
          className='contenedor-agrupador'
          onDragOver={allowDrop}
          onDrop={drop}
          id={id}
        >
          <Grid className='header-agrupador' container>
            <Grid xs={1} xm={1}>
              <Grid xs={2} md={2}>
                <CancelIcon
                  fontSize='large'
                  onClick={() => deleteAgrupador(index, tipo)}
                  style={{ marginTop: 10, marginLeft: 10, cursor: 'pointer' }}
                />
              </Grid>
            </Grid>
            <Grid className='contenedor-nombre-agrupador' xs={3} xm={3}>
              <label>Nombre Agrupador</label>
              <input
                placeholder='Nombre'
                id={id + 'n'}
                style={{ textAlign: 'center' }}
                maxlength='50'
                onChange={(e) => {
                  e.preventDefault()
                  if (e.target.value.length <= 50) {
                    handleChangeNombreAgrupador(e.target.value, id)
                  }
                }}
              />
            </Grid>
            <Grid style={{ display: 'none' }} xs={1} xm={1}>
              <input
                className='check-aleatorio'
                onChange={(e) => {
                  handleChangeCheckedAleatorio(e.target.checked, id)
                  setAleatorioAgrupador(e.target.checked)
                }}
                id={id + 'c'}
                type='checkbox'
              />
            </Grid>
            <Grid style={{ display: 'none' }} xs={2} xm={2}>
              <p>Aleatorio</p>
            </Grid>
            {aleatorioAgrupador ? (
              <Grid xs={2} xm={2}>
                <label>Total aleatorio</label>
                <input
                  type='number'
                  placeholder='Total aleatorio'
                  id={id + 'ii'}
                  disabled={!aleatorioAgrupador}
                  style={{ textAlign: 'center' }}
                  onKeyDown={(e) => {
                    if (
                      (e.keyCode >= 48 && e.keyCode <= 57) ||
                      (e.keyCode >= 96 && e.keyCode <= 105) ||
                      e.keyCode === 8
                    ) {
                      return true
                    } else {
                      e.preventDefault()
                    }
                  }}
                  onChange={(e) => {
                    e.preventDefault()
                    let regex = new RegExp('^[0-9]+$')
                    if (!regex.test(e.target.value)) {
                    } else {
                      handleChangeTotalReactivo(e.target.value, id)
                    }
                  }}
                />
              </Grid>
            ) : null}
            <Grid
              xs={aleatorioAgrupador ? 2 : 3}
              xm={aleatorioAgrupador ? 2 : 3}
              style={{ marginLeft: 'auto' }}
            >
              <label>Peso Agrupador</label>
              <input
                type='number'
                placeholder='Reactivos'
                id={id + 'i'}
                disabled={!aleatorioAgrupador}
                style={{ textAlign: 'center' }}
                onKeyDown={(e) => {
                  if (
                    (e.keyCode >= 48 && e.keyCode <= 57) ||
                    (e.keyCode >= 96 && e.keyCode <= 105) ||
                    e.keyCode === 8
                  ) {
                    return true
                  } else {
                    e.preventDefault()
                  }
                }}
                onChange={(e) => {
                  e.preventDefault()
                  let regex = new RegExp('^[0-9]+$')
                  if (!regex.test(e.target.value)) {
                  } else {
                    handleChangeValorAgrupador(e.target.value, id)
                  }
                }}
              />
            </Grid>
          </Grid>
          <div
            className='tabla-preguntas-instrumento'
            style={{ width: '100%', height: '249px', overflow: 'auto' }}
          >
            {!reactivos
              ? null
              : reactivos.map((reactivo, i) => (
                  <div
                    key={reactivo.id}
                    id={`reactivo-${reactivo.id}`}
                    nombre={reactivo.nombre}
                    pregunta={reactivo.pregunta}
                    onDrop={() => {
                      /*
                      e.currentTarget.parentNode.parentNode.className ===
                        'contenedor-agrupador' && appendSibling(e);
                      */
                    }}
                  >
                    <Grid xs={12} md={12} container>
                      <Grid xs={2} md={2}>
                        <CancelIcon
                          fontSize='large'
                          onClick={() =>
                            deleteReactivo(reactivo, index, i, tipo)
                          }
                          style={{ marginTop: 10, cursor: 'pointer' }}
                        />
                      </Grid>
                      <Grid xs={1} md={1}>
                        {i === 0 ? (
                          <ArrowDropUpIcon style={{ color: 'white' }} />
                        ) : (
                          <ArrowDropUpIcon
                            onClick={() => {
                              handleChangePositionPregunta(id, i, -1)
                            }}
                            className='arrow-icons-change-position'
                          />
                        )}
                        {i === reactivos.length - 1 ? null : (
                          <ArrowDropDownIcon
                            onClick={() => {
                              handleChangePositionPregunta(id, i, 1)
                            }}
                            className='arrow-icons-change-position'
                          />
                        )}
                      </Grid>
                      <Grid
                        className='contenedor-nombre-reactivo'
                        xs={7}
                        md={7}
                      >
                        <p>{reactivo.pregunta}</p>
                      </Grid>
                      <Grid className='tipo-pregunta' xs={2} md={2}>
                        <input
                          className='valor-pregunta'
                          disabled={aleatorioAgrupador}
                          style={{ textAlign: 'center' }}
                          id={id + 'i' + i}
                          onChange={(e) => {
                            handleChangeValorReactivo(e.target.value, id, i)
                          }}
                          placeholder='Valor'
                        />
                      </Grid>
                    </Grid>
                  </div>
                ))}
          </div>
        </div>
      </>
    )
  }

  const handleAgregarAgrupador = () => {
    let dataMas = []
    contenidoNuevoAgrupador.forEach((opcion) => {
      dataMas.push(opcion)
    })
    dataMas.push({
      numero: misId,
      nombre: 'Agrupador ' + misId,
      aleatorio: '0',
      total_reactivos: 0,
      reactivos: [],
    })
    setContenidoNuevoAgrupador(dataMas)

    newId(misId + 1)
  }
  const handleAgregarAgrupadorToEdit = () => {
    let dataMas = []
    agrupadores.forEach((opcion) => {
      dataMas.push(opcion)
    })
    dataMas.push({
      numero: agrupadores.length + 1,
      nombre: 'Agrupador ' + (agrupadores.length + 1),
      aleatorio: '0',
      total_reactivos: 0,
      reactivos: [],
    })
    setAgrupadores(dataMas)

    newId(misId + 1)
  }
  const search = (e) => {
    let text = e.target.value.toLowerCase()
    let result = tab1Data.filter((row) => {
      if (row[0].toString().toLowerCase().includes(text)) return true
      else if (String(row[1]).toLowerCase().includes(text)) return true
      else if (String(row[2]).toLowerCase().includes(text)) return true
      else if (String(row[3]).toLowerCase().includes(text)) return true
      else if (String(row[4]).toLowerCase().includes(text)) return true
      else if (String(row[5]).toLowerCase().includes(text)) return true
      return false
    })
    setTab1FilteredData(result)
  }
  const handleOpenModalEditarInstrumento = async (id) => {
    setOEI(true)
    setVisualizar(false)
    setLModal(true)
    setReactivosEliminar([])

    try {
      let cuestionario = await getRequest(`/instrumento?id=${id}`)
      if (cuestionario.s === 'OK') {
        cuestionario.d.descripcion = cuestionario.d.description
        setEditarReactivoData(cuestionario.d)
        const { clave, descripcion, nombre } = cuestionario.d
        let clave_instrumento = clave
        let nombre_instrumento = nombre
        setClaveInstrumento(clave_instrumento)
        setDescripcionInstrumento(descripcion)
        setNombreInstrumento(nombre_instrumento)

        const { agrupadores } = cuestionario.d
        let myValue = 0
        let subTotalReactivos = 0

        agrupadores.forEach((agrupador, i) => {
          let valueReactivo = 0
          agrupador.reactivos.forEach((reactivo) => {
            valueReactivo += reactivo.valor_reactivo
            subTotalReactivos = subTotalReactivos + 1
          })
          agrupador.total_reactivos = valueReactivo
          agrupador.numero = i + 1
          myValue = myValue + agrupador.total_reactivos
        })
        console.log(agrupadores)
        setAgrupadores(agrupadores)

        const etiquetasData = await getRequest('/instrumento/etiqueta')
        let etiquetas = []
        etiquetasData.d.forEach((etiqueta) => {
          etiquetas.push({
            label: etiqueta.nombre,
            name: etiqueta.nombre,
            value: etiqueta.nombre,
          })
        })
        setOpcionesEtiquetas(etiquetas)
        handleChangeEtiqueta(etiquetas[0], agrupadores, agrupadores)

        const blocksFromHtml = htmlToDraft(descripcion)
        const { contentBlocks, entityMap } = blocksFromHtml
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        )
        const myEditorState = EditorState.createWithContent(contentState)
        setValueHTML(descripcion)
        setEditorState(myEditorState)

        setLModal(false)
        setTimeout(function () {
          document.getElementById('reactivos_total').value = subTotalReactivos
          document.getElementById('peso_total').value =
            cuestionario.d.peso_instrumento
        }, 10)
      }
    } catch (error) {
      setOEI(false)
      setLModal(false)
      console.error(error)
    }
  }

  const handleOpenModalNuevoInstrumento = async () => {
    setNuevoInstrumentoClave('')
    setNuevoInstrumentoDescripcion('')
    setNuevoInstrumentoNombre('')
    setNuevoInstrumentoReactivos('')
    setAgrupadores([])
    setONI(true)
    setLModal(true)
    newId(1)
    setContenidoNuevoAgrupador([])
    setReactivosEliminar([])
    setEditorState(EditorState.createEmpty())
    setValueHTML('')

    try {
      const response = await getRequest('/instrumento/etiqueta')
      const data = response.d

      let etiquetas = []
      data.forEach((etiqueta) => {
        etiquetas.push({
          label: etiqueta.nombre,
          name: etiqueta.nombre,
          value: etiqueta.nombre,
        })
        setOpcionesEtiquetas(etiquetas)
      })
      handleChangeEtiqueta(etiquetas[0], false, false, true)
      setLModal(false)
    } catch (error) {
      setONI(false)
      setLModal(false)
      console.error(error)
    }

    //cargarPreguntas();
  }

  const handleCloseModalNuevoInstrumento = () => {
    setLModal(false)
    setContenidoNuevoAgrupador([])
    handleChangeEtiqueta(opcionesEtiquetas[0], false, false)
    setOEI(false)
    setONI(false)
  }

  const handleChangeEtiqueta = async (
    e,
    agrupadoresNew,
    agrupadoresEditar,
    fromNuevo
  ) => {
    setLModalEtiquetas(true)
    try {
      const response = await getRequest(
        `/instrumento/reactivo?etiqueta=` + e.value
      )
      const data = response.d
      let preguntas = []
      if (data) {
        data.forEach((reg) => {
          reg.id = reg.id_reactivo
          reg.nombre = reg.nombre_reactivo
          reg.pregunta = reg.pregunta_reactivo
        })
        if (agrupadoresNew === false && agrupadoresEditar === false) {
          let ids = []
          let modificarAgrupador = []
          if (fromNuevo) {
          } else {
            modificarAgrupador = contenidoNuevoAgrupador
          }
          modificarAgrupador.forEach((agrupador) => {
            agrupador.reactivos.forEach((reactivo) => {
              ids.push(reactivo.id)
            })
          })
          data.forEach((reg) => {
            if (!ids.includes(reg.id)) {
              preguntas.push(reg)
            }
          })
        } else {
          let ids = []

          if (agrupadores.length === 0) {
            agrupadoresEditar.forEach((agrupador) => {
              agrupador.reactivos.forEach((reactivo) => {
                ids.push(reactivo.id)
              })
            })
          } else {
            agrupadores.forEach((agrupador) => {
              agrupador.reactivos.forEach((reactivo) => {
                ids.push(reactivo.id)
              })
            })
          }
          data.forEach((reg) => {
            if (!ids.includes(reg.id)) {
              preguntas.push(reg)
            }
          })
        }
        setPreguntasNuevaF(preguntas)
        setPreguntasFiltradas(preguntas)
        setLModalEtiquetas(false)
      } else {
        setPreguntasNuevaF([])
        setPreguntasFiltradas([])
        setLModalEtiquetas(false)
      }
    } catch (error) {
      console.error(error)
      setLModalEtiquetas(false)
    }

    setEtiqueta(e.value)
  }

  const loadTable = async () => {
    setTableLoading(true)
    try {
      setTableLoading(true)

      const response = await getRequest('/instrumento')
      if (response.s === 'OK') {
        let newData = []
        if (response.d !== '') {
          response.d.forEach((d) => {
            newData.push([
              d.id,
              d.clave,
              d.total_reactivos,
              d.peso_instrumento,
              d.nombre,
              <EditButton
                onClick={() => {
                  handleOpenModalEditarInstrumento(d.id)
                }}
              >
                Editar
              </EditButton>,
            ])
          })
        }
        setTab1Data(newData)
        setTab1FilteredData(newData)
        setTableLoading(false)
      }
      setTableLoading(false)
    } catch (error) {
      console.error(error)
      setTableLoading(false)
    }
  }

  const handleDeleteInstrument = async () => {
    setDeleteModalLoading(true)
    //let response = await postRequest('/cuestionario/eliminar', {id: editarReactivoData.id})
    const response = eliminarCuestionario()
    if (response.s === 'OK') {
      setDeleteModal(false)
      setDeleteModalLoading(false)
      setOEI(false)
      loadTable()
    } else {
      setDeleteModal(false)
      setDeleteModalLoading(false)
    }
  }

  const handleSubmit = async () => {
    const { id } = editarReactivoData

    agrupadores.forEach((myAgrupador) => {
      for (let i = 0; i < myAgrupador.reactivos.length; i++) {
        myAgrupador.reactivos[i].valor_reactivo = parseFloat(
          myAgrupador.reactivos[i].valor_reactivo
        )
        myAgrupador.reactivos[i].valor = myAgrupador.reactivos[i].valor_reactivo
        if (
          myAgrupador.reactivos[i].numero === undefined ||
          myAgrupador.reactivos[i].numero === null
        ) {
          if (i === 0) {
            myAgrupador.reactivos[i].numero = 1
          } else {
            myAgrupador.reactivos[i].numero =
              myAgrupador.reactivos[i - 1].numero + 1
          }
        }
      }
    })

    const data = {
      id,
      nombre_instrumento: nombreInstrumento,
      descripcion: valueHTML.replaceAll('\n', ''),
      clave_instrumento: claveInstrumento,
      peso_instrumento: parseInt(document.getElementById('peso_total').value),
      total_reactivos: parseInt(
        document.getElementById('reactivos_total').value
      ),
      agrupadores,
    }
    if (validarInstrumento(data)) {
      let myReactivos = {}
      data.agrupadores.forEach((reg) => {
        let regReactivos = []
        reg.reactivos.forEach((reactivo) => {
          regReactivos.push({
            peso_reactivo: reactivo.valor_reactivo,
            id_reactivo: reactivo.id,
            orden_reactivo: reactivo.numero,
          })
        })
        const id_nombre_reactivo = 'agrupador-' + reg.numero + 'n'
        const nombre_agrupador =
          document.getElementById(id_nombre_reactivo).value
        myReactivos[nombre_agrupador] = regReactivos
      })
      data.reactivos = myReactivos
      try {
        setLModal(true)
        const response = await postRequest('/instrumento/editar', data)
        if (response.s === 'OK') {
          toast.success(response.m)
          setLModal(false)
          setOEI(false)
          loadTable()
        } else {
          setLModal(false)
          document.getElementById('peso_total').value = data.peso_instrumento
          document.getElementById('reactivos_total').value =
            data.total_reactivos
        }
      } catch (error) {
        setLModal(false)
        document.getElementById('peso_total').value = data.peso_instrumento
        document.getElementById('reactivos_total').value = data.total_reactivos
        console.error(error)
      }
    }
  }

  const handleSubmitVisualizar = () => {
    setVisualizar(!visualizar)
  }

  const handleSubmitNuevoInstrumento = async () => {
    contenidoNuevoAgrupador.forEach((myAgrupador) => {
      for (let i = 0; i < myAgrupador.reactivos.length; i++) {
        myAgrupador.reactivos[i].numero = i + 1
        myAgrupador.reactivos[i].valor = myAgrupador.reactivos[i].valor_reactivo
      }
    })
    const data = {
      nombre_instrumento: nuevoInstrumentoNombre,
      descripcion: valueHTML.replaceAll('\n', ''),
      clave_instrumento: nuevoInstrumentoClave,
      peso_instrumento: parseInt(document.getElementById('peso_total').value),
      total_reactivos: parseInt(
        document.getElementById('reactivos_total').value
      ),
      agrupadores: contenidoNuevoAgrupador,
    }

    if (validarInstrumento(data)) {
      let myReactivos = {}
      data.agrupadores.forEach((reg) => {
        let regReactivos = []
        reg.reactivos.forEach((reactivo) => {
          regReactivos.push({
            peso_reactivo: reactivo.valor_reactivo,
            id_reactivo: reactivo.id,
            orden_reactivo: reactivo.numero,
          })
        })
        const id_nombre_reactivo = 'agrupador-' + reg.numero + 'n'
        const nombre_agrupador =
          document.getElementById(id_nombre_reactivo).value
        myReactivos[nombre_agrupador] = regReactivos
      })
      data.reactivos = myReactivos
      try {
        setLModal(true)
        const response = await postRequest('/instrumento/nuevo', data)
        if (response.s === 'OK') {
          setLModal(false)
          setONI(false)
          handleCloseModalNuevoInstrumento()
          loadTable()
        } else {
          setLModal(false)
          document.getElementById('peso_total').value = data.peso_instrumento
          document.getElementById('reactivos_total').value =
            data.total_reactivos
        }
      } catch (error) {
        setLModal(false)
        document.getElementById('peso_total').value = data.peso_instrumento
        document.getElementById('reactivos_total').value = data.total_reactivos
        console.error(error)
      }
    }
  }

  function validarInstrumento(body) {
    let valido = true
    if (body.nombre_instrumento === '') {
      document.getElementById('tag-nombre').classList.add('error')
      valido = false
    } else {
      document.getElementById('tag-nombre').classList.remove('error')
    }
    if (body.descripcion === '') {
      document.getElementById('tag-descripcion').classList.add('error')
      valido = false
    } else {
      document.getElementById('tag-descripcion').classList.remove('error')
    }
    if (body.clave_instrumento === '') {
      document.getElementById('tag-clave').classList.add('error')
      valido = false
    } else {
      document.getElementById('tag-clave').classList.remove('error')
    }
    /*
    if(nuevoInstrumentoReactivos===""){
      document.getElementById('tag-total-reactivos').classList.add('error')
      valido=false
    }else{
      document.getElementById('tag-total-reactivos').classList.remove('error')
    }
    */
    if (body.agrupadores.length === 0) {
      document.getElementById('tag-agrupadores').classList.add('error')
      valido = false
    } else {
      let nombres = []
      body.agrupadores.forEach((myAgrupador) => {
        nombres.push(myAgrupador.nombre)
        if (
          myAgrupador.total_reactivos < 0 ||
          myAgrupador.total_reactivos === ''
        ) {
          toast.error('Total reactivos debe ser mayor o igual a 0')
          valido = false
        }
        if (myAgrupador.reactivos.length === 0) {
          toast.error('Debe agregar reactivos a todos los agrupadores')
          valido = false
        }
        if (
          myAgrupador.reactivos.length < myAgrupador.total_aleatorio &&
          myAgrupador.aleatorio
        ) {
          toast.error(
            'Cantidad de ractivos en agrupador aleatorio debe ser mayor o igual al total aleatorio'
          )
          valido = false
        }
      })
      for (let j = 0; j < nombres.length; j++) {
        let contador = 0
        for (let i = 0; i < nombres.length; i++) {
          if (nombres[i] == nombres[j]) {
            contador++
          }
        }
        if (contador > 1) {
          toast.error(
            'Los agrupadores deben tener nombres unicos:' + nombres[j]
          )
          valido = false
          j = nombres.length + 1
        }
        contador = 0
      }
    }
    return valido
  }

  useEffect(() => {
    ;(() => {
      const text = buscarEtiquetas.toLowerCase()

      const preguntasNuevasFFiltradas = preguntasNuevasF.filter((pregunta) => {
        if (pregunta.nombre.toLowerCase().includes(text)) {
          return true
        } else if (pregunta.pregunta.toLowerCase().includes(text)) {
          return true
        } else {
          return false
        }
      })
      setPreguntasFiltradas(preguntasNuevasFFiltradas)
    })()
  }, [buscarEtiquetas])
  const customStyles = {
    option: (provided, _state) => ({
      ...provided,
      color: '#616b74',
      fontSize: 14,
      fontWeight: 400,
      padding: '5px 25px',
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      userSelect: 'none',
      backgroundColor: '#fff',
      borderTop: '1px solid #ccc',
    }),
    control: (provided, _state) => ({
      ...provided,
      minHeight: 0,
      height: '25px',
      borderRadius: 15,
      fontSize: 11,
      backgroundColor: '#f9f9f9',
      padding: 0,
    }),
    placeholder: (provided, _state) => ({
      ...provided,
      padding: 0,
      fontSize: 11,
      fontWeight: 400,
    }),
    menu: (provided, _state) => ({
      ...provided,
      borderRadius: 0,
    }),
    input: (provided, _state) => ({
      ...provided,
      padding: 0,
      fontSize: 11,
      fontWeight: 400,
      borderRadius: 0,
    }),
    singleValue: (provided, _state) => ({
      ...provided,
      top: '50%',
    }),
  }

  return (
    <Contenedor title='Instrumentos'>
      <div className='opciones-bar'>
        <div className='sub-filtro'>
          <NewButton onClick={handleOpenModalNuevoInstrumento} />
        </div>
        <div style={{ float: 'right' }}>
          <input
            type='search'
            placeholder='Buscar'
            className='SearchInput-tabla-completa SearchInput-Usuarios'
            onChange={search}
          />
        </div>
      </div>
      <div style={{ height: 'calc(100% - 70px)' }}>
        <DataTable
          headers={headers}
          data={tab1FilteredData}
          loading={tableLoading}
          paginate
        />
      </div>

      <NewModal
        instrumento
        height={620}
        title='Nuevo Instrumento'
        open={openNuevoInstrumento}
        handleClose={handleCloseModalNuevoInstrumento}
        loading={loadingModal}
        handleSubmit={handleSubmitNuevoInstrumento}
      >
        <div id='contenedor-NuevoInstrumento'>
          <Grid container spacing={1}>
            <Grid
              className='titulos-instrumento'
              container
              xs={12}
              md={12}
              item
            >
              <Grid xs={6} md={6} item>
                <h1>Diseño del Instrumento</h1>
              </Grid>
              <Grid xs={6} md={6} item>
                <h1>Lista de Reactivos</h1>
              </Grid>
            </Grid>

            <Grid
              className='contenedor-Agrupadores'
              container
              xs={6}
              md={6}
              item
            >
              <Grid xs={3} md={3} item>
                <label id='tag-clave'>Clave (*)</label>
                <input
                  placeholder='Ingresar nombre'
                  value={nuevoInstrumentoClave}
                  onChange={(e) => setNuevoInstrumentoClave(e.target.value)}
                />
              </Grid>
              <Grid xs={6} md={6} item>
                <label id='tag-nombre'>Nombre (*)</label>
                <input
                  placeholder='Ingresar nombre'
                  value={nuevoInstrumentoNombre}
                  onChange={(e) => setNuevoInstrumentoNombre(e.target.value)}
                />
              </Grid>
              <Grid xs={3} md={3} style={{ textAlign: 'center' }} item>
                <label id='tag-descripcion'>Descripción (*)</label>
                <NewButton
                  noIcon
                  onClick={() => setModalDescripcion(true)}
                  style={{ marginLeft: '30px' }}
                  agregar='Abrir'
                />
              </Grid>
              <Grid className='boton-agregar' xs={3} md={3} item>
                <NewButton
                  onClick={handleAgregarAgrupador}
                  agregar={'Agrupador'}
                />
              </Grid>

              <Grid xs={3} md={3} item>
                <label id='tag-total-reactivos'>Total reactivos (*)</label>
                <input
                  placeholder='Total reactivos'
                  disabled
                  id='reactivos_total'
                  style={{ textAlign: 'center' }}
                />
              </Grid>
              <Grid xs={3} md={3} item>
                <label id='tag-total-reactivos'>Peso(*)</label>
                <input
                  id='peso_total'
                  placeholder='Peso'
                  disabled
                  style={{ textAlign: 'center' }}
                />
              </Grid>

              <Grid xs={12} md={12} item>
                {contenidoNuevoAgrupador.length === 0 ? (
                  <h1 id='tag-agrupadores'>No existen agrupadores</h1>
                ) : null}
                <div className='tabla-preguntas-agrupador'>
                  {contenidoNuevoAgrupador.map((agrupador, i) => (
                    <Agrupador
                      nombre={agrupador.nombre}
                      key={agrupador.numero}
                      valorAgrupador={agrupador.total_reactivos}
                      valorAleatorio={agrupador.aleatorio}
                      valorTotalAleatorio={agrupador.total_aleatorio}
                      id={`agrupador-${agrupador.numero}`}
                      reactivos={agrupador.reactivos}
                      index={i}
                      tipo='nuevo'
                    />
                  ))}
                </div>
              </Grid>
            </Grid>
            <Grid
              className='contenedor-Selectores-Reactivos'
              container
              xs={6}
              md={6}
              item
            >
              <Grid xs={4} md={4} item>
                <label>Etiquetas</label>
                <Select
                  options={opcionesEtiquetas}
                  value={{ label: etiqueta, value: etiqueta }}
                  styles={customStyles}
                  className='select-search-box'
                  onChange={(e) => {
                    handleChangeEtiqueta(e, false, false)
                  }}
                  placeholder='Opciones etiquetas'
                />
              </Grid>
              <Grid xs={5} md={5} item></Grid>
              <Grid xs={3} md={3} item>
                <SearchIcon className='searchIconImage' />
                <input
                  id='to-search-tag'
                  type='search'
                  placeholder='Buscar'
                  value={buscarEtiquetas}
                  onChange={(e) => setBuscarEtiquetas(e.target.value)}
                />
              </Grid>

              <Grid xs={12} md={12} onDragOver={allowDrop} item>
                {loadingModalEtiquetas ? (
                  <div style={{ textAlign: 'center', paddingTop: 30 }}>
                    <CircularProgress size={50} />
                  </div>
                ) : (
                  <div
                    className='tabla-preguntas-instrumento-derecha'
                    id='tabla-preguntas'
                    style={
                      preguntasFiltradas.length === 0
                        ? {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }
                        : {}
                    }
                  >
                    {}
                    {preguntasFiltradas.length > 0 ? (
                      preguntasFiltradas.map((reactivo) => (
                        <div
                          key={reactivo.id}
                          id={`reactivo-${reactivo.id}`}
                          nombre={reactivo.nombre}
                          pregunta={reactivo.pregunta}
                          draggable='true'
                          onDragStart={drag}
                          onDragOver={allowDrop}
                          onDrop={(e) => {
                            e.currentTarget.parentNode.parentNode.className ===
                              'contenedor-agrupador' && appendSibling(e)
                          }}
                        >
                          <Grid xs={12} md={12} container>
                            <Grid xs={2} md={2}>
                              <Reorder className='to-widget' fontSize='large' />
                            </Grid>
                            <Grid
                              className='contenedor-nombre-reactivo'
                              xs={8}
                              md={8}
                            >
                              <p>{reactivo.pregunta}</p>
                            </Grid>
                          </Grid>
                        </div>
                      ))
                    ) : (
                      <p style={{ textAlign: 'center' }}>
                        No hay reactivos para mostrar
                      </p>
                    )}
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </NewModal>
      <NewModal
        visualizar={handleSubmitVisualizar}
        instrumento
        canDelete
        height={620}
        title='Editar Instrumento'
        open={openEditarInstrumento}
        handleClose={handleCloseModalNuevoInstrumento}
        loading={loadingModal}
        handleSubmit={handleSubmit}
        onDelete={() => setDeleteModal(true)}
        noFooter={visualizar}
        permiso='objetos_instrumento_instrumento'
      >
        <div id='contenedor-NuevoInstrumento'>
          {visualizar ? (
            <Visualizar
              detalle={editarReactivoData}
              salir={() => {
                setVisualizar(false)
              }}
            />
          ) : (
            <Grid container spacing={1}>
              <Grid
                className='titulos-instrumento'
                container
                xs={12}
                md={12}
                item
              >
                <Grid xs={6} md={6} item>
                  <h1>Diseño del Instrumento</h1>
                </Grid>
                <Grid xs={6} md={6} item>
                  <h1>Lista de Reactivos</h1>
                </Grid>
              </Grid>

              <Grid
                className='contenedor-Agrupadores'
                container
                xs={6}
                md={6}
                item
              >
                <Grid xs={3} md={3} item>
                  <label id='tag-clave'>Clave (*)</label>
                  <input
                    disabled
                    style={{ backgroundColor: '#dcdbdb' }}
                    placeholder='Ingresar nombre'
                    value={claveInstrumento}
                  />
                </Grid>
                <Grid xs={6} md={6} item>
                  <label id='tag-nombre'>Nombre (*)</label>
                  <input
                    placeholder='Ingresar nombre'
                    value={nombreInstrumento}
                    onChange={(e) => setNombreInstrumento(e.target.value)}
                  />
                </Grid>
                <Grid xs={3} md={3} item>
                  <label id='tag-descripcion'>Descripcion (*)</label>

                  <NewButton
                    noIcon
                    onClick={() => setModalDescripcion(true)}
                    agregar='Modificar'
                  />
                </Grid>
                <Grid className='boton-agregar' xs={3} md={3} item>
                  <NewButton
                    onClick={handleAgregarAgrupadorToEdit}
                    agregar={'Agrupador'}
                  />
                </Grid>
                <Grid xs={3} md={3} item>
                  <label id='tag-total-reactivos'>Total reactivos(*)</label>
                  <input
                    placeholder='Total reactivos'
                    id='reactivos_total'
                    disabled
                    style={{ textAlign: 'center' }}
                  />
                </Grid>
                <Grid xs={3} md={3} item>
                  <label id='tag-total-reactivos'>Peso(*)</label>
                  <input
                    placeholder='Peso'
                    id='peso_total'
                    disabled
                    style={{ textAlign: 'center' }}
                  />
                </Grid>
                <Grid xs={12} md={12} item>
                  <div
                    className='tabla-preguntas-agrupador'
                    id='agrupador-preguntas'
                  >
                    {agrupadores.map((agrupador, i) => (
                      <Agrupador
                        nombre={agrupador.nombre}
                        key={agrupador.id}
                        valorAgrupador={agrupador.total_reactivos}
                        valorAleatorio={agrupador.aleatorio}
                        valorTotalAleatorio={agrupador.total_aleatorio}
                        reactivos={agrupador.reactivos}
                        total={agrupador.total_reactivos}
                        id={`agrupador-${agrupador.numero}`}
                        index={i}
                        tipo='editar'
                      />
                    ))}
                  </div>
                </Grid>
              </Grid>
              <Grid
                className='contenedor-Selectores-Reactivos'
                container
                xs={6}
                md={6}
                item
              >
                <Grid xs={4} md={4} item>
                  <label>Etiquetas</label>
                  <Select
                    options={opcionesEtiquetas}
                    value={{ label: etiqueta, value: etiqueta }}
                    styles={customStyles}
                    className='select-search-box'
                    onChange={(e) => {
                      handleChangeEtiqueta(e, '', '')
                    }}
                    placeholder='Opciones etiquetas'
                  />
                </Grid>
                <Grid xs={5} md={5} item></Grid>
                <Grid xs={3} md={3} item>
                  <SearchIcon className='searchIconImage' />
                  <input
                    id='to-search-tag'
                    type='search'
                    placeholder='Buscar'
                    value={buscarEtiquetas}
                    onChange={(e) => setBuscarEtiquetas(e.target.value)}
                  />
                </Grid>

                <Grid xs={12} md={12} onDragOver={allowDrop} item>
                  {loadingModalEtiquetas ? (
                    <div style={{ textAlign: 'center', paddingTop: 30 }}>
                      <CircularProgress size={50} />
                    </div>
                  ) : (
                    <div
                      className='tabla-preguntas-instrumento-derecha'
                      id='tabla-preguntas'
                      style={
                        preguntasFiltradas.length === 0
                          ? {
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }
                          : {}
                      }
                    >
                      {preguntasFiltradas.length > 0 ? (
                        preguntasFiltradas.map((reactivo) => (
                          <div
                            key={reactivo.id}
                            id={`reactivo-${reactivo.id}`}
                            nombre={reactivo.nombre}
                            pregunta={reactivo.pregunta}
                            draggable='true'
                            onDragStart={drag}
                            onDragOver={allowDrop}
                            onDrop={(e) => {
                              e.currentTarget.parentNode.parentNode
                                .className === 'contenedor-agrupador' &&
                                appendSibling(e)
                            }}
                          >
                            <Grid xs={12} md={12} container>
                              <Grid xs={2} md={2}>
                                <Reorder
                                  className='to-widget'
                                  fontSize='large'
                                />
                              </Grid>
                              <Grid
                                className='contenedor-nombre-reactivo'
                                xs={8}
                                md={8}
                              >
                                <p>{reactivo.pregunta}</p>
                              </Grid>
                            </Grid>
                          </div>
                        ))
                      ) : (
                        <p style={{ textAlign: 'center' }}>
                          No hay reactivos para mostrar
                        </p>
                      )}
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </div>
      </NewModal>
      <NewModal
        open={deleteModal}
        title='Confirmar'
        loading={deleteModalLoading}
        handleClose={() => setDeleteModal(false)}
        handleSubmit={handleDeleteInstrument}
      >
        <div className='delete-confirm'>
          <p>¿Está seguro de que desea eliminar este cuestionario?</p>
        </div>
      </NewModal>
      <Descripcion
        valueHTML={valueHTML}
        setValueHTML={setValueHTML}
        modalDescripcion={modalDescripcion}
        setModalDescripcion={setModalDescripcion}
        handleSubmitDescripcion={() => {
          setModalDescripcion(false)
        }}
      />
    </Contenedor>
  )
}

function eliminarCuestionario() {
  return { s: 'OK', m: 'OK', d: 'OK' }
}
