/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Select from 'react-select'
import { toast } from 'react-toastify'
import Cancel from '@material-ui/icons/Cancel'
import CircularProgress from '@material-ui/core/CircularProgress'

import Contenedor from '../../Components/Contenedor/Contenedor'
import NewButton from '../../Components/Botones/NewButton'
import EditButton from '../../Components/Botones/EditButton'
import ToggableButton from '../../Components/Botones/ToggableButton'
import DataTable from '../../Components/DataTables'
import Cuestionarios from './Cuestionarios'
import NewModal from '../../Components/Modal/NewModal'
import Escala100 from './Escala'
import EstructuraCampos from './EstructuraCampos'
import Tabs from '../../Components/Tabs'
import MultiSelect from '../../Components/MultiSelect'

import { getRequest, postRequest, putRequest } from '../../utils/requester'
import './style.css'
/*import Can from '../../Utils/Funciones/can'*/

function Dimensiones() {
  const [dataInicial, setDataInicial] = useState([])
  const [dataFiltradas, setDataFiltradas] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [togeadoASI, setTogeadoASI] = useState(false)
  const [togeadoPTC, setTogeadoPTC] = useState(false)
  const [togeadoCoordinador, setTogeadoCoordinador] = useState(false)
  const [PTCvalue, setPTCvalue] = useState('')
  const [evaluadorValue, setEvaluadorValue] = useState('')
  const [tipoEvaluacionValue, setTipoEvaluacion] = useState('')
  const [imagen, setImagen] = useState('')
  const [criterioEvaluacion, setCriterioEvaluacion] = useState('')
  const [archivoSubir, setArchivoSubir] = useState({})
  const [DesarrolloValue, setDesarrolloValue] = useState('')
  const [nombreImagen, setNombreImagen] = useState('')
  const [modalidad, setModalidad] = useState('')
  const [modalDescripcion, setModalDescripcion] = useState(false)

  const [instrumento, setInstrumento] = useState('')
  const [modalInstrumento, setModalInstrumento] = useState(false)
  const [modalConfigurarCampos, setModalConfigurarCampos] = useState(false)
  const [modalNuevaIsOpen, setModalNuevaIsOpen] = useState(false)

  const [estructuraCampos, setEstrucutraCampos] = useState([])
  const [dataCampos, setDataCampos] = useState([])
  const [modalImageIsOpen, setModalImageIsOpen] = useState(false)
  const [imageUrl, setImageUrl] = useState(false)

  const [quantityCampus, setQuantityCampus] = useState(0)

  const [nueva, setNueva] = useState({
    tipo: '',
    clave: '',
    nombre: '',
    categoria: {},
    descripcion: '',
  })
  const [editable, setEditable] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [tab, setTab] = useState(1)
  const [, setLeftValueRango] = useState('')
  const [, setRightValueRango] = useState('')

  const [opcionesCategorias, setOpcionesCategorias] = useState([])

  const [rangosEscala100, setRangosEscala100] = useState([])

  const [loadingImagen, setLoadingImagen] = useState(false)
  const [urlExterna, setUrlExterna] = useState('')

  const [verdadero, setVerdadero] = useState({ valor: '', descripcion: '' })
  const [falso, setFalso] = useState({ valor: '', descripcion: '' })
  const [escala5, setEscala5] = useState({
    0: '',
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
  })

  const namesTabs = [
    [1, 'Común'],
    [2, 'Específica'],
    [3, 'Reconocimiento'],
  ]

  const opcionesRango = [
    {
      label: 'Menor que',
      value: 'menor que',
    },
    {
      label: 'Menor o igual que',
      value: 'menor o igual que',
    },
    {
      label: 'Igual que',
      value: 'igual que',
    },
    {
      label: 'Mayor que',
      value: 'mayor que',
    },
    {
      label: 'Mayor o igual que',
      value: 'mayor o igual que',
    },
  ]

  const opcionesDesarrollo = [
    {
      label: 'Sin avenida de desarrollo',
      value: '',
    },
    {
      label: 'Diseño Instruccional',
      value: 'diseño instruccional',
    },
    {
      label: 'Diseño Curricular',
      value: 'diseño curricular',
    },
    {
      label: 'Campo Clínico',
      value: 'campo clínico',
    },
    {
      label: 'Consultoria',
      value: 'consultoria',
    },
    {
      label: 'Investigación',
      value: 'investigacion',
    },
    {
      label: 'Coaching Académico',
      value: 'coaching académico',
    },
  ]

  const opcionesPTC = [
    {
      label: 'PTC',
      value: 'PTC',
    },
    {
      label: 'CHEF',
      value: 'CHEF',
    },
    {
      label: 'PTC/SNI',
      value: 'PTC/SNI',
    },
    {
      label: 'Todos',
      value: 'todos',
    },
  ]
  const opcionesModalidad = [
    {
      label: 'TR',
      value: 'TR',
    },
    {
      label: 'ONLN',
      value: 'ONLN',
    },
  ]
  const opcionesEvaluador = [
    {
      label: 'Docente',
      value: 'Docente',
    },
    {
      label: 'Coordinador',
      value: 'Coordinador',
    },
    {
      label: 'Integración',
      value: 'Integración',
    },
  ]
  const opcionesTipoEvaluacion = [
    {
      label: 'global',
      value: 'global',
    },
    {
      label: 'nivel-periodo',
      value: 'nivel-periodo',
    },
    {
      label: 'materia',
      value: 'materia',
    },
  ]
  const opcionesCriterios = [
    {
      label: 'Booleano',
      value: 'Booleano',
    },
    {
      label: 'Carga de documentos',
      value: 'Carga de documentos',
    },
    {
      label: 'Escala 5',
      value: 'Escala 5',
    },
    {
      label: 'Escala 100',
      value: 'Escala 100',
    },
    {
      label: 'Instrumento',
      value: 'Instrumento',
    },
    {
      label: 'Instrumento Externo',
      value: 'Instrumento Externo',
    },
  ]

  const testHeader = ['Folio', 'Clave', 'Categoria', 'Nombre', 'Acciones']

  const containersTabs = [
    [
      <DataTable
        headers={testHeader}
        data={dataFiltradas}
        loading={loading}
        paginate
      />,
    ],
    [
      <DataTable
        headers={testHeader}
        data={dataFiltradas}
        loading={loading}
        paginate
      />,
    ],
    [
      <DataTable
        headers={testHeader}
        data={dataFiltradas}
        loading={loading}
        paginate
      />,
    ],
  ]
  const openEditModal = async (reg) => {
    setNueva({
      id: reg.id,
      tipo: reg.type,
      clave: reg.key,
      nombre: reg.name,
      categoria: {
        label: reg.categoria,
        value: String(reg.id_categoria),
      },
      descripcion: reg.descripcion,
    })
    setImageUrl(reg.url_img_rubrica)
    setTogeadoASI(reg.ASI === 'si')
    setTogeadoPTC(reg.PTC === 'si')
    setPTCvalue({ label: reg.tipo_PTC, value: reg.tipo_PTC })
    if (reg.avenida_desarrollo != '') {
      if (Array.isArray(reg.avenida_desarrollo)) {
        const values = reg.avenida_desarrollo.join(',')
        setDesarrolloValue(values)
      } else {
        setDesarrolloValue(reg.avenida_desarrollo)
      }
    }
    setTogeadoCoordinador(reg.coordinador === 'si')

    let misModalidades = []
    reg.modalidad.split(',').forEach((reg) => {
      misModalidades.push({
        label: reg,
        value: reg,
      })
    })
    setModalidad(misModalidades)
    setEvaluadorValue({
      label: reg.evaluador,
      value: reg.evaluador,
    })
    setTipoEvaluacion({
      label: reg.tipo_evaluacion,
      value: reg.tipo_evaluacion,
    })
    setCriterioEvaluacion({
      label: reg.criterio_evaluacion,
      value: reg.criterio_evaluacion,
    })
    setImagen(reg.url_img_rubrica)
    let url_img_rubrica = reg.url_img_rubrica.split('/')
    let nombreImagenRubrica = url_img_rubrica[url_img_rubrica.length - 1]
    nombreImagenRubrica = nombreImagenRubrica.replaceAll('+', ' ')
    setNombreImagen(decodeURI(nombreImagenRubrica))
    setEstrucutraCampos(reg.campos_carga_documento)

    if (
      reg.criterio_evaluacion === 'Booleano' ||
      reg.criterio_evaluacion === 'Carga de documentos'
    ) {
      if (reg.criterio_evaluacion_estructura.verdadero != undefined) {
        setVerdadero(reg.criterio_evaluacion_estructura.verdadero)
        setFalso(reg.criterio_evaluacion_estructura.falso)
      }
    } else if (reg.criterio_evaluacion === 'Escala 5') {
      if (reg.criterio_evaluacion_estructura != null) {
        let estructura_escala = {}
        Object.keys(reg.criterio_evaluacion_estructura).forEach((indexKey) => {
          estructura_escala[indexKey] =
            reg.criterio_evaluacion_estructura[indexKey].descripcion
        })
        setEscala5(estructura_escala)
      }
    } else if (
      reg.criterio_evaluacion === 'Escala 100' ||
      reg.criterio_evaluacion === 'Instrumento'
    ) {
      if (reg.criterio_evaluacion === 'Instrumento') {
        setInstrumento({
          id_instrumento: reg.id_instrumento,
          clave_instrumento: reg.clave_instrumento,
        })
      }
      let myEscala = []
      reg.criterio_evaluacion_estructura.forEach((rango) => {
        myEscala.push({
          rangoInicial: {
            label: rango.condicion_inferior,
            value: rango.condicion_inferior,
          },
          rangoFinal: {
            label: rango.condicion_superior,
            value: rango.condicion_superior,
          },
          valorInicial: rango.valor_inferior,
          valorFinal: rango.valor_superior,
          puntajeNumerico: rango.puntaje,
        })
      })
      setRangosEscala100(myEscala)
    } else if (reg.criterio_evaluacion === 'Instrumento Externo') {
      setUrlExterna(reg.url_externa.link_instrumento_externo)
    }

    handleOpenModalEditar()
  }

  const handleEditar = async () => {
    let valoresPtc = ''
    if (
      PTCvalue != null &&
      PTCvalue != '' &&
      PTCvalue.value != 'todos' &&
      PTCvalue.length > 1
    ) {
      PTCvalue.forEach((valor, i) => {
        valoresPtc += valor.value
        if (i != PTCvalue.length - 1) {
          valoresPtc += ','
        }
      })
    } else {
      valoresPtc = 'todos'
      if (PTCvalue.value != null) {
        valoresPtc = PTCvalue.value
      }
    }

    let valoresModalidad = ''
    if (modalidad != null && modalidad != '') {
      modalidad.forEach((valor, i) => {
        valoresModalidad += valor.value
        if (i != modalidad.length - 1) {
          valoresModalidad += ','
        }
      })
    }
    let body = {
      id: nueva.id,
      nombre: nueva.nombre,
      tipo: nueva.tipo,
      clave: nueva.clave,
      id_categoria:
        nueva.categoria.value != undefined
          ? parseInt(nueva.categoria.value)
          : '',
      ASI: togeadoASI ? 'si' : 'no',
      PTC: togeadoPTC ? 'si' : 'no',
      coordinador: togeadoCoordinador ? 'si' : 'no',
      tipo_PTC: valoresPtc,
      criterio_evaluacion: criterioEvaluacion.value,
      modalidad: valoresModalidad,
      evaluador: evaluadorValue.value,
      tipo_evaluacion: tipoEvaluacionValue.value,
      url_img_rubrica: imagen,
      descripcion: nueva.descripcion,
      avenida_desarrollo: DesarrolloValue,
      campos_carga_documento:
        criterioEvaluacion.value === 'Carga de documentos'
          ? estructuraCampos
          : '',
      id_instrumento:
        criterioEvaluacion.value === 'Instrumento'
          ? instrumento.id_instrumento
          : null,
      url_externa:
        criterioEvaluacion.value === 'Instrumento Externo' ? urlExterna : '',
      status: 1,
    }

    if (
      body.criterio_evaluacion === 'Booleano' ||
      body.criterio_evaluacion === 'Carga de documentos'
    ) {
      body.criterio_evaluacion_estructura = {}
      body.criterio_evaluacion_estructura.verdadero = verdadero
      body.criterio_evaluacion_estructura.falso = falso
    } else if (body.criterio_evaluacion === 'Escala 5') {
      body.criterio_evaluacion_estructura = {}
      Object.keys(escala5).forEach((indexKey) => {
        body.criterio_evaluacion_estructura[indexKey] = {}
        body.criterio_evaluacion_estructura[indexKey].descripcion =
          escala5[indexKey]
      })
    } else if (
      body.criterio_evaluacion === 'Escala 100' ||
      body.criterio_evaluacion === 'Instrumento'
    ) {
      body.criterio_evaluacion_estructura = []
      rangosEscala100.forEach((reg) => {
        body.criterio_evaluacion_estructura.push({
          condicion_inferior: reg.rangoInicial.value,
          condicion_superior: reg.rangoFinal.value,
          valor_inferior: parseFloat(reg.valorInicial),
          valor_superior: parseFloat(reg.valorFinal),
          puntaje: parseInt(reg.puntajeNumerico),
        })
      })
    } else {
      body.criterio_evaluacion_estructura = null
    }
    if (validar(body)) {
      try {
        setLoadingModal(true)
        const nuevaResponse = await postRequest(
          '/evaluacion/dimensiones/editar',
          body
        )
        if (nuevaResponse.s === 'OK') {
          toast.success(nuevaResponse.m)
          setLoadingModal(false)
          setModalNuevaIsOpen(false)
          setEditable(false)
          reloadList()
        }
        setLoadingModal(false)
      } catch (error) {
        console.error(error)
        setLoadingModal(false)
      }
    }
  }

  const handleNueva = async () => {
    let valoresPtc = ''
    if (PTCvalue != null && PTCvalue != '') {
      PTCvalue.forEach((valor, i) => {
        valoresPtc += valor.value
        if (i != PTCvalue.length - 1) {
          valoresPtc += ','
        }
      })
    }

    let valoresModalidad = ''
    if (modalidad != null && modalidad != '') {
      modalidad.forEach((valor, i) => {
        valoresModalidad += valor.value
        if (i != modalidad.length - 1) {
          valoresModalidad += ','
        }
      })
    }
    let body = {
      nombre: nueva.nombre,
      tipo: nueva.tipo,
      clave: nueva.clave,
      id_categoria:
        nueva.categoria.value != undefined
          ? parseInt(nueva.categoria.value)
          : '',
      ASI: togeadoASI ? 'si' : 'no',
      PTC: togeadoPTC ? 'si' : 'no',
      coordinador: togeadoCoordinador ? 'si' : 'no',
      tipo_PTC: valoresPtc,
      criterio_evaluacion: criterioEvaluacion.value,
      modalidad: valoresModalidad,
      evaluador: evaluadorValue.value,
      tipo_evaluacion: tipoEvaluacionValue.value,
      url_img_rubrica: imagen,
      descripcion: nueva.descripcion,
      avenida_desarrollo: togeadoPTC ? DesarrolloValue : '',
      campos_carga_documento:
        criterioEvaluacion.value === 'Carga de documentos'
          ? estructuraCampos
          : '',
      id_instrumento:
        criterioEvaluacion.value === 'Instrumento'
          ? instrumento.id_instrumento
          : null,
      url_externa:
        criterioEvaluacion.value === 'Instrumento Externo' ? urlExterna : '',
    }

    if (
      body.criterio_evaluacion === 'Booleano' ||
      body.criterio_evaluacion === 'Carga de documentos'
    ) {
      body.criterio_evaluacion_estructura = {}
      body.criterio_evaluacion_estructura.verdadero = verdadero
      body.criterio_evaluacion_estructura.falso = falso
    } else if (body.criterio_evaluacion === 'Escala 5') {
      body.criterio_evaluacion_estructura = {}
      Object.keys(escala5).forEach((indexKey) => {
        body.criterio_evaluacion_estructura[indexKey] = {}
        body.criterio_evaluacion_estructura[indexKey].descripcion =
          escala5[indexKey]
      })
    } else if (
      body.criterio_evaluacion === 'Escala 100' ||
      body.criterio_evaluacion === 'Instrumento'
    ) {
      body.criterio_evaluacion_estructura = []
      rangosEscala100.forEach((reg) => {
        body.criterio_evaluacion_estructura.push({
          condicion_inferior: reg.rangoInicial.value,
          condicion_superior: reg.rangoFinal.value,
          valor_inferior: parseFloat(reg.valorInicial),
          valor_superior: parseFloat(reg.valorFinal),
          puntaje: parseInt(reg.puntajeNumerico),
        })
      })
    } else {
      body.criterio_evaluacion_estructura = null
    }

    if (validar(body)) {
      try {
        setLoadingModal(true)
        const nuevaResponse = await postRequest(
          '/evaluacion/dimensiones/nueva',
          body
        )
        if (nuevaResponse.s === 'OK') {
          setLoadingModal(false)
          setModalNuevaIsOpen(false)
          reloadList()
        }
        setLoadingModal(false)
      } catch (error) {
        console.error(error)
        setLoadingModal(false)
      }
    }
  }

  const validar = (body) => {
    let valido = true
    if (body.nombre === '') {
      document.getElementById('tag-nombre').classList.add('error')
      valido = false
    } else {
      document.getElementById('tag-nombre').classList.remove('error')
    }
    if (body.clave === '') {
      document.getElementById('tag-clave').classList.add('error')
      valido = false
    } else {
      document.getElementById('tag-clave').classList.remove('error')
    }
    if (body.id_categoria === '') {
      document.getElementById('tag-categoria').classList.add('error')
      valido = false
    } else {
      document.getElementById('tag-categoria').classList.remove('error')
    }

    if (body.modalidad === '') {
      document.getElementById('tag-modalidad').classList.add('error')
      valido = false
    } else {
      document.getElementById('tag-modalidad').classList.remove('error')
    }

    if (body.evaluador === '' || body.evaluador === undefined) {
      document.getElementById('tag-evaluador').classList.add('error')
      valido = false
    } else {
      document.getElementById('tag-evaluador').classList.remove('error')
    }

    if (
      body.criterio_evaluacion === '' ||
      body.criterio_evaluacion === undefined
    ) {
      document.getElementById('tag-criterio').classList.add('error')
      valido = false
    } else {
      document.getElementById('tag-criterio').classList.remove('error')
    }

    if (body.url_externa != '' && !body.url_externa.includes('http')) {
      valido = false
      toast.error('Url externa no valida')
    } else {
      if (
        body.url_externa === '' &&
        criterioEvaluacion.value === 'Instrumento Externo'
      ) {
        valido = false
        toast.error('Url externa no valida')
      }
    }

    if (body.descripcion === '' || body.descripcion === undefined) {
      document.getElementById('tag-descripcion').classList.add('error')
      valido = false
    } else {
      document.getElementById('tag-descripcion').classList.remove('error')
    }

    return valido
  }

  const handleDelete = async () => {
    let valoresPtc = ''
    if (
      PTCvalue != null &&
      PTCvalue != '' &&
      PTCvalue.value != 'todos' &&
      PTCvalue.length > 1
    ) {
      PTCvalue.forEach((valor, i) => {
        valoresPtc += valor.value
        if (i != PTCvalue.length - 1) {
          valoresPtc += ','
        }
      })
    } else {
      valoresPtc = 'todos'
    }

    let valoresModalidad = ''
    if (modalidad != null && modalidad != '') {
      modalidad.forEach((valor, i) => {
        valoresModalidad += valor.value
        if (i != modalidad.length - 1) {
          valoresModalidad += ','
        }
      })
    }
    let body = {
      id: nueva.id,
      nombre: nueva.nombre,
      tipo: nueva.tipo,
      clave: nueva.clave,
      id_categoria:
        nueva.categoria.value != undefined
          ? parseInt(nueva.categoria.value)
          : '',
      ASI: togeadoASI ? 'si' : 'no',
      PTC: togeadoPTC ? 'si' : 'no',
      coordinador: togeadoCoordinador ? 'si' : 'no',
      tipo_PTC: valoresPtc,
      criterio_evaluacion: criterioEvaluacion.value,
      modalidad: valoresModalidad,
      evaluador: evaluadorValue.value,
      tipo_evaluacion: tipoEvaluacionValue.value,
      url_img_rubrica: imagen,
      descripcion: nueva.descripcion,
      avenida_desarrollo: DesarrolloValue.value,
      id_instrumento:
        criterioEvaluacion.value === 'Instrumento'
          ? instrumento.id_instrumento
          : null,
      status: 0,
      url_externa:
        criterioEvaluacion.value === 'Instrumento Externo' ? urlExterna : '',
    }
    if (
      body.criterio_evaluacion === 'Booleano' ||
      body.criterio_evaluacion === 'Carga de documentos'
    ) {
      body.criterio_evaluacion_estructura = {}
      body.criterio_evaluacion_estructura.verdadero = verdadero
      body.criterio_evaluacion_estructura.falso = falso
    } else if (body.criterio_evaluacion === 'Escala 5') {
      body.criterio_evaluacion_estructura = {}
      Object.keys(escala5).forEach((indexKey) => {
        body.criterio_evaluacion_estructura[indexKey] = {}
        body.criterio_evaluacion_estructura[indexKey].descripcion =
          escala5[indexKey]
      })
    } else if (
      body.criterio_evaluacion === 'Escala 100' ||
      body.criterio_evaluacion === 'Instrumento'
    ) {
      body.criterio_evaluacion_estructura = []
      rangosEscala100.forEach((reg) => {
        body.criterio_evaluacion_estructura.push({
          condicion_inferior: reg.rangoInicial.value,
          condicion_superior: reg.rangoFinal.value,
          valor_inferior: parseFloat(reg.valorInicial),
          valor_superior: parseFloat(reg.valorFinal),
          puntaje: parseInt(reg.puntajeNumerico),
        })
      })
    } else {
      body.criterio_evaluacion_estructura = null
    }

    if (true) {
      try {
        setLoadingModal(true)
        const nuevaResponse = await postRequest(
          '/evaluacion/dimensiones/editar',
          body
        )
        if (nuevaResponse.s === 'OK') {
          setLoadingModal(false)
          setModalNuevaIsOpen(false)
          setEditable(false)
          reloadList()
        }
        setDeleteModal(false)
        setLoadingModal(false)
      } catch (error) {
        console.error(error)
        setDeleteModal(false)
        setLoadingModal(false)
      }
    }
  }

  ////////// Filtrar resultados segun el input de busqueda //////////
  const search = (e) => {
    let text = e.target.value.toLowerCase()
    let result = dataInicial.filter((row) => {
      if (JSON.stringify(row).toLowerCase().includes(text)) {
        return true
      }
      return false
    })
    setDataFiltradas(result)
  }

  useEffect(() => {
    reloadList()
  }, [tab])

  const reloadList = async () => {
    setLoading(true)
    try {
      const response = await getRequest(
        '/evaluacion/dimensiones?tipo=' + namesTabs[tab - 1][1]
      )
      //const response = getRegs()
      if (response.s === 'OK') {
        let data = []
        response.d.forEach((reg) => {
          data.push([
            reg.id,
            reg.key,
            reg.categoria,
            reg.name,
            <EditButton onClick={() => openEditModal(reg)}>Editar</EditButton>,
          ])
        })
        setDataInicial(data)
        setDataFiltradas(data)
        setLoading(false)
      } else {
        setDataInicial([])
        setDataFiltradas([])
        setLoading(false)
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    reloadList()
  }, [])

  const handleChangeEvaluador = ({ value }) => {
    setEvaluadorValue({
      label: value,
      value,
    })
  }
  const handleChangeTipoEvaluacion = ({ value }) => {
    setTipoEvaluacion({
      label: value,
      value,
    })
  }
  const handleChangeCriterio = ({ value }) => {
    setCriterioEvaluacion({
      label: value,
      value,
    })
  }
  const handleChangeLeftValue = ({ value }) => {
    setLeftValueRango({
      label: value,
      value,
    })
  }
  const handleChangeRightValue = ({ value }) => {
    setRightValueRango({
      label: value,
      value,
    })
  }
  function handleClickButtonExaminar() {
    document.getElementById('input-examinar').click()
  }

  async function handleChangeImagenRubrica(e) {
    var reader = new FileReader()
    reader.readAsArrayBuffer(e.target.files[0])
    reader.onload = () => {
      new Uint8Array(reader.result)
    }
    let fileSave = e.target.files[0]
    if (
      (fileSave.name.toLowerCase().includes('jpeg') ||
        fileSave.name.toLowerCase().includes('jpg') ||
        fileSave.name.toLowerCase().includes('png')) &&
      fileSave.size <= 15000000
    ) {
      setArchivoSubir(e.target.files[0])
      //setNombreImagen(e.target.files[0].name)
    } else {
      if (fileSave.size > 15000000) {
        toast.error('Archivo supera los 15MB')
      } else {
        toast.error('Archivo no permitido')
      }
    }
  }

  useEffect(async () => {
    if (archivoSubir.name !== undefined) {
      setLoadingImagen(true)

      try {
        let response = await postRequest('/usuarios/subir-documento', {
          nombre: archivoSubir.name,
        })
        if (response.s === 'OK') {
          await putRequest(
            response.d.presign_url,
            archivoSubir,
            archivoSubir.type
          )
          setImagen(response.d.url)
          setNombreImagen(archivoSubir.name)
          setLoadingImagen(false)
        } else {
          setLoadingImagen(false)
        }
      } catch (e) {
        console.log(e)
        setLoadingImagen(false)
        toast.error('Error subiendo archivo')
      }
    }
  }, [archivoSubir])

  const customStyles = {
    option: (provided, _state) => ({
      ...provided,
      color: '#616b74',
      fontSize: 14,
      fontWeight: 400,
      padding: '5px 25px',
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      userSelect: 'none',
      backgroundColor: '#fff',
      borderTop: '1px solid #ccc',
    }),
    control: (provided, _state) => ({
      ...provided,
      minHeight: 0,
      height: '25px',
      borderRadius: 15,
      fontSize: 11,
      backgroundColor: '#f9f9f9',
      padding: 0,
    }),
    placeholder: (provided, _state) => ({
      ...provided,
      padding: 0,
      fontSize: 11,
      fontWeight: 400,
    }),
    menu: (provided, _state) => ({
      ...provided,
      borderRadius: 0,
    }),
    input: (provided, _state) => ({
      ...provided,
      padding: 0,
      fontSize: 11,
      fontWeight: 400,
      borderRadius: 0,
    }),
    singleValue: (provided, _state) => ({
      ...provided,
      top: '50%',
    }),
  }

  async function getCategorias() {
    setLoadingModal(true)
    try {
      let response = await getRequest('/evaluacion/categoria')
      if (response.s === 'OK') {
        let misOpciones = []
        response.d.forEach((reg) => {
          misOpciones.push({
            label: reg.nombre,
            value: String(reg.folio),
          })
        })
        setOpcionesCategorias(misOpciones)
      } else {
        setModalNuevaIsOpen(false)
      }

      setLoadingModal(false)
    } catch (error) {
      setModalNuevaIsOpen(false)
      setEditable(false)
    }
  }

  function handleOpenModalNuevo() {
    setModalNuevaIsOpen(true)

    getCategorias()

    setRangosEscala100([
      {
        rangoInicial: '',
        valorInicial: '',
        rangoFinal: '',
        valorFinal: '',
        puntajeNumerico: '',
        descripcion: '',
      },
    ])

    setTogeadoASI(false)
    setTogeadoPTC(false)
    setTogeadoCoordinador(false)
    setPTCvalue('')
    setEvaluadorValue('')
    setTipoEvaluacion('')
    setImagen('')
    setNombreImagen('')
    setCriterioEvaluacion('')
    setModalidad('')

    setEditable(false)
    setNueva({
      tipo: namesTabs[tab - 1][1],
      clave: '',
      categoria: {},
      nombre: '',
    })
    setInstrumento('')

    setVerdadero({ valor: '', descripcion: '' })
    setFalso({ valor: '', descripcion: '' })
    setEscala5({
      0: '',
      1: '',
      2: '',
      3: '',
      4: '',
      5: '',
    })
    setDataCampos([])
    setEstrucutraCampos([])
  }

  function handleAddNewRangoEscala100() {
    setRangosEscala100([
      ...rangosEscala100,
      {
        rangoInicial: '',
        valorInicial: '',
        rangoFinal: '',
        valorFinal: '',
        puntajeNumerico: '',
      },
    ])
  }

  function handleDeleteRangoEscala100(index) {
    let eliminarEscala = rangosEscala100.filter((_opc, j) => {
      return j != index
    })
    setRangosEscala100(eliminarEscala)
  }

  function handleOpenModalEditar() {
    setModalNuevaIsOpen(true)
    setEditable(true)

    getCategorias()
  }
  return (
    <Contenedor title='Dimensiones'>
      <div className='opciones-bar'>
        <div className='sub-filtro'>
          <NewButton onClick={handleOpenModalNuevo} />
        </div>
        <div style={{ float: 'right' }}>
          <input
            type='search'
            placeholder='Buscar'
            className='SearchInput-tabla-completa SearchInput-Usuarios'
            onChange={search}
          />
        </div>
      </div>
      <div style={{ height: 'calc(100% - 73px)' }}>
        <Tabs
          namesTabs={namesTabs}
          containersTabs={containersTabs}
          changeBox={setTab}
        />
      </div>
      <NewModal
        onDelete={() => {
          setDeleteModal(true)
        }}
        canDelete={editable}
        DeleteText='Desactivar'
        title={editable ? 'Editar Dimensión' : 'Crear Dimesión'}
        open={modalNuevaIsOpen}
        handleClose={() => {
          setModalNuevaIsOpen(false)
          setNueva({
            tipo: '',
            clave: '',
            grupo: '',
            nombre: '',
            descripcion: '',
            status: 'activa',
          })
          setDesarrolloValue('')
          setQuantityCampus(0)
        }}
        height={600}
        loading={loadingModal}
        handleSubmit={() => {
          if (editable) {
            handleEditar(nueva)
          } else {
            handleNueva(nueva)
          }
        }}
      >
        <div className='nuevoDimensionForm'>
          <Grid container spacing={1}>
            <Grid item xs={12} md={3}>
              <label id='tag-tipo'>Tipo de Dimensión (*)</label>
              <input disabled placeholder='Ingresa Nombre' value={nueva.tipo} />
            </Grid>
            <Grid item xs={12} md={3}>
              <label id='tag-clave'>Clave (*)</label>
              <input
                placeholder='Ingresa Clave'
                value={nueva.clave}
                onChange={(e) =>
                  setNueva({
                    ...nueva,
                    clave: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <label id='tag-categoria'>Categoria (*)</label>
              <Select
                options={opcionesCategorias}
                value={nueva.categoria}
                styles={customStyles}
                name='etiqueta'
                placeholder='Selección...'
                className='select-search-box'
                onChange={(e) => {
                  setNueva({ ...nueva, categoria: e })
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <label id='tag-nombre'>Nombre (*)</label>
              <input
                placeholder='Ingresa Nombre'
                value={nueva.nombre}
                onChange={(e) =>
                  setNueva({
                    ...nueva,
                    nombre: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid xs={2}></Grid>
            <Grid xs={4}>
              <span id='tag-descripcion'>Descripción (*):</span>
            </Grid>
            <Grid xs={4}>
              <button
                style={{ marginTop: '-5px' }}
                className='descripcion-dimension'
                onClick={() => {
                  setModalDescripcion(true)
                }}
              >
                Modificar
              </button>
            </Grid>
            <Grid xs={2}></Grid>
            <Grid xs={2}></Grid>
            <Grid xs={4}>ASI</Grid>
            <Grid xs={1}>
              <ToggableButton
                yes={togeadoASI}
                onClick={() => {
                  setTogeadoASI(!togeadoASI)
                }}
              />
            </Grid>
            <Grid xs={5}></Grid>

            <Grid xs={2}></Grid>
            <Grid xs={4}>PTC:</Grid>
            <Grid xs={1}>
              <ToggableButton
                yes={togeadoPTC}
                onClick={() => {
                  setTogeadoPTC(!togeadoPTC)
                }}
              />
            </Grid>
            <Grid xs={4}>
              <Select
                options={opcionesPTC}
                value={PTCvalue}
                styles={customStyles}
                name='etiqueta'
                placeholder='Selección...'
                className='select-search-box'
                onChange={setPTCvalue}
                isMulti
              />
            </Grid>
            <Grid xs={1}></Grid>

            {togeadoPTC ? (
              <>
                <Grid xs={2}></Grid>
                <Grid xs={4}>Avenida de Desarrollo:</Grid>
                <Grid xs={5}>
                  <MultiSelect
                    fromUser={true}
                    loading={false}
                    options={opcionesDesarrollo}
                    setCantidad={setQuantityCampus}
                    cantidad={quantityCampus}
                    onSet={setDesarrolloValue}
                    selecteds={DesarrolloValue}
                    noSelectAll
                  />
                </Grid>
                <Grid xs={1}></Grid>
              </>
            ) : null}
            <Grid xs={2}></Grid>
            <Grid xs={4}>Coordinador:</Grid>
            <Grid xs={1}>
              <ToggableButton
                yes={togeadoCoordinador}
                onClick={() => {
                  setTogeadoCoordinador(!togeadoCoordinador)
                }}
              />
            </Grid>
            <Grid xs={5}></Grid>
            <Grid xs={2}></Grid>
            <Grid xs={4}>
              <span id='tag-modalidad'>Modalidad(*):</span>
            </Grid>
            <Grid xs={5}>
              <Select
                options={opcionesModalidad}
                value={modalidad}
                styles={customStyles}
                name='etiqueta'
                placeholder='Selección...'
                className='select-search-box'
                onChange={setModalidad}
                isMulti
              />
            </Grid>
            <Grid xs={1}></Grid>

            <Grid xs={2}></Grid>
            <Grid xs={4}>
              <span id='tag-evaluador'>Evaluador(*):</span>
            </Grid>
            <Grid xs={5}>
              <Select
                options={opcionesEvaluador}
                value={evaluadorValue}
                styles={customStyles}
                name='etiqueta'
                placeholder='Selección...'
                className='select-search-box'
                onChange={handleChangeEvaluador}
              />
            </Grid>
            <Grid xs={1}></Grid>

            <Grid xs={2}></Grid>
            <Grid xs={4}>
              <span id='tag-evaluador'>Tipo de Evaluación(*):</span>
            </Grid>
            <Grid xs={5}>
              <Select
                options={opcionesTipoEvaluacion}
                value={tipoEvaluacionValue}
                styles={customStyles}
                name='etiqueta'
                placeholder='Selección...'
                className='select-search-box'
                onChange={handleChangeTipoEvaluacion}
              />
            </Grid>
            <Grid xs={1}></Grid>

            <Grid xs={2}></Grid>
            <Grid xs={4}>
              <span id='tag-criterio'>Criterio Evaluación (*):</span>
            </Grid>
            <Grid xs={5}>
              <Select
                options={opcionesCriterios}
                value={criterioEvaluacion}
                styles={customStyles}
                name='etiqueta'
                placeholder='Selección...'
                className='select-search-box'
                onChange={handleChangeCriterio}
              />
            </Grid>
            <Grid xs={1}></Grid>
            {criterioEvaluacion.value === 'Carga de documentos' ? (
              <>
                <Grid xs={2}></Grid>
                <Grid xs={4}>
                  <span id='tag-descripcion'>Configurar Campos (*):</span>
                </Grid>
                <Grid xs={4}>
                  <button
                    style={{ marginTop: '-5px' }}
                    className='descripcion-dimension'
                    onClick={() => {
                      setModalConfigurarCampos(true)
                    }}
                  >
                    Configurar
                  </button>
                </Grid>
                <Grid xs={2}></Grid>
              </>
            ) : null}

            {criterioEvaluacion.value === 'Instrumento Externo' ? (
              <>
                <Grid xs={2}></Grid>
                <Grid xs={4}>
                  <span id='tag-descripcion'>Url Externa (*):</span>
                </Grid>
                <Grid xs={5}>
                  <input
                    value={urlExterna}
                    onChange={(e) => {
                      setUrlExterna(e.target.value)
                    }}
                    style={{ marginTop: '-5px' }}
                  />
                </Grid>
                <Grid xs={1}></Grid>
              </>
            ) : null}

            {criterioEvaluacion.value === 'Instrumento' ||
            criterioEvaluacion.value === 'Escala 100' ? (
              <>
                {criterioEvaluacion.value === 'Instrumento' ? (
                  <>
                    <Grid xs={2}></Grid>
                    <Grid xs={4}>Instrumento Seleccionado:</Grid>
                    <Grid xs={4}>
                      <input value={instrumento.clave_instrumento} />
                    </Grid>
                    <Grid xs={1}>
                      <NewButton
                        noIcon
                        onClick={() => setModalInstrumento(true)}
                        style={{ marginTop: 20, float: 'right' }}
                        agregar='Buscar'
                      />
                    </Grid>
                    <Grid xs={1}></Grid>
                  </>
                ) : null}

                <Grid xs={2}></Grid>
                <Grid xs={4}>Conversión de escala:</Grid>
                <Grid xs={5}>
                  <button
                    className='add-escala100-button'
                    title='Añadir nuevo rango'
                    onClick={handleAddNewRangoEscala100}
                  >
                    + Añadir Rango
                  </button>
                </Grid>
                <Grid xs={1}></Grid>
              </>
            ) : null}

            <Grid xs={12}>
              {criterioEvaluacion.value === 'Escala 5' ? (
                <Grid container className='contenedor-estructura-booleana'>
                  <Grid style={{ textAlign: 'center' }} xs={3}>
                    Valor
                  </Grid>
                  <Grid style={{ textAlign: 'center' }} xs={9}>
                    Descripción
                  </Grid>
                  {Object.keys(escala5).map((indexKey) => (
                    <>
                      <Grid style={{ textAlign: 'center' }} xs={3}>
                        {indexKey}
                      </Grid>
                      <Grid style={{ textAlign: 'center' }} xs={8}>
                        <input
                          value={escala5[indexKey]}
                          onChange={(e) => {
                            setEscala5({
                              ...escala5,
                              [indexKey]: e.target.value,
                            })
                          }}
                          className='inputs-tabla-escala'
                        />
                      </Grid>
                      <Grid style={{ textAlign: 'center' }} xs={1}>
                        <button
                          onClick={() => {
                            let eliminarEscala = { ...escala5 }
                            delete eliminarEscala[indexKey]
                            setEscala5(eliminarEscala)
                          }}
                          className='eliminar-escala5'
                        >
                          <Cancel></Cancel>
                        </button>
                      </Grid>
                    </>
                  ))}
                </Grid>
              ) : ['Booleano', 'Carga de documentos'].includes(
                  criterioEvaluacion.value
                ) ? (
                <Grid container className='contenedor-estructura-booleana'>
                  <Grid style={{ textAlign: 'center' }} xs={2}></Grid>
                  <Grid style={{ textAlign: 'center' }} xs={5}>
                    Valor
                  </Grid>
                  <Grid style={{ textAlign: 'center' }} xs={5}>
                    Descripción
                  </Grid>
                  <Grid style={{ textAlign: 'center' }} xs={2}>
                    Verdadero
                  </Grid>
                  <Grid xs={5}>
                    <input
                      type='number'
                      value={verdadero.valor}
                      onChange={(e) => {
                        setVerdadero({
                          ...verdadero,
                          valor: parseInt(e.target.value),
                        })
                      }}
                      className='inputs-tabla-escala'
                    />
                  </Grid>
                  <Grid xs={5}>
                    <input
                      value={verdadero.descripcion}
                      onChange={(e) => {
                        setVerdadero({
                          ...verdadero,
                          descripcion: e.target.value,
                        })
                      }}
                      className='inputs-tabla-escala'
                    />
                  </Grid>
                  <Grid style={{ textAlign: 'center' }} xs={2}>
                    Falso
                  </Grid>
                  <Grid xs={5}>
                    <input
                      type='number'
                      value={falso.valor}
                      onChange={(e) => {
                        setFalso({ ...falso, valor: parseInt(e.target.value) })
                      }}
                      className='inputs-tabla-escala'
                    />
                  </Grid>
                  <Grid xs={5}>
                    <input
                      value={falso.descripcion}
                      onChange={(e) => {
                        setFalso({ ...falso, descripcion: e.target.value })
                      }}
                      className='inputs-tabla-escala'
                    />
                  </Grid>
                </Grid>
              ) : criterioEvaluacion.value === 'Escala 100' ? (
                <div
                  style={togeadoPTC ? { height: '175px' } : { height: '210px' }}
                  className='contenedor-rangos-escala100'
                >
                  {rangosEscala100.map((rango, k) => (
                    <Escala100
                      customStyles={customStyles}
                      values={rango}
                      indiceRango={k}
                      opcionesRango={opcionesRango}
                      setRangosEscala100={setRangosEscala100}
                      rangosEscala100={rangosEscala100}
                      handleChangeLeftValue={handleChangeLeftValue}
                      handleChangeRightValue={handleChangeRightValue}
                      handleDeleteRangoEscala100={handleDeleteRangoEscala100}
                    />
                  ))}
                </div>
              ) : criterioEvaluacion.value === 'Instrumento' ? (
                <>
                  <div
                    style={
                      togeadoPTC
                        ? { height: '104px', textAlign: 'center' }
                        : { height: '140px', textAlign: 'center' }
                    }
                    className='contenedor-rangos-escala100-instrumento'
                  >
                    {rangosEscala100.map((rango, k) => (
                      <Escala100
                        customStyles={customStyles}
                        values={rango}
                        indiceRango={k}
                        opcionesRango={opcionesRango}
                        setRangosEscala100={setRangosEscala100}
                        rangosEscala100={rangosEscala100}
                        handleChangeLeftValue={handleChangeLeftValue}
                        handleChangeRightValue={handleChangeRightValue}
                        handleDeleteRangoEscala100={handleDeleteRangoEscala100}
                      />
                    ))}
                  </div>
                </>
              ) : null}
            </Grid>

            {criterioEvaluacion.value === 'Instrumento Externo' ? null : (
              <>
                <Grid xs={2}></Grid>
                <Grid xs={4}>Imagen de Rúbrica:</Grid>
                <Grid xs={3}>
                  <div className='examinar-imagen'>
                    <input
                      id='input-examinar'
                      style={{ display: 'none' }}
                      type='file'
                      onChange={(e) => {
                        handleChangeImagenRubrica(e)
                      }}
                    />
                    {loadingImagen ? (
                      <>
                        <CircularProgress size={25} />
                      </>
                    ) : (
                      <button
                        style={{
                          color: 'white',
                          backgroundColor: 'rgb(0,144,217)',
                        }}
                        onClick={handleClickButtonExaminar}
                      >
                        Examinar
                      </button>
                    )}
                  </div>
                </Grid>
              </>
            )}
            <Grid xs={3}>
              <a onClick={() => setModalImageIsOpen(true)} href='#'>
                {nombreImagen}
              </a>
            </Grid>
          </Grid>
        </div>
      </NewModal>
      <NewModal
        height={390}
        width={800}
        open={modalInstrumento}
        title='Seleccionar Instrumento'
        handleClose={() => setModalInstrumento(false)}
        noFooter
      >
        <Cuestionarios
          setInstrumento={setInstrumento}
          setModal={setModalInstrumento}
        />
      </NewModal>

      <NewModal
        height={410}
        width={800}
        open={modalConfigurarCampos}
        title='Configuración de campos Carga de documentos'
        handleClose={() => setModalConfigurarCampos(false)}
        handleSubmit={() => {
          setModalConfigurarCampos(false)
        }}
        noFooter
      >
        <EstructuraCampos
          setEstrucutraCampos={setEstrucutraCampos}
          setDataCampos={setDataCampos}
          dataCampos={dataCampos}
          estructuraCampos={estructuraCampos}
        />
      </NewModal>

      <NewModal
        open={modalDescripcion}
        height={160}
        title={editable ? 'Editar Dimensión' : 'Crear Dimesión'}
        loading={false}
        handleClose={() => setModalDescripcion(false)}
        handleSubmit={() => {
          setModalDescripcion(false)
        }}
      >
        <Grid container>
          <Grid xs={12} md={12}>
            <label>Descripción(*):</label>
            <textarea
              value={nueva.descripcion}
              onChange={(e) => {
                setNueva({
                  ...nueva,
                  descripcion: e.target.value,
                })
              }}
              placeholder='Ingresa Descripción'
              className='descripcion-dimension-textarea'
            />
          </Grid>
        </Grid>
      </NewModal>
      <NewModal
        open={deleteModal}
        height={120}
        title='Confirmar'
        loading={loadingModal}
        handleClose={() => setDeleteModal(false)}
        handleSubmit={handleDelete}
      >
        <div class='delete-confirm'>
          <h2 style={{ textAlign: 'center' }}>
            ¿Está seguro de que desea desactivar esta dimension?
          </h2>
        </div>
      </NewModal>
      <NewModal
        title='Información Rúbrica'
        open={modalImageIsOpen}
        handleClose={() => {
          setModalImageIsOpen(false)
        }}
        noSaveBtn
        width='50%'
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            overflowY: 'scroll',
            padding: '70px 10px 90px',
          }}
        >
          <img style={{ width: '100%' }} src={imageUrl} alt='imagen rubrica' />
        </div>
      </NewModal>
    </Contenedor>
  )
}

export default Dimensiones
