import React from 'react'
import PropTypes from 'prop-types'

import './style.css'

function EditButton(props) {
  return (
    <button
      id={props.id}
      className='EditButton'
      onClick={props.onClick}
      style={props.style}
    >
      {props.children}
    </button>
  )
}

EditButton.propTypes = {
  id: PropTypes.number,
  onClick: PropTypes.func,
  style: PropTypes.any,
  children: PropTypes.element,
}

export default EditButton
