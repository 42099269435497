import { createStore } from 'redux';
import reducer from './reducers';

const initialState = {
  user: {},
  isLoged: false,
  version: '',
  tokenKey:'',
  urlScriptAyuda:"https://static.zdassets.com/ekr/snippet.js?key=b3f90d0c-ebfb-4de3-b4b9-7fa276e2bd04",
  listaCampus:[],
  permisos:[]
};

const store = createStore(
  reducer,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export default store;
