import React from "react";

const CalendarioIcon = () => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 172 175" enable-background="new 0 0 172 175" space="preserve">
            <image id="image0" width="172" height="175" x="0" y="0"
                href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKwAAACvCAQAAADKrP39AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
                AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZ
                cwAACxIAAAsSAdLdfvwAAAAHdElNRQflAgMXCCbhlWYfAAAFnElEQVR42u2dP2wURxTGP8cnuEgg
                ljQUVpLrKGJZFymiQuJSBCVFJJepYiKBFFxQ0CR0LhMJCUQDEhQmFVJAcuQOiri0IiSuiAu6Sywk
                0oRFsoTjnOMU5zN/ipl9s/PNnI/v53Lf7O77eTzrnbc7O7EDweCd3CcwrkgsCYklIbEkJJaExJKQ
                WBISS0JiSUgsCYklIbEkJJaExJKQWBISS0JiSUgsCYklIbEkJJaExJKQWBISS0JiSUgsCYklIbEk
                JJZEg7DPFlrO7SW6SXNso3Bu76FHOOpO/J+FHTddwjFdP13P+SwwjqqhgITEkpBYEhJLQmJJSCwJ
                iSUhsSQmorzn1cEsOihQ4EjuhIJ5jhIlVrCElRi7qyu2hWs4jQkcyO0lGlvYwX1cqHubW0dsgZ/w
                BWW2YRRYxtcow5uHi53FHRzMnT2Vf/AVlkIbh168LuHumGsFDuIuLoU2Duux13EOk7nzTsI2buJ8
                SMOQHjv/1mgFJnEO8yEN7T22jUe5s03Ox/apebvYP/F+7jyTs44PrE2sQ8E8pnJnmYEp+3Bg7bF/
                42juLLPwDO/ZGth6bAeHc2eYicPo2BrYxF4c2/ssHw1ctDWwDQXlPp5kqctzTxH9DSw9dj/PXdXn
                iE2s5U+7jRd41xNTAoDnFDbQT6qkgUPec/bFAC/Qtkwo2sZMt9b/sIFvUOIM5pxxf+FsPVNGbnmk
                /YJFFLiNQ86/X1+XeoOYF6N/cQpdwHv93IgzlVyZDc/2HlYAnMJqzGmlmKWZp4mfyYpLF09j7k41
                LxISS0JiSUgsCYklIbEkYv4f29z9D7bliZu0zhTVxFdGau2eTzPmQS2TMB38mlTIqPGp5cZGQwEJ
                iSUhsSQkloTEkpBYEhJLQmJJSCwJiSUhsSQkloTEkpBYEjHnY/t4kjudWkzFtBFT7BPvFPdo08OH
                8XamoYCExJKILbbjWdxnFQCwUCnqqifqKgBg1RO1UCmqM+pifVQr2A2iCk9UYd5jQjQUkJBYEhJL
                QmJJSCwJiSUhsSQkloTEkpBYEhJLQmJJSCyJ+OsP9B2vWDb2Xk/exKY3atMZ1dzd1ne+9Dyc1XJH
                +V5QDiC22C4+c24vAQCLnofOB1E/4I4zqgcAOOtdjL9KVDeyh+hiy0rP6Vf78kC1qG6l86oWFRGN
                sSRSl2YeAvCXZgZRNzxRNwAADyuVZnxRnVEX66Pa0DOI8pVTmuY9JkRDAQmJJSGxJCSWhMSSkFgS
                EktCYklILAmJJSGxJCSWhMSSSC222sqxw9KMm81XYuMcNyIxVzH6Y8zfmtEqRqNA7AngAm3n9sF3
                v6t9HdwXNaiJ5fmyt5fYYtt44Cx/P8YnAM7gO2f5exD1Peac5e/b+BbALRx3lr9/3C3OJCZ+yaLh
                7EHD4zWdhZfGXowraliaITwVUB+NsSQkloTEkpBYEhJLQmJJSCwJiSUhsSQkloTEkpBYEhJLIrVY
                X7nl1ajSE1Ua9pgclWZeMrKlmeQrBUUm6vnHFHsMi2lNRGURx2LuzlZB6Hvi5/D5qI55HppercYC
                ukVsWSEm6m99xKiS/x62T1QbP20/ZkxYgm1j7KPcuWXEmLtN7M+5s8uIMXfbUFDgWe78snHUNsba
                emyJ5dz5ZeKeTau1xwItPMaB3FkmZwvHrQ8qWW8QeriC7dx5JmYbV+zPf1l7LAD8jo9y55qUNUzb
                G4Xc0p7Eeu5cE7KOkyHNQsSWmHlr1K5jxnrZGhA2CVNiBmtjP9ZuYy1Ua/jsVolpXMZW7tyJbOEy
                pkO1hl28XtLCNXyZ2wCFZVyo9yR4PbEAUOA8ZnECfWRYeSUqgwx+wxKuh/fUIfXFDvG9C7AfKOOt
                zxVPrHgNlb9JSCwJiSUhsSQkloTEkpBYEhJLQmJJSCwJiSUhsSQkloTEkpBYEv8DNJBId+qPdtkA
                AAAldEVYdGRhdGU6Y3JlYXRlADIwMjEtMDItMDNUMjM6MDg6MzgrMDM6MDCmbr7MAAAAJXRFWHRk
                YXRlOm1vZGlmeQAyMDIxLTAyLTAzVDIzOjA4OjM4KzAzOjAw1zMGcAAAAABJRU5ErkJggg==" />
        </svg>
    )
}

export default CalendarioIcon;