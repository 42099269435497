/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Close from '@material-ui/icons/Close'
import './styles.css'

function SeleccionPregunta({ setSeleccionSimple, seleccionSimple }) {
  const [opciones, setOpciones] = useState([
    { id: 0, check: false, option: 'Opcion ' + 1, value: '' },
  ])
  const [, setCanAdd] = useState(false)
  const [misId, newId] = useState(1)
  const [numeroOpcion, setNumeroOpcion] = useState(1)
  const [pregunta, setPregunta] = useState('')

  let respuestaSeleccionData = {
    pregunta,
    opciones,
  }
  useEffect(() => {
    if (seleccionSimple) {
      setPregunta(seleccionSimple.pregunta)
      if (seleccionSimple.opciones) {
        setOpciones(seleccionSimple.opciones)
        setNumeroOpcion(seleccionSimple.opciones.length)
        newId(
          seleccionSimple.opciones[seleccionSimple.opciones.length - 1].id + 1
        )
      }
      setSeleccionSimple({ ...respuestaSeleccionData })
    }
  }, [])

  useEffect(() => {
    setSeleccionSimple({ ...respuestaSeleccionData })
  }, [pregunta, opciones])

  const addOption = () => {
    let misOpciones = []
    opciones.forEach((opcion) => {
      misOpciones.push(opcion)
    })
    misOpciones.push({
      id: misId,
      check: false,
      option: 'Opcion ' + (numeroOpcion + 1),
      value: '',
    })
    setOpciones(misOpciones)
    setSeleccionSimple({ ...respuestaSeleccionData, opciones: misOpciones })
    setCanAdd(false)
    newId(misId + 1)
    document.getElementsByClassName('input-pregunta')[numeroOpcion].focus()
    setNumeroOpcion(numeroOpcion + 1)
  }

  const handleChangeInputOption = (e) => {
    let misOpciones = []
    opciones.forEach((opcion) => {
      misOpciones.push(opcion)
    })

    misOpciones.forEach((opcion) => {
      if (opcion.id.toString() === e.target.id) {
        opcion.option = e.target.value
      }
    })
    setOpciones(misOpciones)
    setSeleccionSimple({ ...respuestaSeleccionData, opciones: misOpciones })

    if (misOpciones[misOpciones.length - 1].option === '') {
      setCanAdd(false)
    } else {
      setCanAdd(true)
    }
  }

  const handleChangeInputPregunta = (e) => {
    setPregunta(e.target.value)
    setSeleccionSimple({ ...respuestaSeleccionData, pregunta: e.target.value })
  }

  const handleChangeInputValor = (e) => {
    let misOpciones = []
    opciones.forEach((opcion) => {
      misOpciones.push(opcion)
    })
    misOpciones.forEach((opcion) => {
      if (opcion.id.toString() === e.target.name) {
        opcion.value = e.target.value
      }
      setOpciones(misOpciones)
      setSeleccionSimple({ ...respuestaSeleccionData, opciones: misOpciones })
    })
  }

  const handleDeleteOption = (id) => {
    let misOpciones = []
    misOpciones = opciones.filter((opcion) => opcion.id !== id)
    setSeleccionSimple({ ...respuestaSeleccionData, opciones: misOpciones })
    setOpciones(misOpciones)
    setNumeroOpcion(numeroOpcion - 1)
  }

  return (
    <>
      <textarea
        rows={3}
        cols={60}
        value={pregunta}
        onChange={handleChangeInputPregunta}
        id='caja-pregunta'
        className='input-pregunta'
        placeholder='Escriba una pregunta'
      />
      <div className='contenedor-respuestas'>
        {opciones.map((opcion, i) => (
          <Grid className='caja-opcion' container key={i}>
            <Grid container xs={6} md={6}>
              <Grid xs={1} md={1}>
                <input
                  className='botones-radio'
                  type='radio'
                  name='first'
                  value='firstValue'
                />
              </Grid>
              <Grid xs={10} md={10}>
                <input
                  id={opcion.id}
                  value={opcion.option}
                  onChange={handleChangeInputOption}
                  className='input-pregunta'
                  placeholder='Agregar opción...'
                />
              </Grid>
              <Grid>
                <Close
                  onClick={() => {
                    handleDeleteOption(opcion.id)
                  }}
                  fontSize='small'
                  className='button-delete-option'
                />
              </Grid>
            </Grid>
            <Grid container xs={6} md={6}>
              <Grid xs={3} md={3}>
                <label className='tag-valor'>
                  <nobr>Peso porcentual:</nobr>
                </label>
              </Grid>
              <Grid xs={9} md={9}>
                <input
                  style={{ float: 'right', width: '80%' }}
                  type='number'
                  min='0'
                  pattern='[0-9]'
                  step='1'
                  name={opcion.id}
                  value={opcion.value}
                  onChange={handleChangeInputValor}
                  placeholder='Valor de respuesta'
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
        <Grid className='caja-opcion' container>
          <Grid container xs={6} md={6}>
            <Grid xs={1} md={1}>
              <input
                className='botones-radio'
                type='radio'
                name='first'
                value='firstValue'
              />
            </Grid>
            <Grid xs={11} md={11}>
              <input
                id={misId}
                onFocus={addOption}
                className='input-pregunta'
                placeholder='Agregar opción...'
              />
            </Grid>
          </Grid>
          <Grid container xs={6} md={6}>
            <Grid xs={3} md={3}>
              <label className='tag-valor'>
                <nobr>Peso porcentual:</nobr>
              </label>
            </Grid>
            <Grid xs={9} md={9}>
              <input
                style={{ float: 'right', width: '80%' }}
                placeholder='Valor de respuesta'
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default SeleccionPregunta
