/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ArrowDropDown } from '@material-ui/icons/'
import CircularProgress from '@material-ui/core/CircularProgress'

import './style.css'

function MultiSelect({
  options,
  selecteds,
  cantidad,
  loading,
  onOpen,
  onSet,
  setCantidad,
  condicionOnline,
  fromUser,
  fromReport,
  noSelectAll,
}) {
  const [opciones, setOpciones] = useState(options)
  const [seleccionados, setSeleccionados] = useState([])
  const [openOptions, setOpenOptions] = useState(false)

  useEffect(() => {
    setSeleccionados([])
    if (selecteds.length > 0) {
      setSeleccionados(selecteds.split(','))
    } else {
      if (cantidad > 0) {
        options.forEach(() => {
          selectOption(true, 0)
        })
      }
    }
    setOpciones(options)
  }, [loading, options])

  function setValues() {
    let stringValues = ''

    for (let i = 0; i < seleccionados.length; i++) {
      if (seleccionados[i] != '0') {
        stringValues = stringValues + seleccionados[i]
        if (i !== seleccionados.length - 1) {
          stringValues = stringValues + ','
        }
      }
    }
    onSet(stringValues)
    setCantidad(
      seleccionados.length === options.length
        ? options.length - 1
        : seleccionados.length
    )
  }

  function selectOption(e, value) {
    if (value == 0 && !noSelectAll) {
      let mySeleccionados = []
      if (e) {
        opciones.forEach((option) => {
          if (option.label === 'ONLINE' && condicionOnline) {
          } else {
            mySeleccionados.push(option.value)
          }
        })
      }
      setSeleccionados(mySeleccionados)
    } else {
      let mySeleccionados = []
      if (e) {
        seleccionados.forEach((selected) => {
          mySeleccionados.push(selected)
        })
        mySeleccionados.push(value)
        if (mySeleccionados.length + 1 === opciones.length) {
          mySeleccionados.unshift('0')
        }
      } else {
        seleccionados.forEach((selected) => {
          if (selected != value && selected != '0') {
            mySeleccionados.push(selected)
          }
        })
        if (mySeleccionados.length + 1 === opciones.length) {
          mySeleccionados.unshift('0')
        }
      }
      setSeleccionados(mySeleccionados)
    }
  }

  function searchOption(e) {
    let text = e.target.value
    let myOptions = []
    options.forEach((option) => {
      if (
        option.label !== 'Todos' &&
        option.label
          .toString()
          .toLowerCase()
          .includes(text.toString().toLowerCase())
      ) {
        myOptions.push(option)
      } else if (option.label === 'Todos' && text === '') {
        myOptions.push(option)
      }
    })
    setOpciones(myOptions)
  }

  return (
    <div className='contenedor-multiselect'>
      <input
        value={
          'Total:' +
          String(
            seleccionados.includes('0')
              ? seleccionados.length - 1
              : seleccionados.length
          )
        }
        onFocus={() => {
          setOpenOptions(true)
          if (onOpen) {
            onOpen()
          }
        }}
        type='text'
        name=''
        id=''
        className='principal-input-multiselect'
        placeholder='Seleccione...'
      />
      <span className='arrow-down-select'>
        <ArrowDropDown />
      </span>
      <div
        style={!openOptions ? { display: 'none' } : {}}
        className={
          fromUser
            ? 'contenedor-aceptar-opciones2-fromuser'
            : fromReport
            ? 'contenedor-aceptar-opciones3'
            : 'contenedor-aceptar-opciones'
        }
      >
        {loading ? (
          <div style={{ textAlign: 'center', paddingTop: 30 }}>
            <CircularProgress size={50} />
          </div>
        ) : (
          <>
            <input type='text' placeholder='Busca...' onChange={searchOption} />
            <div className='div-contenedor-opciones'>
              {opciones.map((option) => (
                <p
                  onClick={() => {
                    if (seleccionados.includes(option.value)) {
                      selectOption(false, option.value)
                    } else {
                      selectOption(true, option.value)
                    }
                  }}
                >
                  {option.label}
                  <input
                    disabled={
                      condicionOnline &&
                      ((option.label === 'ONLINE' &&
                        !seleccionados.includes(option.value) &&
                        seleccionados.length > 0) ||
                        (option.label !== 'ONLINE' &&
                          seleccionados.includes('53')))
                    }
                    checked={seleccionados.includes(option.value)}
                    onChange={(e) => {
                      selectOption(e.target.checked, option.value)
                    }}
                    type='checkbox'
                  />
                </p>
              ))}
            </div>
            <button
              className='button-aceptar-opciones'
              onClick={() => {
                setValues()
                setOpenOptions(false)
              }}
            >
              Aceptar
            </button>
          </>
        )}
      </div>
    </div>
  )
}

MultiSelect.propTypes = {
  cantidad: PropTypes.number,
  loading: PropTypes.bool,
  onOpen: PropTypes.func,
  onSet: PropTypes.func,
  setCantidad: PropTypes.func,
  onChange: PropTypes.func,
  noSelectAll: PropTypes.bool,
  fromUser: PropTypes.bool,
}

export default MultiSelect
