import React from "react";

const MisEvaluacionesIcon = () => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 172 175" enable-background="new 0 0 172 175" space="preserve">
            <image id="image0" width="172" height="175" x="0" y="0"
            href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKwAAACvCAQAAADKrP39AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZ
cwAACxIAAAsSAdLdfvwAAAAHdElNRQflAgMWJjVv7kTaAAAJB0lEQVR42u2df4gdVxXHP+l72SRG
2xGFJltTBrSkpSWZgrQplfJKi7ZV5CEsGmqbR/APN6KIUDCiMfQv/xDRouYPdUmCtYGIhCXWrX89
SsC0//gaW9hIoA83LgaW5G1b7f54s+Mfm+y+3exmd2buOee+ffOdP5Z9mXvPnM87OXvnzp1zNyUU
ktBt1hewUVWAFVIBVkgFWCEVYIVUgBVSAVZIBVghFWCFVIAVUtn6AlIpoEpIkzO0rC9lLW3qkrmC
kP0MsotZNjPLZsY4xis0rS9rdfkPNuI7VLmd0k3/EvMeZ3iJhvUlriR/wQZUOMjnKa2RrtrE/JUh
6n6lBx/BhlQY5CHiFaJ0NcWUeJNj1H1JD36BjfgKNXbl6mOM4/zJPj34ArbKAE+vmEmzKOY9/sJp
ztg5ZA02oMrXqKyZSbOoTUydUzaDMzuwIfsZ4MFUmTSLYkr8ndPagzMLsBE1BuhXtzvOaY5rZV9d
sFUO8hjb6NM0ukQzfMjrDMlnXx2wAVUGicAQaadmgAbHJLOvNNiAQQ6wm7aHsxJtylzkBMck8MqC
DbjAnZ5E6Wqa4Qp73KOVnTas0e85Vuijn5r7bmXBBsSi/btRTOC+U9lUEHKBjwiPU/Mq5n/scT/G
lY3YJns4yTVv4zbmGiclsGoNt1xMrriW8GSN5g1CyDMcSDkd6Frz04sneFX8BjfRPoKkmowkU8ls
oqvZZCoZSapJ0HEtlWQ4qcn4qQ920amXk6tJWwFpO7mavJxUlliPkqHkajKbJMlRGf/s7ofq1JHP
vqtl0j/yaWH/zCK28wiT15I5gVj90aoWmwvnCEWsHws2nuEJNgn0+yKRlUs+gK3yktg44Q0rtPZg
Q04JDr/6GJa4YV1b9mDPskW0/35+ZuGWNdjD3CdsocRzVPQdswUb8GOFKyhzUt81W7BHhdPADe3k
kLZrlmADBpUslXlR2zlLsEcVny7cQVXXOUuwNUVbZQ7rOmcHtsp2VXsP6Y5n7cAeVJ8AqmoaswP7
mLpFrT+VgB3YkDvUbe7VNGYFNjKwWSLUM2YFtmJiNbz+c5hPLXx2WOZLtgL7WQOb5etfZ5Uvdsyn
9XFeYrxgBfaTJlZDAI4s87okMV6wArvVxOoOAB5c9mlZIjFZTxvqah7s5E2fT7k31Vtg53V+2e9t
Rtwb6UWw31/2+2WJhfO9BfYSAA2+xezCZ82bcq4TWYFtmVi9fP3nrxlf+OyEzLVYgb1kYrWpZ8oK
7DkDmzOab9hagW0wo26zj7qeMSuwdYOXPsbzd7F+2Y0KLqpbrGsaswN7Qtlem99omrMD+4qyvf/2
SsQ2eUfV3nFd9yzvvH5IW9Haz3WdswR7ZoV5Jim9tuzmYHE+q5muo/XKdq7gG8wpWfrmst9vTMSM
SVUusK4J8zb3K1j5Bd+96bOQGi2OS81aWIMNeVfcxiSh/qSP9bRhkx8Ip4OYam9VMVrUOR4V6zvm
lyukAQX5ADbggtgrdHUet3HKB7AQMiqytntMoijJ+mSdY+fVZB/Tzns1xOoLWGhwL2MO+4t5yxKr
P2Dnq3G85agWR8yrRLb1ZP0BCy0ifuqgn2kO8GVrZ/z449WpiGHuyvyFz/E3vu5DcV6fInZeDe7m
20xmuG1oM8YTfM4HrHhSr2Cl41AykaL+xmwymlTNr7nj8C8VdCriBZ7mY7d4DaRNmXF+x5Ancbog
v8HOK6TCl3iAHUveW7jCBOcYoeEb0nl1A9hFRUvWXtetL+dW8q/4aKcCIp4iIrwerVMdM/9b2QpM
c5lLNBihUeyDsLYCKgxQoZ+ZdS7siCkxyev+bDThG9j52sjZq8nNlzr/LX8wx2s9LOk4omQ4mXZS
4qydJMnIshJmyoc1zBtHLRl1XjUuTiaSQ70MtpZMiFU6jJOWDVzrHFvhJDuFxyZzvE9Ne3sUS7Ah
v2efUsnTOf5BtRdWdMNhLvKoWiXZ29jLu/xEzz2biA05y30GX2rMGI/rxK1FxD7LKPebWC4R8k+d
UlH6ETvE88Z15uf4s/wTBl2wAXUe8KB8f8y49KNGzf+QIRfY6wFWKLGLpmyVD72IjTivVHBvvZrh
4e4v1x/xhoe7zgii1UkFfmKFPrmKyBpgfcUKgmjlwfqMFcTQyu9AJ7dE051m2O36fkwa7L+6ACsI
rEyUTQXDBpukZtMuzrrtUBLsoSWFw3zXPrev2Mmlgog32SyOw6VinnS3VkEKbMA7XZMGFjXNDleZ
VioV/KoLscIWd5lWBmyFr6rBcKtHeNZNRxKpIKBpUHbXlRylA4mIPdrFWGELp1x04z5iNd6OlZWT
0YF7sDrvc8tqjLvzduE6FVTEdzvS0F35Hzi6jthumRtYS5N5y0u7jdgaO+1YONX2vDHrNmIn+IQl
DafKGbMuI7ba1cOs5coZsy4jdpTd1jScKlfMuovYiHusSTjW9jxl/N1F7AhfsCbhXBe5N2tTV2AD
rllTEFCbe7I+C3OVClS3dFJTmSNZm7qK2H935fzr2nqf27M1dBOxIXdaExDStqzbpbgBe6SLHhqm
U5nvZWvoJhVs1EQAMJ1twyEXEbtxEwFAKdsCJBdg92/YRABQ5oUszVyAHbD2XViVLI1c5FjPXiAX
0MfTP17MH7EVgz04dNXOErP5wVa9Xv3qQuUsyS4/2Ees/VbQw+mb5M+xU569CyOjTWkb5I3YcEMP
tW7ow/RZNj/YXtC29H7mBfuU5wWmXKmStkFesJG1x0pKvaV2XrCfsfZYSam31M47KmhtqEfet1LK
cUHeiO0VrKR9FJ4PbKi6cZSlPkj71yQv2A+sPVbSR9M2yJsKAmuP1RSmO92/Gt2+Kkx3ej6wFWtv
FbUj3elFxK5XKR8pFmCFVIAVUgFWSAVYIRVghVSAFVIBdr1qpTs9H9i6tbeKaqU7PW/E9srsVuo9
wvOC7ZXZLWWwjZ6Z3WqlbZD30czGXxB3QykXxuVNBZPW/qqple70vGD/Y+2vkq6kbZAX7NvWHisp
dQAVYNen82kb5AVb79gTbiNLHWwj28s6Xaap9JW886+PneiBhZxB+vWx+dcKPtkDNwmt9E2s9/Pa
sCqmDYVUgBXS/wHLxKNCl8pEiQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMS0wMi0wM1QyMjozODo1
MyswMzowMCZyXDUAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjEtMDItMDNUMjI6Mzg6NTMrMDM6MDBX
L+SJAAAAAElFTkSuQmCC" />
        </svg>
    )
}

export default MisEvaluacionesIcon;