import styled, { css } from 'styled-components';

export const StepperContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  width: 70%;
  margin: 0 auto;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 20px;
  border-radius: 15px;
  box-shadow: inset -2px 19px 23px rgba(0, 0, 0, 0.2);
  margin: 0 20px 5px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  position: relative;

  @media screen and (max-width: 480px) {
    height: 10px;
    margin: 0 20px 10px;
  }
`;

export const Bar = styled.div`
  width: ${({ percent }) => percent};
  height: inherit;
  background-color: red;
  border-radius: 15px;
  justify-content: flex-end;
  display: flex;
  align-items: flex-end;
  position: absolute;
  background-image: linear-gradient(
    45deg,
    hsla(0, 0%, 100%, 0.15) 25%,
    transparent 0,
    transparent 50%,
    hsla(0, 0%, 100%, 0.15) 0,
    hsla(0, 0%, 100%, 0.15) 75%,
    transparent 0,
    transparent
  );
  background-size: 2rem 2rem;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Button = styled.button`
  color: white;
  background-color: red;
  width: 53px;
  border-radius: 20px;
  padding: 6px 11px;
  border: none;
  cursor: pointer;
`;

export const ImageContainer = styled.div`
  position: relative;
  ${({ show }) =>
    !show &&
    css`
      display: none;
    `}
`;

export const Image = styled.img`
  width: 60px;
  position: absolute;
  top: -100px;
  left: -30px;
`;

export const PercentText = styled.span`
  color: white;
  position: absolute;
  top: -80px;
  left: ${({ now }) => (now >= 100 ? '-19px' : '-15px')};
`;

export const CheckpointContainer = styled.div`
  height: 20px;
  width: ${({ percent }) => percent};
  position: relative;
`;

export const Point = styled.div`
  height: 36px;
  width: 36px;
  border-radius: 50%;
  position: absolute;
  right: -18px;
  top: -8px;
  background-color: ${({ isActive }) => (isActive ? '#f00' : '#c5c5c5')};
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  @media screen and (max-width: 480px) {
    height: 26px;
    width: 26px;
    right: -13px;
  }
`;
