import React from 'react'
import PropTypes from 'prop-types'

import './index.css'

function PageTitle(props) {
  return (
    <p className='view-title' style={props.width ? { width: props.width } : {}}>
      {props.title}
    </p>
  )
}

PageTitle.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
}

export default PageTitle
