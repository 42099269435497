import React, { useRef, useState } from 'react';
import { Radio } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import { TD, FirstOptionsText } from './styles';

const RedRadio = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    },
    padding: 0,
  },
  checked: {},
})((props) => <Radio color='default' {...props} />);

export default function TableRow({
  id,
  asignatura,
  docente,
  handleClick,
  answerData,
  reactivos,
  questionIndex,
  tipoEvaluacion
}){
  const respuestaFiltrada = answerData.respuestas.filter(
    (respuesta) =>
      respuesta.id_asignacion === id &&
      reactivos[questionIndex].id === respuesta.id_reactivo,
  );

  return (
    <tr>
    {
      tipoEvaluacion==='Opinion_Estudiantil' ?
      <>
        <TD
          odd={id % 2 !== 0}
          style={{ borderRight: '2px solid #b1b1b1', width: '30px' }}>
          <FirstOptionsText>{asignatura}</FirstOptionsText>
        </TD>
        <TD style={{ paddingLeft: '11px' }} odd={id % 2 !== 0}>
          <FirstOptionsText>{docente}</FirstOptionsText>
        </TD>
      </>:null
    }
      {reactivos[questionIndex].opciones.map((option, i) => {
        const handleChange = () => {
          handleClick(option.nombre, option.valor);
        };

        return (
          <TD
            onClick={handleChange}
            key={option.id}
            odd={id % 2 !== 0}
            dark={i % 2 === 0}>
            <RedRadio
              checked={
                respuestaFiltrada.length > 0 &&
                (respuestaFiltrada[0].valor_respuesta === option.valor && respuestaFiltrada[0].respuesta === option.nombre)
              }
              onChange={handleChange}
              size='small'
            />
          </TD>
        );
      })}
    </tr>
  );
};
