import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import './style.css'

function Table({ loading, calendarios, estructuraAgrupadores }) {
  return (
    <>
      {loading ? (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <CircularProgress size={100} style={{ marginTop: '130px' }} />
        </div>
      ) : (
        <table
          className='table-participacion-global'
          style={{ borderCollapse: 'collapse' }}
        >
          <thead>
            <tr className='first-header-row'>
              <th
                className='extra-header'
                style={{ backgroundColor: 'white' }}
              ></th>
              {Object.keys(calendarios).map((calendario, i) => (
                <th key={i} className='first-header-item' colSpan={2}>
                  <nobr>{calendario}</nobr>
                </th>
              ))}
              <th className='first-header-item' colSpan={2}>
                <nobr>TOTAL PERIODOS</nobr>
              </th>
              <th className='first-header-item'>
                <nobr>TOTAL</nobr>
              </th>
            </tr>

            <tr>
              <th
                className='second-header-item'
                style={{ backgroundColor: '#d2d2d2' }}
              ></th>
              {Object.keys(calendarios).map(() => (
                <>
                  <th
                    className='second-header-item'
                    style={{
                      backgroundColor: '#fff',
                      borderRight: '3px solid #d2d2d2',
                    }}
                  >
                    DOCENTE
                  </th>
                  <th
                    className='second-header-item'
                    style={{
                      backgroundColor: '#c7c7c7',
                      borderRight: '3px solid #d2d2d2',
                    }}
                  >
                    COORDINADOR
                  </th>
                </>
              ))}
              <th
                className='second-header-item'
                style={{
                  backgroundColor: '#fff',
                  borderRight: '3px solid #d2d2d2',
                }}
              >
                DOCENTE
              </th>
              <th
                className='second-header-item'
                style={{
                  backgroundColor: '#c7c7c7',
                }}
              >
                COORDINADOR
              </th>
              <th className='second-header-item'></th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(estructuraAgrupadores)
              .sort()
              .map((agrupadorKey) => {
                return (
                  <>
                    <tr className='sub-header'>
                      <th
                        className='campus-cell'
                        style={{ backgroundColor: 'black' }}
                      >
                        {agrupadorKey === 'GLOBAL' ? 'TOTAL UVM' : agrupadorKey}
                      </th>
                      {estructuraAgrupadores[agrupadorKey].promedios.map(
                        (promedio) => (
                          <th>{promedio === null ? '-' : promedio + '%'}</th>
                        )
                      )}
                    </tr>
                    {agrupadorKey === 'GLOBAL' && (
                      <tr>
                        <td
                          className='campus-cell'
                          style={{
                            backgroundColor: '#ebebeb',
                            fontWeight: 'bold',
                          }}
                        ></td>
                        {Object.keys(calendarios).map(() => (
                          <>
                            <td
                              style={{
                                backgroundColor: '#ebebeb',
                                fontWeight: 'bold',
                                padding: '5px',
                              }}
                            ></td>
                            <td
                              style={{
                                backgroundColor: '#ebebeb',
                                fontWeight: 'bold',
                                padding: '5px',
                              }}
                            ></td>
                          </>
                        ))}
                        <td
                          style={{
                            backgroundColor: '#ebebeb',
                            fontWeight: 'bold',
                            padding: '5px',
                          }}
                        ></td>
                        <td
                          style={{
                            backgroundColor: '#ebebeb',
                            fontWeight: 'bold',
                            padding: '5px',
                          }}
                        ></td>
                        <td
                          style={{
                            backgroundColor: '#ebebeb',
                            fontWeight: 'bold',
                            padding: '5px',
                          }}
                        >
                          <span style={{ opacity: 0 }}>.</span>
                        </td>
                      </tr>
                    )}
                    {Object.keys(estructuraAgrupadores[agrupadorKey])
                      .sort()
                      .map((campusKey, i) => {
                        if (campusKey !== 'promedios') {
                          return (
                            <>
                              <tr key={i}>
                                <td
                                  className='campus-cell'
                                  style={{
                                    backgroundColor: '#ebebeb',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {campusKey}
                                </td>
                                {estructuraAgrupadores[agrupadorKey][
                                  campusKey
                                ].map((valor, i) => (
                                  <td
                                    style={
                                      i ===
                                      estructuraAgrupadores[agrupadorKey][
                                        campusKey
                                      ].length -
                                        1
                                        ? {
                                            backgroundColor: '#333',
                                            color: '#fff',
                                            fontWeight: 'bold',
                                          }
                                        : {
                                            backgroundColor:
                                              i % 2 === 0 ? '#fff' : '#dbdbdb',
                                            fontWeight: 'bold',
                                            padding: '5px',
                                            borderRight: '3px solid #ebebeb',
                                          }
                                    }
                                  >
                                    {valor === null ? '-' : valor + '%'}
                                  </td>
                                ))}
                              </tr>
                            </>
                          )
                        }
                        return null
                      })}
                  </>
                )
              })}
          </tbody>
        </table>
      )}
    </>
  )
}

export default Table
