/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Route, MemoryRouter as Router } from 'react-router-dom'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { getRequest, postLogin, url_script_ayuda } from './utils/requester'
import {
  setUser,
  setIsLoged,
  setCampus,
  setTokenKey,
  setAllPermises,
} from './actions'
import './index.css'
import LoginPage from './Vistas/LoginPage'
import MisEvaluaciones from './Vistas/MisEvaluaciones'
import EvaluacionesDocentes from './Vistas/EvaluacionesDocentes'
import ParticipacionGlobal from './Vistas/ReportesAvances-ParticipacionGlobal'
import ParticipacionCoordinador from './Vistas/ReportesAvances-ParticipacionCoordinador'
import ParticipacionDocente from './Vistas/ReportesAvances-ParticipacionDocente'
import IntegralPeriodoDocente from './Vistas/ReportesAvances-IntegralPeriodoDocente'
import IntegralDimension from './Vistas/ReportesAvances-IntegralDimension'
import ReporteCRNDocente from './Vistas/ReportesAvances-ReporteCRNDocente'
import CRNDocenteDimensiones from './Vistas/ReportesAvances-CRNDocenteDimensiones'
import DetalleInstrumento from './Vistas/DetalleInstrumento'
import AsignacionesFigpo from './Vistas/ReportesAvances-AsignacionesFigpo'
import ParticipacionAcademicos from './Vistas/Estructuras-ParticipacionAcademicos'
import AvanceEstructuras from './Vistas/Estructuras-AvanceEstructuras'
import CreadoEstructuras from './Vistas/Estrucutras-CreadoEstructuras'
import CargaProfesor from './Vistas/CargaManual-Profesor'
import CargaCRN from './Vistas/CargaManual-CRN'
import Etiquetas from './Vistas/Objetos-Etiquetas'
import Reactivos from './Vistas/Objetos-Reactivos'
import Instrumentos from './Vistas/Objetos-Instrumentos'
import CategoriaEvaluacion from './Vistas/Dimensiones-CategoriaEvaluacion'
import Dimensiones from './Vistas/Dimensiones-Dimensiones'
import EvaluacionIntegral from './Vistas/Dimensiones-EvaluacionIntegral'
import CreadoCicloPeriodo from './Vistas/Calendario-CreadoCicloPeriodo'
import CreadoPlantillaEvaluaciones from './Vistas/Calendario-CreadoPlantillaEvaluaciones'
import CreadoEvaluacion from './Vistas/Calendario-CreadoEvaluacion'
import Campus from './Vistas/Campus'
import Usuarios from './Vistas/Usuarios'
import Header from './Components/Header'
import { ability, setPermisos } from './utils/ability'
import Menu from './Components/Menu'

toast.configure()

function App({
  isLoged,
  urlScriptAyuda,
  setIsLoged,
  setCampus,
  setUser,
  setTokenKey,
  setAllPermises,
}) {
  const [loadingLogin, setLoadingLogin] = useState(false)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = url_script_ayuda
    script.id = 'ze-snippet'
    script.async = true
    document.body.appendChild(script)
    setTimeout(() => {
      window.zE('webWidget', 'setLocale', 'es')
    }, [500])
    ;(async function checkLogin() {
      let token = localStorage.getItem('token_sieda')
      if (token) {
        clearUrl()
        setLoadingLogin(true)
        try {
          let response = await getRequest('/login/detalle', false, false)
          if (response.s === 'OK') {
            setAllPermises(response.d.permisos)
            ability.update(setPermisos(response.d.permisos))
            setIsLoged(true)
            setCampus(response.d.campus)
            setUser({
              username: response.d.nombre,
              rol: response.d.rol,
            })
            setTokenKey(response.d.token)
          } else {
            setTimeout(() => {
              window.location.reload()
            }, 2000)

            window.localStorage.removeItem('token_sieda')
            setLoadingLogin(false)
          }
        } catch (error) {
          setLoadingLogin(false)
          window.location.reload()
        }
      } else {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const code = urlParams.get('code')
        const mode = urlParams.get('mode')
        const mail = urlParams.get('mail')
        let params_login = ''
        if (code !== null) {
          if (mode !== null && mail !== null) {
            params_login = `?plt=sieda&code=${code}&rol=administrador&mode=${mode}&mail=${mail}`
          } else {
            params_login = `?plt=sieda&code=${code}&rol=administrador`
          }
          setLoadingLogin(true)
          try {
            const data = await getRequest(params_login, false, true)

            if (data.s === 'OK') {
              window.localStorage.setItem(
                'token_sieda',
                data['d']['data']['token']
              )
              window.location.href = data['d']['data']['reload_url']
            } else {
              window.localStorage.removeItem('token_sieda')
              clearUrl()
              setLoadingLogin(false)
            }
          } catch (error) {
            console.log(error)
            clearUrl()
            setTimeout(() => {
              window.location.reload()
            }, 2000)

            window.localStorage.removeItem('token_sieda')
            setLoadingLogin(false)
          }
        }
      }
    })()
  }, [])
  async function doLogin() {
    setLoadingLogin(true)
    try {
      const data = await getRequest(
        '?plt=sieda&token=&rol=administrador',
        false,
        true
      )
      if (data.s === 'OK') {
        if (data.d['data']['token']) {
          window.localStorage.setItem('token_sieda', data['d']['data']['token'])
          window.location.reload()
        } else {
          window.location.href = data['d']['data']['url_login']
        }
      } else {
        setLoadingLogin(false)
        toast.error(data.m)
      }
    } catch (error) {
      console.error(error)
      toast.error(error)
      setLoadingLogin(false)
    }
  }

  function clearUrl() {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const code = urlParams.get('code')
    if (code !== null) {
      let myUrl = window.location.href
      let inicioParametros = myUrl.indexOf('?')
      let newUrl = myUrl.substr(0, inicioParametros)
      window.history.pushState('obj', 'newtitle', newUrl)
    }
  }

  return (
    <>
      {isLoged ? (
        <>
          <Router>
            <Header />
            <Menu />
            <Route path='/mis-evaluaciones'>
              <MisEvaluaciones />
            </Route>
            <Route path='/evaluaciones-docentes'>
              <EvaluacionesDocentes />
            </Route>
            <Route path='/reportes/participacion-global'>
              <ParticipacionGlobal />
            </Route>
            <Route path='/reportes/participacion-coordinador'>
              <ParticipacionCoordinador />
            </Route>
            <Route path='/reportes/participacion-docente'>
              <ParticipacionDocente />
            </Route>
            <Route path='/reportes/integral-periodo-docente'>
              <IntegralPeriodoDocente />
            </Route>
            <Route path='/reportes/integral-dimension'>
              <IntegralDimension />
            </Route>
            <Route path='/reportes/crn-docente'>
              <ReporteCRNDocente />
            </Route>
            <Route path='/reportes/crn-docnete-domenciones'>
              <CRNDocenteDimensiones />
            </Route>
            <Route path='/reportes/detalle-instrumento'>
              <DetalleInstrumento />
            </Route>
            <Route path='/reportes/asignaciones-figpo'>
              <AsignacionesFigpo />
            </Route>
            <Route path='/estructura/participacion-academicos'>
              <ParticipacionAcademicos />
            </Route>
            <Route path='/estructura/reporte-avance'>
              <AvanceEstructuras />
            </Route>
            <Route path='/estructura/creado-de-estructura'>
              <CreadoEstructuras />
            </Route>
            <Route path='/usuarios'>
              <Usuarios />
            </Route>
            <Route path='/carga-manual/profesor'>
              <CargaProfesor />
            </Route>
            <Route path='/carga-manual/crn'>
              <CargaCRN />
            </Route>
            <Route path='/objetos/etiquetas'>
              <Etiquetas />
            </Route>
            <Route path='/objetos/reactivos'>
              <Reactivos />
            </Route>
            <Route path='/objetos/Instrumentos'>
              <Instrumentos />
            </Route>
            <Route path='/dimensiones/categoria-evaluacion'>
              <CategoriaEvaluacion />
            </Route>
            <Route path='/dimensiones/dimensiones'>
              <Dimensiones />
            </Route>
            <Route path='/dimensiones/evaluacion_integral'>
              <EvaluacionIntegral />
            </Route>
            <Route path='/campus'>
              <Campus />
            </Route>
            <Route path='/calendario/creado-cicloPeriodo'>
              <CreadoCicloPeriodo />
            </Route>
            <Route path='/calendario/creado-plantillaEvaluaciones'>
              <CreadoPlantillaEvaluaciones />
            </Route>
            <Route path='/calendario/creado-evaluacion'>
              <CreadoEvaluacion />
            </Route>
          </Router>
        </>
      ) : (
        <LoginPage loading={loadingLogin} doLogin={doLogin} />
      )}
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    isLoged: state.isLoged,
    urlScriptAyuda: state.urlScriptAyuda,
  }
}

const mapDispatchToProps = {
  setIsLoged,
  setCampus,
  setUser,
  setTokenKey,
  setAllPermises,
}

App.propTypes = {
  isLoged: PropTypes.bool,
  urlScriptAyuda: PropTypes.string,
  setIsLoged: PropTypes.func,
  setCampus: PropTypes.func,
  setUser: PropTypes.func,
  setTokenKey: PropTypes.func,
  setAllPermises: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
