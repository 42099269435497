import React from "react";

const MenuIcon = props => (
  <svg viewBox="0 0 35.92 26.73" {...props}>
    <defs>
      <style>{".MenuIcon_svg__cls-1{fill:#fff}"}</style>
    </defs>
    <g id="MenuIcon_svg__Layer_2" data-name="Layer 2">
      <g id="MenuIcon_svg__Capa_1" data-name="Capa 1">
        <rect
          className="MenuIcon_svg__cls-1"
          width={35.69}
          height={6.34}
          rx={2.01}
          ry={2.01}
        />
        <rect
          className="MenuIcon_svg__cls-1"
          y={10.2}
          width={35.69}
          height={6.34}
          rx={2.01}
          ry={2.01}
        />
        <rect
          className="MenuIcon_svg__cls-1"
          x={0.23}
          y={20.39}
          width={35.69}
          height={6.34}
          rx={2.01}
          ry={2.01}
        />
      </g>
    </g>
  </svg>
);

export default MenuIcon;
