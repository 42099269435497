/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { toast } from 'react-toastify'

import Contenedor from '../../Components/Contenedor/Contenedor'
import NewButton from '../../Components/Botones/NewButton'
import EditButton from '../../Components/Botones/EditButton'
import DataTable from '../../Components/DataTables'
import NewModal from '../../Components/Modal/NewModal'
import { getRequest, postRequest } from '../../utils/requester'
/*import Can from '../../Utils/Funciones/can'*/

function CategoriaEvaluacion() {
  const [dataInicial, setDataInicial] = useState([])
  const [dataFiltradas, setDataFiltradas] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [loadingModalEditar, setLoadingModalEditar] = useState(false)
  const [modalEditarIsOpen, setModalEditarIsOpen] = useState(false)
  const [modalNuevaIsOpen, setModalNuevaIsOpen] = useState(false)
  const [nueva, setNueva] = useState({
    nombre: '',
  })
  const [editar, setEditar] = useState({
    id: '',
    nombre: '',
    descripcion: '',
  })
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteModalLoading, setDeleteModalLoading] = useState(false)

  const openEditModal = async ({ folio, nombre, descripcion }) => {
    const reg = { id: folio, nombre, descripcion }
    setEditar(reg)
    setModalEditarIsOpen(true)
  }

  const handleEditar = async (data) => {
    let body = {
      id: data.id,
      nombre: data.nombre,
      status: 1,
    }
    if (validar(body)) {
      setLoadingModalEditar(true)
      try {
        const editarResponse = await postRequest(
          '/evaluacion/categoria/editar',
          body
        )
        if (editarResponse.s === 'OK') {
          toast.success(editarResponse.m)
          setModalEditarIsOpen(false)
          setLoadingModalEditar(false)
          reloadList()
        }
        setLoadingModalEditar(false)
      } catch (error) {
        toast.error('Error editando')
        console.error(error)
        setLoadingModalEditar(false)
      }
    }
  }

  const handleNueva = async (data) => {
    let body = {
      nombre: data.nombre,
    }
    if (validar(body)) {
      try {
        setLoadingModal(true)
        const nuevaResponse = await postRequest(
          '/evaluacion/categoria/nuevo',
          body
        )
        if (nuevaResponse.s === 'OK') {
          setLoadingModal(false)
          setModalNuevaIsOpen(false)
          reloadList()
        }
        setLoadingModal(false)
      } catch (error) {
        toast.error('Error creando')
        setLoadingModal(false)
      }
    }
  }

  const validar = (body) => {
    let valido = true
    if (body.nombre === '') {
      document.getElementById('tag-nombre').classList.add('error')
      valido = false
    } else {
      document.getElementById('tag-nombre').classList.remove('error')
    }
    return valido
  }

  const handleDelete = async () => {
    let body = {
      id: editar.id,
      nombre: editar.nombre,
      status: 0,
    }
    setDeleteModalLoading(true)
    try {
      const eliminarResponse = await postRequest(
        '/evaluacion/categoria/editar',
        body
      )
      if (eliminarResponse.s === 'OK') {
        setModalEditarIsOpen(false)
        setDeleteModalLoading(false)
        reloadList()
        setDeleteModal(false)
      }
      setDeleteModal(false)
      setDeleteModalLoading(false)
    } catch (error) {
      toast.error('Error Eliminando')
      console.error(error)
      setDeleteModal(false)
      setDeleteModalLoading(false)
    }
  }

  const testHeader = ['Folio', 'Nombre', 'Acciones']

  ////////// Filtrar resultados segun el input de busqueda //////////
  const search = (e) => {
    let text = e.target.value.toLowerCase()

    let result = dataInicial.filter((row) => {
      if (row[0].toString().toLowerCase().includes(text)) return true
      else if (row[1].toLowerCase().includes(text)) return true
      return false
    })
    setDataFiltradas(result)
  }

  const reloadList = async () => {
    setLoading(true)
    try {
      const response = await getRequest('/evaluacion/categoria')
      if (response.s === 'OK') {
        let data = []
        response.d.forEach((reg) => {
          data.push([
            reg.folio,
            reg.nombre,
            <EditButton onClick={() => openEditModal(reg)}>Editar</EditButton>,
          ])
        })
        setDataInicial(data)
        setDataFiltradas(data)
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    reloadList()
  }, [])

  return (
    <Contenedor title='Categoria de Evaluación'>
      <div className='opciones-bar'>
        <div className='sub-filtro'>
          <NewButton
            onClick={() => {
              setModalNuevaIsOpen(true)
              setNueva({
                nombre: '',
                descripcion: '',
                status: 'activa',
              })
            }}
          />
        </div>
        <div style={{ float: 'right' }}>
          <input
            type='search'
            placeholder='Buscar'
            className='SearchInput-tabla-completa SearchInput-Usuarios'
            onChange={search}
          />
        </div>
      </div>
      <div style={{ height: 'calc(100% - 70px)' }}>
        <DataTable
          headers={testHeader}
          data={dataFiltradas}
          loading={loading}
          paginate
        />
      </div>
      <NewModal
        title='Editar Categoria'
        canDelete
        open={modalEditarIsOpen}
        handleClose={() => setModalEditarIsOpen(false)}
        height={200}
        loading={loadingModalEditar}
        handleSubmit={() => handleEditar(editar)}
        onDelete={() => setDeleteModal(true)}
        permiso='objetos_dimensiones_categorias_evaluacion'
      >
        <div className='nuevoEtiquetaForm'>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <label id='tag-nombre'>Nombre (*)</label>
              <input
                placeholder='Ingresa Nombre'
                value={editar.nombre}
                onChange={(e) => {
                  if (!e.target.value.includes(' ')) {
                    setEditar({
                      ...editar,
                      nombre: e.target.value,
                    })
                  }
                }}
              />
            </Grid>
          </Grid>
        </div>
      </NewModal>
      <NewModal
        title='Nueva Categoria'
        open={modalNuevaIsOpen}
        handleClose={() => {
          setModalNuevaIsOpen(false)
          setNueva({
            nombre: '',
            descripcion: '',
            status: 'activa',
          })
        }}
        height={200}
        loading={loadingModal}
        handleSubmit={() => handleNueva(nueva)}
      >
        <div className='nuevoEtiquetaForm'>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <label id='tag-nombre'>Nombre (*)</label>
              <input
                placeholder='Ingresa Nombre'
                value={nueva.nombre}
                onChange={(e) => {
                  if (!e.target.value.includes(' ')) {
                    setNueva({
                      ...nueva,
                      nombre: e.target.value,
                    })
                  }
                }}
              />
            </Grid>
          </Grid>
        </div>
      </NewModal>
      <NewModal
        open={deleteModal}
        height={120}
        title='Confirmar'
        loading={deleteModalLoading}
        handleClose={() => setDeleteModal(false)}
        handleSubmit={handleDelete}
      >
        <div class='delete-confirm'>
          <h2 style={{ textAlign: 'center' }}>
            ¿Está seguro de que desea eliminar esta categoria?
          </h2>
        </div>
      </NewModal>
    </Contenedor>
  )
}

export default CategoriaEvaluacion
