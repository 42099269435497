import React from 'react';

import { CheckpointContainer, Point } from './styles';

export default function Checkpoint({ percent, number, questionIndex, agrupadores }){
  const reactivos = [];

  for (let i = 0; i < number; i++) {
    reactivos.push(...agrupadores[i].reactivos);
  }

  const isActive = questionIndex >= reactivos.length;

  return (
    <CheckpointContainer percent={`${percent}%`}>
      <Point isActive={isActive}>{number + 1}</Point>
    </CheckpointContainer>
  );
};

