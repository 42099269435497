import React from "react";

const EvaluacionesDocenteIcon = () => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 172 175" enable-background="new 0 0 172 175" space="preserve">
      <image id="image0" width="172" height="175" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKwAAACvCAQAAADKrP39AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZ
cwAACxIAAAsSAdLdfvwAAAAHdElNRQflAgMWCSuH/UHUAAAKLElEQVR42u2df4hcVxXHP8mM2y2J
3Vd/tUQDk0ZJRY0jKY3RCi81aWsUmYhR8RdLoH80gaBS/1gQlP5RUvCPVRTyjzEoahWpIbQaUUig
ERuIdAhbsJrg2pi0EWlmmyizm5lc/5j8mN2d3Znz5p5zXjbzXQhk9r537/nsmfPuve/ec5cFBtLQ
cu8GLFUNwCppAFZJA7BKGoBV0gCskgZglTQAq6QBWCUNwCqp6N2AriqTUCa5/v8qNarUvJvVTXkF
m5Cykw2sos7wnN/VGWaaU/yM31H1buiCCvn7GQ3HQwiN0F2N8HrYH0ruLe7w496AWT9JGA/1npC2
63KYCKl723MLNgnjYVqI9IaaeYO7LCfzsSkHWdFnxG/wK3bn5bGWB7AJP+TzFKLca4oKR70Ngjz0
Y8uc5IuRsMIIf2Cvt0ng77FljjMU+Z5XeI5P+5rl7bGjClhhOds40TakcJGnx1b4jdq9m5xmnZ9p
nh5b5peKdy+wlkNutuEHtsQLCkGgXQW2Me5kHX6h4B+UDGpp8lkO+hjo47HjrDapp8DTXg8xD48t
86JhbX/iAXsTfTz2adPaNpE62OgAdhdrjS18xtxGPMA+aT65PsIucyvNY+woP3L4Y17gLdZVWoN9
xag/MFsNtlrPedl6T5lVtuZdVZGnrKu0Bbsn2vSgVPdb92dtwVZsjWtT07puS7Al3mxrXJsKfMG2
Qkuwqesqhg/bVmcJ1thn5mjENspagn23pWHz1KBsWZ0lWNuh7FwVbecM/N/S2qlkWZkd2DJ1S8M6
yDQU2YFN3MGutKzsVgoFphqAVdIArJLswNbmrcy21iXLyuzAVt3BnrKs7FYKBVXLyizBnrY0bJ7q
SxesqWHzNLx0wT5radg8TdkuorcEe9TSsHl6wbY6S7CTXLA1rk1N9tlWaNsrOGhrXJsK1t8X23UF
Jf7u9Hrm9zxiW6Gtx046dbka9jtprAcIe2lYmwhM2T84rcEeYMraRBo8bl6nw5D2cXOffZUD5la6
rOie4H2GtTXZ4tGD9piE+bJpbc/7DEw8wFZ5iqZRXdNsd7AQv+1INuHAKQyA33zsA0yr13GF7/rN
T/jtpdXY992uJr/13APu9wahykZm1O7ujNX31YweWnes3u+8qmxUiLVX/LF6g4Uq93ImcufrW/5Y
/VOXtLSfr0SZTmxwkQfzkT3O22Nb2slWLkTw259yTz6w5sVjWxrj2xQyem6Dl6gw6W1Cm7wzqs3J
FjcWauGyOE/c4XxlictTprh2VdjDZmZ6GD40uMgP2J8rT72qPIJtqcIONrCOBo05q74usZIpJniO
X+QRaUv5BXtNJUok13e8TDKJ9wqFnpRvsCmQkpCw8voOglNcosbk1czHuVX+wCakpGzhXYx0yHbc
rjrDTPEyf+Zg/nw4T2DL7OFhVtEU7xGfYYgzPMOB/PhwPsCWeYKHMvdhb6jJGxzk+3nA6w024TG+
yUjUEWCTc+zl584peh070aVwqI/k0d3ydk/7pkX3g3pYDOvf4b/ia37tBdej0iQcEuJphBOhEgiE
0fCKGO54SG4FsHvFX/9GODTrzyJHWw+7ljbYcjgbmn1hzYq2GSZCeamCHRfj6IQ1K9oQQti79MCW
w1nxiRwLYc2OthkmrB5mNlh3hZlMHnZkkXsm4T+Z7jkdvrRUwB7K4KstpYvedzTjXRthXN9q7ZFX
wouszpgf7jx3dynxP27PdOcmz7Ndd2Sm+zKxzElKmdPu6e3WLpByUjddlCbYMsf7yr25tkveodGM
/trSal7STBilFwr6X/TW4BibF/xtwsm+U6bOsFFrJkzLY2OsJSzysQUPiYiBFYY4ruW1OmBjLdEs
sK0j2jhYQRGtRiiIu/J1/srBeFhbmubu+D2E+GATXuO2qHecjTY2VoAzrI+NNnYoSDgZGWsrIBy5
2kOoKGCF1RyLfcvYHnuMj0Y3+5rOs5y3K907+lLluB47rpr+9i41rFDgk3FPS4jpsSl/dEtuHkcf
iterjQc2/kNrthoUr/+rpSlKsR5i8ULBMUWsTS7wKGtYw6OcU9zVOBIxIVCkabKxzFODvUzzzZ7u
zj4N2V3NWLO1cUJBib/xJjU/+gv3zfnkiGJa6EjDhTih4FlFrGfYMu+z7ZxRq++2OOEgBtgx3qtm
ZucxUY31img3xTjlo/9QkHBebU/sBe5Z8GuZcIq3KtUboXfQv8f+RA3rDA8uYl6NLWp7cVfwnX5v
0a/H6mXS6mUSWm8H+Qzr+tvf0K/HPqvWYe9lbl9vm/NQvyn7+gObKj22muzucXBZ5etKA4aP93kg
RV/d4L8qDQhkS9h2KQ0YDvfDph+sqXiBWy+6HMYyjPs0dLmf5Uj9hIJ9Km/MirwqvuYuhXZAsZ84
mx1NSe20I/m5X1vEV/Smh7PH2exg96n1B+ST5Xqppr6W9cKs/dhEMXtxg/eI+pCa+ZCms57ekNVj
v6FkCMiPNPuEYpqpYtYXNlk99nXuVDNGmp9Y8wUmvMy9WS7L5rFl5YNQZVH2/aptWZvtAZYN7BPK
mbZHBMaUWKHalmK2B1g2sA+pmgIIoqz+abdfzXJRFrAVlimbIunL6p92e0eWZXNZwO5UTfbY0v09
l/yAelsKjMovytIreMPkDO81PfVlNfvTN3SOd0ovkXtsWXVZxjU1eoyyqUkq9XfIewZysJ8xCARQ
ZGtP5XaYnASS4RRmOdjPGRgCvfYLNhi1RtwzkMdYu5Qcd/bwptSqNRe5Q3aB1GNTxSzFs9VLlLVr
ze3SKCsFWzGJsABFdnQt84hha1LZBVKwm4wMgV6i7ObuN4mmT8mKS2NsjRFDY7pF2bpJ16+l07LT
7mUem5hi7RZlywZD6xsSvoiSgS1TNzSlW5RNzSIsQF02YyAFa3vMdLrob20P6xuW9QukYG21atGt
75o7dDoplRSWgb1PVLp/LRZltSe450v0ikYG9m3GpiwWZfUnuOeqJCksA6uz4mQxpQv+prdJmpjq
lkhllmT9WI/UnQv1Zc+yyrwtgu6dxGNTxSwtC2lmAZ9NHLCKJAsF9qcgDy2wddhu+uWGRD1ZGVjb
XmxLH+n4qe3goKW6JO+RBGzJ3BSAdR0/NT7JG0CWTir/YDtH2XXS21grH6cjLaahDtvZPCKsUPkH
2+lrv8khwoJoUCsB6/Hogk5f+4pTSwS6GTx2fpT9oHeTuksC1nIutl1zo+yo4l7zaLoZPBYea1tX
PcaP3Vpd672o/Vgqi4b4Hk8yAaw3nyxsV7X3ojcHWCgyorogProkX6qj3o11lugZc3PE2HxIEWzN
2zZXJZIYKwE7yUpv25xV671o/t8g5ElKbxBQOFn+ZtI/JYVlYP/lbZurapLCMrCvedvmqqqksAzs
CW/bXFWVFJaBPeptm6PqmmAn3Wa4/DUsAytdeHzrdriEKSGkQ9pz3va56ZSsuHR2a7f5Us686Kis
uPdJyktWg9ktJQ3AKmkAVkn/B9OYvcjp13qoAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTAyLTAz
VDIyOjA5OjQzKzAzOjAwnPhRlAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0wMi0wM1QyMjowOTo0
MyswMzowMO2l6SgAAAAASUVORK5CYII=" />
    </svg>
  )
}

export default EvaluacionesDocenteIcon;
