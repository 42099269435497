import React from "react";

const ObjetosInstrumentoIcon = () => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 172 175" enable-background="new 0 0 172 175" space="preserve">
            <image id="image0" width="172" height="175" x="0" y="0"
                href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKwAAACvCAQAAADKrP39AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
                AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZ
                cwAACxIAAAsSAdLdfvwAAAAHdElNRQflAgMWOSNWYP8VAAAIpUlEQVR42u3dz2uURxgH8K8miIdQ
                t3+A5fVkS8HurUpB1pNibdmeKgSseHMPoRR6SCml0EJzLLa1PQktVSp4kFUkOSXQQG0v3VIP5mRI
                wJPYtQb7Jrvr00Oi7q9kZ555nnfmfZnvIvjjnX1nP05m3x8z8+4ixGhkt+8KFDURVikRVikRVikR
                VikRVikRVikRVikRVikRVikRVikRVikRVikRVikRVikRVikRVikRVikRVikRVikRVinjviuQYSpb
                v55lYeuXSnYV/vZ3ggpO4zD2YQ0Tff+2hgncwBk05XdbZNgSzuM89o/c7gbeld95UWET/IDj5gry
                FShiH2uHqpTiHRXMYMmS9S+NahSrxSa4iVcxZlWmg081qlKkFlvFEl63Zp3CTY3KuMFWeo4L/aaG
                a9hjWaaDKVxUqg9xX9OU0hMiatEildnvIvW6Qm2yTZtqejWS+SAbNO2Z9WlYrFzYwfbRonkqeWKt
                hdZaubDb/dg99NIlVENk5cDu3Jtl3SUktB4iqz3sqC+JDjUy7RLuWbN2aCaLmsmybialSkas04xu
                oJFN3eRZiYha9HUGled0A0RJaLB2R4ptuqP+EWYZrPVsWM1hOd+9KVVV26t92tkdt5hej10xuGA8
                7JTxKiaVThnreMe6zCOUlGozEFNY7vXwDu7jKJbF613CP4xSczghXpNtYnoRZo35/mPYjyXUxOt9
                nlWqIV6PbWMK+5vDPvbgAurCP4Q82FS0DjvGFPYLtB32MoaTWBSsdcLq8TONKeyvjjBjeE2wQ6gw
                y+0Vq8HImF/ofg+PHPf0gVitTzPLlR32eQoNEMi4U7M4Niszjhy7syx2lNhk1uAhe4/dlyaNTjJs
                bs008AmeOvyfy3117GOWewkJq1wNF7rupRkdP9vd8/oKfztw/OhQtjsVh4O/zxilelmBNZM+3vZm
                YgXrzA+1iu+ZJQczwS45ad1m+1kN924L28QJdBgfaBWHxIaeVRzK7sHPVtsPshrWwP729wK+taZd
                F2R1zRGLw77hrEbhjCv4EHctS4zjJzEY1+zGd4a0DqzcARunLHvaMZxEXRDHNSa0My6sXNhlnLbs
                DkKj/QaLO3yNJWjgYxdW/hCj67iaa9rdeAtLuDQEt4w67uEN11FtLgOP7S9+d3BLYPR0BfPO7/Gi
                Rv/iDzSQAtiLMg5jwqClHjOYu+BwYllm3dN3v+tUoceOJ9dueWJyF9ptqDzne1Oi1foe328wtN6t
                J7mIW9ZlJPpat+tsrjHau+vA4zO4P/B3q9jYsYw77W3HWrvFaO+usE283ce4ikN4U5n2Fwkf5b0L
                XB2tUkqtrY79ztbIrdFfbC5fY5wxBXJJTOooM8+rhCoqaGIB15//XRm/jxi67vI1xhvnIJFVvGK0
                ndhVfc7hGLfVcg70pGI4UFV3ZqJOqx39rpp52ew6ne50pIbC15hf1jnTy5/6c2llW61fVuCA6XAp
                /Ql0kq3WN+uc+Si0bGZ/y7Ra36wbOGgOm82UT4lW65u1g89tRk1mt16BW6v1zQos44DN5tlNUnZp
                tf5ZN3DMrkCWs7+5tP5ZO3jfdvB0ttPqNY5r9dPBVNepumkUT2nlTnR9nsI+pWucz5k9bL5o23SF
                9yl9wOaH1mHWrR/YfNCuu8xT8wUbOq3zzEp/sCHTrrsvD+ATNlTaWYlZwH5hw6OtS02t9g2rTZsa
                k67QtOQSFr5Z3Wlbz7dqDcEqUUJnaZaaREMGJj0moibN0ln5JQDCWI2Tc+WrjNvYhTlcwgKaABKU
                cQ7Hu96nf3h+Bd2D3JtoQHFhXt+t1aXVJkN+dEt0eWtG1oq35aqC6Qr4tMNfVUp9s4bSFTz74Za6
                7VjGsu/JJCHBao+eyTRhLXOaz+u1Q+MbtoxLPX+2p51BCgLhIaY9f5beeP/C6jAO/198jdW71uHs
                ZLf40+iXb1bumdVmmXrfolUSMxxyD9uNx6N9MGQtsGBoQ2Dl0rod7RYSdhhawWhDYS0cbTisBaMN
                ibVQtGGxFog2NNbC0IbHWhDaEFmJiDp0tu8d5lmw3mhDGSo/mN5pP3WcZK944eVSYxZXtzis82Ks
                ni416sPyhg13r3pUc2IFvNBqw/JY13rGT3/pyAp4oPU95XPbenX9XqqKmfa1mi3WZe5ASaE+mbZa
                PVgX1rWeJXT/FKtThrRasG4zXSZwpOtPH7GWqRye7GiDOB0YzErP+3Eeheb5lCFMVqJW39pWuaMN
                k5WI6E7f++aMNlRWos7AcPVc0YbKuplqfmlDZh02YjA3tPlizRFt3lhzQ5s/1pzQ5pE1F7T5ZM0B
                bV5Zg6fNL2vgtHlmDZo236wB0+adNVja/LMGSlsE1iBpi8EaIG1RWIOjLQ5rYLQ2GyeBs2rQzmQB
                ey94VnnalskDe9xga9TJAas87QNtWO7Ti7NmlaZtDQyBFoWtDlnKJlRWadq7nBqYDjE6h3GhoTeS
                T6jdPhcxJTYw6SBniJ4p7FGhSmbDCkjStjlPuTeF5T7GvDfZsQJytOOcZzebwZZEPmi2rIAcbWJf
                xAw2QepcuexZAdm+1ipmsA3sddyPH1ZAhpbRrLJZbMcfKyBBa/usc5jD3jfcblj8sgKutBto2Bcy
                hZ1jV8s/K+BKu2BfxBT2ArNaYbACLrS8B2Ibn6TdDfTkVftEl3l9y3zTinWlQmPl0S7y9mSz8WWr
                CzEhstrTpty1kG02LtFK7lntaNv8R0zYbW5KGzKrOW2L/0AU+7u0JVoc0SG0aD5wVhBocuR68yn3
                pgwPdvP/O90Gt0VNmvSOZvZKdmgiLZp1bRy8YiWq0QoR/fe8KutEtOLyo+PlVaY6pV2fgqhFqcxD
                JlzWKyihjDJK2Fz6vhHIiYB9yki2LmU3sMw5fR2WsNboLlB8LyVd2ERYpURYpURYpURYpURYpURY
                pURYpURYpURYpURYpURYpURYpURYpURYpfwPaXyMNEDQZqoAAAAldEVYdGRhdGU6Y3JlYXRlADIw
                MjEtMDItMDNUMjI6NTc6MzUrMDM6MDCac/FGAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIxLTAyLTAz
                VDIyOjU3OjM1KzAzOjAw6y5J+gAAAABJRU5ErkJggg==" />
        </svg>
    )
}

export default ObjetosInstrumentoIcon;