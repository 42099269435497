import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import { toast } from 'react-toastify'

import DataTable from '../../Components/DataTables'
import DataSelectTable from '../../Components/DataSelectTable'
import NewModal from '../../Components/Modal/NewModal'
import Despliegue from '../../Components/BotonDespliegue'
import { postRequest } from '../../utils/requester'
import { getRequest } from '../../utils/requester'
import Aside from './Aside'
import './style.css'

const formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

const formatFirstDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '01',
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

const SeguimientoEstandares = ({ permisosPorReporte }) => {
  const [data, setData] = useState([])
  const [campos, setCampos] = useState([])
  const [dataFiltrada, setDatFiltrada] = useState([])
  const [detalleActualizaciones, setDetalleActualizaciones] = useState(false)
  const [desplegada, setDesplegada] = useState('')
  const [inicioFiltro, setInicioFiltro] = useState(formatFirstDate(new Date()))
  const [finFiltro, setFinFiltro] = useState(formatDate(new Date()))
  const [catalogo, setCatalogo] = useState([])
  const [loadingTable, setLoadingTable] = useState(false)
  const [numerosColumnasConSelect, setNumeroColumnasConSelect] = useState([])
  const [numerosColumnasconInput, setNumerosColumnasconInput] = useState([])
  const [
    numerosColumnasConSelectMultiple,
    setNumeroColumnasConSelectMultiple,
  ] = useState([])
  const [vacantes] = useState({
    vacantes: {},
    vacantes_ocupadas: {},
    alertas: {},
  })
  const [headers, setHeaders] = useState([])
  const [listaOpciones, setListaOpciones] = useState([])
  const [, setVacantesData] = useState([])

  useEffect(() => {
    if (permisosPorReporte.leer) {
      getReportePlantillaAcademica()
      getListaDeOpciones()
    }
  }, [permisosPorReporte.leer])

  async function getReportePlantillaAcademica() {
    setLoadingTable(true)
    let response = await getRequest(
      '/estructura/participacion_academica?tipo=estandares'
    )
    if (response.s === 'OK') {
      let myData = []
      let myCampos = []
      let myHeaders = []
      if (response.d !== '') {
        Object.keys(response.d[0]).forEach((key) => {
          if (key !== 'id') {
            myCampos.push(key)
            myHeaders.push(key.replaceAll('_', ' ').replace('coordinador', ''))
          }
        })
        response.d.forEach((reg) => {
          let myReg = []
          myCampos.forEach((key) => {
            myReg.push(reg[key])
          })
          myReg.push(reg['id'])
          myData.push(myReg)
        })
      }

      setData(myData)
      setDatFiltrada(myData)
      setCampos(myCampos)
      setHeaders(myHeaders)
    } else {
      setLoadingTable(false)
    }
  }

  async function getListaDeOpciones() {
    let response = await getRequest(
      '/estructura/participacion_academica/catalogo?tipo=estandares'
    )
    if (response.s === 'OK') {
      setCatalogo(response.d)
    } else {
      console.log('error')
    }
  }

  useEffect(() => {
    if (catalogo.length > 0 && campos.length > 0) {
      let myColumnasConSelect = []
      let myColumnasConInput = []
      let myColumnasConMultiple = []
      let myOpcionesToselect = {}
      catalogo.forEach((registro) => {
        campos.forEach((campo, i) => {
          if (campo === registro.name) {
            if (
              registro.type === 'selección unica' ||
              registro.type === 'multi selección'
            ) {
              myColumnasConSelect.push(i)
              if (registro.type === 'multi selección') {
                myColumnasConMultiple.push(i)
              }
              let mySubOptions = []
              registro.options.forEach((option) => {
                mySubOptions.push({
                  label: option,
                  value: option,
                })
              })
              myOpcionesToselect[i] = mySubOptions
            } else if (registro.type === 'texto') {
              myColumnasConInput.push(i)
            }
          }
        })
      })
      setNumerosColumnasconInput(myColumnasConInput)
      setListaOpciones(myOpcionesToselect)
      setNumeroColumnasConSelect(myColumnasConSelect)
      setNumeroColumnasConSelectMultiple(myColumnasConMultiple)
      setLoadingTable(false)
    }
  }, [catalogo, campos])

  function verMas(tipo) {
    if (tipo === 'Vacantes') {
      getVacantes()
    } else if (tipo === 'Vacantes Ocupadas') {
      getVacantesOcupadas()
    } else if (tipo === 'Alertas') {
      getAlertas()
    }
    setDesplegada(tipo)
    setDetalleActualizaciones(true)
  }

  function handleDateChangeStart(value) {
    setInicioFiltro(value.target.value)
  }

  function handleDateChangeEnd(value) {
    setFinFiltro(value.target.value)
  }

  function search(e) {
    let text = e.target.value.toLowerCase()
    let result = []
    data.forEach((reg) => {
      let contiene = false
      reg.forEach((cel) => {
        if (String(cel).toLowerCase().includes(text)) {
          contiene = true
        }
      })
      if (contiene) {
        result.push(reg)
      }
    })
    setDatFiltrada(result)
  }

  async function modificarCelda(id, campo, valor) {
    await postRequest('/estructura/participacion_academica/editar', {
      id: id,
      campo: campo,
      valor: valor,
    })
  }

  const getVacantes = async () => {
    try {
      const response = await fetch(
        `https://jlmm2gbdu6.execute-api.us-east-1.amazonaws.com/dev/reportes/alertas?fecha_inicio=${inicioFiltro
          .split('-')
          .reverse()
          .join('-')}&fecha_fin=${finFiltro.split('-').reverse().join('-')}`
      )
      const data = await response.json()
      setVacantesData(data.d)
      console.log(data)
    } catch (error) {
      console.error(error)
      toast.error('Error obteniendo detalle de asignación')
    }
  }

  const getVacantesOcupadas = async () => {
    try {
      const response = await fetch(
        `https://jlmm2gbdu6.execute-api.us-east-1.amazonaws.com/dev/reportes/alertas?fecha_inicio=${inicioFiltro
          .split('-')
          .reverse()
          .join('-')}&fecha_fin=${finFiltro.split('-').reverse().join('-')}`
      )
      const data = await response.json()
      setVacantesData(data.d)
      console.log(data)
    } catch (error) {
      console.error(error)
      toast.error('Error obteniendo detalle de asignación')
    }
  }

  const getAlertas = async () => {
    try {
      const response = await fetch(
        `https://jlmm2gbdu6.execute-api.us-east-1.amazonaws.com/dev/reportes/alertas?fecha_inicio=${inicioFiltro
          .split('-')
          .reverse()
          .join('-')}&fecha_fin=${finFiltro.split('-').reverse().join('-')}`
      )
      const data = await response.json()
      setVacantesData(data.d)
      console.log(data)
    } catch (error) {
      console.error(error)
      toast.error('Error obteniendo detalle de asignación')
    }
  }

  return (
    <>
      <div className='contenedor-reporte-estructura'>
        {permisosPorReporte.leer ? (
          <>
            <div style={{ float: 'right' }}>
              {loadingTable ? (
                <></>
              ) : (
                <input
                  type='search'
                  placeholder='Buscar'
                  className='SearchInput-participacion'
                  onChange={search}
                />
              )}
            </div>
            <DataSelectTable
              title='Seguimiento Estandares'
              resaltada='tipo PTC'
              headers={headers}
              data={dataFiltrada}
              loading={loadingTable}
              paginate
              listaOpciones={listaOpciones}
              numerosColumnasConSelect={numerosColumnasConSelect}
              numerosColumnasconInput={numerosColumnasconInput}
              multiples={numerosColumnasConSelectMultiple}
              campos={campos}
              modificarCelda={modificarCelda}
              permiso='estructura_seguimiento_academicos_estandares'
            />
          </>
        ) : null}
      </div>
      <div className='aside-estructura'>
        <Aside
          vacantes={vacantes.vacantes}
          vacantes_ocupadas={vacantes.vacantes_ocupadas}
          alertas={vacantes.alertas}
          verMas={verMas}
        />
      </div>
      <NewModal
        title='Detalle de Actualizaciones'
        open={detalleActualizaciones}
        handleClose={() => setDetalleActualizaciones(false)}
        height={750}
        loading={false}
        handleSubmit={() => {}}
        width={'80%'}
      >
        <Despliegue
          desplegada={desplegada === 'Vacantes'}
          nombre='Vacantes'
          open={() => {
            setDesplegada('Vacantes')
          }}
          style={{ marginBottom: 0 }}
          marginTop='15px'
          height='65%'
        >
          <div className='contenedor-filtro-despliegue'>
            <div className='contenedor-pickers-filtro'>
              <span>Periodo:</span>
              <TextField
                inputProps={{
                  min: formatFirstDate(new Date()),
                  max: formatDate(new Date()),
                }}
                className='start-filter'
                type='date'
                value={inicioFiltro}
                onChange={handleDateChangeStart}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              _
              <TextField
                inputProps={{
                  max: formatDate(new Date()),
                }}
                className='end-filter'
                type='date'
                value={finFiltro}
                onChange={handleDateChangeEnd}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <button className='search-button' onClick={getVacantes}>
                Buscar
              </button>
            </div>
            <input
              type='search'
              placeholder='Buscar'
              className='SearchInput-despliegue '
            />
          </div>
          <DataTable
            headers={['Fecha', 'ID Puesto', 'Puesto', 'Departamento', 'Campus']}
            data={[]}
            loading={false}
            paginate
            height='calc(100% - 91px)'
          />
        </Despliegue>
        <Despliegue
          desplegada={desplegada === 'Vacantes Ocupadas'}
          nombre='Vacantes Ocupadas'
          open={() => {
            setDesplegada('Vacantes Ocupadas')
          }}
          style={{ marginBottom: 0 }}
          marginTop='15px'
          height='65%'
        >
          <div className='contenedor-filtro-despliegue'>
            <div className='contenedor-pickers-filtro'>
              <span>Periodo:</span>
              <TextField
                inputProps={{
                  min: formatFirstDate(new Date()),
                  max: formatDate(new Date()),
                }}
                className='start-filter'
                type='date'
                value={inicioFiltro}
                onChange={handleDateChangeStart}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              _
              <TextField
                inputProps={{
                  max: formatDate(new Date()),
                }}
                className='end-filter'
                type='date'
                value={finFiltro}
                onChange={handleDateChangeEnd}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <button className='search-button' onClick={getVacantesOcupadas}>
                Buscar
              </button>
            </div>
            <input
              type='search'
              placeholder='Buscar'
              className='SearchInput-despliegue '
            />
          </div>
          <DataTable
            headers={['Fecha', 'ID Puesto', 'Puesto', 'Departamento', 'Campus']}
            data={[]}
            loading={false}
            paginate
            height='calc(100% - 91px)'
          />
        </Despliegue>
        <Despliegue
          desplegada={desplegada === 'Alertas'}
          nombre='Alertas'
          open={() => {
            setDesplegada('Alertas')
          }}
          style={{ marginBottom: 0 }}
          marginTop='15px'
          height='65%'
        >
          <div className='contenedor-filtro-despliegue'>
            <div className='contenedor-pickers-filtro'>
              <span>Periodo:</span>
              <TextField
                inputProps={{
                  min: formatFirstDate(new Date()),
                  max: formatDate(new Date()),
                }}
                className='start-filter'
                type='date'
                value={inicioFiltro}
                onChange={handleDateChangeStart}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              _
              <TextField
                inputProps={{
                  max: formatDate(new Date()),
                }}
                className='end-filter'
                type='date'
                value={finFiltro}
                onChange={handleDateChangeEnd}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <button className='search-button' onClick={getAlertas}>
                Buscar
              </button>
            </div>
            <input
              type='search'
              placeholder='Buscar'
              className='SearchInput-despliegue '
            />
          </div>
          <DataTable
            headers={['Fecha', 'ID Puesto', 'Puesto', 'Departamento', 'Campus']}
            data={[]}
            loading={false}
            paginate
            height='calc(100% - 91px)'
          />
        </Despliegue>
      </NewModal>
    </>
  )
}

export default SeguimientoEstandares
