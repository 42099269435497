import React from 'react'
import PropTypes from 'prop-types'
import Modal from '@material-ui/core/Modal'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Close'

import Can from '../../utils/can'
import './style.css'

function NewModal(props) {
  return (
    <Modal open={props.open} disableBackdropClick>
      <div
        className='NewModal'
        style={props.width && { maxWidth: props.width }}
      >
        <CloseIcon onClick={props.handleClose} />
        <h3>{props.title}</h3>
        <div className='NewModal-container2' style={{ height: props.height }}>
          {props.loading ? (
            <div style={{ textAlign: 'center', paddingTop: 30 }}>
              <CircularProgress size={50} />
            </div>
          ) : (
            <>{props.children}</>
          )}
        </div>
        <div className='NewModal-footer'>
          {props.canDelete ? (
            <button onClick={props.onDelete} className='delete-button'>
              {props.DeleteText ? props.DeleteText : 'Eliminar'}
            </button>
          ) : null}
          {props.noCancel ? (
            <></>
          ) : (
            <button className='gray-btn' onClick={props.handleClose}>
              Cancelar
            </button>
          )}
          {props.noSaveBtn ? null : props.permiso ? (
            <Can I='edit' a={props.permiso}>
              <button className='black-btn' onClick={props.handleSubmit}>
                {props.buttonAcceptText ? props.buttonAcceptText : 'Aceptar'}
              </button>
            </Can>
          ) : (
            <button className='black-btn' onClick={props.handleSubmit}>
              {props.buttonAcceptText ? props.buttonAcceptText : 'Aceptar'}
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}

NewModal.propTypes = {
  open: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleClose: PropTypes.func,
  title: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  children: PropTypes.element,
  canDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  DeleteText: PropTypes.string,
  noCancel: PropTypes.bool,
  noSaveBtn: PropTypes.bool,
  permiso: PropTypes.string,
  handleSubmit: PropTypes.func,
  buttonAcceptText: PropTypes.string,
}

export default NewModal
