/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Select from 'react-select'
import './styles.css'

function EscalaPregunta({ setEscalaLineal, escalaLineal }) {
  const valores = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
    { label: '13', value: '13' },
    { label: '14', value: '14' },
    { label: '15', value: '15' },
    { label: '16', value: '16' },
    { label: '17', value: '17' },
    { label: '18', value: '18' },
    { label: '19', value: '19' },
    { label: '20', value: '20' },
  ]
  const [pregunta, setPregunta] = useState('')
  const [inicio, setInicio] = useState('1')
  const [fin, setFin] = useState('20')
  const [etiquetaInicio, setEtiquetaInicio] = useState('')
  const [etiquetaFin, setEtiquetaFin] = useState('')
  const [valueInicio, setValueInicio] = useState('')
  const [valueFin, setValueFin] = useState('')
  let escalaLinealData = {
    pregunta,
    inicio,
    fin,
    etiquetaInicio,
    etiquetaFin,
    valueInicio,
    valueFin,
  }
  const handleChangeInputPregunta = (e) => {
    setPregunta(e.target.value)
    setEscalaLineal({ ...escalaLinealData, pregunta: e.target.value })
  }
  const handleChangeEtiquetaInicio = (e) => {
    setEtiquetaInicio(e.target.value)
    setEscalaLineal({ ...escalaLinealData, etiquetaInicio: e.target.value })
  }

  const handleChangeEtiquetaFin = (e) => {
    setEtiquetaFin(e.target.value)
    setEscalaLineal({ ...escalaLinealData, etiquetaFin: e.target.value })
  }

  useEffect(() => {
    let pasos = fin - inicio + 1
    let escalon = parseInt(100 / pasos)
    setValueInicio(escalon)
    setValueFin(100)
  }, [inicio, fin])

  useEffect(() => {
    if (escalaLineal) {
      setPregunta(escalaLineal.pregunta)
      setInicio(escalaLineal.inicio)
      setFin(escalaLineal.fin)
      setEtiquetaInicio(escalaLineal.etiquetaInicio)
      setEtiquetaFin(escalaLineal.etiquetaFin)
      setValueInicio(escalaLineal.valueInicio)
      setValueFin(escalaLineal.valueFin)
      setEscalaLineal({ ...escalaLinealData })
    }
  }, [])

  useEffect(() => {
    setEscalaLineal({ ...escalaLinealData })
  }, [
    pregunta,
    inicio,
    fin,
    etiquetaInicio,
    etiquetaFin,
    valueInicio,
    valueFin,
  ])
  const customStyles = {
    option: (provided, _state) => ({
      ...provided,
      color: '#616b74',
      fontSize: 14,
      fontWeight: 400,
      padding: '5px 25px',
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      userSelect: 'none',
      backgroundColor: '#fff',
      borderTop: '1px solid #ccc',
    }),
    control: (provided, _state) => ({
      ...provided,
      minHeight: 0,
      height: '25px',
      borderRadius: 15,
      fontSize: 11,
      backgroundColor: '#f9f9f9',
      padding: 0,
    }),
    placeholder: (provided, _state) => ({
      ...provided,
      padding: 0,
      fontSize: 11,
      fontWeight: 400,
    }),
    menu: (provided, _state) => ({
      ...provided,
      borderRadius: 0,
    }),
    input: (provided, _state) => ({
      ...provided,
      padding: 0,
      fontSize: 11,
      fontWeight: 400,
      borderRadius: 0,
    }),
    singleValue: (provided, _state) => ({
      ...provided,
      top: '50%',
    }),
  }
  return (
    <>
      <textarea
        rows={3}
        cols={60}
        id='caja-pregunta'
        className='input-pregunta'
        placeholder='Escriba una pregunta'
        value={pregunta}
        onChange={handleChangeInputPregunta}
      />
      <Grid className='caja-escala' container>
        <Grid xs={6} md={6} container>
          <Grid xs={6} md={6}></Grid>
          <Grid xs={1} md={1}>
            <label>De</label>
          </Grid>
          <Grid xs={5} md={5}>
            <Select
              onChange={(e) => setInicio(e.value)}
              options={valores}
              value={{ label: inicio, value: inicio }}
              name='inicio'
              styles={customStyles}
              placeholder='Desde'
              name='tipo-pregunta'
              className='select-search-box'
              isSearchable={false}
            />
          </Grid>

          <Grid xs={12} md={12} container className='caja-etiquetas'>
            <Grid xs={3} md={3}>
              <label style={{ fontSize: '15px' }}>Rango {inicio}</label>
            </Grid>
            <Grid xs={9} md={9}>
              <input
                value={etiquetaInicio}
                onChange={handleChangeEtiquetaInicio}
                className='input-pregunta'
                placeholder='Escriba etiqueta'
              />
            </Grid>
          </Grid>
          <Grid xs={12} md={12} container className='caja-etiquetas'>
            <Grid xs={3} md={3}>
              <label style={{ fontSize: '15px' }}>Rango {fin}</label>
            </Grid>
            <Grid xs={9} md={9}>
              <input
                value={etiquetaFin}
                onChange={handleChangeEtiquetaFin}
                className='input-pregunta'
                placeholder='Escriba etiqueta'
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6} md={6} container>
          <Grid xs={1} md={1}>
            <label>a</label>
          </Grid>
          <Grid xs={5} md={5}>
            <Select
              onChange={(e) => setFin(e.value)}
              options={valores}
              value={{ label: fin, value: fin }}
              name='fin'
              styles={customStyles}
              placeholder='hasta'
              name='tipo-pregunta'
              className='select-search-box'
              isSearchable={false}
            />
          </Grid>
          <Grid xs={5} md={5}></Grid>
          <Grid xs={4} md={4}>
            <label style={{ fontSize: '18px', marginTop: '40px' }}>
              Valor inicial
            </label>
          </Grid>
          <Grid xs={8} md={8}>
            <input
              style={{ marginTop: '40px' }}
              value={valueInicio}
              placeholder='Valor de respuesta'
            />
          </Grid>
          <Grid xs={4} md={4}>
            <label style={{ fontSize: '18px', marginTop: '40px' }}>
              Valor final
            </label>
          </Grid>
          <Grid xs={8} md={8}>
            <input
              style={{ marginTop: '40px' }}
              value={valueFin}
              placeholder='Valor de respuesta'
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default EscalaPregunta
