/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Cancel from '@material-ui/icons/Cancel'

export default function Escala({
  customStyles,
  values,
  indiceRango,
  opcionesRango,
  setRangosEscala100,
  rangosEscala100,
  handleDeleteRangoEscala100,
}) {
  useEffect(() => {
    console.log(values)
  }, [])
  function handleChangeLeftValue(e) {
    let text = e.target.value
    let myRangos = [...rangosEscala100]
    myRangos[indiceRango].valorInicial = text
    setRangosEscala100(myRangos)
  }

  function handleChangeRightValue(e) {
    let text = e.target.value
    let myRangos = [...rangosEscala100]
    myRangos[indiceRango].valorFinal = text
    setRangosEscala100(myRangos)
  }

  function handleChangeLeftRango(e) {
    let value = e.target.value
    let rango = {
      label: value,
      value,
    }
    let myRangos = [...rangosEscala100]
    myRangos[indiceRango].rangoInicial = rango
    setRangosEscala100(myRangos)
  }

  function handleChangeRightRango(e) {
    let value = e.target.value
    let rango = {
      label: value,
      value,
    }
    let myRangos = [...rangosEscala100]
    myRangos[indiceRango].rangoFinal = rango
    setRangosEscala100(myRangos)
  }

  function handleChangePuntajeNumerico(e) {
    let text = e.target.value
    let myRangos = [...rangosEscala100]
    myRangos[indiceRango].puntajeNumerico = text
    setRangosEscala100(myRangos)
  }
  return (
    <Grid container className='contenedor-escala100'>
      <Grid xs={2}>
        <label id='tag-nombre'>Rango:</label>
        <select
          value={
            values.rangoInicial.value === undefined
              ? ''
              : values.rangoInicial.value
          }
          styles={customStyles}
          name='etiqueta'
          placeholder='Selección...'
          className='select-search-box'
          onChange={handleChangeLeftRango}
        >
          <option value={''} disabled>
            Seleccione...
          </option>
          {opcionesRango.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>
      </Grid>
      <Grid xs={0.5}>
        <label
          style={{
            color: 'white',
            marginLeft: '2px',
            marginRight: '2px',
            marginBottom: '8px',
          }}
        >
          :
        </label>
        :
      </Grid>
      <Grid xs={2}>
        <label style={{ color: 'white' }}>asd</label>
        <input value={values.valorInicial} onChange={handleChangeLeftValue} />
      </Grid>
      <Grid xs={0.5}>
        <label
          style={{
            color: 'white',
            marginLeft: '3px',
            marginRight: '4px',
            marginBottom: '8px',
          }}
        >
          :
        </label>
        y
      </Grid>
      <Grid xs={2}>
        <label style={{ color: 'white' }}>asd</label>
        <select
          value={
            values.rangoFinal.value === undefined ? '' : values.rangoFinal.value
          }
          styles={customStyles}
          name='etiqueta'
          placeholder='Selección...'
          className='select-search-box'
          onChange={handleChangeRightRango}
        >
          <option value={''} disabled>
            Seleccione...
          </option>
          {opcionesRango.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>
      </Grid>
      <Grid xs={0.5}>
        <label
          style={{
            color: 'white',
            marginLeft: '2px',
            marginRight: '2px',
            marginBottom: '8px',
          }}
        >
          :
        </label>
        :
      </Grid>
      <Grid xs={2}>
        <label style={{ color: 'white' }}>asd</label>
        <input value={values.valorFinal} onChange={handleChangeRightValue} />
      </Grid>
      <Grid xs={1}></Grid>
      <Grid xs={2}>
        <label style={{ marginLeft: '0px' }}>Puntaje numerico</label>
        <input
          value={values.puntajeNumerico}
          onChange={handleChangePuntajeNumerico}
        />
        <button
          onClick={() => {
            handleDeleteRangoEscala100(indiceRango)
          }}
          className='eliminar-escala'
        >
          <Cancel />
        </button>
      </Grid>
    </Grid>
  )
}
