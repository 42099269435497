/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import Contenedor from '../../Components/Contenedor/Contenedor'
import Tabs from '../../Components/Tabs'
import SeguimientoEstandares from './SeguimientoEstandares'
import SeguimientoPlantillaAcademica from './SeguimientoPlantillaAcademica'
import Catalogo from './Catalogo'

import './style.css'

const ParticipacionAcademicos = ({ permisos }) => {
  const [, setTab] = useState(1)
  const [permisosPorReporte, setPermisosPorReporte] = useState({
    estructura_seguimiento_academicos_estandares: {},
    estructura_seguimiento_academicos_plantilla_academica: {},
    estructura_seguimiento_academicos_catalogos: {},
  })
  const [, setDefaultTab] = useState(1)

  const namesTabs = [
    [1, 'Seguimiento a Estándares'],
    [2, 'Seguimiento Plantilla Academica'],
    [3, 'Catálogos'],
  ]

  const permisosReportes = [
    'estructura_seguimiento_academicos_estandares',
    'estructura_seguimiento_academicos_plantilla_academica',
    'estructura_seguimiento_academicos_catalogos',
  ]

  useEffect(() => {
    let myPermisos = {}
    let myDefaultTab = 0
    permisos.forEach((permiso) => {
      if (permisosReportes.includes(permiso.funcionalidad)) {
        myPermisos[permiso.funcionalidad] = permiso
        if (myDefaultTab === 0 && permiso.leer) {
          myDefaultTab = permisosReportes.indexOf(permiso.funcionalidad) + 1
        }
      }
    })
    if (myDefaultTab === 0) {
      myDefaultTab = 1
    }
    setDefaultTab(1)
    setPermisosPorReporte(myPermisos)
  }, [])

  const containersTabs = [
    [
      <SeguimientoEstandares
        permisosPorReporte={
          permisosPorReporte['estructura_seguimiento_academicos_estandares']
        }
      />,
    ],
    [
      <SeguimientoPlantillaAcademica
        permisosPorReporte={
          permisosPorReporte[
            'estructura_seguimiento_academicos_plantilla_academica'
          ]
        }
      />,
    ],
    [<Catalogo />],
  ]

  return (
    <>
      <Contenedor title='Seguimiento Academicos'>
        <Tabs
          defaultTab={1}
          permisos={permisosReportes}
          namesTabs={namesTabs}
          containersTabs={containersTabs}
          changeBox={setTab}
        />
      </Contenedor>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    permisos: state.permisos,
  }
}

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParticipacionAcademicos)
