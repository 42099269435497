import React, { useState } from 'react'
import NewModal from '../../Components/Modal/NewModal'
import Grid from '@material-ui/core/Grid'
import ContenedorFranja from '../../Components/ContenedorFranja'

export default function DetalleIntegral({
  modalDetalleIntegral,
  setModalDetalleIntegral,
  loadingDetalleIntegral,
  detalleIntegral,
  firmarDetalle,
  hideButton,
}) {
  const [modalFirmar, setModalFirmar] = useState(false)

  return (
    <>
      <NewModal
        title='Detalle Integral'
        open={modalDetalleIntegral}
        handleClose={() => setModalDetalleIntegral(false)}
        height={600}
        loading={loadingDetalleIntegral}
        handleSubmit={() => {
          if (detalleIntegral.detalle.estatus === 'pendiente de firmar') {
            setModalFirmar(true)
          } else {
            setModalDetalleIntegral(false)
          }
        }}
        buttonAcceptText={
          detalleIntegral.detalle.estatus === 'pendiente de firmar'
            ? 'Firmar'
            : 'Cerrar'
        }
        noSaveBtn={
          hideButton ||
          detalleIntegral.detalle.estatus !== 'pendiente de firmar'
        }
        noCancel
      >
        <Grid container className='contenedor-info-detalle-asignacion'>
          <Grid xs={4}>
            <p>
              <span className='campo-informacion'>Clave docente:</span>
              {detalleIntegral.detalle.clave_docente}
            </p>
          </Grid>
          <Grid xs={8}>
            <p>
              <span className='campo-informacion'>Nombre docente:</span>
              {detalleIntegral.detalle.nombre_docente}
            </p>
          </Grid>
          <Grid xs={4}>
            <p>
              <span className='campo-informacion'>Nivel:</span>
              {detalleIntegral.detalle.nivel}
            </p>
          </Grid>
          <Grid xs={4}>
            <p>
              <span className='campo-informacion'>Periodo:</span>
              {detalleIntegral.detalle.periodo}
            </p>
          </Grid>
          <Grid xs={4}>
            <p>
              <span className='campo-informacion'>Campus:</span>
              {detalleIntegral.detalle.campus}
            </p>
          </Grid>
          <Grid xs={4}>
            <p>
              <span className='campo-informacion'>Nombre Calendario:</span>
              {detalleIntegral.detalle.nombre_calendario}
            </p>
          </Grid>
          <Grid xs={4}>
            <p>
              <span className='campo-informacion'>Estatus:</span>
              {detalleIntegral.detalle.estatus}
            </p>
          </Grid>
          <Grid xs={4}>
            <p>
              <span className='campo-informacion'>Hora de Registro:</span>
              {detalleIntegral.detalle.fecha_hora_firma &&
                detalleIntegral.detalle.fecha_hora_firma.split(' ')[0] +
                  ' ' +
                  detalleIntegral.detalle.fecha_hora_firma
                    .split(' ')[1]
                    .split(':')[0] +
                  ':' +
                  detalleIntegral.detalle.fecha_hora_firma
                    .split(' ')[1]
                    .split(':')[1]}
            </p>
          </Grid>
        </Grid>
        <Grid container className='contenedor-header-detalle-integral'>
          <div className='resultados-detalle-integral' xs={1}>
            <p>
              Resultado Integral Periodo:
              <span>{detalleIntegral.detalle.resultados.integral_periodo}</span>
            </p>
            <p>
              Puntos Reconocimiento:
              <span>
                {detalleIntegral.detalle.resultados.puntos_reconocimiento}
              </span>
            </p>
          </div>
          <Grid xs={7}></Grid>
          <Grid className='contenedor-registros-franja' xs={5}>
            <p className='contenedor-headers-detalle-integral-p'>
              <span className='contenedor-titulo-registro vertical-text'>
                Integral
              </span>
            </p>
          </Grid>
        </Grid>
        <div className='contenedor-reporte-detalle-integral'>
          {detalleIntegral.dimensiones.map((dimension) => (
            <ContenedorFranja
              titulo={dimension.nombre_dimension}
              resultados={dimension.resultados}
            />
          ))}
        </div>
      </NewModal>

      <NewModal
        title='Firmar Evaluación Intregal'
        open={modalFirmar}
        handleClose={() => setModalFirmar(false)}
        height={80}
        width={500}
        loading={loadingDetalleIntegral}
        handleSubmit={() => {
          firmarDetalle(setModalFirmar)
        }}
      >
        <h4 style={{ textAlign: 'center' }}>¿Está seguro que desea firmar?</h4>
      </NewModal>
    </>
  )
}
