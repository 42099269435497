import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1rem;
`;

export const Button = styled.button`
  border: none;
  height: 35px;
  width: 125px;
  background-color: #e2e2e2;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 2rem;
  border-radius: 40px;
`;
