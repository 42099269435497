import React from 'react';
import TableRow from './TableRow';
import { StyledTable, TH } from './styles';

export default function AbiertaMobile({ 
  abiertaOptions,
  tipoEvaluacion
}){
  return (
    <StyledTable>
      <colgroup>
        <col span='1' style={{ width: '25%' }} />
        <col span='1' style={{ width: '35%' }} />
        <col span='1' style={{ width: '40%' }} />
      </colgroup>
      <thead>
        <tr>
          {
            tipoEvaluacion==='Opinion_Estudiantil' ?
            <>
              <TH>Asignatura</TH>
              <TH>Docente</TH>
            </>:null
          }
          <TH>Respuesta</TH>
        </tr>
      </thead>
      <tbody>
        {
          tipoEvaluacion==='Opinion_Estudiantil' ?
            abiertaOptions.map(({ id, asignatura, docente }) => (
            <TableRow
              tipoEvaluacion={tipoEvaluacion}
              key={id}
              id={id}
              asignatura={asignatura}
              docente={docente}
            />
           ))
          :
          <TableRow
            tipoEvaluacion={tipoEvaluacion}
            key={1}
            id={1}
          />
      }
      </tbody>
    </StyledTable>
  );
};

