import React from 'react';
import { ButtonContainer, Button } from './styles';

export default function ButtonExit({ isModalOpen, setModal,onClick }){
  return (
    <>
      <ButtonContainer>
        <Button onClick={onClick}>Salir</Button>
      </ButtonContainer>
    </>
  );
};
