import React, { useState, useEffect } from 'react'
import DataTable from '../../Components/DataTables'
import EditButton from '../../Components/Botones/EditButton'
import { getRequest } from '../../utils/requester'
import NewButton from '../../Components/Botones/NewButton'
import NewModal from '../../Components/Modal/NewModal'
import Grid from '@material-ui/core/Grid';
import Select from 'react-select';
import Cancel from '@material-ui/icons/Cancel';
import { Route, useHistory, Switch } from 'react-router-dom'

export default function Configurar({
  setEstrucutraCampos,
  setDataCampos,
  dataCampos,
  estructuraCampos
}){
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [modalNuevoCampo,setModalNuevoCampo] = useState(false)
  const [toDelete,setToDelete] = useState({})
  const [nueva,setNueva] = useState({
    nombre:'',
    tipo_dato:{label:'',value:''},
    opciones:[]
  })

  const headers = [
    'Nombre',
    'Tipo de dato',
    'Opciones',
    'Acciones'
  ]

  const opcionesTipo=[
    {
      label:'Selección unica',
      value:'Selección unica'
    },
    {
      label:'Selección multiple',
      value:'Selección multiple'
    },
    {
      label:'Texto',
      value:'Texto'
    },
    {
      label:'Fecha-Hora',
      value:'Fecha-Hora'
    },
    {
      label:'Archivo',
      value:'Archivo'
    },
  ]

  useEffect(() => {
    setNueva({
      nombre:'',
      tipo_dato:opcionesTipo[0],
      opciones:[]
    })
  }, [modalNuevoCampo])


  useEffect(()=>{
    let myDeleteData=[]
    estructuraCampos.forEach(estructura=>{
      if(estructura != toDelete){
        myDeleteData.push(estructura)
      }
    })
    setEstrucutraCampos(myDeleteData)
  },[toDelete])

  useEffect(()=>{
    let myData=[]
    estructuraCampos.forEach(estructura=>{
      myData.push([
        estructura.nombre,
        estructura.tipo_dato,
        estructura.opciones.join(),
        <button 
          onClick={()=>{
            setToDelete(estructura)
          }}
          >
          Eliminar
        </button>
        ])
    })
    setDataCampos(myData)
  },[estructuraCampos])

  function addToEstructura(){
    if(validarEstructura(nueva)){    
      let orden = estructuraCampos.length === 0 ? 1 : estructuraCampos[estructuraCampos.length-1].order+1
      setEstrucutraCampos([...estructuraCampos,{
        nombre:nueva.nombre,
        opciones:nueva.opciones,
        tipo_dato:nueva.tipo_dato.value,
        order:orden
      }])
      setModalNuevoCampo(false)
    }
  }

  function validarEstructura(body){
    let valido = true
    if (body.nombre === '') {
      document.getElementById('tag-nombre2').classList.add('error')
      valido = false
    } else {
      document.getElementById('tag-nombre2').classList.remove('error')
    }
    if (body.tipo_dato === '') {
      document.getElementById('tag-tipo2').classList.add('error')
      valido = false
    } else {
      document.getElementById('tag-tipo2').classList.remove('error')
    }
    if (body.tipo_dato.value.includes('Selecci')){
      if(body.opciones.length===0){
        document.getElementById('tag-opciones2').classList.add('error')
        valido = false
      }else{
        document.getElementById('tag-opciones2').classList.remove('error')
      }
    }else{
      document.getElementById('tag-opciones2').classList.remove('error')
    }
    return valido
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: '#616b74',
      fontSize: 14,
      fontWeight: 400,
      padding: '5px 25px',
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      userSelect: 'none',
      backgroundColor: '#fff',
      borderTop: '1px solid #ccc',
    }),
    control: (provided, state) => ({
      ...provided,
      minHeight: 0,
      height: '25px',
      borderRadius: 15,
      fontSize: 11,
      backgroundColor: '#f9f9f9',
      padding: 0,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      padding: 0,
      fontSize: 11,
      fontWeight: 400,
    }),
    menu: (provided, state) => ({
      ...provided,
      borderRadius: 0,
    }),
    input: (provided, state) => ({
      ...provided,
      padding: 0,
      fontSize: 11,
      fontWeight: 400,
      borderRadius: 0,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      top: '50%',
    }),
  };

  return (
    <>
    <div className="contenedor-tabs-cuestionarios">
      <div className='opciones-bar'>
        <div className='sub-filtro'>
          <NewButton onClick={()=>{setModalNuevoCampo(true)}} />
        </div>
      </div>
        <div className="cuestionarios-container">
          <DataTable 
            headers={headers}
            data={dataCampos}
            loading={false}
            paginate
          />
        </div>
    </div>
      <NewModal
        title='Nuevo Campo'
        open={modalNuevoCampo}
        handleClose={() => setModalNuevoCampo(false)}
        height={480}
        width={600}
        loading={false}
        handleSubmit={() => addToEstructura()}
        permiso="cuestionarios_etiquetas"
      >
        <div className='editar-catalogo-from'>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <label id="tag-nombre2">Nombre(*):</label>
            </Grid>
            <Grid item xs={10}>
              <input
                placeholder='Ingresa Nombre'
                value={nueva.nombre}
                onChange={(e)=>{
                  setNueva({
                    ...nueva,
                    nombre:e.target.value
                  })
                }}
                style={{textAlign:'center'}}
              />
            </Grid>
            <Grid item xs={2}>
              <label id="tag-tipo2">Tipo (*):</label>
            </Grid>
            <Grid item xs={10}>
              <Select
                value={nueva.tipo_dato}
                onChange={valores=>{
                  setNueva({
                    ...nueva,
                    tipo_dato:valores
                  })
                }}
                style={customStyles}
                options={opcionesTipo}
                className='select-search-box'
                classNamePrefix='select-search'
              />
            </Grid>
            <Grid item xs={2}>
              <label id="tag-opciones2">Opciones(*):</label>
            </Grid>   
            <Grid item xs={10}>
              <div className="contenedor-opciones-descripcion-catalogo">
                {
                  !nueva.tipo_dato.value.includes('Selecci') ?
                  <>
                  </>
                  :
                  nueva.opciones.map((opcion,i)=>(
                  <div className="contenedor-una-opcion">
                    <input
                      disabled={nueva.tipo_dato.value=='texto'}
                      value={opcion}
                      onChange={(e) =>{
                        if(!e.target.value.includes(',')){                        
                            let misOpciones=[...nueva.opciones]
                            misOpciones[i]=e.target.value
                            setNueva({
                              ...nueva,
                              opciones: misOpciones
                            })
                        }
                        }
                      }
                      />
                      <button 
                        disabled={nueva.tipo_dato.value=='texto'}
                        onClick={()=>{
                          let eliminarOpcionArray=nueva.opciones.filter((opc,j)=>{
                            return (j != i)
                          })
                          setNueva({...nueva,opciones:eliminarOpcionArray})
                        }}
                        className="eliminar-opcion-descripcion"
                        ><Cancel/></button>
                    </div>  
                  ))
                }
                {
                  nueva.tipo_dato.value === 'texto' ?
                  <>
                  </>:
                  <button
                      disabled={nueva.tipo_dato.value=='texto'}
                      onClick={()=>{setNueva({...nueva,opciones:[...nueva.opciones,""]})}}
                    >Añadir Opción</button>
                }
              </div>
            </Grid>   
          </Grid>
        </div>
      </NewModal>
    </>
  )
}