/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Select from 'react-select'
import { toast } from 'react-toastify'
import moment from 'moment'

import Contenedor from '../../Components/Contenedor/Contenedor'
import NewButton from '../../Components/Botones/NewButton'
import EditButton from '../../Components/Botones/EditButton'
import DataTable from '../../Components/DataTables'
import NewModal from '../../Components/Modal/NewModal'
import { getRequest, postRequest } from '../../utils/requester'

import './styles.css'

function CreadoEvaluacion() {
  const [dataInicial, setDataInicial] = useState([])
  const [dataFiltradas, setDataFiltradas] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [loadingModalEditar, setLoadingModalEditar] = useState(false)
  const [modalEditarIsOpen, setModalEditarIsOpen] = useState(false)
  const [modalNuevaIsOpen, setModalNuevaIsOpen] = useState(false)
  const [nombreEvaluacion, setNombreEvaluacion] = useState('')
  const [editar, setEditar] = useState({
    id: '',
    cycle: '',
    period: '',
  })

  const [isCalendar, setIsCalendar] = useState(true)
  const [deleteModal, setDeleteModal] = useState(false)
  const [, setCicloPeriodoValue] = useState('')
  const [deleteModalLoading, setDeleteModalLoading] = useState(false)
  const [nivelValue, setNivelValue] = useState('')
  const [partePeriodoValue, setPartePeriodoValue] = useState([])
  const [estatusValue, setEstatusValue] = useState('')
  const [modalidadValue, setModalidadValue] = useState('')
  const [year, setYear] = useState()
  const [opcionesNivel, setOpcionesNivel] = useState([])
  const [opcionesPartePeriodo, setOpcionesPartePeriodo] = useState([])
  const [opcionesEvaluaciones, setOpcionesEvaluaciones] = useState([])
  const [, setOpcionesCicloPeriodo] = useState([])
  const [evaluationStartDate, setEvaluationStartDate] = useState('')
  const [evaluationEndDate, setEvaluationEndDate] = useState('')
  const [evaluationStartStructure, setEvaluationStartStructure] = useState('')
  const [evaluacionesValue, setEvaluacionesValue] = useState({})
  const [menuPosition, setMenuPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
  })
  const [inputDisabled, setInputDisabled] = useState(false)
  const [isEvaluationStarted, setIsEvaluationStarted] = useState(false)

  const years = [
    {
      label: '2021',
      value: '2021',
    },
    {
      label: '2022',
      value: '2022',
    },
    {
      label: '2023',
      value: '2023',
    },
    {
      label: '2024',
      value: '2024',
    },
    {
      label: '2025',
      value: '2025',
    },
    {
      label: '2026',
      value: '2026',
    },
    {
      label: '2027',
      value: '2027',
    },
    {
      label: '2028',
      value: '2028',
    },
  ]

  const opcionesEstatus = [
    {
      label: 'Activo',
      value: 'Activo',
    },
    {
      label: 'Suspendido',
      value: 'Suspendido',
    },
  ]

  const opcionesModalidad = [
    {
      label: 'TR',
      value: 'TR',
    },
    {
      label: 'ONLN',
      value: 'ONLN',
    },
  ]

  const openEditModal = async ({
    id,
    cycle,
    period,
    level,
    parte_periodo,
    modalidad,
    name,
    fecha_fin_evaluacion,
    fecha_inicio_estrucutra,
    fecha_inicio_evaluacion,
    status,
    id_integral_evaluation,
  }) => {
    setModalEditarIsOpen(true)
    setLoadingModalEditar(true)

    setEditar({
      id,
      cycle,
      period,
    })

    setCicloPeriodoValue({
      label: cycle + '-' + period,
      value: cycle + '-' + period,
    })
    setNivelValue({
      label: level,
      value: level,
    })
    setNombreEvaluacion(name)

    const newPartePeriodo = parte_periodo.split(',').map((item) => ({
      label: item,
      value: item,
    }))
    setPartePeriodoValue(newPartePeriodo)

    setEstatusValue({
      label: status,
      value: status,
    })

    setModalidadValue({
      label: modalidad,
      value: modalidad,
    })
    if (fecha_inicio_evaluacion !== null) {
      setIsEvaluationStarted(new Date() > new Date(fecha_inicio_evaluacion))
      setEvaluationStartDate(
        moment(fecha_inicio_evaluacion).format('YYYY-MM-DDTHH:mm')
      )
    } else {
      setIsEvaluationStarted(false)
      // Get the actual date
      setEvaluationStartDate('')
    }

    if (fecha_fin_evaluacion !== null) {
      setEvaluationEndDate(
        moment(fecha_fin_evaluacion).format('YYYY-MM-DDTHH:mm')
      )
    } else {
      // Get the actual date
      setEvaluationEndDate('')
    }

    if (fecha_inicio_estrucutra !== null) {
      setEvaluationStartStructure(
        moment(fecha_inicio_estrucutra).format('YYYY-MM-DDTHH:mm')
      )
    } else {
      // Get the actual date
      setEvaluationStartStructure('')
    }
    getOpcionesPartePeriodo(level)
    getNiveles()
    const evaluaciones = await getEvaluation()
    if (id_integral_evaluation !== null) {
      const evaluacion = evaluaciones.find(
        (item) => item.id === id_integral_evaluation
      )
      if (evaluacion) {
        setInputDisabled(!evaluacion.status_check)
        setEvaluacionesValue({
          label: evaluacion.label,
          value: evaluacion.value,
          id: evaluacion.id,
        })
      } else {
        setInputDisabled(false)
        setEvaluacionesValue({
          label: 'Sin Evaluación Integral',
          value: 'Sin Evaluación Integral',
          id: null,
        })
      }
    } else {
      setInputDisabled(false)
      setEvaluacionesValue({
        label: 'Sin Evaluación Integral',
        value: 'Sin Evaluación Integral',
        id: null,
      })
    }
    setLoadingModalEditar(false)
  }

  // Get the evaluations
  const getEvaluation = async () => {
    try {
      if (!opcionesEvaluaciones.length) {
        const response = await getRequest('/evaluacion')
        if (response.s === 'OK') {
          let evaluaciones = [
            {
              label: 'Sin Evaluación Integral',
              value: 'Sin Evaluación Integral',
              id: null,
            },
          ]
          response.d.length > 0 &&
            response.d.forEach((item) => {
              evaluaciones = [
                ...evaluaciones,
                {
                  label: item.clave_evaluacion,
                  value: item.clave_evaluacion,
                  id: item.id,
                  status_check: item.status_check,
                },
              ]
            })
          setOpcionesEvaluaciones(
            evaluaciones.filter((evaluacion) => evaluacion.status_check)
          )
          return evaluaciones
        }
      } else {
        return opcionesEvaluaciones
      }
    } catch (error) {
      console.error(error)
    }
  }
  // Get the levels
  const getNiveles = async () => {
    try {
      if (!opcionesNivel.length) {
        const response = await getRequest('/calendario/nivel')
        if (response.s === 'OK') {
          let niveles = []
          response.d.length > 0 &&
            response.d.forEach((item) => {
              niveles = [
                ...niveles,
                { label: item.nombre, value: item.nombre, id: item.id },
              ]
            })
          setOpcionesNivel(niveles)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }
  // Get the period
  const getOpcionesPartePeriodo = async (nivel) => {
    try {
      if (!opcionesPartePeriodo.length) {
        const response = await getRequest(
          `/calendario/parte_periodo?nivel=${nivel}`
        )
        if (response.s === 'OK') {
          let options = []
          response.d.length > 0 &&
            response.d.forEach((option) => {
              options = [...options, { label: option, value: option }]
            })
          setOpcionesPartePeriodo(options)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  // Get the cycle period
  const getOpcionesCicloPeriodo = async () => {
    try {
      const response = await getRequest('/calendario/ciclo_periodo')
      if (response.s === 'OK') {
        let options = []
        response.d.length > 0 &&
          response.d.forEach((option) => {
            options = [
              ...options,
              {
                label: option.ciclo + '-' + option.periodo,
                value: option.ciclo + '-' + option.periodo,
                id: option.id,
              },
            ]
          })
        setOpcionesCicloPeriodo(options)
      }
    } catch (error) {
      console.error(error)
    }
  }

  // Edit the Calendar
  const handleEditar = async (data) => {
    if (validar(data)) {
      setLoadingModalEditar(true)
      try {
        let parte_periodo_str = partePeriodoValue
          .map((item) => item.value)
          .toString()

        const calendar = {
          id: data.id,
          name: nombreEvaluacion,
          cycle: editar.cycle,
          period: parseInt(editar.period),
          year: parseInt(year.value),
          parte_periodo: parte_periodo_str,
          level: nivelValue.value,
          modalidad: modalidadValue.value,
          fecha_inicio_evaluacion:
            moment(evaluationStartDate).format('YYYY-MM-DD HH:mm'),
          fecha_fin_evaluacion:
            moment(evaluationEndDate).format('YYYY-MM-DD HH:mm'),
          fecha_inicio_estrucutra: moment(evaluationStartStructure).format(
            'YYYY-MM-DD HH:mm'
          ),
          id_integral_evaluation: evaluacionesValue.id,
        }

        const response = await postRequest('/calendario/editar', calendar)
        if (response.s === 'OK') {
          toast.success(response.m)
          setModalEditarIsOpen(false)
          setLoadingModalEditar(false)
          reloadList()
        } else {
          setLoadingModalEditar(false)
        }

        setLoadingModalEditar(false)
      } catch (error) {
        console.error(error)
        setLoadingModalEditar(false)
        toast.error('Ocurrió un error inesperado.')
      }
    }
  }

  const validar = (body) => {
    let valido = true
    if (body.nombre === '') {
      document.getElementById('tag-nombre').classList.add('error')
      valido = false
    } else {
      document.getElementById('tag-nombre').classList.remove('error')
    }
    if (body.descripcion === '') {
      document.getElementById('tag-descripcion').classList.add('error')
      valido = false
    } else {
      document.getElementById('tag-descripcion').classList.remove('error')
    }
    return valido
  }

  const handleDelete = async () => {
    setDeleteModalLoading(true)
    try {
      const eliminarResponse = eliminar()
      if (eliminarResponse.s === 'OK') {
        setModalEditarIsOpen(false)
        setDeleteModalLoading(false)
        reloadList()
        setDeleteModal(false)
      }
      setDeleteModal(false)
      setDeleteModalLoading(false)
    } catch (error) {
      console.error(error)
      setDeleteModal(false)
      setDeleteModalLoading(false)
    }
  }

  const testHeader = [
    'Folio',
    'Ciclo',
    'Periodo',
    'Nivel',
    'Modalidad',
    'Nombre Evaluación',
    'Fecha Inicio Estructura',
    'Fecha Inicio Evaluación',
    'Fecha Fin Evaluación',
    'Acciones',
  ]

  const parseDate = (date) => {
    if (date) {
      const parsedDate = new Date(date).toLocaleDateString('es-MX')
      return parsedDate
    }
    return ''
  }

  ////////// Filtrar resultados segun el input de busqueda //////////
  const search = (e) => {
    let text = e.target.value.toLowerCase()

    let result = dataInicial.filter((row) => {
      if (JSON.stringify(row).toLowerCase().includes(text)) {
        return true
      }
      return false
    })
    setDataFiltradas(result)
  }

  const reloadList = async () => {
    setLoading(true)
    let data = []
    try {
      const response = await getRequest(`/calendario?year=${year.value}`)
      if (response.s === 'OK') {
        setIsCalendar(true)
        response.d.forEach((reg) => {
          const fecha_inicio_estrucutra = parseDate(reg.fecha_inicio_estrucutra)
          const fecha_inicio_evaluacion = parseDate(reg.fecha_inicio_evaluacion)
          const fecha_fin_evaluacion = parseDate(reg.fecha_fin_evaluacion)
          data.push([
            reg.id,
            reg.cycle,
            reg.period,
            reg.level,
            reg.modalidad,
            reg.name,
            fecha_inicio_estrucutra,
            fecha_inicio_evaluacion,
            fecha_fin_evaluacion,
            <EditButton onClick={() => openEditModal(reg)}>Editar</EditButton>,
          ])
        })
        setLoading(false)
      } else {
        setLoading(false)
        setIsCalendar(false)
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
    setDataInicial(data)
    setDataFiltradas(data)
  }

  const handleCreateCalendar = async (year) => {
    setLoading(true)
    setLoadingModal(true)
    try {
      const response = await postRequest('/calendario/nuevo', {
        year: parseInt(year),
      })
      if (response.s === 'OK') {
        setLoadingModal(false)
        setModalNuevaIsOpen(false)
        reloadList()
        setLoading(false)
      } else {
        setLoadingModal(false)
        setModalNuevaIsOpen(false)
        setLoading(false)
      }
    } catch (error) {
      setLoadingModal(false)
      setModalNuevaIsOpen(false)
      console.log(error)
      toast.error('Ocurrió un error inesperado.')
    }
  }

  const handleChangeEvaluation = ({ id }) => {
    const evalucion = opcionesEvaluaciones.find((item) => item.id === id)
    setEvaluacionesValue({
      label: evalucion.label,
      value: evalucion.value,
      id: evalucion.id,
    })
  }

  const handleChangeNivel = ({ value }) => {
    setNivelValue({
      label: value,
      value,
    })
    getOpcionesPartePeriodo(value)
    setPartePeriodoValue([])
  }

  const handleChangePartePeriodo = (items) => {
    if (items.length) {
      setPartePeriodoValue(items)
    }
  }

  const handleChangeEstatus = ({ value }) => {
    setEstatusValue({
      label: value,
      value,
    })
  }

  const handleChangeModalidad = ({ value }) => {
    setModalidadValue({
      label: value,
      value: value,
    })
  }

  const handleChangeStartDate = (date) => {
    setEvaluationStartDate(date)
  }

  const handleChangeEndDate = (date) => {
    setEvaluationEndDate(date)
  }

  const handleChangeStartStructure = (date) => {
    setEvaluationStartStructure(date)
  }
  useEffect(() => {
    const actualYear = new Date().getFullYear()
    setYear({
      label: String(actualYear),
      value: String(actualYear),
    })
  },[])
  useEffect(() => {
    reloadList()
    getOpcionesCicloPeriodo()
  }, [year])

  const getMenuPosition = () => {
    const element = document.getElementById('select-evaluacion')
    const label = document.getElementById('tag-nombre')
    if (element) {
      const rect = element.getBoundingClientRect()
      setMenuPosition({
        top: rect.bottom,
        left: rect.left,
        width: label.offsetWidth,
      })
    }
  }

  const customStyles = {
    option: (provided, _state) => ({
      ...provided,
      color: '#616b74',
      fontSize: 14,
      fontWeight: 400,
      padding: '5px 25px',
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      userSelect: 'none',
      backgroundColor: '#fff',
      borderTop: '1px solid #ccc',
    }),
    control: (provided, _state) => ({
      ...provided,
      minHeight: '25px',
      height: 'auto',
      borderRadius: 15,
      fontSize: 11,
      backgroundColor: '#f9f9f9',
      padding: 0,
    }),
    placeholder: (provided, _state) => ({
      ...provided,
      padding: 0,
      fontSize: 11,
      fontWeight: 400,
    }),
    menu: (provided, _state) => ({
      ...provided,
      borderRadius: 0,
    }),
    input: (provided, _state) => ({
      ...provided,
      padding: 0,
      fontSize: 11,
      fontWeight: 400,
      borderRadius: 0,
    }),
    singleValue: (provided, _state) => ({
      ...provided,
      top: '50%',
    }),
  }

  return (
    <Contenedor title='Creado de Evaluación Año'>
      <div className='opciones-bar'>
        <div className='sub-filtro'>
          <p style={{ marginBottom: '6px' }}>Seleccionar Año:</p>
          <Select
            options={years}
            className='select-period'
            classNamePrefix='select-search'
            value={year}
            onChange={setYear}
          />
        </div>
        {/* If calendar = false create de new button */}
        {!isCalendar ? (
          <div style={{ marginLeft: '10px' }} className='sub-filtro'>
            <NewButton
              onClick={() => {
                setCicloPeriodoValue('')
                setModalNuevaIsOpen(true)
              }}
            />
          </div>
        ) : null}
        <div style={{ marginLeft: 'auto' }}>
          <input
            type='search'
            placeholder='Buscar'
            style={{
              marginTop: '-20px',
              height: '30px',
              position: 'initial',
            }}
            onChange={search}
            className='SearchInput-Calendario'
          />
        </div>
      </div>
      <div style={{ height: 'calc(100% - 70px)' }}>
        <DataTable
          headers={testHeader}
          data={dataFiltradas}
          loading={loading}
          paginate
        />
      </div>
      <NewModal
        title='Editar Calendario'
        canDelete
        open={modalEditarIsOpen}
        handleClose={() => setModalEditarIsOpen(false)}
        height={550}
        loading={loadingModalEditar}
        handleSubmit={() => handleEditar(editar)}
        onDelete={() => setDeleteModal(true)}
        permiso='calendario_evaluacion-año'
      >
        <div className='nuevoEtiquetaForm' style={{ 'overflow-x': 'hidden' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <label id='tag-descripcion'>Nombre</label>
              <div
                className='inputs-container'
                style={{ 'grid-template-columns': 'none' }}
              >
                <input disabled placeholder='' value={nombreEvaluacion} />
              </div>
            </Grid>
            <Grid item xs={12}>
              <label id='tag-nombre'>Ciclo (*)</label>
              <div
                className='inputs-container'
                style={{ 'grid-template-columns': 'none' }}
              >
                <input
                  placeholder=''
                  value={editar.cycle}
                  onChange={(e) =>
                    setEditar({ ...editar, cycle: e.target.value })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <label id='tag-nombre'>Periodo (*)</label>
              <div
                className='inputs-container'
                style={{ 'grid-template-columns': 'none' }}
              >
                <input
                  placeholder=''
                  value={editar.period}
                  onChange={(e) =>
                    setEditar({ ...editar, period: e.target.value })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <label id='tag-nombre'>Nivel (*)</label>
              <Select
                options={opcionesNivel}
                value={nivelValue}
                styles={customStyles}
                name='etiqueta'
                placeholder='Selección...'
                className='select-search-box'
                onChange={handleChangeNivel}
              />
            </Grid>
            <Grid item xs={12}>
              <label id='tag-nombre'>Parte Periodo (*)</label>
              <Select
                options={opcionesPartePeriodo}
                isMulti
                value={partePeriodoValue}
                styles={customStyles}
                name='etiqueta'
                placeholder='Selección...'
                className='select-search-box'
                onChange={handleChangePartePeriodo}
              />
            </Grid>
            <Grid item xs={12}>
              <label id='tag-nombre'>Modalidad (*)</label>
              <Select
                options={opcionesModalidad}
                value={modalidadValue}
                styles={customStyles}
                name='etiqueta'
                placeholder='Selección...'
                className='select-search-box'
                onChange={handleChangeModalidad}
              />
            </Grid>
            <Grid item xs={12}>
              <label id='tag-nombre'>
                Fecha inicio creado de Estructura (*)
              </label>
              <TextField
                className='selector-fecha'
                type='datetime-local'
                defaultValue={evaluationStartStructure}
                onChange={(e) => {
                  handleChangeStartStructure(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <label id='tag-nombre'>Fecha inicio de Evaluación (*)</label>
              <TextField
                className='selector-fecha'
                type='datetime-local'
                defaultValue={evaluationStartDate}
                onChange={(e) => {
                  handleChangeStartDate(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <label id='tag-nombre'>Fecha fin de Evaluación (*)</label>
              <TextField
                className='selector-fecha'
                type='datetime-local'
                defaultValue={evaluationEndDate}
                onChange={(e) => {
                  handleChangeEndDate(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <label id='tag-nombre'>Evaluación Integral (*)</label>
              <Select
                options={opcionesEvaluaciones}
                value={evaluacionesValue}
                styles={customStyles}
                name='etiqueta'
                placeholder='Selección...'
                className='select-search-box'
                onChange={handleChangeEvaluation}
                id='select-evaluacion'
                onFocus={getMenuPosition}
                isDisabled={inputDisabled || isEvaluationStarted}
              />
            </Grid>
            <Grid item xs={12}>
              <label id='tag-nombre'>Estatus (*)</label>
              <Select
                options={opcionesEstatus}
                value={estatusValue}
                styles={customStyles}
                name='etiqueta'
                placeholder='Selección...'
                className='select-search-box'
                onChange={handleChangeEstatus}
              />
            </Grid>
          </Grid>
        </div>
      </NewModal>
      <NewModal
        open={modalNuevaIsOpen}
        height={120}
        title='Confirmar'
        loading={loadingModal}
        handleClose={() => setModalNuevaIsOpen(false)}
        handleSubmit={() => {
          handleCreateCalendar(year.value)
        }}
      >
        <div className='delete-confirm'>
          <h2 style={{ textAlign: 'center' }}>
            ¿Está seguro que deseas crear un calendario en el año {year?.value}?
          </h2>
        </div>
      </NewModal>
      <NewModal
        open={deleteModal}
        height={120}
        title='Confirmar'
        loading={deleteModalLoading}
        handleClose={() => setDeleteModal(false)}
        handleSubmit={handleDelete}
      >
        <div class='delete-confirm'>
          <h2 style={{ textAlign: 'center' }}>
            ¿Está seguro de que desea eliminar este Ciclo-Periodo?
          </h2>
        </div>
      </NewModal>
    </Contenedor>
  )
}

export default CreadoEvaluacion

function eliminar() {
  return { s: 'OK', m: 'etiqueta eliminada correctamente', d: '' }
}
