import React from 'react'
import Grid from '@material-ui/core/Grid'
import NewModal from '../../Components/Modal/NewModal'
import ContenedorFranja from '../../Components/ContenedorFranja'

export default function DetalleAsignacion({
  modalDetalleAsignacion,
  setModalDetalleAsignacion,
  loadingDetalleAsignacion,
  detalleAsignacion,
}) {
  return (
    <NewModal
      title='Detalle Asignación'
      open={modalDetalleAsignacion}
      handleClose={() => setModalDetalleAsignacion(false)}
      height={600}
      loading={loadingDetalleAsignacion}
      handleSubmit={() => {
        setModalDetalleAsignacion(false)
      }}
      noCancel
      buttonAcceptText='Cerrar'
    >
      <Grid container className='contenedor-info-detalle-asignacion'>
        <Grid xs={8}>
          <p>
            <span className='campo-informacion'>Nombre de materia:</span>
            {detalleAsignacion.detalle.nombre_asignacion}
          </p>
        </Grid>
        <Grid xs={4}>
          <p>
            <span className='campo-informacion'>Campus:</span>
            {detalleAsignacion.detalle.campus}
          </p>
        </Grid>
        <Grid xs={4}>
          <p>
            <span className='campo-informacion'>CRN:</span>
            {detalleAsignacion.detalle.crn}
          </p>
        </Grid>
        <Grid xs={4}>
          <p>
            <span className='campo-informacion'>Nivel:</span>
            {detalleAsignacion.detalle.nivel}
          </p>
        </Grid>
        <Grid xs={4}>
          <p>
            <span className='campo-informacion'>Periodo:</span>
            {detalleAsignacion.detalle.periodo}
          </p>
        </Grid>
        <Grid xs={4}>
          <p>
            <span className='campo-informacion'>Nombre Calendario:</span>
            {detalleAsignacion.detalle.nombre_calendario}
          </p>
        </Grid>
      </Grid>
      <Grid container className='contenedor-header-detalle-asignacion'>
        <Grid xs={5}>
          <h2 style={{ marginLeft: '20px' }}>Resultado De Evaluaciones</h2>
          <h2 style={{ marginLeft: '20px' }}>Puntos de Reconocimiento</h2>
        </Grid>
        <Grid xs={7}>
          <h2 style={{ textAlign: 'center' }}>
            <span>{detalleAsignacion.detalle.resultados.escala_5}</span>
          </h2>
          <h2 style={{ textAlign: 'center' }}>
            <span>
              {detalleAsignacion.detalle.resultados.puntos_reconocimiento}
            </span>
          </h2>
        </Grid>
      </Grid>
      <div className='contenedor-reporte-detalle-asignacion'>
        {detalleAsignacion.dimensiones.map((dimension) => (
          <ContenedorFranja
            titulo={dimension.nombre_dimension}
            resultados={dimension.resultados}
          />
        ))}
      </div>
    </NewModal>
  )
}
