import React from 'react'
import './index.css'
import PageTitle from '../PageTitle'
export default function SubContenedor(props) {
  return (
    <div className='sub-main'>
      <PageTitle title={props.title} />
      <div
        className='sub-contenedor'
        style={props.noScroll && { overflow: 'hidden' }}
      >
        {props.children}
      </div>
    </div>
  )
}
