import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import './style.css'

function Contenedor({ titulo, resultados }) {
  return (
    <Grid container className='cotenedor-franja'>
      <Grid className='franja-titulo' xs={1}>
        <p className='titulo-contenedor-franja'>{titulo}</p>
      </Grid>
      <Grid className='contenedor-registros-franja' xs={11}>
        {resultados.map((resultado) => (
          <p style={{ height: '19px' }}>
            {resultado.nombre}
            {resultado.resultado.map((numero) => (
              <span
                className='contenedor-numero-registro'
                style={{ height: '100%' }}
              >
                {numero.toString() === '0' ? ' ' : numero}
              </span>
            ))}
          </p>
        ))}
      </Grid>
    </Grid>
  )
}

Contenedor.propTypes = {
  titulos: PropTypes.string,
  resultados: PropTypes.arrayOf(PropTypes.any),
}

export default Contenedor
