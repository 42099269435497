import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 25,
    borderRadius: 15,
    marginTop: 0,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 15,
    background: 'linear-gradient(to right, #c50200, red)',
  },
}))(LinearProgress)

export default BorderLinearProgress
