import React from "react";

const UsuariosIcon = () => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 172 175" enable-background="new 0 0 172 175" space="preserve">
            <image id="image0" width="172" height="175" x="0" y="0"
                href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKwAAACvCAQAAADKrP39AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
            AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZ
            cwAACxIAAAsSAdLdfvwAAAAHdElNRQflAgMWNhKAJuPgAAAGV0lEQVR42u2d7XWjRhSGX+fkv+jA
            pAKTCkwqWFKB2QpCOtB2oFSwuILVVhBUwaIKFnUgVUB++EuWbEd3mHvfAebhn8/AcJ9zfWEGNFz1
            iGjwC/sEpkoUq0QUq0QUq0QUq0QUq0QUq0QUq8Sv7BP4gAw5MqS4Pfn7Dh0atGiwZ5/i+1wFOfIq
            UKDA4n/bbVFjjY59um/Sh7Wl/arf9zKavqCf99lGP4FXUmuh0he60OTST+B5W4oz9TxzM3oUgYnN
            +nag1CeW9FgetxAuXiW+ejzaBkUIdwt8sTXuPB/xgBwtOyz2AMG/VmCBBhk5LnLGrvFJ6cj0rGVm
            bK2mNYCs5YldKRSBYxZokNKio5WCAt8MetnyspaTsSlqk35usKLEB1bGNmczVnr8gYYRIiNjS0Ot
            MPrfOMNebIKlaX/XqMxjBENshWvjHpdIzKOkiLVmgdK8T3Ox5QXPBfxT2XdpLZYQIoBrFNZd2opN
            cWMd4COFdYe2Ys3D4/U8F7EL5LYd2oq1HBicktt2ZynWODRu75ZiM9vQuL1bik1sQzvB+P55PqXA
            uH/2w8TJEsUqEcUqEcUqEcUqEcUqEcUqYSm2Icdq2r+l2L1lYGfsbLuzFNvahnZCZ9vdfEqBce+2
            F6+NbXCvaGy7sxW7tg3uiEMUO5GebcV2tGJQW3doPUAwDxAAsLO/cNqLNb6fBADj1/AAMIa09kHu
            GP8n9mLtc3ZpHiM4kzCVaW9bTl2f/qvyv3OG0pxpwwIHo56+sGYo4s+RlGBNdK/xRb2PA/NNBt4T
            hCXuVY9/QM6cAWY+mikV1c76R8p6aula2WKBEv94P+aOr5W9XsEDJVYe3wUMZOkSdsYCQI3M03Ti
            AX9zL1lHsFf7ed4qD8tDpfQonjf6CRxtyYC1t5o+p5//qy2EGntMggql8Ne237GivwxyRmhiHyhQ
            IL9A7wbrUBeNDFPsA9njlp4o3mCPFg3aQC5TbxKy2FET8sK8T6RnqxF1Yf77HxOm2PSxCCQfTog/
            lIQWbYiawyoFKQrkyMXjsB0aNGhCEhyK2AwlisGLmgS0tDRfbILS8zoxW6ywpt8xUMcn2YDFoz9m
            39fcAS6v67xvlKS+QJTLylR9qWS59l0mav/+77PqE+s4rS9eFZaUBaIOKG3fkbUUm6KmLl7yHaXd
            vYLdE4QKLVUr8Amd3XI/NhmbqC4bLeMelUXeWojNUNMWMnuLLQr90Zm+2AI15XL1EQbvHWjX2BLf
            gtMKLPBDe4VOXbG114+d+OWr7gvQmqVA46scfrnXy1u9jA1fK3Cn9xq9ltgxaAUU1eqIHYtWALjT
            +VWNRo1d4S9lGb757D9v/Yv1+9kzK/70PUXjW2yOf81k+MT7kMGv2BRtgMOBy9gh8zmH4PfitR6t
            VuDabzHwKXYV1FSLnFuf9wf+SoHNT+K08fYtJV9iE3QjLgMveKu0vkpBeFODblz7Kgd+MnYaZeAJ
            L+XAh9iplIEndj4+YumjFPj8lVYIeCkHwzM2ww+2Ce8ckA19KjY8Y1dsCwoshufs0Iyd1mXrmIGX
            sKFiO/MvIFqxGbaMxLBSIP2p25i4HSZ2WMZON1+BgTk7JGOnnK/AwJwdJnbqVO67upeCsT4rkPGb
            6/2se8aW7JhNqFx3dM3YFD/ZMZtwQOo2jeiasQU7YiMWrpG6Zuy0b7SOcbzpchM7xYmX93G6gLmV
            gpIdqymFy05uGTufQgA4runpkrHZrLQCNy5PFFzEluxIzSnku7iIzdlxmlPId5HX2LkMDV5zJd1B
            nrE5O0YKhXSHKFYp6ij2MjLpDtIaO88KC4irrDRjM3Z8NHJZ8yhWKXKp2JwdH41M1lwqNmXHR0MY
            ufTiRV//jIjo8iXL2JwdG5VU0lgmNmHHRiWVNJaJzdixUUkljWPGXk4qaRwz9nISSeMQvtwxFjJJ
            Y5nYlB3beJCJndezrkHIBghzHh4AoiFCrLFKRLFKRLFKSMQm7JMdEzFjlYh3BRLiXQGfKFaJKFaJ
            KPZydpLGUezldJLGMrF+Pss7C2LGXs5e0lgmVnToydFKGsvEig49b2LGXk4jaRwzVgnZXMF8344F
            VF8x6tixEREND+S3W/O9k+1kzaVihYefEI2suVRsy46PhjBy/gd/J0oc0ioRxSoRxSoRxSoRxSoR
            xSoRxSrxH3dIVniBwaqfAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTAyLTAzVDIyOjU0OjE4KzAz
            OjAwUrYs+AAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0wMi0wM1QyMjo1NDoxOCswMzowMCPrlEQA
            AAAASUVORK5CYII=" />
        </svg>
    )
}

export default UsuariosIcon;