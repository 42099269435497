import React from 'react'
import './style.css'
import { connect } from 'react-redux'
import AyudaButton from '../Botones/AyudaButton'
function Footer({ version }) {
  return (
    <>
      <AyudaButton />
      <footer>
        <span>V{version}</span>
        <span>Powered by Jalisoft S de RL de CV</span>
      </footer>
    </>
  )
}

const mapStateToProps = (state) => ({
  version: state.version,
})

export default connect(mapStateToProps)(Footer)
