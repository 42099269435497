/* eslint-disable eqeqeq */
/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Grid, CircularProgress } from '@material-ui/core'
import Select from 'react-select'

import NewButton from '../../Components/Botones/NewButton'
import NewModal from '../../Components/NewModal'
import { getRequest } from '../../utils/requester'
import CancelIcon from '@material-ui/icons/Cancel'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { toast } from 'react-toastify'
//import Can from '../../Utils/Funciones/can';
import './style.css'

export default function NuevaEvaluacion({
  title,
  open,
  handleClose,
  loading,
  setLoadingModal,
  handleSubmit,
  nueva,
  setNueva,
  canDelete,
  editable,
  copiar,
  showCopyButton,
  isCopia,
}) {
  const [opcionesCategorias, setOpcionesCategorias] = useState([])
  const [categoria, setCategoria] = useState('')
  const [loadingDimensiones, setLoadingDimensiones] = useState(false)
  const [dimensiones, setDimensiones] = useState([])
  const [modalDescripcion, setModalDescripcion] = useState(false)
  const [dimensionesComunes, setDimensionesComunes] = useState([])
  const [dimensionesEspecifica, setDimensionesEspecifica] = useState([])
  const [dimensionesReconocimiento, setDimensionesReconocimiento] = useState([])
  const [dimensionesSinFiltro, setDimensionesSinFiltro] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)

  const [dimensionDrag, setDimensionDrag] = useState({})

  useEffect(() => {
    if (!editable && !isCopia) {
      setDimensionesComunes([])
      setDimensionesEspecifica([])
      setDimensionesReconocimiento([])
    } else {
      let misDimensionesComunes = []
      let misDImensionesEspecifica = []
      let misDimensionesReconocimiento = []

      nueva.dimensiones.forEach((reg) => {
        switch (reg.tipo_dimension) {
          case 'Común':
            misDimensionesComunes.push({
              key: reg.clave_dimension,
              id: reg.id_DIMENSION,
              name: reg.nombre_dimension,
              type: reg.tipo_dimension,
            })
            break
          case 'Específica':
            misDImensionesEspecifica.push({
              key: reg.clave_dimension,
              id: reg.id_DIMENSION,
              name: reg.nombre_dimension,
              type: reg.tipo_dimension,
            })
            break
          case 'Reconocimiento':
            misDimensionesReconocimiento.push({
              key: reg.clave_dimension,
              id: reg.id_DIMENSION,
              name: reg.nombre_dimension,
              type: reg.tipo_dimension,
            })
            break
        }
      })
      setDimensionesComunes(misDimensionesComunes)
      setDimensionesEspecifica(misDImensionesEspecifica)
      setDimensionesReconocimiento(misDimensionesReconocimiento)
    }
    if (open) {
      getCategorias()
    }
  }, [open])

  useEffect(() => {
    if (categoria.value != undefined) {
      getDimensiones()
    }
  }, [categoria])

  useEffect(() => {
    let misDimensionesFiltradas = []
    dimensionesSinFiltro.forEach((dimension) => {
      let incluida = false
      dimensionesComunes.forEach((dimensionComun) => {
        if (dimensionComun.id == dimension.id) {
          incluida = true
        }
      })
      dimensionesEspecifica.forEach((dimensionEspecifica) => {
        if (dimensionEspecifica.id == dimension.id) {
          incluida = true
        }
      })
      dimensionesReconocimiento.forEach((dimensionReconocimiento) => {
        if (dimensionReconocimiento.id == dimension.id) {
          incluida = true
        }
      })
      if (!incluida) {
        misDimensionesFiltradas.push(dimension)
      }
    })
    setDimensiones(misDimensionesFiltradas)
  }, [dimensionesComunes, dimensionesEspecifica, dimensionesReconocimiento])

  async function getCategorias() {
    setLoadingModal(true)
    try {
      const response = await getRequest('/evaluacion/categoria')
      if (response.s === 'OK') {
        let misOpciones = []
        response.d.forEach((reg) => {
          misOpciones.push({
            label: reg.nombre,
            name: reg.nombre,
            value: reg.nombre,
          })
        })
        setOpcionesCategorias(misOpciones)
        setCategoria(misOpciones[0])
        setLoadingModal(false)
      } else {
        setLoadingModal(false)
      }
    } catch (error) {
      console.error(error)
      setLoadingModal(false)
    }
  }

  async function getDimensiones() {
    setLoadingDimensiones(true)
    try {
      const response = await getRequest(
        '/evaluacion/dimensiones?categoria=' + categoria.value
      )
      if (response.s === 'OK') {
        let misDimensionesFiltradas = []
        response.d.forEach((dimension) => {
          let incluida = false
          dimensionesComunes.forEach((dimensionComun) => {
            if (dimensionComun.id == dimension.id) {
              incluida = true
            }
          })
          dimensionesEspecifica.forEach((dimensionEspecifica) => {
            if (dimensionEspecifica.id == dimension.id) {
              incluida = true
            }
          })
          dimensionesReconocimiento.forEach((dimensionReconocimiento) => {
            if (dimensionReconocimiento.id == dimension.id) {
              incluida = true
            }
          })

          if (!incluida) {
            misDimensionesFiltradas.push(dimension)
          }
        })
        setDimensionesSinFiltro(response.d)
        setDimensiones(misDimensionesFiltradas)
        setLoadingDimensiones(false)
      } else {
        setDimensiones([])
        setLoadingDimensiones(false)
      }
    } catch (error) {
      console.error(error)
      setLoadingDimensiones(false)
    }
  }

  function deleteDimension(toDelete, grupo) {
    let listaDeGrupo = []
    let listaDelete = []
    switch (grupo) {
      case 'Común':
        listaDeGrupo = [...dimensionesComunes]
        break
      case 'Específica':
        listaDeGrupo = [...dimensionesEspecifica]
        break
      case 'Reconocimiento':
        listaDeGrupo = [...dimensionesReconocimiento]
    }
    listaDeGrupo.forEach((reg) => {
      if (reg.id != toDelete.id) {
        listaDelete.push(reg)
      }
    })
    switch (grupo) {
      case 'Común':
        setDimensionesComunes(listaDelete)
        break
      case 'Específica':
        setDimensionesEspecifica(listaDelete)
        break
      case 'Reconocimiento':
        setDimensionesReconocimiento(listaDelete)
    }
    setNueva({ ...nueva, dimensiones_total: nueva.dimensiones_total - 1 })
  }

  function changePosition(toChange, grupo, direccion) {
    let listaDeGrupo = []
    switch (grupo) {
      case 'Común':
        listaDeGrupo = [...dimensionesComunes]
        break
      case 'Específica':
        listaDeGrupo = [...dimensionesEspecifica]
        break
      case 'Reconocimiento':
        listaDeGrupo = [...dimensionesReconocimiento]
    }
    let regAux = {}
    let myReg = {}
    let position = -1
    let newPosition = -1
    listaDeGrupo.forEach((reg, i) => {
      if (reg.id == toChange.id) {
        position = i
        myReg = reg
        if (direccion === 'up') {
          regAux = listaDeGrupo[i - 1]
          newPosition = i - 1
        } else {
          regAux = listaDeGrupo[i + 1]
          newPosition = i + 1
        }
      }
    })
    listaDeGrupo[position] = regAux
    listaDeGrupo[newPosition] = myReg

    switch (grupo) {
      case 'Común':
        setDimensionesComunes(listaDeGrupo)
        break
      case 'Específica':
        setDimensionesEspecifica(listaDeGrupo)
        break
      case 'Reconocimiento':
        setDimensionesReconocimiento(listaDeGrupo)
    }
  }

  function handleDragStart(dimensionDragStrat) {
    setDimensionDrag(dimensionDragStrat)
  }

  function handleDragEnter() {}

  function handleDropEnd(agrupadorDrop) {
    if (agrupadorDrop != dimensionDrag.type) {
      toast.error('No se pueden agrupar dimensiones de distintos tipos')
      return
    }
    switch (agrupadorDrop) {
      case 'Común':
        setDimensionesComunes([...dimensionesComunes, dimensionDrag])
        break
      case 'Específica':
        setDimensionesEspecifica([...dimensionesEspecifica, dimensionDrag])
        break
      case 'Reconocimiento':
        setDimensionesReconocimiento([
          ...dimensionesReconocimiento,
          dimensionDrag,
        ])
    }
    let deleteDimension = []
    dimensiones.forEach((dimension) => {
      if (dimension != dimensionDrag) {
        deleteDimension.push(dimension)
      }
    })
    setNueva({ ...nueva, dimensiones_total: nueva.dimensiones_total + 1 })
    setDimensiones(deleteDimension)
  }

  function enviarRegistro() {
    let idsDimensiones = []
    dimensionesComunes.forEach((dimension) => {
      idsDimensiones.push(dimension.id)
    })
    dimensionesEspecifica.forEach((dimension) => {
      idsDimensiones.push(dimension.id)
    })
    dimensionesReconocimiento.forEach((dimension) => {
      idsDimensiones.push(dimension.id)
    })
    let body = {
      dimensiones: idsDimensiones,
      clave_evaluacion: nueva.clave,
      nombre_evaluacion: nueva.nombre,
      descripcion_evaluacion: nueva.descripcion,
      status_check: nueva.status_check ? 1 : 0,
    }
    if (editable) {
      body.status = 1
      body.id = nueva.id
    }
    handleSubmit(body)
  }

  function eliminarRegistro() {
    let idsDimensiones = []
    dimensionesComunes.forEach((dimension) => {
      idsDimensiones.push(dimension.id)
    })
    dimensionesEspecifica.forEach((dimension) => {
      idsDimensiones.push(dimension.id)
    })
    dimensionesReconocimiento.forEach((dimension) => {
      idsDimensiones.push(dimension.id)
    })
    let body = {
      dimensiones: idsDimensiones,
      clave_evaluacion: nueva.clave,
      nombre_evaluacion: nueva.nombre,
      descripcion_evaluacion: nueva.descripcion,
    }
    if (editable) {
      body.status = 0
      body.id = nueva.id
    }
    setDeleteModal(false)
    handleSubmit(body)
  }

  const handleChangeActiva = (ev) => {
    setNueva({ ...nueva, status_check: ev.target.checked })
  }

  const customStyles = {
    option: (provided, _state) => ({
      ...provided,
      color: '#616b74',
      fontSize: 14,
      fontWeight: 400,
      padding: '5px 25px',
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      userSelect: 'none',
      backgroundColor: '#fff',
      borderTop: '1px solid #ccc',
    }),
    control: (provided, _state) => ({
      ...provided,
      minHeight: 0,
      height: '25px',
      borderRadius: 15,
      fontSize: 11,
      backgroundColor: '#f9f9f9',
      padding: 0,
    }),
    placeholder: (provided, _state) => ({
      ...provided,
      padding: 0,
      fontSize: 11,
      fontWeight: 400,
    }),
    menu: (provided, _state) => ({
      ...provided,
      borderRadius: 0,
    }),
    input: (provided, _state) => ({
      ...provided,
      padding: 0,
      fontSize: 11,
      fontWeight: 400,
      borderRadius: 0,
    }),
    singleValue: (provided, _state) => ({
      ...provided,
      top: '55%',
      paddingRight: '12px',
    }),
  }
  return (
    <>
      <NewModal
        canDelete={canDelete}
        height={'75vh'}
        width={'78%'}
        title={title}
        open={open}
        handleClose={handleClose}
        loading={loading}
        handleSubmit={enviarRegistro}
        onDelete={() => {
          setDeleteModal(true)
        }}
        copiar={() =>
          copiar(
            dimensionesComunes,
            dimensionesEspecifica,
            dimensionesReconocimiento
          )
        }
        showCopyButton={showCopyButton}
        showCheckbox
        activeCheckbox={nueva.status_check}
        handleChangeActiva={handleChangeActiva}
      >
        <div
          id='contenedor-NuevoInstrumento'
          className='contenedor-nuevo-evaluacion-integral'
        >
          <Grid container spacing={1}>
            <Grid
              className='titulos-instrumento'
              container
              xs={12}
              md={12}
              item
            >
              <Grid xs={6} md={6} item>
                <h1>Diseño de evaluación integral</h1>
              </Grid>
              <Grid xs={6} md={6} item>
                <h1>Lista de Dimensiones</h1>
              </Grid>
            </Grid>

            <Grid
              className='contenedor-Agrupadores contenedor-Agrupadores2'
              container
              xs={6}
              md={6}
              item
            >
              <Grid xs={3} md={3} item>
                <label id='tag-clave'>Clave (*)</label>
                <input
                  value={nueva.clave}
                  onChange={(e) => {
                    if (!e.target.value.includes(' ')) {
                      setNueva({
                        ...nueva,
                        clave: e.target.value,
                      })
                    }
                  }}
                  disabled={editable}
                  className='input-evaluacion'
                  placeholder='Ingresar clave'
                />
              </Grid>
              <Grid xs={4} md={4} item>
                <label id='tag-nombre'>Nombre (*)</label>
                <input
                  value={nueva.nombre}
                  onChange={(e) => {
                    setNueva({
                      ...nueva,
                      nombre: e.target.value,
                    })
                  }}
                  className='input-evaluacion'
                  placeholder='Ingresar Nombre'
                />
              </Grid>
              <Grid xs={3} md={2} item>
                <label id='tag-clave'>Dimensiones</label>
                <input
                  value={nueva.dimensiones_total}
                  disabled
                  style={{ textAlign: 'center' }}
                  className='input-evaluacion'
                  placeholder='Ingresar clave'
                />
              </Grid>
              <Grid xs={2} md={2} item>
                <label id='tag-descripcion'>Descripción</label>
                <NewButton
                  noIcon
                  onClick={() => {
                    setModalDescripcion(true)
                  }}
                  editar
                />
              </Grid>
              <Grid xs={12} className='contenedor-agrupadores-dimensiones'>
                <Agrupador
                  changePosition={changePosition}
                  deleteDimension={deleteDimension}
                  handleDragEnter={handleDragEnter}
                  handleDropEnd={handleDropEnd}
                  titulo='Común'
                  dimensiones={dimensionesComunes}
                />
                <Agrupador
                  changePosition={changePosition}
                  deleteDimension={deleteDimension}
                  handleDragEnter={handleDragEnter}
                  handleDropEnd={handleDropEnd}
                  titulo='Específica'
                  dimensiones={dimensionesEspecifica}
                />
                <Agrupador
                  changePosition={changePosition}
                  deleteDimension={deleteDimension}
                  handleDragEnter={handleDragEnter}
                  handleDropEnd={handleDropEnd}
                  titulo='Reconocimiento'
                  dimensiones={dimensionesReconocimiento}
                />
              </Grid>
            </Grid>
            <Grid
              className='contenedor-Selectores-Reactivos contenedor-Selectores-Reactivos-dimensiones'
              container
              xs={6}
              md={6}
              item
            >
              <div style={{ width: '100%', display: 'flex', height: '15%' }}>
                <Grid xs={4} md={4} item>
                  <label>Categorias:</label>
                  <Select
                    options={opcionesCategorias}
                    value={categoria}
                    styles={customStyles}
                    className='select-search-box'
                    onChange={setCategoria}
                    placeholder='Opciones Categorias'
                  />
                </Grid>
                <Grid xs={5} md={5} item></Grid>
                <Grid xs={3} md={3} item>
                  <input
                    className='input-evaluacion'
                    id='to-search2-categorias'
                    type='search'
                    placeholder='Buscar'
                    onChange={() => {}}
                    style={{ marginTop: '25px' }}
                  ></input>
                </Grid>
              </div>

              <Grid
                xs={12}
                md={12}
                onDragOver={() => {}}
                item
                style={{ height: '85%' }}
              >
                {loadingDimensiones ? (
                  <div style={{ textAlign: 'center', paddingTop: 30 }}>
                    <CircularProgress size={50} />
                  </div>
                ) : (
                  <div
                    style={{ height: '100%', overflowX: 'auto' }}
                    className='lista-dimensiones con-borde'
                    id='tabla-preguntas'
                  >
                    {dimensiones.length > 0 ? (
                      <>
                        {dimensiones.map((dimension) => (
                          <>
                            <Grid
                              draggable='true'
                              onDragStart={() => {
                                handleDragStart(dimension)
                              }}
                              onDragOver={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                              }}
                              xs={12}
                              md={12}
                              container
                              className='dimension-derecha'
                            >
                              <Grid xs={2} md={2}>
                                {dimension.key}
                              </Grid>
                              <Grid xs={6} md={6}>
                                {dimension.name}
                              </Grid>
                              <Grid
                                xs={4}
                                md={4}
                                className={dimension.type + '-class'}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <span style={{ marginTop: '-15px' }}>
                                  {dimension.type}
                                </span>
                              </Grid>
                            </Grid>
                          </>
                        ))}
                      </>
                    ) : (
                      <p style={{ textAlign: 'center' }}>
                        No hay dimensiones para mostrar
                      </p>
                    )}
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </NewModal>
      <NewModal
        handleClose={() => setModalDescripcion(false)}
        handleSubmit={() => {
          setModalDescripcion(false)
        }}
        height={160}
        width={'78%'}
        title={title}
        open={modalDescripcion}
        loading={false}
      >
        <Grid container>
          <Grid xs={12} md={12}>
            <label>Descripción(*):</label>
            <textarea
              value={nueva.descripcion}
              onChange={(e) => {
                setNueva({
                  ...nueva,
                  descripcion: e.target.value,
                })
              }}
              placeholder='Ingresa Descripción'
              className='descripcion-dimension-textarea'
            />
          </Grid>
        </Grid>
      </NewModal>
      <NewModal
        open={deleteModal}
        height={120}
        title='Confirmar'
        loading={loading}
        handleClose={() => setDeleteModal(false)}
        handleSubmit={eliminarRegistro}
      >
        <div class='delete-confirm'>
          <h2 style={{ textAlign: 'center' }}>
            ¿Está seguro de que desea eliminar esta evaluación?
          </h2>
        </div>
      </NewModal>
    </>
  )
}

function Agrupador({
  titulo,
  dimensiones,
  handleDropEnd,
  deleteDimension,
  changePosition,
}) {
  function allowDrop(e) {
    e.preventDefault()
    e.stopPropagation()
  }
  return (
    <Grid
      onDragOver={allowDrop}
      onDrop={() => {
        handleDropEnd(titulo)
      }}
      container
      className='agrupador-dimension'
    >
      <Grid xs={12} md={12}>
        <h6>{titulo}</h6>
      </Grid>
      <Grid className='contenedor-dimensiones' xs={12} md={12}>
        {!dimensiones
          ? null
          : dimensiones.map((dimension, i) => (
              <div className='dimension-registro' key={i + 1234214}>
                <Grid xs={12} md={12} container>
                  <Grid xs={1} md={1}>
                    <CancelIcon
                      fontSize='large'
                      onClick={() => {
                        deleteDimension(dimension, titulo)
                      }}
                      style={{ marginTop: 10, cursor: 'pointer' }}
                    />
                  </Grid>
                  <Grid xs={1} md={1}>
                    {i === 0 ? (
                      <ArrowDropUpIcon style={{ color: 'white' }} />
                    ) : (
                      <ArrowDropUpIcon
                        onClick={() => {
                          changePosition(dimension, titulo, 'up')
                        }}
                        className='arrow-icons-change-position'
                      />
                    )}
                    {i === dimensiones.length - 1 ? null : (
                      <ArrowDropDownIcon
                        onClick={() => {
                          changePosition(dimension, titulo, 'down')
                        }}
                        className='arrow-icons-change-position'
                      />
                    )}
                  </Grid>
                  <Grid xs={2} md={2}>
                    {dimension.key}
                  </Grid>
                  <Grid className='contenedor-nombre-reactivo' xs={8} md={8}>
                    <p>{dimension.name}</p>
                  </Grid>
                </Grid>
              </div>
            ))}
      </Grid>
    </Grid>
  )
}
