const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'SET_USER':
      return {
        ...state,
        user: payload,
      };
    case 'SET_IS_LOGED':
      return {
        ...state,
        isLoged: payload,
      };
    case 'SET_VERSION':
      return {
        ...state,
        version: payload
      }
    case 'SET_TOKEN_KEY':
      return {
        ...state,
        tokenKey: payload
      }
    case 'SET_CAMPUS':
      return {
        ...state,
        listaCampus: payload
      }
    case 'SET_ALL_PERMISES':
      return {
        ...state,
        permisos: payload
      }
    default:
      return state;
  }
};

export default reducer;
