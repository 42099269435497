/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import Contenedor from '../../Components/Contenedor/Contenedor'
import NewButton from '../../Components/Botones/NewButton'
import EditButton from '../../Components/Botones/EditButton'
import DataTable from '../../Components/DataTables'
import NuevaEvaluacion from './NuevaEvaluacion'
import { getRequest, postRequest } from '../../utils/requester'
import './style.css'
/*import Can from '../../Utils/Funciones/can'*/

function EvaluacionIntegral() {
  const [dataInicial, setDataInicial] = useState([])
  const [dataFiltradas, setDataFiltradas] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [loadingModalEditar, setLoadingModalEditar] = useState(false)
  const [modalEditarIsOpen, setModalEditarIsOpen] = useState(false)
  const [modalNuevaIsOpen, setModalNuevaIsOpen] = useState(false)
  const [modalCopiaIsOpen, setModalCopiaIsOpen] = useState(false)
  const [nueva, setNueva] = useState({
    nombre: '',
    descripcion: '',
    status: 'activa',
  })
  const [copia, setCopia] = useState({
    nombre: '',
    descripcion: '',
    status: 'activa',
    dimensiones: [],
  })
  const [editar, setEditar] = useState({
    id: '',
    nombre: '',
    descripcion: '',
    dimensiones: [],
    status_check: false,
  })
  const [, setDeleteModal] = useState(false)

  const openEditModal = async (reg) => {
    setEditar({
      clave: reg.clave_evaluacion,
      nombre: reg.nombre_evaluacion,
      descripcion: reg.descripcion_evaluacion,
      dimensiones_total: reg.dimensiones.length,
      dimensiones: reg.dimensiones,
      id: reg.id,
      status_check: reg.status_check,
    })
    setModalEditarIsOpen(true)
  }

  const openNuevoModal = () => {
    setModalNuevaIsOpen(true)
    setNueva({
      clave: '',
      nombre: '',
      descripcion: '',
      dimensiones_total: 0,
      dimensiones: [],
    })
  }

  const handleEditar = async (data) => {
    if (validar(data)) {
      try {
        setLoadingModalEditar(true)
        const editarResponse = await postRequest(`/evaluacion/editar`, data)
        if (editarResponse.s === 'OK') {
          setLoadingModal(false)
          setModalEditarIsOpen(false)
          toast.success(editarResponse.m)

          reloadList()
        }

        setLoadingModalEditar(false)
      } catch (error) {
        console.error(error)
        setLoadingModalEditar(false)
      }
    }
  }

  const handleNueva = async (data) => {
    if (validar(data)) {
      try {
        setLoadingModal(true)
        const nuevaResponse = await postRequest(`/evaluacion/nueva`, data)
        if (nuevaResponse.s === 'OK') {
          setLoadingModal(false)
          setModalNuevaIsOpen(false)
          toast.success(nuevaResponse.m)
          reloadList()
        }
        setLoadingModal(false)
      } catch (error) {
        console.error(error)
        setLoadingModal(false)
      }
    }
  }

  const handleCopia = async (data) => {
    if (validar(data)) {
      try {
        setLoadingModal(true)
        const copiaResponse = await postRequest(`/evaluacion/nueva`, data)
        if (copiaResponse.s === 'OK') {
          setLoadingModal(false)
          setModalCopiaIsOpen(false)
          toast.success(copiaResponse.m)
          reloadList()
        }
        setLoadingModal(false)
      } catch (error) {
        console.error(error)
        setLoadingModal(false)
      }
    }
  }

  const validar = (body) => {
    let valido = true
    if (body.clave_evaluacion === '') {
      document.getElementById('tag-clave').classList.add('error')
      valido = false
    } else {
      document.getElementById('tag-clave').classList.remove('error')
    }
    if (body.nombre_evaluacion === '') {
      document.getElementById('tag-nombre').classList.add('error')
      valido = false
    } else {
      document.getElementById('tag-nombre').classList.remove('error')
    }
    if (body.descripcion_evaluacion === '') {
      document.getElementById('tag-descripcion').classList.add('error')
      valido = false
    } else {
      document.getElementById('tag-descripcion').classList.remove('error')
    }
    return valido
  }

  const testHeader = [
    'Folio',
    'Clave',
    'Nombre',
    'Activo',
    'Total Dimensiones',
    'Acciones',
  ]

  ////////// Filtrar resultados segun el input de busqueda //////////
  const search = (e) => {
    let text = e.target.value.toLowerCase()

    let result = dataInicial.filter((row) => {
      if (JSON.stringify(row).toLowerCase().includes(text)) {
        return true
      }
      return false
    })
    setDataFiltradas(result)
  }

  const reloadList = async () => {
    setLoading(true)
    try {
      const response = await getRequest('/evaluacion')
      if (response.s === 'OK') {
        let data = []
        response.d.forEach((reg) => {
          data.push([
            reg.id,
            reg.clave_evaluacion,
            reg.nombre_evaluacion,
            reg.status_check ? 'Si' : 'No',
            reg.dimensiones.length,
            <EditButton onClick={() => openEditModal(reg)}>Editar</EditButton>,
          ])
        })
        setDataInicial(data)
        setDataFiltradas(data)
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const mapDimensiones = (dimensiones) =>
    dimensiones.map(({ key, id, name, type }) => ({
      clave_dimension: key,
      id_DIMENSION: id,
      nombre_dimension: name,
      tipo_dimension: type,
    }))

  const copiar = (
    dimensionesComunes,
    dimensionesEspecifica,
    dimensionesReconocimiento
  ) => {
    setCopia({
      clave: '',
      nombre: '',
      descripcion: '',
      dimensiones_total: [
        ...dimensionesComunes,
        ...dimensionesEspecifica,
        ...dimensionesReconocimiento,
      ].length,
      dimensiones: mapDimensiones([
        ...dimensionesComunes,
        ...dimensionesEspecifica,
        ...dimensionesReconocimiento,
      ]),
    })
    setModalEditarIsOpen(false)
    setModalCopiaIsOpen(true)
  }

  useEffect(() => {
    reloadList()
  }, [])

  return (
    <Contenedor title='Evaluación Integral'>
      <div className='opciones-bar'>
        <div className='sub-filtro'>
          <NewButton
            onClick={() => {
              openNuevoModal()
            }}
          />
        </div>
        <div style={{ float: 'right' }}>
          <input
            type='search'
            placeholder='Buscar'
            className='SearchInput-tabla-completa SearchInput-Usuarios'
            onChange={search}
          />
        </div>
      </div>
      <div style={{ height: 'calc(100% - 70px)' }}>
        <DataTable
          headers={testHeader}
          data={dataFiltradas}
          loading={loading}
          paginate
        />
      </div>
      <NuevaEvaluacion
        title='Editar Evaluación Integral'
        open={modalEditarIsOpen}
        handleClose={() => setModalEditarIsOpen(false)}
        loading={loadingModalEditar}
        setLoadingModal={setLoadingModalEditar}
        handleSubmit={handleEditar}
        onDelete={() => setDeleteModal(true)}
        permiso='cuestionarios_etiquetas'
        nueva={editar}
        setNueva={setEditar}
        editable={true}
        copiar={copiar}
        showCopyButton
        canDelete
      />

      <NuevaEvaluacion
        title='Nueva Evaluación Integral'
        open={modalCopiaIsOpen}
        handleClose={() => {
          setModalCopiaIsOpen(false)
          setCopia({
            nombre: '',
            descripcion: '',
            status: 'activa',
            dimensiones: [],
          })
        }}
        loading={loadingModal}
        setLoadingModal={setLoadingModal}
        handleSubmit={handleCopia}
        nueva={copia}
        setNueva={setCopia}
        isCopia
      />
      <NuevaEvaluacion
        title='Nueva Evaluación Integral'
        open={modalNuevaIsOpen}
        handleClose={() => {
          setModalNuevaIsOpen(false)
          setNueva({
            nombre: '',
            descripcion: '',
            status: 'activa',
          })
        }}
        loading={loadingModal}
        setLoadingModal={setLoadingModal}
        handleSubmit={handleNueva}
        nueva={nueva}
        setNueva={setNueva}
        editable={false}
      />
    </Contenedor>
  )
}
export default EvaluacionIntegral
