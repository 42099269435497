/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Select from 'react-select'
import { toast } from 'react-toastify'
import TextField from '@material-ui/core/TextField'

import NewModal from '../../Components/Modal/NewModal'
import DataTable from '../../Components/DataTables'
import NewButton from '../../Components/Botones/NewButton'
import { postRequest, putRequest } from '../../utils/requester'

const getDate = () => {
  const year = new Date().getFullYear()
  const month = new Date().getMonth() + 1
  const day = new Date().getDate()
  const hour = new Date().getHours()
  const minute =
    new Date().getMinutes() < 10
      ? '0' + new Date().getMinutes()
      : new Date().getMinutes()

  return `${year}${month}${day}_${hour}${minute}`
}

export default function DetalleAsignacion({
  modalActualizacionProfesional,
  setModalActualizacionProfesional,
  loadingActualizacionProfesional,
  detalleEvaluacionGlobal,
  setDetalleEvaluacionGlobal,
  deleteElement,
  enviarRespuestaEvaluacionGlobal,
}) {
  const [modalNuevoRegistro, setModalNuevoRegistro] = useState(false)
  const [opciones, setOpciones] = useState([])
  const [loadingModalNuevoRegistro, setLoadingModalNuevoRegistro] =
    useState(false)
  const [values, setValues] = useState([])
  const [archivoSubir, setArchivoSubir] = useState({})
  const [indicesFaltantes, setIndicesFaltantes] = useState([])
  const [userAgent, setUserAgent] = useState('')

  useEffect(() => {
    if (navigator.userAgent.includes('Chrome')) {
      setUserAgent('Chrome')
    } else if (navigator.userAgent.includes('Firefox')) {
      setUserAgent('Firefox')
    }
  }, [])

  useEffect(() => {
    if (modalNuevoRegistro) {
      let myTodasOpciones = []
      let myValues = []
      detalleEvaluacionGlobal.elementos.forEach((elemento) => {
        if (elemento.opciones.length > 0) {
          let myOpciones = []
          elemento.opciones.forEach((opcion) => {
            myOpciones.push({
              label: opcion,
              value: opcion,
            })
          })
          myTodasOpciones.push(myOpciones)
        } else {
          myTodasOpciones.push([])
        }
        if (elemento.tipo_dato === 'Selección multiple') {
          myValues.push([])
        } else {
          myValues.push('')
        }
      })
      setValues(myValues)
      setOpciones(myTodasOpciones)
    }
  }, [modalNuevoRegistro])

  const tipoFormacion = {
    CERTIFICACION: '60',
    'MAESTRIA (CURSANDO)': '120',
    'DOCTORADO (CURSANDO)': '160',
    'ESPECIALIDAD (CURSANDO)': '120',
  }

  const customStyles = {
    option: (provided, _state) => ({
      ...provided,
      color: '#616b74',
      fontSize: 14,
      fontWeight: 400,
      padding: '5px 25px',
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      userSelect: 'none',
      backgroundColor: '#fff',
      borderTop: '1px solid #ccc',
    }),
    control: (provided, _state) => ({
      ...provided,
      minHeight: 0,
      height: '25px',
      borderRadius: 15,
      fontSize: 11,
      backgroundColor: '#f9f9f9',
      padding: 0,
    }),
    placeholder: (provided, _state) => ({
      ...provided,
      padding: 0,
      fontSize: 11,
      fontWeight: 400,
    }),
    menu: (provided, _state) => ({
      ...provided,
      borderRadius: 0,
    }),
    input: (provided, _state) => ({
      ...provided,
      padding: 0,
      fontSize: 11,
      fontWeight: 400,
      borderRadius: 0,
    }),
    singleValue: (provided, _state) => ({
      ...provided,
      top: '50%',
    }),
  }

  function handleChangeReader(e, i) {
    var reader = new FileReader()
    reader.readAsArrayBuffer(e.target.files[0])
    reader.onload = () => {}
    let fileSave = e.target.files[0]

    let name = e.target.files[0].name

    if (
      (fileSave.name.toLowerCase().includes('jpeg') ||
        fileSave.name.toLowerCase().includes('jpg') ||
        fileSave.name.toLowerCase().includes('png') ||
        fileSave.name.toLowerCase().includes('xlsx') ||
        fileSave.name.toLowerCase().includes('pptx') ||
        fileSave.name.toLowerCase().includes('pdf')) &&
      fileSave.size <= 15000000
    ) {
      let myValues = [...values]
      setArchivoSubir(e.target.files[0])
      myValues[i] = name
      setValues(myValues)
    } else {
      if (fileSave.size > 15000000) {
        toast.error('Archivo supera los 15MB')
      } else {
        toast.error('Archivo no permitido')
      }
    }
  }

  async function handleAddNuevoRegistro() {
    let myData = [...detalleEvaluacionGlobal.data]
    let myValues = [...values]
    let nombreArchivo = ''
    let nombreArchivoValido = true

    myValues.forEach((singleValue, i) => {
      if (
        detalleEvaluacionGlobal.elementos[i].tipo_dato === 'Selección multiple'
      ) {
        let valor = []
        singleValue.forEach((value) => {
          valor.push(value.value)
        })
        myValues[i] = valor.join()
      } else if (detalleEvaluacionGlobal.elementos[i].tipo_dato === 'Archivo') {
        const extension =
          archivoSubir.name.split('.')[archivoSubir.name.split('.').length - 1]

        nombreArchivo =
          detalleEvaluacionGlobal.nombre.replaceAll(' ', '_') +
          '_' +
          getDate() +
          '.' +
          extension
      }
    })
    let numeroElemento = 0
    if (myData.length > 0) {
      numeroElemento =
        myData[myData.length - 1][detalleEvaluacionGlobal.headers.length + 1] +
        1
    } else {
      numeroElemento = 1
    }

    let valido = true
    const indices = []
    myValues.forEach((valor, i) => {
      if (valor === '' || valor === undefined) {
        indices.push(i)
        valido = false
      }
    })
    setIndicesFaltantes(indices)
    if (!valido) {
      toast.error('Debe llenar todos los campos')
      return
    } else if (!nombreArchivoValido) {
      toast.error(
        'Nombre del archivo no valido, contiene caracteres especiales especiales y/o espacios'
      )
      return
    } else {
      setLoadingModalNuevoRegistro(true)
      try {
        let response = await postRequest('/usuarios/subir-documento', {
          nombre: nombreArchivo,
        })
        if (response.s === 'OK') {
          await putRequest(
            response.d.presign_url,
            archivoSubir,
            archivoSubir.type
          )

          myValues.forEach((value, i) => {
            if (value === archivoSubir.name) {
              myValues[i] = response.d.url
            }
          })
          setModalNuevoRegistro(false)
          myValues.push('')
          myValues.push(
            <button
              className='delete-reg-eval'
              onClick={() => {
                deleteElement(numeroElemento)
              }}
            >
              Eliminar
            </button>
          )
          myValues.push('')
          myValues.push(numeroElemento)
          myData.push(myValues)
          setDetalleEvaluacionGlobal({
            ...detalleEvaluacionGlobal,
            data: myData,
          })
        } else {
          setLoadingModalNuevoRegistro(false)
        }
      } catch (e) {
        console.log(e)
        setLoadingModalNuevoRegistro(false)
        toast.error('Error subiendo archivo')
      }
    }
  }

  return (
    <>
      <NewModal
        title='Responder Evaluación'
        open={modalActualizacionProfesional}
        handleClose={() => setModalActualizacionProfesional(false)}
        height={600}
        loading={loadingActualizacionProfesional}
        handleSubmit={() => {
          enviarRespuestaEvaluacionGlobal()
        }}
        width={'90%'}
        buttonAcceptText={'Guardar'}
      >
        <Grid
          style={{ flexDirection: 'column', justifyContent: 'space-around' }}
          container
          className='contenedor-info-detalle-asignacion'
        >
          <Grid xs={15}>
            <p>
              <span className='campo-informacion'>NOMBRE DE DIMENSIÓN: </span>
              {detalleEvaluacionGlobal.nombre}
            </p>
          </Grid>
          <Grid xs={15}>
            <p style={{ marginBottom: '5px' }}>
              <span className='campo-informacion'>
                DESCRIPCIÓN DE DIMENSIÓN:
              </span>
              {detalleEvaluacionGlobal.descripcion}
            </p>
          </Grid>
        </Grid>
        <div className='contenedor-header-actualizacion-profesional'>
          <NewButton
            onClick={() => {
              setModalNuevoRegistro(true)
              setLoadingModalNuevoRegistro(false)
            }}
          />
        </div>
        <div className='contenedor-reporte-actualizacion-profesional'>
          <DataTable
            headers={detalleEvaluacionGlobal.headers}
            data={detalleEvaluacionGlobal.data}
          />
        </div>
      </NewModal>
      <NewModal
        title='Añadir'
        open={modalNuevoRegistro}
        handleClose={() => setModalNuevoRegistro(false)}
        height={600}
        loading={loadingModalNuevoRegistro}
        handleSubmit={() => {
          handleAddNuevoRegistro()
        }}
        width={700}
      >
        <div
          className='formEvaluacionActual'
          style={{
            height: 'calc(80%  - 60px)',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <Grid container spacing={1}>
            {detalleEvaluacionGlobal.elementos.map((elemento, i) =>
              elemento.tipo_dato === 'Selección unica' ? (
                <Grid item xs={12} md={12}>
                  <label
                    style={indicesFaltantes.includes(i) ? { color: 'red' } : {}}
                    id='lable-etiqueta'
                  >
                    {elemento.nombre}(*)
                  </label>
                  <Select
                    options={opciones[i]}
                    styles={customStyles}
                    value={{ label: values[i], value: [values[i]] }}
                    onChange={(e) => {
                      let myValues = [...values]
                      myValues[i] = e.value
                      if (elemento.nombre === 'TIPO DE FORMACION') {
                        detalleEvaluacionGlobal.elementos.forEach((ele, j) => {
                          if (ele.nombre === 'NUMERO DE HORAS') {
                            myValues[j] = tipoFormacion[e.value]
                          }
                        })
                      }
                      setValues(myValues)
                    }}
                    name='etiqueta'
                    placeholder='Selección...'
                    className='select-search-box'
                  />
                </Grid>
              ) : elemento.tipo_dato === 'Selección multiple' ? (
                <Grid item xs={12} md={12}>
                  <label
                    style={indicesFaltantes.includes(i) ? { color: 'red' } : {}}
                    id='lable-etiqueta'
                  >
                    {elemento.nombre}(*)
                  </label>
                  <Select
                    options={opciones[i]}
                    styles={customStyles}
                    value={values[i]}
                    onChange={(e) => {
                      let myValues = [...values]
                      myValues[i] = e
                      setValues(myValues)
                    }}
                    name='etiqueta'
                    placeholder='Selección...'
                    className='select-search-box'
                    isMulti
                  />
                </Grid>
              ) : elemento.tipo_dato === 'Texto' ? (
                <Grid item xs={12} md={12}>
                  <label
                    style={indicesFaltantes.includes(i) ? { color: 'red' } : {}}
                    id='lable-etiqueta'
                  >
                    {elemento.nombre}(*)
                  </label>
                  <input
                    type='text'
                    value={
                      elemento.nombre === 'NUMERO DE HORAS' &&
                      tipoFormacion[values[0]] !== undefined
                        ? tipoFormacion[values[0]]
                        : values[i]
                    }
                    onChange={(e) => {
                      const text = e.target.value
                      if (elemento.nombre === 'NUMERO DE HORAS') {
                        const regex = /[0-9]/g
                        if (
                          text.length === 0 ||
                          (text.match(regex) &&
                            text.match(regex).length === text.length &&
                            text[0] > 0 &&
                            !text.includes('"') &&
                            !text.includes("'") &&
                            !text.includes('´') &&
                            !text.includes('`') &&
                            !text.includes('¨') &&
                            !text.includes('{') &&
                            !text.includes('}') &&
                            !text.includes('[') &&
                            !text.includes(']') &&
                            !text.includes('^') &&
                            !text.includes('/'))
                        ) {
                          let myValues = [...values]
                          if (
                            tipoFormacion[values[0]] !== undefined &&
                            elemento.nombre === 'NUMERO DE HORAS'
                          ) {
                          } else {
                            myValues[i] = e.target.value
                            setValues(myValues)
                          }
                        }
                      } else {
                        const regex = /[A-zÀ-ú0-9 ]/g
                        if (
                          text.length === 0 ||
                          (text.match(regex) &&
                            text.match(regex).length === text.length &&
                            !text.includes('"') &&
                            !text.includes("'") &&
                            !text.includes('´') &&
                            !text.includes('`') &&
                            !text.includes('¨') &&
                            !text.includes('{') &&
                            !text.includes('}') &&
                            !text.includes('[') &&
                            !text.includes(']') &&
                            !text.includes('^') &&
                            !text.includes('/'))
                        ) {
                          let myValues = [...values]
                          if (
                            tipoFormacion[values[0]] !== undefined &&
                            elemento.nombre === 'NUMERO DE HORAS'
                          ) {
                          } else {
                            myValues[i] = e.target.value
                            setValues(myValues)
                          }
                        }
                      }
                    }}
                    placeholder={'Ingresar ' + elemento.nombre}
                  />
                </Grid>
              ) : elemento.tipo_dato === 'Numerico' ? (
                <Grid item xs={12} md={12}>
                  <label
                    style={indicesFaltantes.includes(i) ? { color: 'red' } : {}}
                    id='lable-etiqueta'
                  >
                    {elemento.nombre}(*)
                  </label>
                  <input
                    type='text'
                    value={
                      tipoFormacion[values[0]] !== undefined
                        ? tipoFormacion[values[0]]
                        : values[i]
                    }
                    onChange={(e) => {
                      const text = e.target.value
                      const regex = /[0-9]/g
                      if (
                        text.length === 0 ||
                        (text.match(regex) &&
                          text.match(regex).length === text.length &&
                          !text.includes('"') &&
                          !text.includes("'") &&
                          !text.includes('´') &&
                          !text.includes('`') &&
                          !text.includes('¨') &&
                          !text.includes('{') &&
                          !text.includes('}') &&
                          !text.includes('[') &&
                          !text.includes(']') &&
                          !text.includes('^') &&
                          !text.includes('/'))
                      ) {
                        let myValues = [...values]
                        if (tipoFormacion[values[0]] !== undefined) {
                        } else {
                          myValues[i] = e.target.value
                          setValues(myValues)
                        }
                      }
                    }}
                    placeholder={'Ingresar ' + elemento.nombre}
                  />
                </Grid>
              ) : elemento.tipo_dato === 'Fecha-Hora' ? (
                <Grid item xs={12} md={12}>
                  <label
                    style={indicesFaltantes.includes(i) ? { color: 'red' } : {}}
                    id='lable-etiqueta'
                  >
                    {elemento.nombre}(*)
                  </label>
                  <TextField
                    value={values[i]}
                    onChange={(e) => {
                      let myValues = [...values]
                      myValues[i] = e.target.value
                      setValues(myValues)
                    }}
                    inputProps={{
                      min: new Date().getFullYear() + '-01-01T00:00',
                      max: new Date().getFullYear() + '-12-31T23:59',
                    }}
                    className='selector-fecha'
                    type={userAgent === 'Firefox' ? 'date' : 'datetime-local'}
                  />
                </Grid>
              ) : elemento.tipo_dato === 'Archivo' ? (
                <Grid item xs={12} md={12} container>
                  <Grid xs={12} md={12}>
                    <label
                      style={
                        indicesFaltantes.includes(i) ? { color: 'red' } : {}
                      }
                      id='lable-etiqueta'
                    >
                      {elemento.nombre}(*)
                    </label>
                  </Grid>
                  <Grid xs={8} md={8}>
                    <input
                      onChange={(e) => {
                        handleChangeReader(e, i)
                      }}
                      type='file'
                      id='input-carga-masiva'
                      style={{ display: 'none' }}
                    />
                    <input
                      value={values[i]}
                      onChange={(ev) => {
                        const text = ev.target.value
                        const regex = /[A-zÀ-ú0-9]/g
                        if (
                          text.length === 0 ||
                          (text[text.length - 1].match(regex) &&
                            text[text.length - 1] !== '"' &&
                            text[text.length - 1] !== "'" &&
                            text[text.length - 1] !== '´' &&
                            text[text.length - 1] !== '`' &&
                            text[text.length - 1] !== '¨' &&
                            text[text.length - 1] !== '{' &&
                            text[text.length - 1] !== '}' &&
                            text[text.length - 1] !== '[' &&
                            text[text.length - 1] !== ']' &&
                            text[text.length - 1] !== '^' &&
                            text[text.length - 1] !== '_' &&
                            text[text.length - 1] !== '/')
                        ) {
                          let myValues = [...values]
                          if (
                            tipoFormacion[values[0]] !== undefined &&
                            elemento.nombre === 'NUMERO DE HORAS'
                          ) {
                          } else {
                            myValues[i] = text
                            setValues(myValues)
                          }
                        }
                      }}
                      placeholder={'Nombre del archivo'}
                    />
                  </Grid>
                  <Grid xs={4} md={4}>
                    <button
                      onClick={() =>
                        document.getElementById('input-carga-masiva').click()
                      }
                      style={{
                        marginTop: 0,
                        marginLeft: '20px',
                        width: '150px',
                      }}
                    >
                      Buscar Archivo
                    </button>
                  </Grid>
                </Grid>
              ) : null
            )}
          </Grid>
        </div>
      </NewModal>
    </>
  )
}
