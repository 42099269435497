import styled from 'styled-components';

export const Option = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 4px;
`;

export const SquareCheck = styled.label`
  width: ${({ size }) => (size ? size : '25px')};
  height: ${({ size }) => (size ? size : '25px')};
  border: 2px solid gray;
  cursor: pointer;
  background-color: ${({ checked }) => (checked ? 'red' : 'white')};
`;

export const TextLabel = styled.p`
  text-align: center;
`;
