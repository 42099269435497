/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import './styles.css'

function RespCortaPregunta({ setRespuestaCorta, respuestaCorta }) {
  const [pregunta, setPregunta] = useState('')
  const [respuesta, setRespuesta] = useState('')
  const [valor, setValor] = useState('')
  let respuestaCortaData = {
    pregunta,
    respuesta,
    valor,
  }

  const handleChangeInputPregunta = (e) => {
    setPregunta(e.target.value)
    setRespuestaCorta({ ...respuestaCortaData, pregunta: e.target.value })
  }

  const handleChangeInputRespuesta = (e) => {
    setRespuesta(e.target.value)
    setRespuestaCorta({ ...respuestaCortaData, respuesta: e.target.value })
  }

  const handleChangeInputValor = (e) => {
    setValor(e.target.value)
    setRespuestaCorta({ ...respuestaCortaData, valor: e.target.value })
  }

  useEffect(() => {
    if (respuestaCorta) {
      setPregunta(respuestaCorta.pregunta)
      setRespuesta(respuestaCorta.respuesta)
      setValor(respuestaCorta.valor)
    }
  }, [])

  useEffect(() => {
    setRespuestaCorta({ ...respuestaCortaData })
  }, [pregunta, respuesta, valor])

  return (
    <>
      <textarea
        rows={3}
        cols={60}
        id='caja-pregunta'
        className='input-pregunta'
        placeholder='Escriba una pregunta'
        value={pregunta}
        onChange={handleChangeInputPregunta}
      />
      <div className='contenedor-respuestas'>
        <Grid className='caja-respuesta-corta' container>
          <Grid container xs={6} md={6}>
            <Grid xs={12} md={12}>
              <input
                id='mi-respuesta'
                value={respuesta}
                onChange={handleChangeInputRespuesta}
                className='input-pregunta'
                placeholder='Texto de respuesta corta...'
              />
            </Grid>
          </Grid>
          <Grid container xs={6} md={6}>
            <Grid xs={3} md={3}>
              <label className='tag-valor'>
                <nobr>Peso porcentual:</nobr>
              </label>
            </Grid>
            <Grid xs={9} md={9}>
              <input
                style={{ float: 'right', width: '80%' }}
                type='number'
                min='0'
                pattern='[0-9]'
                step='1'
                value={valor}
                onChange={handleChangeInputValor}
                placeholder='Valor de respuesta'
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default RespCortaPregunta
