import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from '@material-ui/core'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import CircularProgress from '@material-ui/core/CircularProgress'
import './index.css'

function DataTables(props) {
  const data = props.data
  const [orderBy, setOrderBy] = useState({ col: 0, asc: false })
  const [rowsPerPage, setRowsPerPage] = useState(data.length)
  const [pageActual, setPageActual] = useState(0)
  const [resize, setResize] = useState(false)
  const tableRef = useRef(null)
  let i = 0

  if (orderBy.asc && !props.dobleTabla) {
    data.sort((a, b) => {
      if (typeof a[orderBy.col] == 'string') {
        if (a[orderBy.col].includes('%')) {
          const ordenadorA = parseInt(a[orderBy.col].split('%')[0])
          const ordenadorB = parseInt(b[orderBy.col].split('%')[0])
          if (ordenadorA > ordenadorB) {
            return 1
          }
          if (ordenadorA < ordenadorB) {
            return -1
          }
        }
      }

      if (props.headers[orderBy.col].split(' ').includes('Fecha')) {
        const dateA = a[orderBy.col]
        const dateB = b[orderBy.col]

        let [dayA, monthA, yearA] = dateA.split('/')
        let [dayB, monthB, yearB] = dateB.split('/')

        if (dayA && dayA.length < 2) {
          dayA = '0' + dayA
        }

        if (dayB && dayB.length < 2) {
          dayB = '0' + dayB
        }

        if (monthA && monthA.length < 2) {
          monthA = '0' + monthA
        }

        if (monthB && monthB.length < 2) {
          monthB = '0' + monthB
        }

        const parsedDateA = Date.parse(`${yearA}-${monthA}-${dayA}`)
        const parsedDateB = Date.parse(`${yearB}-${monthB}-${dayB}`)

        if (parsedDateA < parsedDateB) {
          return 1
        }
        if (parsedDateA > parsedDateB) {
          return -1
        }
        if (isNaN(parsedDateA)) {
          return 1
        }
        if (isNaN(parsedDateB)) {
          return -1
        }
        return 0
      }

      if (a[orderBy.col] > b[orderBy.col]) {
        return 1
      }
      if (a[orderBy.col] < b[orderBy.col]) {
        return -1
      }
      return 0
    })
  } else if (!props.dobleTabla) {
    data.sort((a, b) => {
      if (typeof a[orderBy.col] == 'string') {
        if (a[orderBy.col].includes('%')) {
          const ordenadorA = parseInt(a[orderBy.col].split('%')[0])
          const ordenadorB = parseInt(b[orderBy.col].split('%')[0])
          if (ordenadorA < ordenadorB) {
            return 1
          }
          if (ordenadorA > ordenadorB) {
            return -1
          }
        }
      }

      if (props.headers[orderBy.col].split(' ').includes('Fecha')) {
        const dateA = a[orderBy.col]
        const dateB = b[orderBy.col]

        let [dayA, monthA, yearA] = dateA.split('/')
        let [dayB, monthB, yearB] = dateB.split('/')

        if (dayA && dayA.length < 2) {
          dayA = '0' + dayA
        }

        if (dayB && dayB.length < 2) {
          dayB = '0' + dayB
        }

        if (monthA && monthA.length < 2) {
          monthA = '0' + monthA
        }

        if (monthB && monthB.length < 2) {
          monthB = '0' + monthB
        }

        const parsedDateA = Date.parse(`${yearA}-${monthA}-${dayA}`)
        const parsedDateB = Date.parse(`${yearB}-${monthB}-${dayB}`)

        if (parsedDateA > parsedDateB) {
          return 1
        }
        if (parsedDateA < parsedDateB) {
          return -1
        }
        if (isNaN(parsedDateA)) {
          return -1
        }
        if (isNaN(parsedDateB)) {
          return 1
        }
        return 0
      }

      if (a[orderBy.col] < b[orderBy.col]) {
        return 1
      }
      if (a[orderBy.col] > b[orderBy.col]) {
        return -1
      }
      return 0
    })
  }

  useEffect(() => {
    setPageActual(0)
  }, [data])

  useEffect(() => {
    if (props.paginate) {
      let height = tableRef.current.clientHeight
      let newRows = Math.trunc(height / 35)
      setRowsPerPage(newRows)
    } else {
      setRowsPerPage(data.length)
    }
  }, [data, props.paginate, resize])

  useEffect(() => {
    window.onresize = function onresize() {
      setResize(!resize)
    }
  })

  function getRows() {
    let rows = []
    let maxLength =
      pageActual < Math.ceil(data.length / rowsPerPage) - 1
        ? rowsPerPage * pageActual + rowsPerPage
        : data.length
    if (data.length > 0) {
      for (i = rowsPerPage * pageActual; i < maxLength; i++) {
        rows.push(
          ////////Genera un key aleatorio con 100000000 como valor maximo////////
          <TableRow key={Math.round(Math.random() * 100000000)}>
            {data[i].map((cell, k) =>
              k < props.headers.length ? (
                <>
                  <TableCell key={Math.round(Math.random() * 100000000)}>
                    {String(cell).includes('http') ? (
                      <button
                        className='delete-reg-eval'
                        onClick={() => {
                          window.open(cell)
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        Descargar
                      </button>
                    ) : (
                      cell
                    )}
                  </TableCell>
                </>
              ) : null
            )}
          </TableRow>
        )
      }
      if (props.ultimaFila) {
        rows.push(
          <TableRow
            className='ultimaFila'
            key={Math.round(Math.random() * 100000000)}
          >
            {props.ultimaFila.map((cell) => (
              <TableCell key={Math.round(Math.random() * 100000000)}>
                {cell}
              </TableCell>
            ))}
          </TableRow>
        )
      }
    } else {
      return null
    }
    return rows
  }

  function getPaginate() {
    let buttons = []
    buttons.push(
      <button
        key={Math.round(Math.random() * 10000)}
        onClick={() => {
          if (pageActual > 0) {
            setPageActual(pageActual - 1)
          }
        }}
      >
        Anterior
      </button>
    )

    if (Math.ceil(data.length / rowsPerPage) > 6) {
      if (pageActual <= 2) {
        for (i = 0; i < 3; i++) {
          buttons.push(
            <button
              onClick={setPageActual.bind(this, i)}
              className={pageActual === i ? 'active' : null}
            >
              {i + 1}
            </button>
          )
        }
        buttons.push(<button>...</button>)
      } else if (pageActual >= Math.ceil(data.length / rowsPerPage) - 3) {
        buttons.push(<button>...</button>)
        for (
          i = Math.ceil(data.length / rowsPerPage) - 3;
          i < Math.ceil(data.length / rowsPerPage);
          i++
        ) {
          buttons.push(
            <button
              onClick={setPageActual.bind(this, i)}
              className={pageActual === i ? 'active' : null}
            >
              {i + 1}
            </button>
          )
        }
      } else {
        buttons.push(<button>...</button>)
        for (i = pageActual - 2; i < pageActual + 3; i++) {
          buttons.push(
            <button
              onClick={setPageActual.bind(this, i)}
              className={pageActual === i ? 'active' : null}
            >
              {i + 1}
            </button>
          )
        }
        buttons.push(<button>...</button>)
      }
    } else {
      for (i = 0; i < Math.ceil(data.length / rowsPerPage); i++) {
        buttons.push(
          <button
            key={Math.round(Math.random() * 10000)}
            onClick={setPageActual.bind(this, i)}
            className={pageActual === i ? 'active' : null}
          >
            {i + 1}
          </button>
        )
      }
    }
    buttons.push(
      <button
        key={Math.round(Math.random() * 10000)}
        onClick={() => {
          if (pageActual < data.length / rowsPerPage - 1) {
            setPageActual(pageActual + 1)
          }
        }}
      >
        Siguiente
      </button>
    )

    return (
      <div
        className='contenedor-buttons'
        style={{ position: 'relative', bottom: '10px' }}
      >
        <button
          style={{ transition: 'none' }}
          key={Math.round(Math.random() * 10000)}
          onClick={() => {
            if (pageActual > 0) {
              setPageActual(0)
            }
          }}
        >
          Inicio
        </button>
        {buttons}
        <button
          key={Math.round(Math.random() * 10000)}
          onClick={() => {
            if (pageActual < data.length / rowsPerPage - 1) {
              setPageActual(Math.floor(data.length / rowsPerPage))
            }
          }}
        >
          Fin
        </button>
      </div>
    )
  }

  return (
    <>
      <div
        ref={tableRef}
        style={
          props.paginate
            ? {
                height: props.height ? props.height : 'calc(100% - 50px)',
                overflow: 'auto',
                position: 'relative',
                marginBottom: '10px',
              }
            : { height: '100%', overflow: 'auto', position: 'relative' }
        }
      >
        <Table stickyHeader className='table-dash'>
          <TableHead className={props.dobleTabla ? 'doble-tabla' : null}>
            <TableRow>
              {props.headers.map((header, j) => {
                return (
                  <TableCell
                    key={header}
                    className='HeadCell'
                    onClick={() => {
                      if (!props.dobleTabla) {
                        let value = parseInt(j)
                        console.log(value)
                        if (value === orderBy.col) {
                          setOrderBy({ col: orderBy.col, asc: !orderBy.asc })
                        } else {
                          setOrderBy({ col: value, asc: true })
                        }
                      }
                    }}
                  >
                    {header}
                    {props.dobleTabla ? null : (
                      <>
                        <div className='HeadArrows'>
                          <ArrowDropUpIcon
                            style={
                              orderBy.col === j && orderBy.asc
                                ? { color: 'red' }
                                : null
                            }
                          />
                          <ArrowDropDownIcon
                            style={
                              orderBy.col === j && !orderBy.asc
                                ? { color: 'red' }
                                : null
                            }
                          />
                        </div>
                      </>
                    )}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          {props.loading ? null : <TableBody>{getRows()}</TableBody>}
        </Table>
        {props.loading ? (
          <div
            style={{
              textAlign: 'center',
              marginTop: '10px',
              position: 'absolute',
              width: '100%',
            }}
          >
            <CircularProgress size={50} />
          </div>
        ) : null}
        {data.length === 0 && !props.loading ? (
          <p
            style={{ textAlign: 'center', width: '100%', position: 'absolute' }}
          >
            No hay registros que mostrar.
          </p>
        ) : null}
      </div>
      {props.paginate ? getPaginate() : null}
    </>
  )
}

DataTables.propTypes = {
  data: PropTypes.any,
  dobleTabla: PropTypes.bool,
  paginate: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  ultimaFila: PropTypes.any,
}

export default DataTables
