import { getRequest } from './requester'
export const particionGlobalAbierto = async (
  tipoPeriodo,
  nivelesParsed,
  setEstructuraAgrupadores,
  setCalendarios
) => {
  const response = await getRequest(
    '/reportes/participacion_global?year=' +
      '&tipo=' +
      tipoPeriodo +
      '&nivel=' +
      nivelesParsed
  )
  if (response.s === 'OK') {
    let myEstructuraCalendarios = {}
    let myEstructuraAgrupadores = {}
    let allCampus = []
    let agrupadores = {}

    Object.keys(response.d).forEach((key) => {
      let calendarioRegistro = response.d[key]
      Object.keys(calendarioRegistro).forEach((campusKey) => {
        if (!allCampus.includes(campusKey)) {
          allCampus = [...allCampus, campusKey]
        }

        agrupadores[campusKey] = calendarioRegistro[campusKey].agrupador_campus
      })
    })

    Object.keys(response.d).forEach((key) => {
      myEstructuraCalendarios[key] = {}
      let calendarioRegistro = response.d[key]
      let myAgrupadoresCampus = {}

      allCampus.forEach((campusKey) => {
        let campusRegistro

        if (calendarioRegistro.hasOwnProperty(campusKey)) {
          campusRegistro = calendarioRegistro[campusKey]
        } else {
          campusRegistro = {
            'Participación de Coordinador': null,
            'Participación docente': null,
            agrupador_campus: agrupadores[campusKey],
          }
        }

        if (
          !Object.keys(myAgrupadoresCampus).includes(
            campusRegistro.agrupador_campus
          )
        ) {
          myAgrupadoresCampus[campusRegistro.agrupador_campus] = {}
          myAgrupadoresCampus[campusRegistro.agrupador_campus][campusKey] =
            campusRegistro
          myEstructuraAgrupadores[campusRegistro.agrupador_campus] = {}
          myEstructuraAgrupadores[campusRegistro.agrupador_campus][campusKey] =
            []
        } else {
          myAgrupadoresCampus[campusRegistro.agrupador_campus][campusKey] =
            campusRegistro
          myEstructuraAgrupadores[campusRegistro.agrupador_campus][campusKey] =
            []
        }

        myEstructuraCalendarios[key] = myAgrupadoresCampus
      })
    })

    Object.keys(myEstructuraAgrupadores).forEach((key) => {
      Object.keys(myEstructuraCalendarios).forEach((calendarioKey) => {
        const agrupadorReg = myEstructuraCalendarios[calendarioKey][key]
        agrupadorReg &&
          Object.keys(agrupadorReg).forEach((campusKey) => {
            const regCampus =
              myEstructuraCalendarios[calendarioKey][key][campusKey]
            myEstructuraAgrupadores[key][campusKey].push(
              regCampus['Participación docente']
            )
            myEstructuraAgrupadores[key][campusKey].push(
              regCampus['Participación de Coordinador']
            )
          })
      })
    })

    Object.keys(myEstructuraAgrupadores).forEach((key) => {
      const agrupadorReg = myEstructuraAgrupadores[key]
      Object.keys(agrupadorReg).forEach((campusKey) => {
        let divisor = 0
        let promedioDocente = 0
        let promedioCoordinador = 0
        let promedioTotal = 0
        for (
          let i = 0;
          i < myEstructuraAgrupadores[key][campusKey].length;
          i += 2
        ) {
          if (myEstructuraAgrupadores[key][campusKey][i] != null) {
            promedioDocente += parseInt(
              myEstructuraAgrupadores[key][campusKey][i]
            )
            promedioCoordinador += parseInt(
              myEstructuraAgrupadores[key][campusKey][i + 1]
            )
            divisor += 1
          }
        }
        if (divisor !== 0) {
          promedioDocente /= divisor
          promedioCoordinador /= divisor
          promedioTotal = (promedioDocente + promedioCoordinador) / 2

          promedioDocente = parseInt(promedioDocente)
          promedioCoordinador = parseInt(promedioCoordinador)
          promedioTotal = parseInt(promedioTotal)
        } else {
          promedioDocente = null
          promedioCoordinador = null
          promedioTotal = null
        }

        myEstructuraAgrupadores[key][campusKey].push(promedioDocente)
        myEstructuraAgrupadores[key][campusKey].push(promedioCoordinador)
        myEstructuraAgrupadores[key][campusKey].push(promedioTotal)
      })
      agrupadorReg.promedios = []
      const divisores = []
      Object.keys(agrupadorReg).forEach((campusKey) => {
        if (campusKey !== 'promedios') {
          let campusRegistro = agrupadorReg[campusKey]
          campusRegistro.forEach((_value, i) => {
            if (agrupadorReg.promedios.length <= i) {
              if (campusRegistro[i] !== null) {
                agrupadorReg.promedios.push(parseInt(campusRegistro[i]))
                divisores.push(1)
              } else {
                agrupadorReg.promedios.push(null)
                divisores.push(0)
              }
            } else {
              if (campusRegistro[i] !== null) {
                if (agrupadorReg.promedios[i] === null) {
                  agrupadorReg.promedios[i] = parseInt(campusRegistro[i])
                } else {
                  agrupadorReg.promedios[i] += parseInt(campusRegistro[i])
                }
                divisores[i] += 1
              }
            }
          })
        }
      })
      agrupadorReg.promedios.forEach((_promedio, i) => {
        if (divisores[i] !== 0) {
          agrupadorReg.promedios[i] /= divisores[i]
        }
        agrupadorReg.promedios[i] =
          agrupadorReg.promedios[i] === null
            ? null
            : parseInt(agrupadorReg.promedios[i])
      })
    })

    const divisoresGlobales = []
    myEstructuraAgrupadores.GLOBAL = {
      promedios: [],
    }

    Object.keys(myEstructuraAgrupadores).forEach((key) => {
      if (key !== 'GLOBAL') {
        myEstructuraAgrupadores[key].promedios.forEach((promedio, i) => {
          if (myEstructuraAgrupadores.GLOBAL.promedios.length <= i) {
            if (promedio != null) {
              myEstructuraAgrupadores.GLOBAL.promedios.push(parseInt(promedio))
              divisoresGlobales.push(1)
            } else {
              myEstructuraAgrupadores.GLOBAL.promedios.push(null)
              divisoresGlobales.push(0)
            }
          } else {
            if (promedio != null) {
              myEstructuraAgrupadores.GLOBAL.promedios[i] += promedio
              divisoresGlobales[i] += 1
            }
          }
        })
      }
    })
    myEstructuraAgrupadores.GLOBAL.promedios.forEach((promedio, i) => {
      if (promedio != null) {
        myEstructuraAgrupadores.GLOBAL.promedios[i] /= divisoresGlobales[i]
        myEstructuraAgrupadores.GLOBAL.promedios[i] = parseInt(
          myEstructuraAgrupadores.GLOBAL.promedios[i]
        )
      }
    })

    myEstructuraAgrupadores = {
      GLOBAL: myEstructuraAgrupadores,
      ...myEstructuraAgrupadores,
    }

    setEstructuraAgrupadores(myEstructuraAgrupadores)
    setCalendarios(myEstructuraCalendarios)
  }
}
