/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import moment from 'moment'

import NewModal from '../../Components/Modal/NewModal'
import Contenedor from '../../Components/Contenedor/Contenedor'
import DataTable from '../../Components/DataTables'
import { getRequest } from '../../utils/requester'

import './style.css'

const ParticipacionCoordinador = () => {
  const [dataInicial, setDataInicial] = useState([])
  const [dataFiltradas, setDataFiltradas] = useState([])
  const [dataCompleta, setDataCompleta] = useState([])
  const [tipoPeriodo, setTipoPeriodo] = useState({
    label: 'Abierto',
    value: 'abierto',
  })
  const [years, setYears] = useState([])
  const [year, setYear] = useState({})
  const [loading, setLoading] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [loadingPeriodos, setLoadingPeriodos] = useState(false)
  const [periodos, setPeriodos] = useState([])
  const [periodo, setPeriodo] = useState({})
  const [ciclo, setCiclo] = useState({})
  const [detalleCoordinador, setDetalleCoordinador] = useState([])
  const [detalleCoordinadorFiltrado, setDetalleCoordinadorFiltrado] = useState(
    []
  )

  useEffect(() => {
    const myOpcionesYears = []
    const actualYear = new Date().getFullYear()
    for (let i = actualYear - 4; i <= actualYear; i++) {
      myOpcionesYears.push({
        label: String(i),
        value: String(i),
      })
    }
    setYears(myOpcionesYears)
    setYear({
      label: String(actualYear),
      value: String(actualYear),
    })
  }, [])

  useEffect(() => {
    getPeriodos()
  }, [])

  const ciclos = [
    {
      label: 'C1',
      value: 'C1',
    },
    {
      label: 'C2',
      value: 'C2',
    },
    {
      label: 'C3',
      value: 'C3',
    },
  ]

  const tipoPeriodoOptions = [
    {
      label: 'Abierto',
      value: 'abierto',
    },
    {
      label: 'Cerrado',
      value: 'cerrado',
    },
  ]

  const testHeader = [
    'Campus',
    'Periodo',
    'Clave Coordinador',
    'Nombre del Coordinador',
    'Específica',
    'Común',
    'Reconocimiento',
    'Porcentaje de Avance',
  ]

  ////////// Filtrar resultados segun el input de busqueda //////////
  const search = (e) => {
    const text = e.target.value.toLowerCase()

    const result = dataInicial.filter((row) => {
      if (row[0].toString().toLowerCase().includes(text.toLowerCase()))
        return true
      else if (row[1].toLowerCase().includes(text.toLowerCase())) return true
      else if (row[2].toLowerCase().includes(text.toLowerCase())) return true
      else if (row[3].props.children.toLowerCase().includes(text.toLowerCase()))
        return true
      return false
    })
    setDataFiltradas(result)
  }

  const searchTable = (e) => {
    const text = e.target.value.toLowerCase()

    const result = detalleCoordinador.filter((row) => {
      if (JSON.stringify(row).toLowerCase().includes(text)) {
        return true
      }
      return false
    })
    setDetalleCoordinadorFiltrado(result)
  }

  const reloadList = async () => {
    setDataInicial([])
    setDataFiltradas([])
    setDataCompleta([])
    setLoading(true)
    try {
      let urlRequest = ''
      if (tipoPeriodo.value === 'abierto') {
        urlRequest = '?tipo=abierto&periodo=' + periodo.value
      } else {
        urlRequest =
          '?tipo=cerrado&year=' + year.value + '&ciclo=' + ciclo.value
      }

      const response = await getRequest(
        '/reportes/participacion_coordinador' + urlRequest
      )
      if (response.s === 'OK') {
        const data = []
        response.d.forEach((reg) => {
          data.push([
            reg.campus,
            reg.periodo,
            reg.clave_coordinador,
            <span
              onClick={() => handleOpenModalDetalle(reg.Docentes)}
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              {reg.nombre_coordinador}
            </span>,

            reg.Específica === '-' ? reg.Específica : reg.Específica + '%',
            reg.Común === '-' ? reg.Común : reg.Común + '%',
            reg.Reconocimiento === '-'
              ? reg.Reconocimiento
              : reg.Reconocimiento + '%',
            reg.Avance === '-' ? reg.Avance : reg.Avance + '%',
            reg.nombre_coordinador,
          ])
        })
        setDataInicial(data)
        setDataFiltradas(data)
        setDataCompleta(response.d)
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  function handleOpenModalDetalle(docentes) {
    setDetalleCoordinador(docentes)
    setDetalleCoordinadorFiltrado(docentes)
    setModalIsOpen(true)
  }

  async function getPeriodos() {
    setLoadingPeriodos(true)
    try {
      const response = await getRequest(
        '/calendario/periodo?status=abierto'
      )
      if (response.s === 'OK') {
        const myPeriodos = []
        response.d.forEach((reg) => {
          myPeriodos.push({
            value: reg,
            label: reg,
          })
        })
        setPeriodos(myPeriodos)
        setLoadingPeriodos(false)
      } else {
        setPeriodos([])
        setLoadingPeriodos(false)
      }
    } catch (error) {
      console.error(error)
      setPeriodos([])
      setLoadingPeriodos(false)
    }
  }

  function downloadReporte() {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

    const dataDownload = dataInicial.map((reg) => {
      return {
        Campus: reg[0],
        Periodo: reg[1],
        'Clave Coordinador': reg[2],
        'Nombre Coordinador': reg[8],
        Específica: reg[4],
        Común: reg[5],
        Reconocimiento: reg[6],
        Avance: reg[7],
      }
    })
    const ws = XLSX.utils.json_to_sheet(dataDownload.map((row) => row))
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data2 = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(
      data2,
      `Reporte Participación Coordinador ::::: ${moment().format(
        'YYYY-MM-DD_HH:mm'
      )}.xlsx`
    )
  }

  function downloadMasiva() {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

    const dataDownload = []

    dataCompleta.forEach((coordinador) => {
      coordinador.Docentes.forEach((docente) => {
        dataDownload.push({
          Campus: coordinador.campus,
          Periodo: coordinador.periodo,
          'Clave Coordinador': coordinador.clave_coordinador,
          'Nombre Coordinador': coordinador.nombre_coordinador,
          Específica: coordinador.Específica + '%',
          Común: coordinador.Común + '%',
          Reconocimiento: coordinador.Reconocimiento + '%',
          Avance: coordinador.Avance + '%',
          'Clave Docente': docente['Clave Docente'],
          'Nombre Dimension': docente['Nombre Dimension'],
          'Nombre Docente': docente['Nombre Docente'],
          'Tipo Evaluación': docente['Tipo Evaluación'],
        })
      })
    })
    const ws = XLSX.utils.json_to_sheet(dataDownload.map((row) => row))
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data2 = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(
      data2,
      `Reporte Participación Coordinador Masiva  ${moment().format(
        'YYYY-MM-DD_HH:mm'
      )}.xlsx`
    )
  }

  return (
    <Contenedor title='Participación por Coordinador'>
      <div className='filtros-tabla-completa' style={{ float: 'left' }}>
        <div className='sub-filtro'>
          <p>FILTROS</p>
        </div>
        <div className='sub-filtro'>
          <p>Tipo Periodo:</p>
          <Select
            options={tipoPeriodoOptions}
            className='select-periodo'
            classNamePrefix='select-search'
            value={tipoPeriodo}
            onChange={setTipoPeriodo}
          />
        </div>
        {tipoPeriodo.value === 'cerrado' ? (
          <>
            <div className='sub-filtro'>
              <p>Año:</p>
              <Select
                options={years}
                className='select-periodo'
                classNamePrefix='select-search'
                value={year}
                onChange={setYear}
              />
            </div>
            <div className='sub-filtro'>
              <p>Ciclo:</p>
              <Select
                options={ciclos}
                className='select-periodo'
                classNamePrefix='select-search'
                value={ciclo}
                onChange={setCiclo}
              />
            </div>
          </>
        ) : (
          <div className='sub-filtro'>
            <p>Periodo:</p>
            {loadingPeriodos ? (
              <CircularProgress />
            ) : (
              <Select
                options={periodos}
                className='select-periodo'
                classNamePrefix='select-search'
                value={periodo}
                onChange={setPeriodo}
              />
            )}
          </div>
        )}
        <div className='sub-filtro'>
          <button
            onClick={loading ? () => {} : reloadList}
            className='NewButton'
          >
            Generar
          </button>
        </div>
      </div>
      <div className='opciones-bar' style={{ marginTop: '50px' }}>
        <div className='sub-filtro' style={{ marginRight: '10px' }}>
          <button className='NewButton' onClick={downloadReporte}>
            Descargar
          </button>
        </div>
        <div className='sub-filtro' style={{ display: 'none' }}>
          <button
            className='NewButton'
            onClick={downloadMasiva}
            style={{ backgroundColor: '#ec2028' }}
          >
            Descarga Masiva
          </button>
        </div>
        <div style={{ float: 'right' }}>
          <input
            type='search'
            placeholder='Buscar'
            className='SearchInput-tabla-completa SearchInput-Usuarios'
            onChange={search}
          />
        </div>
      </div>
      <div style={{ height: 'calc(100% - 120px)' }}>
        <DataTable
          headers={testHeader}
          data={dataFiltradas}
          loading={loading}
          paginate
        />
      </div>
      <NewModal
        title='Docentes pendientes de Evaluar'
        open={modalIsOpen}
        handleClose={() => {
          setModalIsOpen(false)
        }}
        height={500}
      >
        <div style={{ margin: '20px 10px', height: '80%', paddingTop: '30px' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-start',
                }}
              >
                <input
                  type='text'
                  placeholder='BUSCAR'
                  className='input-busqueda'
                  onChange={searchTable}
                />
              </div>
              <div
                style={{
                  height: '380px',
                  overflowY: 'scroll',
                }}
              >
                <table style={{ borderCollapse: 'collapse', margin: '0 auto' }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          backgroundColor: '#c7c7c7',
                          padding: '5px 20px',
                        }}
                      >
                        Clave Docente
                      </th>
                      <th
                        style={{
                          backgroundColor: '#d2d2d2',
                          padding: '5px 50px',
                        }}
                      >
                        Nombre Dimension
                      </th>
                      <th
                        style={{
                          backgroundColor: '#c7c7c7',
                          padding: '5px 20px',
                        }}
                      >
                        Nombre Docente
                      </th>
                      <th
                        style={{
                          backgroundColor: '#d2d2d2',
                          padding: '5px 20px',
                        }}
                      >
                        Tipo Evaluación
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {detalleCoordinadorFiltrado.map((item, i) => (
                      <tr>
                        <td
                          style={{
                            backgroundColor:
                              i % 2 === 0 ? '#ebebeb' : '#dbdbdb',
                          }}
                          className='itemTabla'
                        >
                          {item['Clave Docente']}
                        </td>
                        <td
                          style={{
                            backgroundColor: i % 2 === 0 ? '#fff' : '#ebebeb',
                          }}
                          className='itemTabla'
                        >
                          {item['Nombre Dimension']}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              i % 2 === 0 ? '#ebebeb' : '#dbdbdb',
                          }}
                          className='itemTabla'
                        >
                          {item['Nombre Docente']}
                        </td>
                        <td
                          style={{
                            backgroundColor: i % 2 === 0 ? '#fff' : '#ebebeb',
                          }}
                        >
                          {item['Tipo Evaluación']}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Grid>
          </Grid>
        </div>
      </NewModal>
    </Contenedor>
  )
}

export default ParticipacionCoordinador
