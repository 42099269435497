import React from 'react'
import PropTypes from 'prop-types'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import EditIcon from '@material-ui/icons/Edit'

import './style.css'

function NewButton(props) {
  return (
    <button className='NewButton' onClick={props.onClick}>
      {props.noIcon ? null : <AddCircleIcon />}
      {props.editar ? (
        <EditIcon />
      ) : (
        <span>{props.agregar ? props.agregar : 'Nuevo'}</span>
      )}
    </button>
  )
}

NewButton.propTypes = {
  onClick: PropTypes.func,
  noIcon: PropTypes.bool,
  editar: PropTypes.bool,
  agregar: PropTypes.string,
}

export default NewButton
