import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from '@material-ui/core'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import CircularProgress from '@material-ui/core/CircularProgress'

import SingleSelect from '../SingleSelect'
import './index.css'

function DataTables(props) {
  const data = props.data
  const [orderBy, setOrderBy] = useState({ col: 0, asc: true })
  const [rowsPerPage, setRowsPerPage] = useState(data.length)
  const [pageActual, setPageActual] = useState(0)
  const [resize, setResize] = useState(false)
  const tableRef = useRef(null)
  const [canEdit, setCanEdit] = useState(false)

  const [opcionesCoordinadores] = useState([])
  const [valueCoordinadores, setValueCoordinadores] = useState('')
  const [loadingCoordinadores, setLoadingCoordinadores] = useState(false)
  const [, setSelectedRow] = useState(-1)
  const [refresh] = useState(false)
  let i = 0

  if (orderBy.asc && !props.dobleTabla) {
    data.sort((a, b) => {
      if (a[orderBy.col] > b[orderBy.col]) {
        return 1
      }
      if (a[orderBy.col] < b[orderBy.col]) {
        return -1
      }
      return 0
    })
  } else if (!props.dobleTabla) {
    data.sort((a, b) => {
      if (a[orderBy.col] < b[orderBy.col]) {
        return 1
      }
      if (a[orderBy.col] > b[orderBy.col]) {
        return -1
      }
      return 0
    })
  }

  useEffect(() => {
    setSelectedRow(-1)
    setCanEdit(false)
  }, [props.refreshBusqueda])

  useEffect(() => {
    setPageActual(0)
  }, [data, refresh])

  useEffect(() => {
    if (props.paginate) {
      let height = tableRef.current.clientHeight
      let newRows = Math.trunc(height / 35)
      setRowsPerPage(newRows)
    } else {
      setRowsPerPage(data.length)
    }
  }, [data, props.paginate, resize])

  useEffect(() => {
    window.onresize = function onresize() {
      setResize(!resize)
    }
  })

  function setCoordinadorFila(
    fila,
    coordinadorClave,
    coordinadorNombre,
    idCoordinador
  ) {
    document
      .getElementById(`${fila}-0`)
      .getElementsByTagName('input')[0].value = coordinadorClave
    document
      .getElementById(`${fila}-0`)
      .getElementsByTagName('input')[0].id = idCoordinador
    document.getElementById(`${fila}-1`).innerHTML = coordinadorNombre
  }

  function getRows() {
    let rows = []
    let maxLength =
      pageActual < Math.ceil(data.length / rowsPerPage) - 1
        ? rowsPerPage * pageActual + rowsPerPage
        : data.length
    if (data.length > 0) {
      for (i = rowsPerPage * pageActual; i < maxLength; i++) {
        rows.push(
          ////////Genera un key aleatorio con 100000000 como valor maximo////////
          <TableRow key={Math.round(Math.random() * 100000000)}>
            {data[i].map((cell, k) =>
              k < props.headers.length ? (
                <>
                  <TableCell
                    className={
                      k === 0
                        ? 'td-coordinador-draggable'
                        : k === 1
                        ? 'td-coordinador-draggable-derecho'
                        : ''
                    }
                    onClick={(e) => {
                      if (k === 1 && canEdit) {
                        let filaDrag = e.target.id.split('-')[0]
                        //setSelectedRow(filaDrag)
                        setSelectedRowDom(filaDrag)
                      }
                    }}
                    draggable={
                      (k === 0 || k == 1) && canEdit ? 'true' : 'false'
                    }
                    id={i + '-' + k}
                    onDragStart={handleDragStart}
                    onDragOver={handleDragEnter}
                    key={Math.round(Math.random() * 100000000)}
                  >
                    {k === 0 ? (
                      <>
                        <SingleSelect
                          canEdit={canEdit}
                          options={opcionesCoordinadores}
                          selecteds={valueCoordinadores}
                          loading={loadingCoordinadores}
                          onSet={setValueCoordinadores}
                          setLoadingCoordinadores={setLoadingCoordinadores}
                          fila={i}
                          idCoordinador={data[i][data[i].length - 2]}
                          setCoordinadorFila={setCoordinadorFila}
                          valueOfCell={cell}
                          valueCampus={props.valueCampus}
                        />
                      </>
                    ) : (
                      cell
                    )}
                    {/*
                    k==1 && selectedRow===i ? 
                    (
                      <div className="cuadrito-selector">
                        
                      </div>
                      ):null
                      */}
                  </TableCell>
                </>
              ) : null
            )}
          </TableRow>
        )
      }
      if (props.ultimaFila) {
        rows.push(
          <TableRow
            className='ultimaFila'
            key={Math.round(Math.random() * 100000000)}
          >
            {props.ultimaFila.map((cell) => (
              <TableCell key={Math.round(Math.random() * 100000000)}>
                {cell}
              </TableCell>
            ))}
          </TableRow>
        )
      }
    } else {
      return null
    }
    return rows
  }

  function setSelectedRowDom(filaDrag) {
    let previoSeleccionado = document.getElementsByClassName('selected-row')
    if (previoSeleccionado[0] !== undefined) {
      document
        .getElementsByClassName('selected-row')[0]
        .classList.remove('selected-row')
      document
        .getElementsByClassName('selected-row')[0]
        .classList.remove('selected-row')
    }
    document.getElementById(`${filaDrag}-0`).classList.add('selected-row')
    document.getElementById(`${filaDrag}-1`).classList.add('selected-row')
  }

  function handleDragStart(e) {
    let filaDrag = e.target.id.split('-')[0]
    setSelectedRowDom(filaDrag)
    let img = document.createElement('img')
    e.dataTransfer.setDragImage(img, 0, 0)
    e.dataTransfer.effectAllowed = 'all'
  }

  function handleDragEnter(e) {
    let filaEnterDrag = e.target.id.split('-')[0]
    let previoSeleccionado = document.getElementsByClassName('selected-row')
    let filaPreviaSeleccionada = previoSeleccionado[0].id.split('-')[0]
    if (filaPreviaSeleccionada != filaEnterDrag) {
      let contenedorClaveCoordinadorPrevioSelected = document.getElementById(
        `${filaPreviaSeleccionada}-0`
      )
      let coordinadorPrevioSelected = document.getElementById(
        `${filaPreviaSeleccionada}-1`
      ).innerHTML
      let claveCoordinadorPrevioSelected = contenedorClaveCoordinadorPrevioSelected.getElementsByTagName(
        'input'
      )[0].value
      let idCoordinadorPrevioSelected = contenedorClaveCoordinadorPrevioSelected.getElementsByTagName(
        'input'
      )[0].id
      document
        .getElementById(`${filaEnterDrag}-0`)
        .getElementsByTagName('input')[0].value = claveCoordinadorPrevioSelected
      document
        .getElementById(`${filaEnterDrag}-0`)
        .getElementsByTagName('input')[0].id = idCoordinadorPrevioSelected
      document.getElementById(
        `${filaEnterDrag}-1`
      ).innerHTML = coordinadorPrevioSelected

      //props.actualizarBarra()
      setSelectedRowDom(filaEnterDrag)
    }
    e.dataTransfer.dropEffect = 'move'
  }

  function guardarCambios() {
    let inputsClavesCoordinadoresCambiadas = document.querySelectorAll(
      '.td-coordinador-draggable .input-coordinador-draggable'
    )
    let contenedorNombresCoordinadoresEditados = document.getElementsByClassName(
      'td-coordinador-draggable-derecho'
    )

    let registrosToSave = []

    for (let i = 0; i < contenedorNombresCoordinadoresEditados.length; i++) {
      if (contenedorNombresCoordinadoresEditados[i].innerHTML != '') {
        registrosToSave.push({
          id_coordinador: parseInt(inputsClavesCoordinadoresCambiadas[i].id),
          id_estructura: parseInt(data[i][data[i].length - 1]),
        })
      }
    }

    props.guardarEstructura(registrosToSave)
  }

  function getPaginate() {
    let buttons = []
    buttons.push(
      <button
        key={Math.round(Math.random() * 10000)}
        onClick={() => {
          if (pageActual > 0) {
            setPageActual(pageActual - 1)
          }
        }}
      >
        Anterior
      </button>
    )

    if (Math.ceil(data.length / rowsPerPage) > 6) {
      if (pageActual <= 2) {
        for (i = 0; i < 3; i++) {
          buttons.push(
            <button
              onClick={setPageActual.bind(this, i)}
              className={pageActual === i ? 'active' : null}
            >
              {i + 1}
            </button>
          )
        }
        buttons.push(<button>...</button>)
      } else if (pageActual >= Math.ceil(data.length / rowsPerPage) - 3) {
        buttons.push(<button>...</button>)
        for (
          i = Math.ceil(data.length / rowsPerPage) - 3;
          i < Math.ceil(data.length / rowsPerPage);
          i++
        ) {
          buttons.push(
            <button
              onClick={setPageActual.bind(this, i)}
              className={pageActual === i ? 'active' : null}
            >
              {i + 1}
            </button>
          )
        }
      } else {
        buttons.push(<button>...</button>)
        for (i = pageActual - 2; i < pageActual + 3; i++) {
          buttons.push(
            <button
              onClick={setPageActual.bind(this, i)}
              className={pageActual === i ? 'active' : null}
            >
              {i + 1}
            </button>
          )
        }
        buttons.push(<button>...</button>)
      }
    } else {
      for (i = 0; i < Math.ceil(data.length / rowsPerPage); i++) {
        buttons.push(
          <button
            key={Math.round(Math.random() * 10000)}
            onClick={setPageActual.bind(this, i)}
            className={pageActual === i ? 'active' : null}
          >
            {i + 1}
          </button>
        )
      }
    }
    buttons.push(
      <button
        key={Math.round(Math.random() * 10000)}
        onClick={() => {
          if (pageActual < data.length / rowsPerPage - 1) {
            setPageActual(pageActual + 1)
          }
        }}
      >
        Siguiente
      </button>
    )

    return (
      <div
        className='contenedor-buttons'
        style={{ position: 'relative', bottom: '10px' }}
      >
        {buttons}
      </div>
    )
  }

  return (
    <>
      <div className='header-edit-guardar'>
        {props.loadingGuardar ? (
          <CircularProgress
            size={30}
            color='white'
            style={{ marginLeft: '70px' }}
          />
        ) : (
          <>
            <button
              style={{ backgroundColor: '#fff', width: '70px' }}
              onClick={() => {
                if (canEdit) {
                  setSelectedRow(-1)
                }
                if (canEdit) {
                  props.cancelarEdicion()
                }
                setCanEdit(!canEdit)
              }}
            >
              {canEdit ? 'Cancelar' : 'Editar'}
            </button>
            <button
              style={{ backgroundColor: '#fff' }}
              disabled={!canEdit}
              onClick={guardarCambios}
            >
              Guardar
            </button>
          </>
        )}
      </div>
      <div
        ref={tableRef}
        style={
          props.paginate
            ? {
                height: 'calc(100% - 70px)',
                overflow: 'hidden',
                position: 'relative',
              }
            : {
                height: 'calc(100% - 70px)',
                overflow: 'auto',
                position: 'relative',
              }
        }
      >
        <Table stickyHeader className='table-dash'>
          <TableHead className={props.dobleTabla ? 'doble-tabla' : null}>
            <TableRow>
              {props.headers.map((header, j) => {
                return (
                  <>
                    <TableCell
                      key={header}
                      className={
                        (j === 0
                          ? 'HeadCell left-header'
                          : j === 1
                          ? 'HeadCell right-header'
                          : 'HeadCell') +
                        (props.sinAncho.includes(j) ? ' header-sin-ancho' : '')
                      }
                      col={i++}
                      onClick={(e) => {
                        if (!props.dobleTabla) {
                          let value = parseInt(e.target.getAttribute('col'))
                          if (value === orderBy.col) {
                            setOrderBy({ col: orderBy.col, asc: !orderBy.asc })
                          } else {
                            setOrderBy({ col: value, asc: true })
                          }
                        }
                      }}
                    >
                      {header}
                      {props.dobleTabla ? null : (
                        <>
                          <div className='HeadArrows'>
                            <ArrowDropUpIcon
                              style={
                                orderBy.col === i - 1 && orderBy.asc
                                  ? { color: 'red' }
                                  : null
                              }
                            />
                            <ArrowDropDownIcon
                              style={
                                orderBy.col === i - 1 && !orderBy.asc
                                  ? { color: 'red' }
                                  : null
                              }
                            />
                          </div>
                        </>
                      )}
                    </TableCell>
                  </>
                )
              })}
            </TableRow>
          </TableHead>
          {props.loading ? null : <TableBody>{getRows()}</TableBody>}
        </Table>
        {props.loading ? (
          <div
            style={{
              textAlign: 'center',
              marginTop: '10px',
              position: 'absolute',
              width: '100%',
            }}
          >
            <CircularProgress size={50} />
          </div>
        ) : null}
        {data.length === 0 && !props.loading ? (
          <p
            style={{ textAlign: 'center', width: '100%', position: 'absolute' }}
          >
            No hay registros que mostrar.
          </p>
        ) : null}
      </div>
      {props.paginate ? getPaginate() : null}
    </>
  )
}

DataTables.propTypes = {
  data: PropTypes.any,
  paginate: PropTypes.bool,
  dobleTabla: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  ultimaFila: PropTypes.any,
  guardarEstructura: PropTypes.func,
  refreshBusqueda: PropTypes.any,
  loadingGuardar: PropTypes.bool,
  cancelarEdicion: PropTypes.func,
  sinAncho: PropTypes.arrayOf(PropTypes.number),
  valueCampus: <PropTypes className='string'></PropTypes>,
}

export default DataTables
