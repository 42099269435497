import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Can from '../../utils/can'
import './index.css'

function Tabs({
  containersTabs,
  namesTabs,
  changeBox,
  modalTabs,
  permisos,
  defaultTab,
}) {
  const [activeTab, setActiveTab] = useState(defaultTab ? defaultTab : 1)
  let tabColor = '#E72400',
    tabComponent
  tabComponent = containersTabs

  return (
    <>
      <div className='tabs-list'>
        {namesTabs.map((tab, i) =>
          permisos ? (
            <Can I='read' a={permisos[i]}>
              <div
                key={tab[0]}
                className={activeTab === tab[0] ? 'tab active' : 'tab'}
                onClick={() => {
                  setActiveTab(tab[0])
                  changeBox(tab[0])
                }}
              >
                {tab[1]}
              </div>
            </Can>
          ) : (
            <div
              key={tab[0]}
              className={activeTab === tab[0] ? 'tab active' : 'tab'}
              onClick={() => {
                setActiveTab(tab[0])
                changeBox(tab[0])
              }}
            >
              {tab[1]}
            </div>
          )
        )}
      </div>
      <div className={modalTabs ? 'tab-content-modal' : 'tab-content'}>
        <div className='tab-header' style={{ backgroundColor: tabColor }}></div>
        {tabComponent[activeTab - 1][0]}
      </div>
    </>
  )
}

Tabs.propTypes = {
  containerTabs: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.element)),
  namesTabs: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType(PropTypes.number, PropTypes.string))
  ),
  changeBox: PropTypes.func,
  modalTabs: PropTypes.bool,
  defaultTab: PropTypes.number,
  permisos: PropTypes.arrayOf(PropTypes.any),
}

export default Tabs
