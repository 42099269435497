import React from 'react';
import TableRow from './TableRow';
import { StyledTable, TH, OptionsText } from './styles';

export default function IntervaloMobile ({
  reactivos,
  questionIndex,
  asignaciones,
  agrupadores,
  answerData,
  setAnswerData,
  tipoEvaluacion
}){
  const handleClick = (asignacion, res, val) => {
    const agrupadorActual = agrupadores.filter((agrupador) =>
      agrupador.reactivos.includes(reactivos[questionIndex]),
    )[0];

    if (
      answerData.respuestas.find(
        (respuesta) =>
          respuesta.id_asignacion === asignacion.id &&
          respuesta.id_agrupador === agrupadorActual.id,
      )
    ) {
      const respuestasFiltradas = answerData.respuestas.filter(
        (respuesta) =>
          respuesta.id_asignacion !== asignacion.id ||
          respuesta.id_reactivo !== reactivos[questionIndex].id ||
          respuesta.id_agrupador !== agrupadorActual.id,
      );

      setAnswerData({
        ...answerData,
        respuestas: [
          ...respuestasFiltradas,
          {
            id_agrupador: agrupadorActual.id,
            id_reactivo: reactivos[questionIndex].id,
            clave_materia: asignacion.clave,
            clave_docente: asignacion.clave_docente,
            id_asignacion: asignacion.id,
            respuesta: res,
            valor_respuesta: val,
            tipo: 'intervalo',
          },
        ],
      });
    } else {
      setAnswerData({
        ...answerData,
        respuestas: [
          ...answerData.respuestas,
          {
            id_agrupador: agrupadorActual.id,
            id_reactivo: reactivos[questionIndex].id,
            clave_materia: asignacion.clave,
            clave_docente: asignacion.clave_docente,
            id_asignacion: asignacion.id,
            respuesta: res,
            valor_respuesta: val,
            tipo: 'intervalo',
          },
        ],
      });
    }
  };

  return (
    <StyledTable>
      <thead>
        <tr>
        {
          tipoEvaluacion==='Opinion_Estudiantil' ?
          <>
            <TH>
              <p
                style={{
                  fontSize: '13px',
                  fontWeight: '400',
                  textAlign: 'left',
                }}>
                Asignatura
              </p>
            </TH>
            <TH>
              <p
                style={{
                  fontSize: '13px',
                  fontWeight: '400',
                  textAlign: 'left',
                }}>
                Docente
              </p>
            </TH>
          </>:null
        }
          {reactivos[questionIndex].opciones.map(({ id, nombre, valor }, i) => (
            <TH key={id} dark={i % 2 === 0}>
              <OptionsText>{nombre}</OptionsText>
              <OptionsText>{valor}</OptionsText>
            </TH>
          ))}
        </tr>
      </thead>
      <tbody>
        {asignaciones.map((asignacion, i) => (
          <TableRow
            tipoEvaluacion={tipoEvaluacion}
            answerData={answerData}
            key={asignacion.id}
            dark={i % 2 === 0}
            id={asignacion.id}
            asignatura={asignacion.nombre}
            docente={asignacion.docente}
            reactivos={reactivos}
            questionIndex={questionIndex}
            handleClick={(res, val) => handleClick(asignacion, res, val)}
          />
        ))}
      </tbody>
    </StyledTable>
  );
};