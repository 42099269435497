import React from 'react'
import Select, { components } from 'react-select'
import SimpleCheck from '../SimpleCheck'

const asyncRowSelectStyles = {
  menu: (base) => ({
    ...base,
    maxHeight: '225px',
    zIndex: 9999,
    width: 280,
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: '225px',
    padding: '5px',
  }),
}

const MenuList = (props) => {
  return (
    <components.Menu {...props}>
      <components.MenuList {...props}>
        {props.options.map((item) => (
          <SimpleCheck
            key={item.id}
            label={item.label}
            isActive={item.isActive}
            onClick={() => {
              props.selectProps.onChange(item)
            }}
          />
        ))}
      </components.MenuList>
    </components.Menu>
  )
}

export const SimpleMultiSelect = ({ options, onChange, value }) => {
  return (
    <Select
      components={{ Menu: MenuList }}
      closeMenuOnSelect={false}
      options={options}
      name='etiqueta'
      placeholder='Selección...'
      className='select-periodo'
      styles={asyncRowSelectStyles}
      onChange={onChange}
      value={value}
    />
  )
}
