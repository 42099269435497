/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import Contenedor from '../../Components/Contenedor/Contenedor'
import Tabs from '../../Components/Tabs'
import NewButton from '../../Components/Botones/NewButton'
import EditButton from '../../Components/Botones/EditButton'
import DataTable from '../../Components/DataTables'
import NewModal from '../../Components/Modal/NewModal'
import NuevoUsuario from './NuevoUsuario'
import EditarUsuario from './EditarUsuario'
import Permisos from './Permisos'
import { getRequest, postRequest } from '../../utils/requester'
import './style.css'

function Usuarios({ listaCampus }) {
  const [dataUsuariosInicial, setDataUsuariosInicial] = useState([])
  const [dataUsuariosFiltrados, setDataUsuariosFiltrados] = useState([])

  const [modalNuevoUsuario, setModalNuevoUsuario] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [opcionesCampus, setOpcionesCampus] = useState([])
  const [valueCampus, setValueCampus] = useState('')
  const [quantityCampus, setQuantityCampus] = useState(0)
  const [usuarioNuevo, setUsuarioNuevo] = useState({})
  const [valoresPermisos, setvaloresPermisos] = useState([])
  const [configPermisos, setConfirgPermisos] = useState([])
  const [, setTab] = useState(1)

  const [opcionesRol, setOpcionesRol] = useState([])
  const [valueRol, setValueRol] = useState('')
  const [quantityRol, setQuantityRol] = useState(0)
  const [loading, setLoading] = useState(false)

  const [deleteModal, setDeleteModal] = useState(false)
  const [modalEditarUsuarioIsOpen, setModalEditarUsuarioIsOpen] =
    useState(false)
  const [usuarioEditar, setUsuarioEditar] = useState({})
  const [loadingModalEditar, setLoadingModalEditar] = useState(false)
  const [deleteModalLoading] = useState(false)
  const namesTabs = [
    [1, 'Información'],
    [2, 'Permisos'],
  ]
  useEffect(() => {
    reload()
  }, [])

  const permisos = {
    permiso_docente: [
      {
        nombre: 'mis_evaluaciones',
        label: 'Mis evaluaciones',
      },
    ],
    permiso_coordinador: [
      {
        nombre: 'evaluaciones_docente',
        label: 'Evaluación Docente',
      },
    ],
    permiso_consulta: [
      {
        nombre: 'reportes',
        label: 'Reportes',
      },
      {
        nombre: 'reportes_avance',
        label: 'Reportes de avance',
      },
      {
        nombre: 'reportes_avance_participacion_global',
        label: 'Participación Global',
      },
      {
        nombre: 'reportes_avance_participacion_coordinador',
        label: 'Participación por coordinador',
      },
      {
        nombre: 'reportes_avance_participacion_docente',
        label: 'Participación por Docente',
      },
      {
        nombre: 'reportes_resultados',
        label: 'Reportes de Resultados',
      },
      {
        nombre: 'reportes_avance_integral_periodo_docente',
        label: 'Integral por Periodo',
      },
      {
        nombre: 'reportes_avance_reporte_CRN_docente',
        label: 'Detalle por CRN',
      },
      {
        nombre: 'reportes_avance_CRN_docente_dimension',
        label: 'Integral por dimensión',
      },
      {
        nombre: 'reportes_avance_asignaciones_FIGPO',
        label: 'Asignaciones Evaluables',
      },
      {
        nombre: 'reportes_avance_seguimiento_estructura',
        label: '',
      },
      {
        nombre: 'estructura',
        label: 'Estructura',
      },
      {
        nombre: 'estructura_seguimiento_academicos',
        label: 'Seguimiento Académicos',
      },
      {
        nombre: 'estructura_creado_estructura',
        label: 'Creado de estructura',
      },
      {
        nombre: 'estructura_reporte_avance',
        label: 'Avance de estructuras',
      },
      {
        nombre: 'estructura_seguimiento_academicos_estandares',
        label: 'Seguimiento a Estándares',
      },
      {
        nombre: 'estructura_seguimiento_academicos_plantilla_academica',
        label: 'Seguimiento a plantilla académica',
      },
      {
        nombre: 'estructura_seguimiento_academicos_catalogos',
        label: 'Catalogos Seguimiento Academicos',
      },
    ],
    permiso_administrador: [
      {
        nombre: 'estructura_enviar',
        label: 'Envio de Estructura',
      },
      {
        nombre: 'usuarios',
        label: 'Usuarios',
      },
      {
        nombre: 'objetos_instrumento',
        label: 'Objetos de Instrumentos',
      },
      {
        nombre: 'objetos_instrumento_etiquetas',
        label: 'Etiquetas',
      },
      {
        nombre: 'objetos_instrumento_reactivos',
        label: 'Reactivos',
      },
      {
        nombre: 'objetos_instrumento_instrumento',
        label: 'Instrumentos',
      },
      {
        nombre: 'objetos_dimensiones',
        label: 'Objetos de Dimensiones',
      },
      {
        nombre: 'objetos_dimensiones_categorias_evaluacion',
        label: 'Categoría de evaluación',
      },
      {
        nombre: 'objetos_dimensiones_dimensiones',
        label: 'Dimensiones',
      },
      {
        nombre: 'objetos_dimensiones_evaluacion_integral',
        label: 'Evaluación Integral',
      },
      {
        nombre: 'campus',
        label: 'Campus',
      },
      {
        nombre: 'calendario',
        label: 'Calendario',
      },
      {
        nombre: 'calendario_ciclo-periodo',
        label: 'Relación Ciclo-Periodo',
      },
      {
        nombre: 'calendario_plantilla-evaluaciones',
        label: 'Relación Partes Periodo',
      },
      {
        nombre: 'calendario_evaluacion-año',
        label: 'Apertura de Calendario',
      },
    ],
  }

  const reload = async () => {
    setLoading(true)
    try {
      const usuariosResponse = await getUsuarios()
      if (usuariosResponse.s === 'OK') {
        let usuarios = []
        usuariosResponse.d.forEach((usuario) => {
          usuarios.push([
            usuario.clave.replace('a', ''),
            usuario.nombre,
            <EditButton onClick={() => openEditModal(usuario.id_user)}>
              Editar
            </EditButton>,
          ])
        })
        setDataUsuariosInicial(usuarios)
        setDataUsuariosFiltrados(usuarios)
        setLoading(false)
        setLoading(false)
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const orderPermisos = (permisos) => {
    const permisosArray = []
    for (let i in permisos) {
      const key = Object.keys(permisos[i])
      for (let j in permisos[i][key]) {
        permisosArray.push({
          funcionalidad: permisos[i][key][j].permiso,
          eliminar: permisos[i][key][j].eliminar === '1' ? '1' : '0',
          escribir: permisos[i][key][j].escribir === '1' ? '1' : '0',
          leer: permisos[i][key][j].leer === '1' ? '1' : '0',
          modificar: permisos[i][key][j].modificar === '1' ? '1' : '0',
        })
      }
    }
    return permisosArray
  }

  const getPermisos = async (valores) => {
    if (valoresPermisos) {
      let response = await getRequest('/usuarios/permisos')
      let data = response
      let misValores = []
      Object.keys(data.d).forEach((key) => {
        let registroPremisoArray = []
        Object.keys(data.d[key]).forEach((subKey) => {
          const exists = permisos[key.replaceAll(' ', '_')].find(
            ({ nombre }) => nombre === data.d[key][subKey]
          )

          let registroPremiso = {
            permiso: data.d[key][subKey],
            ...exists,
          }

          // registroPremiso['leer'] = false
          // registroPremiso['escribir'] = false
          // registroPremiso['eliminar'] = false
          // registroPremiso['modificar'] = false
          registroPremiso['todos'] = '1'

          if (data.d[key][subKey].includes('leer')) {
            let posicion = data.d[key][subKey].indexOf('leer')
            data.d[key][subKey][posicion] = data.d[key][subKey][0]
            data.d[key][subKey][0] = 'leer'
          }

          registroPremiso.orden = ['leer', 'crear', 'eliminar', 'actualizar']
          registroPremiso.permisos = [
            'leer',
            'crear',
            'eliminar',
            'actualizar',
            'todos',
          ]

          if (valores) {
            const newValores = valores.reduce(
              (obj, item) => ({
                ...obj,
                [item.funcionalidad]: item,
              }),
              {}
            )

            registroPremiso = {
              ...registroPremiso,
              ...newValores[registroPremiso.permiso],
            }
          }

          registroPremisoArray.push(registroPremiso)
        })
        misValores.push({
          [key]: registroPremisoArray,
        })
      })
      if (valores && valores[0] != null) {
        valores.forEach((valor) => {
          misValores.forEach((tipo) => {
            tipo[Object.keys(tipo)].forEach((valor2) => {
              if (valor.funcionalidad === valor2.permiso) {
                Object.keys(valor).forEach((key) => {
                  valor2[key.toLowerCase()] = valor[key]
                  if (valor[key] === '0') {
                    valor2['todos'] = '0'
                  }
                })
              }
            })
          })
        })
      } else {
        misValores.forEach((tipo) => {
          tipo[Object.keys(tipo)].forEach((valor) => {
            valor.todos = '0'
          })
        })
      }
      setvaloresPermisos(misValores)
      setConfirgPermisos(misValores)
      setLoadingModal(false)
    }
  }

  async function handleOpenModalNuevoUsuario() {
    setModalNuevoUsuario(true)
    setLoadingModal(true)
    setValueCampus('')
    setQuantityCampus(0)
    setUsuarioNuevo({
      clave: '',
      nombre: '',
      usuario: '',
    })

    let myOpcionesCampus = []
    myOpcionesCampus.push({
      label: 'Todos',
      value: '0',
    })
    listaCampus.forEach((reg) => {
      myOpcionesCampus.push({
        label: reg.nombre,
        value: String(reg.id),
      })
    })

    setOpcionesRol([
      {
        label: 'administrador',
        value: 'administrador',
      },
      {
        label: 'consulta',
        value: 'consulta',
      },
      {
        label: 'profesor',
        value: 'profesor',
      },
      {
        label: 'coordinador',
        value: 'coordinador',
      },
    ])

    setOpcionesCampus(myOpcionesCampus)
    getPermisos()
  }
  const openEditModal = async (id) => {
    setModalEditarUsuarioIsOpen(true)

    setLoadingModalEditar(true)

    try {
      const usuarioResponse = await getUsuario(id)
      setValueCampus(usuarioResponse.d.lista_campus)

      if (usuarioResponse.s === 'OK') {
        setUsuarioEditar(usuarioResponse.d)
      }

      if (usuarioResponse.d.permisos !== undefined) {
        getPermisos(usuarioResponse.d.permisos)
      } else {
        getPermisos()
      }
      setLoadingModalEditar(false)

      let myOpcionesCampus = []
      myOpcionesCampus.push({
        label: 'Todos',
        value: '0',
      })
      listaCampus.forEach((reg) => {
        myOpcionesCampus.push({
          label: reg.nombre,
          value: String(reg.id),
        })
      })
      setOpcionesCampus(myOpcionesCampus)

      setLoadingModalEditar(false)
    } catch (error) {
      setModalEditarUsuarioIsOpen(false)
      setLoadingModalEditar(false)
      console.error(error)
    }
  }

  const containersTabsNuevo = [
    [
      <NuevoUsuario
        usuarioEditar={usuarioNuevo}
        setUsuarioEditar={setUsuarioNuevo}
        opcionesCampus={opcionesCampus}
        setQuantityCampus={setQuantityCampus}
        quantityCampus={quantityCampus}
        setValueCampus={setValueCampus}
        valueCampus={valueCampus}
        opcionesRol={opcionesRol}
        setQuantityRol={setQuantityRol}
        quantityRol={quantityRol}
        setValueRol={setValueRol}
        valueRol={valueRol}
      />,
    ],
    [
      <Permisos
        deselectAll={deselectAll}
        selectAll={selectAll}
        valoresPermisos={valoresPermisos}
        changePermiso={changePermiso}
        configPermisos={configPermisos}
      />,
    ],
  ]
  const containersTabsEditar = [
    [
      <EditarUsuario
        usuarioEditar={usuarioEditar}
        setUsuarioEditar={setUsuarioEditar}
        opcionesCampus={opcionesCampus}
        setQuantityCampus={setQuantityCampus}
        quantityCampus={quantityCampus}
        setValueCampus={setValueCampus}
        valueCampus={valueCampus}
        opcionesRol={opcionesRol}
        setQuantityRol={setQuantityRol}
        quantityRol={quantityRol}
        setValueRol={setValueRol}
        valueRol={valueRol}
      />,
    ],
    [
      <Permisos
        deselectAll={deselectAll}
        selectAll={selectAll}
        valoresPermisos={valoresPermisos}
        changePermiso={changePermiso}
        configPermisos={configPermisos}
      />,
    ],
  ]
  function selectAll() {
    valoresPermisos.forEach((key, i) => {
      valoresPermisos[i][Object.keys(key)[0]].forEach((_valor, j) => {
        changePermiso(Object.keys(key), j, 'todos', true)
      })
    })
  }
  function deselectAll() {
    valoresPermisos.forEach((key, i) => {
      valoresPermisos[i][Object.keys(key)[0]].forEach((_valor, j) => {
        changePermiso(Object.keys(key), j, 'todos', false)
      })
    })
  }
  function changePermiso(key, index, permiso, value) {
    let misValores = []
    valoresPermisos.forEach((valor) => {
      misValores.push(valor)
    })
    const valoresIndex = misValores.findIndex(
      (permiso) => Object.keys(permiso)[0] === key[0]
    )
    if (permiso === 'todos') {
      if (value) {
        Object.keys(misValores[valoresIndex][key][index]).forEach((reg) => {
          if (
            reg === 'modificar' ||
            reg === 'eliminar' ||
            reg === 'escribir' ||
            reg === 'leer' ||
            reg === 'todos'
          ) {
            misValores[valoresIndex][key][index][reg] = '1'
          }
        })
      } else {
        Object.keys(misValores[valoresIndex][key][index]).forEach((reg) => {
          if (
            reg === 'modificar' ||
            reg === 'eliminar' ||
            reg === 'escribir' ||
            reg === 'leer' ||
            reg === 'todos'
          ) {
            misValores[valoresIndex][key][index][reg] = '0'
          }
        })
      }
    } else {
      // check if value != true
      if (!value) {
        misValores[valoresIndex][key][index][permiso] = '0'
      } else {
        misValores[valoresIndex][key][index][permiso] = '1'
      }
      // if permiso = 'leer' and value = false, change all values to false
      if (permiso === 'leer' && !value) {
        Object.keys(misValores[valoresIndex][key][index]).forEach((reg) => {
          if (reg === 'modificar' || reg === 'eliminar' || reg === 'escribir') {
            misValores[valoresIndex][key][index][reg] = '0'
          }
        })
      }
      // if all values are true then 'todos' = true
      if (
        misValores[valoresIndex][key][index]['leer'] === '1' &&
        misValores[valoresIndex][key][index]['modificar'] === '1' &&
        misValores[valoresIndex][key][index]['eliminar'] === '1' &&
        misValores[valoresIndex][key][index]['escribir'] === '1'
      ) {
        misValores[valoresIndex][key][index]['todos'] = '1'
      } else {
        misValores[valoresIndex][key][index]['todos'] = '0'
      }
    }
    setvaloresPermisos(misValores)
  }
  const search = (e) => {
    let text = e.target.value.toLowerCase()
    let result = dataUsuariosInicial.filter((row) => {
      if (row[0].toString().toLowerCase().includes(text)) return true
      else if (row[1].toLowerCase().includes(text)) return true
      return false
    })
    setDataUsuariosFiltrados(result)
  }

  // Create new User
  async function nuevoUsuario() {
    if (!valueCampus) {
      toast.error('Debes selecionar al menos un campus')
    } else if (!usuarioNuevo.clave) {
      toast.error('Debes de insertar una Clave')
    } else if (!usuarioNuevo.nombre) {
      toast.error('Debes de insertar un Nombre')
    } else if (!usuarioNuevo.usuario) {
      toast.error('Debes de insertar un Correo Electrónico')
    }
    try {
      setLoadingModal(true)
      const permisos = orderPermisos(valoresPermisos)
      const user = {
        clave: usuarioNuevo.clave,
        nombre: usuarioNuevo.nombre,
        usuario: usuarioNuevo.usuario,
        lista_campus: valueCampus,
        permisos: permisos,
      }

      const response = await postRequest('/usuarios/nuevo', user)
      setLoadingModal(false)
      if (response.s === 'OK') {
        reload()
        setModalNuevoUsuario(false)
      } else {
        setModalNuevoUsuario(false)
      }
    } catch (error) {
      setModalNuevoUsuario(false)
      console.log(error)
      toast.error('Ocurrió un error inesperado.')
    }
  }

  // Update User Info
  async function updateUsuario() {
    try {
      setLoadingModalEditar(true)
      const findUser = dataUsuariosInicial.find(
        (usuarios) => usuarios[0] === usuarioEditar.clave
      )
      const permisos = orderPermisos(valoresPermisos)

      const user = {
        id: parseInt(usuarioEditar.id_user),
        clave: usuarioEditar.clave,
        nombre: usuarioEditar.nombre,
        usuario: usuarioEditar.username,
        lista_campus: valueCampus,
        permisos: permisos,
        status: usuarioEditar.status === true ? '1' : '0',
      }
      const response = await postRequest('/usuarios/editar', user)
      if (response.s === 'OK') {
        toast.success(response.m)
      }
      findUser[1] = usuarioEditar.nombre
      findUser[0] = usuarioEditar.clave
      setModalEditarUsuarioIsOpen(false)
    } catch (error) {
      setModalEditarUsuarioIsOpen(false)
      console.log(error)
      toast.error('Ocurrió un error inesperado.')
    }
  }

  // Delete User
  async function deleteUsuario() {
    try {
      setDeleteModal(false)
      setLoadingModalEditar(true)
      const permisos = orderPermisos(valoresPermisos)

      const user = {
        id: parseInt(usuarioEditar.id_user),
        clave: usuarioEditar.clave,
        nombre: usuarioEditar.nombre,
        usuario: usuarioEditar.username,
        lista_campus: valueCampus,
        permisos: permisos,
        status: '0',
      }
      const response = await postRequest('/usuarios/editar', user)
      if (response.s === 'OK') {
        setLoadingModalEditar(false)
        setModalEditarUsuarioIsOpen(false)
        reload()
      } else {
        setLoadingModalEditar(false)
        setModalEditarUsuarioIsOpen(false)
      }
    } catch (error) {
      setLoadingModalEditar(false)
      setModalEditarUsuarioIsOpen(false)
      console.log(error)
      toast.error('Ocurrió un error inesperado.')
    }
  }

  return (
    <Contenedor title='Usuarios'>
      <div className='opciones-bar'>
        <div className='sub-filtro'>
          <NewButton onClick={handleOpenModalNuevoUsuario} />
        </div>
        <div style={{ float: 'right' }}>
          <input
            type='search'
            placeholder='Buscar'
            className='SearchInput-tabla-completa SearchInput-Usuarios'
            onChange={search}
          />
        </div>
      </div>
      <div style={{ height: 'calc(100% - 70px)' }}>
        <DataTable
          headers={['Clave', 'Nombre', 'Acciones']}
          data={dataUsuariosFiltrados}
          loading={loading}
          paginate
        />
      </div>

      <NewModal
        title='Nuevo Usuario'
        open={modalNuevoUsuario}
        handleSubmit={() => {
          nuevoUsuario()
        }}
        handleClose={() => setModalNuevoUsuario(false)}
        height={'80vh'}
        loading={loadingModal}
      >
        <Tabs
          modalTabs
          namesTabs={namesTabs}
          containersTabs={containersTabsNuevo}
          changeBox={setTab}
        />
      </NewModal>
      <NewModal
        onDelete={() => {
          setDeleteModal(true)
        }}
        canDelete
        title='Editar Usuario'
        open={modalEditarUsuarioIsOpen}
        handleSubmit={() => {
          updateUsuario()
        }}
        handleClose={() => setModalEditarUsuarioIsOpen(false)}
        height={'80vh'}
        permiso='usuarios'
        loading={loadingModalEditar}
      >
        <Tabs
          modalTabs
          namesTabs={namesTabs}
          containersTabs={containersTabsEditar}
          changeBox={setTab}
        />
      </NewModal>
      <NewModal
        open={deleteModal}
        height={120}
        title='Confirmar'
        loading={deleteModalLoading}
        handleClose={() => setDeleteModal(false)}
        handleSubmit={() => {
          deleteUsuario()
        }}
      >
        <div className='delete-confirm'>
          <h2 style={{ textAlign: 'center' }}>
            ¿Está seguro de que desea eliminar este usuario?
          </h2>
        </div>
      </NewModal>
    </Contenedor>
  )
}
const mapStateToProps = (state) => {
  return {
    listaCampus: state.listaCampus,
  }
}

export default connect(mapStateToProps)(Usuarios)

// Get all users Info
async function getUsuarios() {
  try {
    const response = await getRequest('/usuarios')
    return response
  } catch (error) {
    console.log(error)
  }
}
// Get individual user Info
async function getUsuario(id) {
  try {
    const response = await getRequest(`/usuarios/detalle?id=${id}`)
    return response
  } catch (error) {
    console.log(error)
  }
}
