import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import SeleccionMobile from '../components/SeleccionMobile';
import Progress from '../components/Progress';
import Button from '../components/Button';

const InicioContainer = styled.div`
  margin: 1rem 3rem 0;
  @media screen and (max-width: 480px) {
    margin: 1rem 1rem 0;
  }
`;

const Wrapper = styled.div`
  @media screen and (min-width: 1440px) {
    display: grid;
    grid-template-rows: 2fr 1fr;
  }
  @media screen and (min-width: 1366px) {
    grid-template-rows: 1fr 1fr;
  }
`;

const Controls = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;

  @media screen and (max-width: 480px) {
    position: fixed;
    width: 91%;
    margin: 0 1rem;
  }
`;
const Name = styled.p`
  font-size: 24px;
  text-align: center;
`;
const Question = styled.p`
  font-size: 24px;
`;

const FormContainer = styled.div`
  margin: 1rem 1rem 2rem;

  @media screen and (max-width: 1366px) {
    margin: 0 1rem 2rem;
  }

  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;

export default function Seleccion({
  loading,
  agrupadores,
  asignaciones,
  questionIndex,
  setQuestionIndex,
  reactivos,
  selectionOptionsSelected,
  setSelectionOptionsSelected,
  answerData,
  setAnswerData,
  clearAnswers,
  type,
  setTipoActual,
  salir,
  tipoEvaluacion
}){
  const question = reactivos[questionIndex].pregunta;
  let name;
  let orden;
  agrupadores.forEach((agrupador) => {
    if (agrupador.reactivos.includes(reactivos[questionIndex])) {
      name = agrupador.nombre;
      orden = agrupador.orden;
    }
  });
  const respuestasActuales = answerData.respuestas.filter(
    (respuesta) =>
      respuesta.id_reactivo === reactivos[questionIndex].id && 
      (reactivos[questionIndex].tipo === 'seleccion'  || reactivos[questionIndex].tipo==='seleccion simple'),
  );


  return (
    <>
    {
        loading ?
          <div style={{ textAlign: 'center', paddingTop: 30 }}>
            <CircularProgress size={50} />
          </div>
        :
        <InicioContainer>
          <Name>{`${orden}.${name}`}</Name>
          <Question>{`${orden}.${reactivos[questionIndex].orden} ${question}`}</Question>

          <Wrapper>
            <FormContainer>
              <SeleccionMobile 
              tipoEvaluacion={tipoEvaluacion}
              setAnswerData={setAnswerData}
              answerData={answerData}
              setSelectedOptions={setSelectionOptionsSelected}
              selectionOptionsSelected={selectionOptionsSelected}
              reactivos={reactivos} 
              questionIndex={questionIndex} 
              setQuestionIndex={setQuestionIndex}
              asignaciones={asignaciones} 
              agrupadores={agrupadores}
              setTipoActual={setTipoActual}
              />
            </FormContainer>
          </Wrapper>
        </InicioContainer>
    }

      <Controls>
        <Progress
          agrupadores={agrupadores}
          reactivos={reactivos}
          canContinue={true}
          step={questionIndex + 1}
          questionIndex={questionIndex}
          setQuestionIndex={setQuestionIndex}
          clearAnswers={clearAnswers}
          salir={salir}
          answerData={answerData}
          setTipoActual={setTipoActual}
        />
        <Button onClick={salir}/>
      </Controls>
    </>
  );
};