import { getRequest } from './requester'
export const particionGlobalCerrado = async (
  year,
  ciclo,
  periodo,
  tipoPeriodo,
  arraySelect,
  setEstructuraAgrupadores,
  setCalendarios
) => {
  let myEstructuraCalendarios = {}
  let myEstructuraAgrupadores = {
    GLOBAL: { promedios: [] },
  }
  let lastOneWrong = false
  for (let i in arraySelect) {
    const response = await getRequest(
      `/reportes/participacion_global?year=${year}&ciclo=${ciclo}&periodo=${periodo}&tipo=${tipoPeriodo}&id_calendar=${arraySelect[i].id_calendario}`
    )
    if (response.s === 'OK') {
      if (response.d[arraySelect[i].value] !== undefined) {
        const isFirstOne = lastOneWrong
        myEstructuraCalendarios = {
          ...myEstructuraCalendarios,
          ...response.d,
        }
        const responseKeys = Object.keys(response.d[arraySelect[i].value])
        let typeOfCampus = ''
        let totalCoordinatorParticipation = 0
        let totalTeachingParticipation = 0
        responseKeys.forEach((key, index) => {
          typeOfCampus = response.d[arraySelect[i].value][key]?.agrupador_campus
          const coordinatorParticipation =
            response.d[arraySelect[i].value][key][
              'Participación de Coordinador'
            ]
          const teachingParticipation =
            response.d[arraySelect[i].value][key]['Participación docente']

          totalCoordinatorParticipation =
            coordinatorParticipation + totalCoordinatorParticipation
          totalTeachingParticipation =
            teachingParticipation + totalTeachingParticipation

          switch (typeOfCampus) {
            case 'PRESENCIAL':
              if (!myEstructuraAgrupadores.hasOwnProperty(typeOfCampus)) {
                myEstructuraAgrupadores[typeOfCampus] = {}
              }

              if (myEstructuraAgrupadores[typeOfCampus].hasOwnProperty(key)) {
                myEstructuraAgrupadores[typeOfCampus][key] = [
                  ...myEstructuraAgrupadores[typeOfCampus][key],

                  teachingParticipation,
                  coordinatorParticipation,
                ]
              } else {
                const emptyArray =
                  i === '0'
                    ? []
                    : new Array((parseInt(i) - (isFirstOne ? 1 : 0)) * 2).fill(
                        null
                      )
                myEstructuraAgrupadores[typeOfCampus][key] = [
                  ...emptyArray,
                  teachingParticipation,
                  coordinatorParticipation,
                ]
              }
              if (responseKeys.length - 1 === index) {
                totalCoordinatorParticipation = Math.floor(
                  totalCoordinatorParticipation / responseKeys.length
                )
                totalTeachingParticipation = Math.floor(
                  totalTeachingParticipation / responseKeys.length
                )
                myEstructuraAgrupadores.GLOBAL.promedios.push(
                  totalTeachingParticipation,
                  totalCoordinatorParticipation
                )
                if (
                  !myEstructuraAgrupadores[typeOfCampus].hasOwnProperty(
                    'promedios'
                  )
                ) {
                  myEstructuraAgrupadores[typeOfCampus]['promedios'] = [
                    totalTeachingParticipation,
                    totalCoordinatorParticipation,
                  ]
                } else {
                  myEstructuraAgrupadores[typeOfCampus]['promedios'].push(
                    totalTeachingParticipation,
                    totalCoordinatorParticipation
                  )
                }
              }
              break
            case 'TOTAL ONLINE':
              if (!myEstructuraAgrupadores.hasOwnProperty(typeOfCampus)) {
                myEstructuraAgrupadores[typeOfCampus] = {}
              }
              if (
                myEstructuraAgrupadores[typeOfCampus].hasOwnProperty('ONLINE')
              ) {
                myEstructuraAgrupadores[typeOfCampus]['ONLINE'] = [
                  ...myEstructuraAgrupadores[typeOfCampus]['ONLINE'],
                  teachingParticipation,
                  coordinatorParticipation,
                ]
                myEstructuraAgrupadores[typeOfCampus]['promedios'].push(
                  totalTeachingParticipation,
                  totalCoordinatorParticipation
                )
              } else {
                const emptyArray =
                  i === '0'
                    ? []
                    : new Array((parseInt(i) - (isFirstOne ? 1 : 0)) * 2).fill(
                        null
                      )
                myEstructuraAgrupadores[typeOfCampus] = {
                  ONLINE: [
                    ...emptyArray,
                    teachingParticipation,
                    coordinatorParticipation,
                  ],
                }
                myEstructuraAgrupadores[typeOfCampus]['promedios'] = [
                  ...emptyArray,
                  totalTeachingParticipation,
                  totalCoordinatorParticipation,
                ]
              }
              myEstructuraAgrupadores.GLOBAL.promedios.push(
                teachingParticipation,
                coordinatorParticipation
              )
              break
            default:
              break
          }
        })
        // Add nulls
        switch (typeOfCampus) {
          case 'PRESENCIAL':
            const myEstructuraKeys = Object.keys(
              myEstructuraAgrupadores[typeOfCampus]
            )
            myEstructuraKeys.forEach((element) => {
              if (
                myEstructuraAgrupadores[typeOfCampus][element].length !==
                (parseInt(i) + 1) * 2
              ) {
                myEstructuraAgrupadores[typeOfCampus][element].push(null, null)
              }
            })
            break
          case 'TOTAL ONLINE':
            if (myEstructuraAgrupadores.hasOwnProperty('PRESENCIAL')) {
              Object.keys(myEstructuraAgrupadores['PRESENCIAL']).forEach(
                (key) => {
                  myEstructuraAgrupadores['PRESENCIAL'][key] = [
                    ...myEstructuraAgrupadores['PRESENCIAL'][key],
                    null,
                    null,
                  ]
                }
              )
            }
            break
          default:
            break
        }
        lastOneWrong = false
      } else {
        lastOneWrong = true
      }

      if (arraySelect.length - 1 === parseInt(i)) {
        let firstResultTotal = 0
        let secondResultTotal = 0
        let resultTotal = 0
        const presencialKeys = myEstructuraAgrupadores.hasOwnProperty(
          'PRESENCIAL'
        )
          ? Object.keys(myEstructuraAgrupadores['PRESENCIAL'])
          : []
        if (myEstructuraAgrupadores.hasOwnProperty('PRESENCIAL')) {
          presencialKeys.forEach((key) => {
            const resultWithOutNulls = myEstructuraAgrupadores['PRESENCIAL'][
              key
            ].filter((element) => element !== null)
            if (resultWithOutNulls.length) {
              const divisor = resultWithOutNulls.length / 2
              const result = resultWithOutNulls.reduce(
                (acc, val, index) => {
                  if (index % 2 === 0) {
                    acc[0] += val // Sum for 'A' indices (0, 2, 4, ...)
                  } else {
                    acc[1] += val // Sum for 'B' indices (1, 3, 5, ...)
                  }
                  return acc
                },
                [0, 0] // Initial values for sumA and sumB
              )
              const firstResult = result[0] / divisor
              const secondResult = result[1] / divisor
              myEstructuraAgrupadores['PRESENCIAL'][key].push(
                Math.floor(firstResult),
                Math.floor(secondResult),
                Math.floor((firstResult + secondResult) / 2)
              )
              firstResultTotal = firstResult + firstResultTotal
              secondResultTotal = secondResult + secondResultTotal
              resultTotal = (firstResult + secondResult) / 2 + resultTotal
            } else {
              myEstructuraAgrupadores['PRESENCIAL'][key].push(null, null, null)
            }
          })
        }
        if (myEstructuraAgrupadores.hasOwnProperty('TOTAL ONLINE')) {
          const resultWithOutNulls = myEstructuraAgrupadores['TOTAL ONLINE'][
            'ONLINE'
          ].filter((element) => element !== null)
          if (resultWithOutNulls.length) {
            const divisor = resultWithOutNulls.length / 2
            const result = resultWithOutNulls.reduce(
              (acc, val, index) => {
                if (index % 2 === 0) {
                  acc[0] += val // Sum for 'A' indices (0, 2, 4, ...)
                } else {
                  acc[1] += val // Sum for 'B' indices (1, 3, 5, ...)
                }
                return acc
              },
              [0, 0] // Initial values for sumA and sumB
            )
            const firstResult = Math.floor(result[0] / divisor)
            const secondResult = Math.floor(result[1] / divisor)
            firstResultTotal = firstResult + firstResultTotal
            secondResultTotal = firstResult + secondResultTotal
            resultTotal =
              Math.floor((firstResult + secondResult) / 2) + resultTotal

            myEstructuraAgrupadores['TOTAL ONLINE']['ONLINE'].push(
              firstResult,
              secondResult,
              Math.floor((firstResult + secondResult) / 2)
            )
            myEstructuraAgrupadores['TOTAL ONLINE']['promedios'].push(
              firstResult,
              secondResult,
              Math.floor((firstResult + secondResult) / 2)
            )
          } else {
            myEstructuraAgrupadores['TOTAL ONLINE']['ONLINE'].push(
              null,
              null,
              null
            )
          }
        }
        const plus = myEstructuraAgrupadores.hasOwnProperty('TOTAL ONLINE')
          ? 1
          : 0
        myEstructuraAgrupadores.GLOBAL.promedios.push(
          Math.floor(firstResultTotal / (presencialKeys.length + plus)),
          Math.floor(secondResultTotal / (presencialKeys.length + plus)),
          Math.floor(resultTotal / (presencialKeys.length + plus))
        )
      }
    }
  }
  setEstructuraAgrupadores(myEstructuraAgrupadores)
  setCalendarios(myEstructuraCalendarios)
}
