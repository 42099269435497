import React from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import NewModal from '../../Components/NewModal'

export default function Descripcion({
  valueHTML,
  setValueHTML,
  modalDescripcion,
  setModalDescripcion,
  handleSubmitDescripcion,
}) {
  function handleChangeHTML(value) {
    setValueHTML(value.target.value)
  }
  return (
    <NewModal
      height={160}
      title='Descripción Instrumento'
      open={modalDescripcion}
      handleClose={() => {
        setModalDescripcion(false)
      }}
      loading={false}
      handleSubmit={handleSubmitDescripcion}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label>Descripción(*):</label>
        <textarea
          onChange={handleChangeHTML}
          value={valueHTML}
          className='descripcion-dimension-textarea'
          placeholder='Ingresa Descripción'
        ></textarea>
      </div>
    </NewModal>
  )
}
