import React from "react";

const ReportesIcon = () => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 172 175" enable-background="new 0 0 172 175" space="preserve">
            <image id="image0" width="172" height="175" x="0" y="0"
            href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKwAAACvCAQAAADKrP39AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
            AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZ
            cwAACxIAAAsSAdLdfvwAAAAHdElNRQflAgMWLTNleTgkAAADo0lEQVR42u3dMW8TZwCH8X+ohWA7
            NjogBjp0irJBGRCsdOErMLMgxMiQgRFFLMyW8gkQXwCGDulmVR26RELqAhMeUXQIBqrIrW3J7yXP
            vefr85viVz7r3sens2XnXu98jQgXau/AWBkWYliIYSGGhRgWYliIYSGGhRgWYliIYSGGhRgWYliI
            YSGGhRgWYliIYSGGhRgWYliIYSGTDts02au92z2YZX6WzUvDNjnIw7SdnpBt0maSaZ50j7tT9C9G
            Tf7Itdpz7tHf2e2atuwce/C/yppcy0HXTUuO2Cafas+0givdjtmSI3Yvbe1Z9q7t+kJddioY+0vW
            Oc7Y97EQw0IMCzEsxLAQw0IMCzEsxLAQw0IMCzEsxLAQw0KG/kFgm+Mcnd66lRuD3+N/DHs3T3Iz
            s3+N7OX3XKy9W5sY8qmgXcqazHJzO77HGHLY46WsSTLLce0d28SQwx4Vjg/KkMNuNcNCDAsxLMSw
            EMNCDAsxLMSwEMNCDAsxLMSwEMNCDAsxLMSwkP6/TDzJ23w4vXU197bjy8FSfYddvtZvpFc79n0q
            uLN0Odo8d2pHIPQb9mPerxh9n4+1M5y/fsN+LhzfYr4rgBgWYliIYSGGhRgWYliIYSGGhRgWYliI
            YSGGhQz7cqQyX3KUFwuf9zZ5mlv5oc7OjCdsm1d5/J+x13mZR3XmOJ5TwST7K0b3ax064wmblWsQ
            zgsf49yMKeygGBZiWIhhIYaFGBZiWIhhIYaFGBZiWIhhIYaFGBZiWIhhIYaFGBZiWIhhIYaFGBZi
            WIhhIYaFGBZiWIhhIYaFGBZiWIhhIYaFGBZiWIhhIYaFGBZiWIhhIYaFGBZiWIhhIYaFDDns1cLx
            Qek37I9pVow2ub7y3rfXPMq68WbDsV70G/ZCDleMHq75Cd/LebNi9E0ur3n0ww3HetHv+hOT3M+f
            ebGwoHST5/l5zbotk/ya3/Is705H7uZ5fll7MNzPX3m9sHrypTzIT73Ob8HO183vezdvK+zhycJy
            /icVlva/t/DEFhjyi9d3F9f8PXDDD7ulDAsxLMSwEMNCDAsxLMSwEMNCDAsxLMSwEMNCDAspC9sW
            3XsMvnTdsOSD7iafas+zgivdlqMuOWLnmdaeZe+mXVf5LjliR/vjvGst/zzxxsrOsfPsZpoznHm2
            Rptk2j1r6RH7XZO92vPuwexsS/13CasN+D4WYliIYSGGhRgWYliIYSGGhRgWYliIYSGGhRgW8g2N
            72YMcvM98AAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMS0wMi0wM1QyMjo0NTo1MSswMzowMG0o0y8A
            AAAldEVYdGRhdGU6bW9kaWZ5ADIwMjEtMDItMDNUMjI6NDU6NTErMDM6MDAcdWuTAAAAAElFTkSu
            QmCC" />
        </svg>
    )
}

export default ReportesIcon;