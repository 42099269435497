import React from "react";

const ObjetosDimensionesIcon = () => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 172 175" enable-background="new 0 0 172 175" space="preserve">
            <image id="image0" width="172" height="175" x="0" y="0"
                href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKwAAACvCAQAAADKrP39AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
                AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZ
                cwAACxIAAAsSAdLdfvwAAAAHdElNRQflAgMXAyYCYb/UAAAIoUlEQVR42u2d3XmbShCGv5zn3JtU
                YFyBcQUhFRhXYFxBSAWRKzhKBcEVhFRgpYLIFQRVYFzBngss64efnV122JGffblJJAPLq2GAZRk+
                KAQ4+Md3A94rQSwTQSwTQSwTQSwTQSwTQSwTQSwTQSwTQSwTQSwTQSwTQSwTQSwTQSwTQSwTQSwT
                QSwTQSwTQSwTQSwTQSwTQSwTQSwTQSwTQSwTQSwT//puAJEcGSI0qFD6bgqNDycwKC7CCpdv/3tC
                isZ3k/TITwWHWoFLrBD5bpQe6WKPtQInola22D6twEmolSx2SCtwAmrlih3TCohXK1WsTisgXK1M
                sRStgGi1EsVStQKC1coTa6IVEKtWmlhTrYBQtdLELoy1AsAllr4bfoysvoIIz9bzfpTVgyArYhNP
                8zIgS+w7QlYqALrNecEZ6bMPvpt+iLSI/X70/yfkPX+V40kzn3+UtKlSO9YqUqnqkqpIrff+X3lv
                dWeSd2sme70NA5Qjt2EaJMiRA1Jv10jLsV1SPHY++4yV72bpkJZj3w1BLBPyxSbEz4QhOce2h6ez
                3u9eUKLE2ncTh5EpNkaGAufav9tgiQq17+b2IU1shAw5PhnN8xslKlldMLLEZsiQDez6Ol5QoULl
                exN2yBCbIEdG2PV1bFBJyby+xcbIkFt1bg/zhNJ/5vUnNkKGDNdsy/+Fymfm9SN2SjY1wWPmnVus
                q2xqgpfMO59YjmxqwsyZdw6x9tn0CUs0+Nn5/AYRCqsfab7My9zhm6lSNcqcWi1UrKAw0NENBRWr
                haotlt2oUmXcHd18i07U0nqzk73lDIvdrsf2p1serOcExMaqOLhxQqfqiSSd2O2eURmvTSml1qp4
                3TNEi41Ubr2BuYp6l0kTu1277Q86tHYBYtPJ2XRouVSx2/3FPvOmzmw4E5uzbYqp2Gk/cy5LbGq8
                ARV5E+zEbn9u88TkKGrdiDVp/loVRvlsilgoqMjwUFpJEkvb6Wq1tDgCTxXbTjH55K+RJJYWCeZS
                3Ylt5dL2LCdO5rtLe42/qJDNtr5DMlT4y9hJ2WHeIUbXuMYGJcoZu6Fj5Mhn7k8DZkwFx4khmyEV
                2F2POXHia1DcNnaXTD1NEQovcfqGz5Ew5/iGZ5RIHS83RYlnfPOpVcIQo1s8okbh5IGiCAVqPOLW
                90bxid3gt8Ffn+O/ybHbxul/RnH6Gxum7Wc7eK1Ue1Juer1ed67L9AevSBXG3S7N68XKiuvgxSm2
                nfLexo+z3zkzLjZVpfHSV3v9FCcsFpaxu+2hHRJr0/vadC6qT1ysbew2qlRFz+eFRafgqrc/7V2I
                hbK/QzWF47tos4id+3RrjRwx7jrPaXHxhDvEyOcfKOfjPLZBiQRXeMAL41pe8IArJCj9jN/yd4HA
                Gbve4nSH3ysv97HrOU53+L+k3cXu1Kugjf843SFBLNDGbozPeLCc/wGfEfuP0x1SxLaskOMjvhrF
                7gZf8RG5tIdAZYkFgAZLcuy2ccrVpzsJeWJbdLErNE53SBULbGP3ruebO6lxukOy2Jaa+Jkw5Is9
                UYJYJoJYJoJYJoJYJoJYJoJYJqSLjXvHJ2aIfTdMh2Sx7dDLLz3ffPE6JJSEvEpxAG1IG/ewuqnM
                fJdWP5kPwZjyGNG7uUs7hu2QNpfD6pwhRazNkLZ9XAyrc4p/sS6HXgqKXb9ip8ZpH0Ji15fYCDnW
                jEOEb/GINXJ/setDbIISNX6wlzG5xA/UKP2UmJxXbBunf3A7QwWjljPc4o+P2J1PrE2cPuEONz2f
                3xgOTfIRuzNcINgNEd4OvRwe0W0zJPS44ISI8bGRSgeehx0WG1tsfH2w8eND5SOVWzx/UL5tB1Vs
                rFKzGhx0qeVbsxZEsVOfPqCI3f6N7ZMIFLHFW2iUdLlUrYe78lIrtnHwvAxdbNtGm2dn+lp5uNzl
                wXdrqlqa2G48JBqxZox1o5g9S2sTu2Nik863C5Ix0llB1NPzmRHmo7DBvdOhQu3QpHtnD8Z1t5N4
                wUwR21c3M3bQ6F+4QYyF8/7UBgvEuMEvB8tKOp+c0YKKIrbo+aze+3du3NwN7nGBjLUAaYUMF1ax
                u789a6KPLtps0V+hKH09CVkYH6TolQpscmzfZF61oBmtrEhav82BS6laQaUWzfVbbMc8BFKF3jON
                crrYqHeVK4tabCvLInfuiu1EFufV9cA82m3R5dj+fPLJogf1E549F9t5Nny/AgCcD8xTaOfUmLep
                EjjO2AOYHBHL85BpPS1iOapptx15NRbsgy5iLFAzdVKea/e9Ue92JUvprAnlRW0i1r7gKZ3K/uAV
                Mzdt18TMoVjbEr3mxLapoDDaOV5wjwtc4bvxKfk1fr4+/DmN9kHPn8b14Db4jitc4N7wwdNxPyPW
                6Sn/uMjC9JLnZhHrrgy6SVdnY5cKEuLCy4FdwlVZaX1Ht+vy0fTO+cRGLKXYbqU99EwvhD4slrPg
                eURadsYltu2ehnaaUrrfVU2YtrW0Ev3ULvPURiw9x1J71W0zrwvoL5WIyHuYZY41KWhekpo8LfPa
                Y1aMn34HIrcVC5WTI4zabEzMvGaYvz4iIi650RVvp0QYJd+kRs1vJ9vMS8H2hSeUI0tN2QOoq9Pt
                IGZxsT9lDm7/HTLlFT26q03y6HGTVQ6fhDfWG7LbL8z7SrusHLzaZCj56d4wYi22nfoFLCZuzPan
                Mx8ZsNtsVy/jWQz8YIbLsRNweJujdLJB2ykxvInSOH99VHm0dKsfzPYNdBEyJEhQo2QpH5IhIwxK
                fmB61WSKHDHWWNu/rc73e2nHGHtdtcjXUu8jWWzL8QvWBb9IfR/5YlsSpIjQYCWjDpyeUxF7cvh/
                zuudEsQyEcQyEcQyEcQyEcQyEcQyEcQyEcQyEcQyEcQyEcQyEcQyEcQyEcQyEcQyEcQyEcQyEcQy
                EcQyEcQyEcQyEcQyEcQyEcQyEcQy8T8bgwtfkv2y2wAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMS0w
                Mi0wM1QyMzowMzozOCswMzowMF6ORTsAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjEtMDItMDNUMjM6
                MDM6MzgrMDM6MDAv0/2HAAAAAElFTkSuQmCC" />
        </svg>
    )
}

export default ObjetosDimensionesIcon;