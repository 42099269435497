/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useMemo } from 'react'
import Select from 'react-select'
import CircularProgress from '@material-ui/core/CircularProgress'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

import moment from 'moment'
import Contenedor from '../../Components/Contenedor/Contenedor'
import DataTable from '../../Components/DataTables'
import { toast } from 'react-toastify'
import { getRequest } from '../../utils/requester'

import './style.css'

const ParticipacionDocente = () => {
  const searchInput = useRef(null)
  const [dataInicial, setDataInicial] = useState([])
  const [tipoPeriodo, setTipoPeriodo] = useState({
    label: 'Abierto',
    value: 'abierto',
  })
  const [year, setYear] = useState({})
  const [ciclo, setCiclo] = useState({})
  const [estatus, setEstatus] = useState({
    label: 'Incompleto',
    value: 'incompleto',
  })
  const [periodo, setPeriodo] = useState({})
  const [calendario, setCalendario] = useState({})
  const [loading, setLoading] = useState(false)
  const [periodos, setPeriodos] = useState([])
  const [loadingPeriodos, setLoadingPeriodos] = useState(false)
  const [calendarios, setCalendarios] = useState([])
  const [loadingCalendarios, setLoadingCalendarios] = useState(false)
  const [years, setYears] = useState([])
  const [headers, setHeaders] = useState([])
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    const myOpcionesYears = []
    const actualYear = new Date().getFullYear()
    for (let i = actualYear - 4; i <= actualYear; i++) {
      myOpcionesYears.push({
        label: String(i),
        value: String(i),
      })
    }
    setYears(myOpcionesYears)
    setYear({
      label: String(actualYear),
      value: String(actualYear),
    })
  }, [])

  useEffect(() => {
    if (year.value !== undefined) {
      getPeriodos()
    }
  }, [year])

  const tipoPeriodoOptions = [
    {
      label: 'Abierto',
      value: 'abierto',
    },
    {
      label: 'Cerrado',
      value: 'cerrado',
    },
  ]
  const estatusOptions = [
    {
      label: 'Completo',
      value: 'completo',
    },
    {
      label: 'Incompleto',
      value: 'incompleto',
    },
  ]

  const cicloOptions = [
    {
      label: 'C1',
      value: 'C1',
    },
    {
      label: 'C2',
      value: 'C2',
    },
    {
      label: 'C3',
      value: 'C3',
    },
  ]

  const reloadList = async () => {
    try {
      let urlRequest = ''
      if (tipoPeriodo.value === 'abierto') {
        if (!periodo.value || !calendario.value || !estatus.value) {
          toast.error('Debe llenar todos los filtros para generar reporte')
          return
        }
        urlRequest =
          '?tipo=abierto&periodo=' +
          periodo.value +
          '&calendario=' +
          calendario.value +
          '&status=' +
          estatus.value
      } else {
        if (
          !year.value ||
          !ciclo.value ||
          !periodo.value ||
          !calendario.value ||
          !estatus.value
        ) {
          toast.error('Debe llenar todos los filtros para generar reporte')
          return
        }
        urlRequest =
          '?tipo=cerrado&year=' +
          year.value +
          '&ciclo=' +
          ciclo.value +
          '&periodo=' +
          periodo.value +
          '&calendario=' +
          calendario.value +
          '&status=' +
          estatus.value
      }
      console.log(urlRequest)

      setLoading(true)
      const response = await getRequest(
        '/reportes/participacion_docente' + urlRequest
      )

      if (response.s === 'OK') {
        const data = []
        const myHeaders = [
          'Campus',
          'Periodo',
          'Clave Docente',
          'Nombre del Docente',
          'Nombre del Coordinador',
        ]

        response.d.evaluaciones.forEach((evaluacion) => {
          myHeaders.push(evaluacion)
        })

        myHeaders.push('Porcentaje del Avance')
        setHeaders(myHeaders)

        response.d.data.forEach((reg) => {
          let myReg = [
            reg.campus,
            reg.periodo,
            reg['Clave Docente'],
            reg['Nombre Docente'],
            reg.nombre_coordinador,
          ]
          response.d.evaluaciones.forEach((evaluacion) => {
            myReg.push(reg[evaluacion])
          })
          myReg.push(reg.avance + '%')
          data.push(myReg)
        })

        setDataInicial(data)
        setLoading(false)
      } else {
        setDataInicial([])
        setLoading(false)
      }
    } catch (error) {
      setDataInicial([])
      console.error(error)
      setLoading(false)
    }
  }

  async function getPeriodos() {
    setLoadingPeriodos(true)
    let url = ""
    if(tipoPeriodo.value === 'abierto'){
      url = "/calendario/periodo?status=abierto"
    }else{
      url = '/calendario/periodo?year=' + year.value
    }
    const response = await getRequest(url)
    if (response.s === 'OK') {
      const myPeriodos = []
      response.d.forEach((reg) => {
        myPeriodos.push({
          value: reg,
          label: reg,
        })
      })
      setPeriodos(myPeriodos)
      setLoadingPeriodos(false)
    } else {
      setPeriodos([])
      setPeriodo({})
      setLoadingPeriodos(false)
    }
  }

  async function getCalendarios() {
    setLoadingCalendarios(true)
    const response = await getRequest(
      '/calendario?year=' +
        (tipoPeriodo.value === 'abierto'
        ? new Date().getFullYear()
        : year.value) +
        '&periodo=' +
        periodo.value +
        '&tipo=' +
        tipoPeriodo.value
    )
    if (response.s === 'OK') {
      const myPeriodos = []
      response.d.forEach((reg) => {
        myPeriodos.push({
          value: reg.name,
          label: reg.name,
        })
      })
      setCalendario({})
      setCalendarios(myPeriodos)
      setLoadingCalendarios(false)
    } else {
      setCalendario({})
      setCalendarios([])
      setLoadingCalendarios(false)
    }
  }

  useEffect(() => {
    setCiclo({})
    setCalendario({})
    if (tipoPeriodo.value === 'abierto') {
      setYear({
        label: '2021',
        value: '2021',
      })
    }
  }, [tipoPeriodo])

  useEffect(() => {
    if (periodo.value !== undefined) {
      getCalendarios()
    }
  }, [periodo, tipoPeriodo])

  function downloadReporte() {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const dataDownload = dataInicial.map((reg) => {
      let myRegistro = {}
      reg.forEach((cell, i) => {
        myRegistro[headers[i]] = cell
      })
      return myRegistro
    })

    const ws = XLSX.utils.json_to_sheet(dataDownload.map((row) => row))
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data2 = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(
      data2,
      `Reporte Participación Docente  ${moment().format(
        'YYYY-MM-DD_HH:mm'
      )}.xlsx`
    )
  }

  const filteredData = useMemo(
    () =>
      dataInicial.filter((row) => {
        if (
          JSON.stringify(row).toLowerCase().includes(searchText.toLowerCase())
        ) {
          return true
        }
        return false
      }),
    [searchText, dataInicial]
  )

  const handleChange = () => {
    setSearchText(searchInput.current.value)
  }

  return (
    <Contenedor title='Participación por Docente'>
      <div className='filtros-tabla-completa' style={{ float: 'left' }}>
        <div className='sub-filtro'>
          <p>FILTROS</p>
        </div>
        <div className='sub-filtro'>
          <p>Tipo Periodo:</p>
          <Select
            options={tipoPeriodoOptions}
            className='select-periodo'
            classNamePrefix='select-search'
            value={tipoPeriodo}
            onChange={setTipoPeriodo}
          />
        </div>
        {tipoPeriodo.value === 'cerrado' ? (
          <>
            <div className='sub-filtro'>
              <p>Año:</p>
              <Select
                options={years}
                style={{ width: '50px' }}
                className='select-periodo small-bar'
                classNamePrefix='select-search'
                value={year}
                onChange={setYear}
              />
            </div>
            <div className='sub-filtro'>
              <p>Ciclo:</p>
              <Select
                options={cicloOptions}
                style={{ width: '50px' }}
                className='select-periodo small-bar'
                classNamePrefix='select-search'
                value={ciclo}
                onChange={setCiclo}
              />
            </div>
            <div className='sub-filtro'>
              <p>Periodo:</p>
              {loadingPeriodos ? (
                <CircularProgress />
              ) : (
                <Select
                  options={periodos}
                  className='select-periodo'
                  classNamePrefix='select-search'
                  value={periodo}
                  onChange={setPeriodo}
                />
              )}
            </div>
            <div className='sub-filtro'>
              <p>Calendario:</p>
              {loadingCalendarios ? (
                <CircularProgress />
              ) : (
                <Select
                  options={calendarios}
                  className='select-periodo'
                  classNamePrefix='select-search'
                  value={calendario}
                  onChange={setCalendario}
                />
              )}
            </div>
            <div className='sub-filtro'>
              <p>Estatus:</p>
              <Select
                options={estatusOptions}
                className='select-periodo'
                classNamePrefix='select-search'
                value={estatus}
                onChange={setEstatus}
              />
            </div>
          </>
        ) : (
          <>
            <div className='sub-filtro'>
              <p>Periodo:</p>
              {loadingPeriodos ? (
                <CircularProgress />
              ) : (
                <Select
                  options={periodos}
                  className='select-periodo'
                  classNamePrefix='select-search'
                  value={periodo}
                  onChange={setPeriodo}
                />
              )}
            </div>
            <div className='sub-filtro'>
              <p>Calendario:</p>
              {loadingCalendarios ? (
                <CircularProgress />
              ) : (
                <Select
                  options={calendarios}
                  className='select-periodo'
                  classNamePrefix='select-search'
                  value={calendario}
                  onChange={setCalendario}
                />
              )}
            </div>
            <div className='sub-filtro'>
              <p>Estatus:</p>
              <Select
                options={estatusOptions}
                className='select-periodo'
                classNamePrefix='select-search'
                value={estatus}
                onChange={setEstatus}
              />
            </div>
          </>
        )}
        <div className='sub-filtro'>
          <button
            className='NewButton'
            onClick={loading ? () => {} : reloadList}
          >
            Generar
          </button>
        </div>
      </div>
      <div className='opciones-bar' style={{ marginTop: '50px' }}>
        <div className='sub-filtro'>
          <button className='NewButton' onClick={downloadReporte}>
            Descargar
          </button>
        </div>
        <div style={{ float: 'right' }}>
          <input
            ref={searchInput}
            type='search'
            placeholder='Buscar'
            className='SearchInput-tabla-completa SearchInput-Usuarios'
            onChange={handleChange}
          />
        </div>
      </div>
      <div style={{ height: 'calc(100% - 120px)' }}>
        <DataTable
          headers={headers}
          data={filteredData}
          loading={loading}
          paginate
        />
      </div>
    </Contenedor>
  )
}

export default ParticipacionDocente
