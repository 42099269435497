/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Select from 'react-select'
import { toast } from 'react-toastify'

import Contenedor from '../../Components/Contenedor/Contenedor'
import NewButton from '../../Components/Botones/NewButton'
import EditButton from '../../Components/Botones/EditButton'
import DataTable from '../../Components/DataTables'
import NewModal from '../../Components/Modal/NewModal'
import { getRequest, postRequest } from '../../utils/requester'
import './style.css'

function CreadoCicloPeriodo() {
  const [dataInicial, setDataInicial] = useState([])
  const [dataFiltradas, setDataFiltradas] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [loadingModalEditar, setLoadingModalEditar] = useState(false)
  const [modalEditarIsOpen, setModalEditarIsOpen] = useState(false)
  const [modalNuevaIsOpen, setModalNuevaIsOpen] = useState(false)
  const [nueva, setNueva] = useState({
    nombre: '',
    descripcion: '',
    status: 'activa',
  })
  const [editar, setEditar] = useState({
    id: '',
    nombre: '',
    descripcion: '',
  })
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteModalLoading, setDeleteModalLoading] = useState(false)
  const [cicloValue, setCicloValue] = useState('')

  const opcionesCiclo = [
    {
      label: 'C1',
      value: 'C1',
    },
    {
      label: 'C2',
      value: 'C2',
    },
    {
      label: 'C3',
      value: 'C3',
    },
  ]

  const openEditModal = async (id, ciclo, periodo) => {
    setEditar({
      id,
      ciclo,
      periodo,
    })
    setCicloValue({
      label: ciclo,
      value: ciclo,
    })
    setModalEditarIsOpen(true)
  }

  const handleEditar = async (data, status) => {
    if (validar(data)) {
      setLoadingModalEditar(true)
      try {
        const editarResponse = await postRequest(
          `/calendario/ciclo_periodo/editar`,
          { ...data, status }
        )
        if (editarResponse.s === 'OK') {
          toast.success(editarResponse.m)
          setModalEditarIsOpen(false)
          setLoadingModalEditar(false)
          reloadList()
        }
        setLoadingModalEditar(false)
      } catch (error) {
        console.error(error)
        setLoadingModalEditar(false)
      }
    }
  }

  const handleNueva = async () => {
    if (validar(cicloValue.value && nueva.periodo)) {
      try {
        setLoadingModal(true)
        const nuevaResponse = await postRequest(
          `/calendario/ciclo_periodo/nuevo`,
          { ciclo: cicloValue.value, periodo: nueva.periodo }
        )
        if (nuevaResponse.s === 'OK') {
          setLoadingModal(false)
          setModalNuevaIsOpen(false)
          reloadList()
        }
        setLoadingModal(false)
      } catch (error) {
        console.error(error)
        setLoadingModal(false)
      }
    }
  }

  const validar = (body) => {
    let valido = true
    if (body.nombre === '') {
      document.getElementById('tag-nombre').classList.add('error')
      valido = false
    } else {
      document.getElementById('tag-nombre').classList.remove('error')
    }
    if (body.descripcion === '') {
      document.getElementById('tag-descripcion').classList.add('error')
      valido = false
    } else {
      document.getElementById('tag-descripcion').classList.remove('error')
    }
    return valido
  }

  const handleDelete = async () => {
    setDeleteModalLoading(true)
    try {
      /*
      const eliminarEtiquetaResponse = await postRequest(`/etiqueta/eliminar`, {
        id: etiquetaEditar.id,
      });
      */
      const eliminarResponse = eliminar()
      if (eliminarResponse.s === 'OK') {
        setModalEditarIsOpen(false)
        setDeleteModalLoading(false)
        reloadList()
        setDeleteModal(false)
      }
      setDeleteModal(false)
      setDeleteModalLoading(false)
    } catch (error) {
      console.error(error)
      setDeleteModal(false)
      setDeleteModalLoading(false)
    }
  }

  const testHeader = ['Ciclo', 'Periodo', 'Acciones']

  ////////// Filtrar resultados segun el input de busqueda //////////
  const search = (e) => {
    let text = e.target.value.toLowerCase()

    let result = dataInicial.filter((row) => {
      if (row[0].toString().toLowerCase().includes(text)) return true
      else if (row[1].toLowerCase().includes(text)) return true
      return false
    })
    setDataFiltradas(result)
  }

  const reloadList = async () => {
    setLoading(true)
    try {
      const response = await getRequest('/calendario/ciclo_periodo')
      if (response.s === 'OK') {
        let data = []
        response.d.forEach((reg) => {
          data.push([
            reg.ciclo,
            reg.periodo,
            <EditButton
              onClick={() => openEditModal(reg.id, reg.ciclo, reg.periodo)}
            >
              Editar
            </EditButton>,
          ])
        })
        setDataInicial(data)
        setDataFiltradas(data)
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }
  const handleChangeCiclo = ({ value }) => {
    setCicloValue({
      label: value,
      value,
    })
  }
  useEffect(() => {
    reloadList()
  }, [])
  const customStyles = {
    option: (provided, _state) => ({
      ...provided,
      color: '#616b74',
      fontSize: 14,
      fontWeight: 400,
      padding: '5px 25px',
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      userSelect: 'none',
      backgroundColor: '#fff',
      borderTop: '1px solid #ccc',
    }),
    control: (provided, _state) => ({
      ...provided,
      minHeight: 0,
      height: '25px',
      borderRadius: 15,
      fontSize: 11,
      backgroundColor: '#f9f9f9',
      padding: 0,
    }),
    placeholder: (provided, _state) => ({
      ...provided,
      padding: 0,
      fontSize: 11,
      fontWeight: 400,
    }),
    menu: (provided, _state) => ({
      ...provided,
      borderRadius: 0,
    }),
    input: (provided, _state) => ({
      ...provided,
      padding: 0,
      fontSize: 11,
      fontWeight: 400,
      borderRadius: 0,
    }),
    singleValue: (provided, _state) => ({
      ...provided,
      top: '50%',
    }),
  }
  return (
    <Contenedor title='Creado de Ciclo-Periodo'>
      <div className='opciones-bar'>
        <div className='sub-filtro'>
          <NewButton
            onClick={() => {
              setCicloValue('')
              setModalNuevaIsOpen(true)
              setNueva({
                nombre: '',
                descripcion: '',
                status: 'activa',
              })
            }}
          />
        </div>
        <div style={{ float: 'right' }}>
          <input
            type='search'
            placeholder='Buscar'
            className='SearchInput-tabla-completa SearchInput-Usuarios'
            onChange={search}
          />
        </div>
      </div>
      <div style={{ height: 'calc(100% - 70px)' }}>
        <DataTable
          headers={testHeader}
          data={dataFiltradas}
          loading={loading}
          paginate
        />
      </div>
      <NewModal
        title='Editar Ciclo-Periodo'
        canDelete
        open={modalEditarIsOpen}
        handleClose={() => setModalEditarIsOpen(false)}
        height={300}
        loading={loadingModalEditar}
        handleSubmit={() => handleEditar(editar, 1)}
        onDelete={() => handleEditar(editar, 0)}
        permiso='calendario_ciclo-periodo'
      >
        <div
          ref={(el) => el && el.style.setProperty('height', '85%', 'important')}
          className='nuevoEtiquetaForm'
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <label id='tag-nombre'>Ciclo (*)</label>
              <Select
                options={opcionesCiclo}
                value={cicloValue}
                styles={customStyles}
                name='etiqueta'
                placeholder='Selección...'
                className='select-search-box'
                onChange={handleChangeCiclo}
              />
            </Grid>
            <Grid item xs={12}>
              <label id='tag-descripcion'>Periodo (*)</label>
              <input
                placeholder='Ingresa Periodo'
                value={editar.periodo}
                onChange={(e) =>
                  setEditar({
                    ...editar,
                    periodo: e.target.value,
                  })
                }
              />
            </Grid>
          </Grid>
        </div>
      </NewModal>
      <NewModal
        title='Nuevo Ciclo-Periodo'
        open={modalNuevaIsOpen}
        handleClose={() => {
          setModalNuevaIsOpen(false)
          setNueva({
            periodo: '',
            status: 'activa',
          })
        }}
        height={300}
        loading={loadingModal}
        handleSubmit={() => handleNueva(nueva)}
      >
        <div
          ref={(el) => el && el.style.setProperty('height', '85%', 'important')}
          className='nuevoEtiquetaForm'
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <label id='tag-nombre'>Ciclo (*)</label>
              <Select
                options={opcionesCiclo}
                value={cicloValue}
                styles={customStyles}
                name='etiqueta'
                placeholder='Selección...'
                className='select-search-box'
                onChange={handleChangeCiclo}
              />
            </Grid>
            <Grid item xs={12}>
              <label id='tag-descripcion'>Periodo (*)</label>
              <input
                placeholder='Ingresa Periodo'
                value={nueva.periodo}
                onChange={(e) =>
                  setNueva({
                    ...nueva,
                    periodo: e.target.value,
                  })
                }
              />
            </Grid>
          </Grid>
        </div>
      </NewModal>
      <NewModal
        open={deleteModal}
        height={120}
        title='Confirmar'
        loading={deleteModalLoading}
        handleClose={() => setDeleteModal(false)}
        handleSubmit={handleDelete}
      >
        <div class='delete-confirm'>
          <h2 style={{ textAlign: 'center' }}>
            ¿Está seguro de que desea eliminar este Ciclo-Periodo?
          </h2>
        </div>
      </NewModal>
    </Contenedor>
  )
}
export default CreadoCicloPeriodo

function eliminar() {
  return { s: 'OK', m: 'etiqueta eliminada correctamente', d: '' }
}
