/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import CircularProgress from '@material-ui/core/CircularProgress'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import moment from 'moment'
import { getRequest } from '../../utils/requester'
import { toast } from 'react-toastify'
import { SimpleMultiSelect } from '../../Components/SimpleMultiSelect'
import Contenedor from '../../Components/Contenedor/Contenedor'
import Table from './Table'

import './style.css'
import { particionGlobalCerrado } from '../../utils/particionGlobalCerrado'
import { particionGlobalAbierto } from '../../utils/particionGlobalAbierto'

const ParticipacionGlobal = () => {
  const [tipoPeriodo, setTipoPeriodo] = useState({
    label: 'Abierto',
    value: 'abierto',
  })
  const [year, setYear] = useState({
    label: '2021',
    value: '2021',
  })
  const [ciclo, setCiclo] = useState({})
  const [loading, setLoading] = useState(false)
  const [loadingPeriodos, setLoadingPeriodos] = useState(false)
  const [loadingCiclos] = useState(false)
  const [calendarios, setCalendarios] = useState([])
  const [estructuraAgrupadores, setEstructuraAgrupadores] = useState({})
  const [years, setYears] = useState([])
  const [nivelOptions, setNivelOptions] = useState([])
  const [niveles, setNiveles] = useState([])
  const [nivelesParsed, setNivelesParsed] = useState('')
  const [periodoOptions, setPeriodoOptions] = useState([])
  const [periodo, setPeriodo] = useState({})
  const [loadingCalendarios, setLoadingCalendarios] = useState(false)
  const [calendarioOpciones, setCalendarioOpciones] = useState([])
  const cicloOptions = [
    {
      label: 'C1',
      value: 'C1',
    },
    {
      label: 'C2',
      value: 'C2',
    },
    {
      label: 'C3',
      value: 'C3',
    },
  ]

  const tipoPeriodoOptions = [
    {
      label: 'Abierto',
      value: 'abierto',
    },
    {
      label: 'Cerrado',
      value: 'cerrado',
    },
  ]

  useEffect(() => {
    const myOpcionesYears = []
    const actualYear = new Date().getFullYear()
    for (let i = actualYear - 4; i <= actualYear; i++) {
      myOpcionesYears.push({
        label: String(i),
        value: String(i),
      })
    }
    setYears(myOpcionesYears)
    setYear({
      label: String(actualYear),
      value: String(actualYear),
    })
  }, [])

  useEffect(() => {
    getNiveles()
  }, [tipoPeriodo.value])

  useEffect(() => {
    if ((year.value, ciclo.value)) {
      getPeriodos()
    }
  }, [year.value, ciclo.value])

  async function getPeriodos() {
    setLoadingPeriodos(true)
    try {
      const response = await getRequest(
        '/calendario/periodo?year=' +
          year.value +
          '&status=' +
          tipoPeriodo.value +
          '&ciclo=' +
          ciclo.value
      )
      if (response.s === 'OK') {
        const myPeriodos = []
        response.d.forEach((reg) => {
          myPeriodos.push({
            value: reg,
            label: reg,
          })
        })
        setPeriodoOptions(myPeriodos)
        setLoadingPeriodos(false)
      } else {
        setPeriodoOptions([])
        setLoadingPeriodos(false)
      }
    } catch (error) {
      console.error(error)
      setPeriodoOptions([])
      setLoadingPeriodos(false)
    }
  }

  useEffect(() => {
    if (niveles && niveles.length > 0) {
      setNivelesParsed(
        niveles
          .map((nivel) => nivel.value)
          .reduce(
            (acc, curVal, i) => (i === 0 ? acc + curVal : acc + ',' + curVal),
            ''
          )
      )
    } else {
      setNivelesParsed('')
    }
  }, [niveles])

  const reloadList = async () => {
    const arraySelect = calendarioOpciones.filter((el) => el.isActive)
    if (tipoPeriodo.value === 'cerrado') {
      if (!ciclo.value) {
        toast.error('Debe seleccionar ciclo')
        return
      }
      if (!periodo.value) {
        toast.error('Debe seleccionar periodo')
        return
      }
      if (!arraySelect.length) {
        toast.error('Debe seleccionar Calendario')
        return
      }
    }
    setLoading(true)
    try {
      if (tipoPeriodo.value === 'cerrado') {
        await particionGlobalCerrado(
          year.value,
          ciclo.value,
          periodo.value,
          tipoPeriodo.value,
          arraySelect,
          setEstructuraAgrupadores,
          setCalendarios
        )
      } else {
        await particionGlobalAbierto(
          tipoPeriodo.value,
          nivelesParsed,
          setEstructuraAgrupadores,
          setCalendarios
        )
      }
      setLoading(false)
    } catch (error) {
      console.error(error)
      setEstructuraAgrupadores({})
      setLoading(false)
    }
  }

  function downloadReporte() {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const dataDownload = []
    const myHeaders = []
    myHeaders.push('')
    Object.keys(calendarios).forEach((key) => {
      myHeaders.push(key)
      myHeaders.push(key + ' ')
    })
    myHeaders.push('Total Periodos')
    myHeaders.push('Total Periodos ')

    myHeaders.push('Total')

    Object.keys(estructuraAgrupadores).forEach((key, keyIndex) => {
      const reg = {}

      if (keyIndex === 0) {
        const subHeader = {}
        subHeader[myHeaders[0]] = ''
        estructuraAgrupadores[key].promedios.forEach((_, i, arr) => {
          if (i < arr.length - 1)
            if (i % 2 === 0) {
              subHeader[myHeaders[i + 1]] = 'Docente'
            } else {
              subHeader[myHeaders[i + 1]] = 'Coordinador'
            }
        })
        dataDownload.push(subHeader)
      }
      reg[myHeaders[0]] = key
      estructuraAgrupadores[key].promedios.forEach((promedio, i) => {
        if (promedio != null) {
          reg[myHeaders[i + 1]] = promedio + '%'
        } else {
          reg[myHeaders[i + 1]] = '-'
        }
      })
      dataDownload.push(reg)
      Object.keys(estructuraAgrupadores[key]).forEach((campusKey) => {
        if (campusKey !== 'promedios') {
          const subReg = {}
          subReg[myHeaders[0]] = campusKey
          estructuraAgrupadores[key][campusKey].forEach((promedio, i) => {
            if (promedio != null) {
              subReg[myHeaders[i + 1]] = promedio + '%'
            } else {
              subReg[myHeaders[i + 1]] = '-'
            }
          })
          dataDownload.push(subReg)
        }
      })
    })

    const ws = XLSX.utils.json_to_sheet(dataDownload.map((row) => row))
    const wscols = myHeaders.map(() => {
      return { wch: 20 }
    })

    ws['!cols'] = wscols
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data2 = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(
      data2,
      `Reporte Participación Global ${moment().format('YYYY-MM-DD_HH:mm')}.xlsx`
    )
  }

  const getNiveles = async () => {
    try {
      const response = await getRequest(
        `/calendario/nivel?tipo=calendario_${tipoPeriodo.value}`
      )

      setNivelOptions(
        response.d.map((item) => ({ label: item.nombre, value: item.id }))
      )
    } catch (error) {
      console.error(error)
    }
  }
  async function getCalendarios() {
    setLoadingCalendarios(true)
    const response = await getRequest(
      '/calendario?year=' +
        (tipoPeriodo.value === 'abierto'
          ? new Date().getFullYear()
          : year.value) +
        '&periodo=' +
        periodo.value +
        '&tipo=' +
        tipoPeriodo.value
    )

    if (response.s === 'OK') {
      const myPeriodos = response.d
        .map((reg) => ({
          value: reg.name,
          label: reg.name,
          id_calendario: reg.id,
          isActive: false,
        }))
        .sort((a, b) => {
          if (a.label.includes('ONLN') && !b.label.includes('ONLN')) {
            return 1
          } else if (!a.label.includes('ONLN') && b.label.includes('ONLN')) {
            return -1
          } else {
            return 0
          }
        })
      setCalendarioOpciones(myPeriodos)
      setLoadingCalendarios(false)
    } else {
      setLoadingCalendarios(false)
    }
  }

  const handelCalendario = async (e) => {
    setCalendarioOpciones((prev) => {
      const newPrev = prev.map((el) => {
        if (el.value === e.value) {
          return { ...el, isActive: !el.isActive }
        }
        return { ...el }
      })
      return newPrev
    })
  }

  useEffect(() => {
    if (periodo.value !== undefined) {
      getCalendarios()
    }
  }, [periodo, tipoPeriodo])

  return (
    <Contenedor title='Participación Global'>
      <div className='filtros-tabla-completa' style={{ float: 'left' }}>
        <div className='sub-filtro'>
          <p>FILTROS</p>
        </div>
        <div className='sub-filtro'>
          <p>Tipo:</p>
          <Select
            options={tipoPeriodoOptions}
            className='select-periodo'
            classNamePrefix='select-search'
            value={tipoPeriodo}
            onChange={setTipoPeriodo}
          />
        </div>
        {tipoPeriodo.value === 'abierto' ? null : (
          <>
            <div className='sub-filtro'>
              <p>Año:</p>
              <Select
                options={years}
                className='select-periodo'
                classNamePrefix='select-search'
                value={year}
                onChange={setYear}
              />
            </div>
            <div className='sub-filtro'>
              <p>Ciclo*:</p>
              {loadingCiclos ? (
                <div style={{ width: '200px', textAlign: 'center' }}>
                  <CircularProgress size={24} />
                </div>
              ) : (
                <Select
                  options={cicloOptions}
                  className='select-periodo'
                  classNamePrefix='select-search'
                  value={ciclo}
                  onChange={setCiclo}
                />
              )}
            </div>
            <div className='sub-filtro'>
              <p>Periodo*:</p>
              {loadingPeriodos ? (
                <div style={{ width: '200px', textAlign: 'center' }}>
                  <CircularProgress size={24} />
                </div>
              ) : (
                <Select
                  options={periodoOptions}
                  className='select-periodo'
                  classNamePrefix='select-search'
                  value={periodo}
                  onChange={setPeriodo}
                />
              )}
            </div>
            <div className='sub-filtro'>
              <p>Calendario*:</p>
              {loadingCalendarios ? (
                <div style={{ width: '200px', textAlign: 'center' }}>
                  <CircularProgress size={24} />
                </div>
              ) : (
                <SimpleMultiSelect
                  options={calendarioOpciones}
                  onChange={handelCalendario}
                  value={{
                    value: `${
                      calendarioOpciones.filter((item) => item.isActive).length
                    }`,
                    label: `Total: ${
                      calendarioOpciones.filter((item) => item.isActive).length
                    }`,
                  }}
                />
              )}
            </div>
          </>
        )}

        {tipoPeriodo.value === 'abierto' && (
          <div className='sub-filtro'>
            <p>Nivel:</p>
            <Select
              isMulti
              options={nivelOptions}
              className='select-periodo'
              classNamePrefix='select-search'
              value={niveles}
              onChange={setNiveles}
            />
          </div>
        )}
        <div className='sub-filtro'>
          <button
            className='NewButton'
            onClick={loading ? () => {} : reloadList}
          >
            Generar
          </button>
        </div>
      </div>
      <div className='opciones-bar' style={{ marginTop: '50px' }}>
        <div className='sub-filtro' style={{ marginRight: '10px' }}>
          <button onClick={downloadReporte} className='NewButton'>
            Descargar
          </button>
        </div>
      </div>
      <div
        style={{
          height: 'calc(100% - 120px)',
          overflow: 'auto',
          position: 'relative',
        }}
      >
        {loading || Object.keys(calendarios).length > 0 ? (
          <Table
            loading={loading}
            calendarios={calendarios}
            estructuraAgrupadores={estructuraAgrupadores}
          />
        ) : null}
      </div>
    </Contenedor>
  )
}

export default ParticipacionGlobal
