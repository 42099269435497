/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Select from 'react-select'
import { toast } from 'react-toastify'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import moment from 'moment'

import Contenedor from '../../Components/Contenedor/Contenedor'
import { getRequest } from '../../utils/requester'
import Can from '../../utils/can'
import NewModal from '../../Components/Modal/NewModal'
import MultiSelect from '../../Components/MultiSelect'
import DataTable from '../../Components/DataTables'
import './style.css'

const AvanceEstructuras = ({ listaCampus }) => {
  const [periodo, setPeriodo] = useState({})
  const [periodos, setPeriodos] = useState([])
  const [loadingPeriodos, setLoadingPeriodos] = useState(false)
  const [data, setData] = useState([])
  const [modalEnviarEstructura, setModalEnviarEstructura] = useState(false)
  const [enviandoEstructura, setEnviandoEstructura] = useState(false)
  const [dataFiltrada, setDataFiltrada] = useState([])
  const [loadingReporte, setLoadingReporte] = useState(false)
  const [
    modalEnviarEstructuraCampus,
    setModalEnviarEstructuraCampus,
  ] = useState(false)

  const [opcionesCampus, setOpcionesCampus] = useState([])
  const [quantityCampus, setQuantityCampus] = useState(0)
  const [campus] = useState({})
  const [valueCampus, setValueCampus] = useState('')

  const [year, setYear] = useState({})
  const [years, setYears] = useState([])

  useEffect(() => {
    if (periodo.value !== undefined) {
      getReporte()
    }
  }, [periodo])

  useEffect(() => {
    const myOpcionesYears = []
    const actualYear = new Date().getFullYear()
    for (let i = actualYear - 4; i <= actualYear; i++) {
      myOpcionesYears.push({
        label: String(i),
        value: String(i),
      })
    }
    setYears(myOpcionesYears)
    setYear({
      label: String(actualYear),
      value: String(actualYear),
    })

    const myOpcionesCampus = []
    myOpcionesCampus.push({
      label: 'Todos',
      value: '0',
    })
    if (listaCampus) {
      listaCampus.forEach((reg) => {
        myOpcionesCampus.push({
          label: reg.nombre,
          value: String(reg.id),
        })
      })
    }
    setOpcionesCampus(myOpcionesCampus)
  }, [])

  const headers = [
    'Campus',
    'Periodo',
    'Calendario',
    'Modalidad',
    'Total de Asignaciones',
    'Asignaciones Realizadas',
    'Asignaciones Pendientes',
    '% Asignado',
  ]

  useEffect(() => {
    if (year.value !== undefined) {
      getPeriodos()
    }
  }, [year])

  function notificacionEnviarEstrucutra() {
    setModalEnviarEstructura(true)
  }

  const search = (e) => {
    const text = e.target.value.toLowerCase()

    const result = data.filter((row) => {
      if (JSON.stringify(row).toLowerCase().includes(text)) {
        return true
      }
      return false
    })
    setDataFiltrada(result)
  }

  async function enviarEstructura() {
    setEnviandoEstructura(true)

    const getQuery =
      '?id_calendario=' + periodo.id + '&id_campus=' + valueCampus

    const query = await fetch(
      'http://159.203.83.182/sieda_2.0/enviar_estructura.php' + getQuery,
      {
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const response = await query.json()
    if (response.s === 'OK') {
      setEnviandoEstructura(false)
      setModalEnviarEstructura(false)
      setModalEnviarEstructuraCampus(false)
      toast.success(response.m)
    } else {
      toast.error(response.m)
      setEnviandoEstructura(false)
      setModalEnviarEstructuraCampus(false)
    }
  }

  async function getPeriodos() {
    setLoadingPeriodos(true)

    const response = await getRequest('/calendario/periodo?year=' + year.value)
    if (response.s === 'OK') {
      const myPeriodos = []
      response.d.forEach((reg) => {
        myPeriodos.push({
          value: reg,
          label: reg,
        })
      })
      setPeriodos(myPeriodos)
      setLoadingPeriodos(false)
    } else {
      setPeriodos([])
      setLoadingPeriodos(false)
    }
  }

  async function getReporte() {
    setLoadingReporte(true)
    const response = await getRequest(
      '/reportes/estructuras?year=' + year.value + '&periodo=' + periodo.value
    )
    if (response.s === 'OK') {
      const myData = []
      if (response.d !== '') {
        response.d.forEach((reg) => {
          myData.push([
            reg.campus,
            reg.periodo,
            reg.nombre_calendario,
            reg.modalidad,
            reg.total,
            reg.total_coordinadores_asignados,
            reg.total_coordinadores_NO_asignados,
            reg.porcentaje + ' %',
          ])
        })
      }

      setDataFiltrada(myData)
      setData(myData)
      setLoadingReporte(false)
    } else {
      setDataFiltrada([])
      setData([])
      setLoadingReporte(false)
    }
  }

  function downloadReporte() {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const dataDownload = []
    data.forEach((reg) => {
      const myReg = {}
      headers.forEach((header, i) => {
        myReg[header] = reg[i]
      })
      dataDownload.push(myReg)
    })

    const ws = XLSX.utils.json_to_sheet(dataDownload.map((row) => row))
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data2 = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(
      data2,
      `Reporte avance de estructura ${moment().format('YYYY-MM-DD_HH:mm')}.xlsx`
    )
  }

  return (
    <>
      <Contenedor title='Reporte de Avance de Estructuras'>
        <div className='opciones-bar'>
          <div className='filtros-tabla-completa' style={{ float: 'left' }}>
            <div className='sub-filtro'>
              <p>Seleccionar Año:</p>

              <Select
                options={years}
                className='select-periodo'
                classNamePrefix='select-search'
                value={year}
                onChange={setYear}
              />
            </div>
            <div className='sub-filtro'>
              <p>Periodo:</p>
              {loadingPeriodos ? (
                <CircularProgress />
              ) : (
                <Select
                  options={periodos}
                  className='select-periodo'
                  classNamePrefix='select-search'
                  value={periodo}
                  onChange={setPeriodo}
                />
              )}
            </div>
            {periodo.value !== undefined ? (
              <>
                <div className='sub-filtro'>
                  <button
                    className='NewButton'
                    onClick={() => {
                      getReporte()
                    }}
                  >
                    Consultar
                  </button>
                </div>
                <Can I='read' a='estructura_enviar'>
                  <div className='sub-filtro'>
                    <button
                      className='NewButton'
                      onClick={() => {
                        setModalEnviarEstructuraCampus(true)
                      }}
                    >
                      Enviar Estructura
                    </button>
                  </div>
                </Can>
              </>
            ) : null}
            <div className='sub-filtro'>
              {periodo.value !== undefined ? (
                <button className='ButtonXlsx' onClick={downloadReporte}>
                  Descargar Excel
                </button>
              ) : null}
            </div>
          </div>
          <input
            type='search'
            placeholder='Buscar'
            className='SearchInput-AvanceEstrucutras'
            onChange={search}
          />
        </div>
        {periodo.value !== undefined ? (
          <div style={{ height: 'calc(100% - 70px)' }}>
            <DataTable
              headers={headers}
              data={dataFiltrada}
              loading={loadingReporte}
              paginate
            />
          </div>
        ) : null}
        <NewModal
          title='Enviar Estrucutra'
          open={modalEnviarEstructura}
          handleClose={() => setModalEnviarEstructura(false)}
          height={100}
          loading={enviandoEstructura}
          handleSubmit={enviarEstructura}
          width={'30%'}
        >
          <h2 style={{ textAlign: 'center' }}>
            ¿Seguro que desea enviar estrucutra?
          </h2>
        </NewModal>
        <NewModal
          title='Enviar Estrucutra'
          open={modalEnviarEstructuraCampus}
          handleClose={() => setModalEnviarEstructuraCampus(false)}
          height={400}
          loading={enviandoEstructura}
          handleSubmit={notificacionEnviarEstrucutra}
          width={'30%'}
        >
          <Grid container>
            <Grid xs={2} />
            <Grid xs={8} style={{ marginTop: 120 }}>
              <tag>Seleccione Campus:</tag>
            </Grid>
            <Grid xs={2} />
            <Grid xs={2}></Grid>
            <Grid
              xs={8}
              className='nuevoUsuarioForm contenedor-campus-avance-estructuras'
              style={{ textAlign: 'center' }}
            >
              <MultiSelect
                loading={false}
                options={opcionesCampus}
                setCantidad={setQuantityCampus}
                cantidad={quantityCampus}
                onSet={setValueCampus}
                selecteds={valueCampus}
                value={campus}
              />
            </Grid>
          </Grid>
        </NewModal>
      </Contenedor>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    listaCampus: state.listaCampus,
  }
}

AvanceEstructuras.propTypes = {
  listaCampus: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      nombre: PropTypes.string,
      clave: PropTypes.string,
    })
  ),
}

export default connect(mapStateToProps)(AvanceEstructuras)
