/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'

import { getRequest } from '../../utils/requester'
import './style.css'

function SingleSelect({
  onOpen,
  fromUser,
  fromReport,
  fila,
  setCoordinadorFila,
  valueOfCell,
  valueCampus,
  canEdit,
  idCoordinador,
}) {
  const [opciones, setOpciones] = useState([])
  const [seleccionados, setSeleccionados] = useState([valueOfCell])
  const [openOptions, setOpenOptions] = useState(false)
  const [busqueda, setBusqueda] = useState('')
  const [previoSeleccionado, setPrevioSeleccionado] = useState([])
  const [options, setOpcionesCoordinadores] = useState([])
  const [searchTimeout, setSearchTimeout] = useState()
  const [loading, setLoadingCoordinadores] = useState(false)
  const [, onSet] = useState('')
  async function getCoordinadores(e) {
    let text = e.target.value

    clearTimeout(searchTimeout)
    if (text.length > 2) {
      setSearchTimeout(
        setTimeout(async function () {
          setLoadingCoordinadores(true)
          let response = await getRequest(
            '/estructura/coordinador?clave=' + text + '&campus=' + valueCampus
          )
          let coordinadoresArray = []
          if (response.s === 'OK') {
            if (response.d === '') {
            } else {
              response.d.forEach((reg) => {
                coordinadoresArray.push({
                  id: reg.id,
                  value: reg.clave,
                  label: reg.clave + '-' + reg.nombre,
                  nombre: reg.nombre,
                })
              })
            }
            setOpcionesCoordinadores(coordinadoresArray)
            setLoadingCoordinadores(false)
          } else {
            setLoadingCoordinadores(false)
          }
        }, 1000)
      )
    }
  }

  useEffect(() => {
    setSeleccionados([])
    setOpciones(options)
    setSeleccionados([valueOfCell])
  }, [loading, options])

  function setValues() {
    let stringValues = ''
    let stringCompleto = false
    let hereSeleccionados =
      seleccionados.length === 0 ? previoSeleccionado : seleccionados
    if (seleccionados.length === 0) {
      setSeleccionados(previoSeleccionado)
    }
    if (hereSeleccionados.includes('0') && !stringCompleto) {
      stringValues = ''
      if (hereSeleccionados.length + 1 === opciones.length) {
        for (let i = 0; i < hereSeleccionados.length; i++) {
          if (hereSeleccionados[i] != '0' && hereSeleccionados[i] != ['53']) {
            stringValues = stringValues + hereSeleccionados[i]
            if (i !== hereSeleccionados.length - 1) {
              stringValues = stringValues + ','
            }
          }
        }
      }
    } else {
      for (let i = 0; i < hereSeleccionados.length; i++) {
        if (hereSeleccionados[i] != '0') {
          stringValues = stringValues + hereSeleccionados[i]
          if (i !== hereSeleccionados.length - 1) {
            stringValues = stringValues + ','
          }
        }
      }
    }
    setBusqueda('')
    setOpciones(options)
    onSet(stringValues)
    let nombreCoordinador = ''
    let idCoordinador = ''
    opciones.forEach((reg) => {
      if (reg.value === stringValues) {
        nombreCoordinador = reg.nombre
        idCoordinador = reg.id
      }
    })
    if (nombreCoordinador !== '') {
      setCoordinadorFila(fila, stringValues, nombreCoordinador, idCoordinador)
    }
  }

  function selectOption(_, value) {
    setSeleccionados([value])
  }

  function searchOption(e) {
    let text = e.target.value
    let myOptions = []
    options.forEach((option) => {
      if (
        option.label !== 'Todos' &&
        option.label
          .toString()
          .toLowerCase()
          .includes(text.toString().toLowerCase())
      ) {
        myOptions.push(option)
      } else if (option.label === 'Todos' && text === '') {
        myOptions.push(option)
      }
    })
    setOpciones(myOptions)
  }

  return (
    <div className='contenedor-multiselect'>
      <input
        value={
          seleccionados.length === 0 || seleccionados[0] === ''
            ? busqueda
            : seleccionados[0]
        }
        onChange={(e) => {
          getCoordinadores(e)
          setBusqueda(e.target.value)
        }}
        onFocus={() => {
          setOpenOptions(true)
          setPrevioSeleccionado(seleccionados)
          setSeleccionados([])
          if (onOpen) {
            onOpen()
          }
        }}
        disabled={!canEdit}
        type='number'
        name=''
        title={idCoordinador}
        id={idCoordinador}
        className='input-coordinador-draggable'
        placeholder='Buscar...'
      />

      <div
        style={!openOptions ? { display: 'none' } : {}}
        className={
          fromUser
            ? 'contenedor-aceptar-opciones2'
            : fromReport
            ? 'contenedor-aceptar-opciones3'
            : 'contenedor-aceptar-opciones2'
        }
      >
        {loading ? (
          <div style={{ textAlign: 'center', paddingTop: 30 }}>
            <CircularProgress size={50} />
          </div>
        ) : (
          <>
            <input
              style={{ display: 'none' }}
              type='text'
              placeholder='Busca...'
              onChange={searchOption}
            />
            <div className='div-contenedor-opciones2'>
              {opciones.map((option) => (
                <p
                  className={
                    seleccionados.includes(option.value) ? 'selected' : {}
                  }
                  onClick={() => {
                    if (seleccionados.includes(option.value)) {
                      selectOption(false, option.value, option.id)
                    } else {
                      selectOption(true, option.value, option.id)
                    }
                  }}
                >
                  {option.label}
                </p>
              ))}
            </div>
            <button
              className='button-aceptar-opciones'
              onClick={() => {
                setValues()
                setOpenOptions(false)
              }}
            >
              Aceptar
            </button>
          </>
        )}
      </div>
    </div>
  )
}

SingleSelect.propTypes = {
  onOpen: PropTypes.func,
  fromUser: PropTypes.bool,
  fromReport: PropTypes.bool,
  fila: PropTypes.number,
  setCoordinadorFila: PropTypes.func,
  valueOfCell: PropTypes.string,
  valueCampus: PropTypes.string,
  canEdit: PropTypes.bool,
  idCoordinador: PropTypes.number,
}

export default SingleSelect
