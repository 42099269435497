/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import LinearProgress from '@material-ui/core/LinearProgress'
import CircularProgress from '@material-ui/core/CircularProgress'
import { toast } from 'react-toastify'

import Contenedor from '../../Components/Contenedor/Contenedor'
import MultiSelect from '../../Components/MultiSelect'
import NewModal from '../../Components/Modal/NewModal'
import { getRequest, postRequest, putRequest } from '../../utils/requester'
import './style.css'

/**
 * Returns the current date
 *
 * @returns {string} date with format YYYYMMDD_HHMM
 */
const getDate = () => {
  const year = new Date().getFullYear()
  const month = new Date().getMonth() + 1
  const day = new Date().getDate()
  const hour = new Date().getHours()
  const minute =
    new Date().getMinutes() < 10
      ? '0' + new Date().getMinutes()
      : new Date().getMinutes()

  const second =
    new Date().getSeconds() < 10
      ? '0' + new Date().getSeconds()
      : new Date().getSeconds()

  return `${year}${month}${day}_${hour}${minute}${second}`
}

function CargaManualCRN({ listaCampus }) {
  const [periodo, setPeriodo] = useState({})
  const [year, setYear] = useState({})
  const [ciclo, setCiclo] = useState({})
  const [campus] = useState({})
  const [nivel] = useState({})
  const [loading, setLoading] = useState(false)
  const [generado, setGenerado] = useState(false)
  const [years, setYears] = useState([])
  const [campusOptions, setCampusOptions] = useState([])
  const [periodoOptions, setPeriodoOptions] = useState([])
  const [, setNivelOptions] = useState([])
  const [procesandoReporte, setProcesandoReporte] = useState(false)
  const [urlDownload, setURLDownload] = useState('')
  const [urlDownloadReport, setURLDownloadReport] = useState('')
  const [progreso, setProgreso] = useState(0)
  const [quantityCampus, setQuantityCampus] = useState(0)
  const [valueCampus, setValueCampus] = useState('')
  const [loadingCalendarios, setLoadingCalendarios] = useState(false)
  const [calendarios, setCalendarios] = useState([])
  const [calendario, setCalendario] = useState({})
  const [tipoPeriodo, setTipoPeriodo] = useState({
    label: 'Abierto',
    value: 'abierto',
  })
  const [file, setFile] = useState({})
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [resultModal, setResultModal] = useState(false)
  const [resultInfo, setResultInfo] = useState({
    total_elementos: '',
    total_elementos_correctos: '',
    total_elementos_descartados: '',
    total_elementos_error: '',
  })

  useEffect(() => {
    //reloadList()
    getNivelOptions()

    const lista = listaCampus
      .sort((a, b) => {
        if (a.nombre < b.nombre) {
          return -1
        }
        if (a.nombre > b.nombre) {
          return 1
        }
        return 0
      })
      .map(({ nombre, id }) => ({ label: nombre, value: String(id) }))

    lista.unshift({
      label: 'TODOS',
      value: '0',
    })

    setCampusOptions(lista)
  }, [])

  useEffect(() => {
    const myOpcionesYears = []
    const actualYear = new Date().getFullYear()
    for (let i = actualYear - 4; i <= actualYear; i++) {
      myOpcionesYears.push({
        label: String(i),
        value: String(i),
      })
    }
    setYears(myOpcionesYears)
    setYear({
      label: String(actualYear),
      value: String(actualYear),
    })
  }, [])

  const getPeriodoOptions = async () => {
    setLoading(true)
    try {
      const response = await getRequest(
        `/calendario/periodo?year=${year.value}&ciclo=${ciclo.value}`
      )
      if (response.s === 'OK') {
        const periodos = response.d.map((periodo) => ({
          label: periodo,
          value: periodo,
        }))
        periodos.unshift({
          label: '',
          value: undefined,
        })
        setPeriodoOptions(periodos)
        setLoading(false)
      } else {
        setPeriodoOptions([])
        setLoading(false)
      }
    } catch (error) {
      setPeriodoOptions([])
      console.error(error)
      setLoading(false)
    }
  }

  const getNivelOptions = async () => {
    setLoading(true)
    try {
      const response = await getRequest('/calendario/nivel')
      if (response.s === 'OK') {
        const niveles = response.d.map(({ nombre }) => ({
          label: nombre,
          value: nombre,
        }))
        niveles.unshift({
          label: '',
          value: undefined,
        })
        setNivelOptions(niveles)
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (year.value && ciclo.value) {
      getPeriodoOptions()
    }
  }, [year, ciclo])

  const cicloOptions = [
    {
      label: 'C1',
      value: 'C1',
    },
    {
      label: 'C2',
      value: 'C2',
    },
    {
      label: 'C3',
      value: 'C3',
    },
  ]

  async function getReporte() {
    setLoading(true)
    setGenerado(true)
    try {
      const response = await postRequest(
        '/reportes/carga_manual_resultados_dimension',
        {
          campus: valueCampus.split(',').map((id) => parseInt(id)),
          year: parseInt(year.value),
          periodo: periodo.value,
          calendario: calendario.value,
          nivel: nivel.value,
          tipo: 'CRN',
          accion: 'generar',
        }
      )
      if (response.s === 'OK') {
        setLoading(false)
        progresGenerando(response.d.id_rastreo, '', 0, 100, 1, 0)
        setProcesandoReporte(true)
        toast.success('Generando reporte')
      } else {
        setLoading(false)
      }
    } catch (e) {
      toast.error('Error procesando reporte')
      setLoading(false)
      console.log(e)
    }
  }

  function progresGenerando(id, id_job, _myProgreso, _timer, vez, time) {
    setTimeout(function () {
      postRequest('/reportes/carga_manual_resultados_dimension', {
        campus: valueCampus.split(',').map((id) => parseInt(id)),
        ciclo: ciclo.value,
        year: parseInt(year.value),
        periodo: periodo.value,
        nivel: nivel.value,
        tipo: 'CRN',
        accion: 'verificar',
        id_location: parseInt(id),
        id_job: parseInt(id_job === '' ? null : parseInt(id_job)),
        calendario: calendario.value,
      }).then((response) => {
        if (response.s === 'OK') {
          if (response.d.result === 'SUCCESS') {
            toast.success('Reporte obtenido correctamente')
            setURLDownload(response.d.url)
            setProcesandoReporte(false)
            setProgreso(0)
          } else if (response.d.result === 'FAILURE') {
            setProcesandoReporte(false)
            toast.error('Error al generar reporte')
            setProgreso(0)
            return
          } else {
            let duracion = 10000
            if (isNaN(response.d.estimatedDuration)) {
              vez = 0
              duracion = 2000
            }
            let actualProgreso =
              vez < 1 ? 0 : ((10 * vez) / response.d.estimatedDuration) * 100

            setProgreso(actualProgreso < 100 ? actualProgreso : 100)
            progresGenerando(
              id,
              response.d.id_job,
              actualProgreso,
              response.d.estimatedDuration,
              vez + 1,
              duracion
            )
          }
        } else {
          setProcesandoReporte(false)
        }
      })
    }, time)
  }

  async function getCalendarios() {
    setLoadingCalendarios(true)
    const response = await getRequest(
      '/calendario?year=' +
        new Date().getFullYear() +
        '&periodo=' +
        periodo.value +
        '&tipo=' +
        tipoPeriodo.value
    )
    if (response.s === 'OK') {
      const myPeriodos = []
      response.d.forEach((reg) => {
        myPeriodos.push({
          value: reg.name,
          label: reg.name,
        })
      })
      setCalendario({})
      setCalendarios(myPeriodos)
      setLoadingCalendarios(false)
    } else {
      setCalendario({})
      setCalendarios([])
      setLoadingCalendarios(false)
    }
  }

  useEffect(() => {
    if (periodo.value !== undefined) {
      getCalendarios()
    }
  }, [periodo, tipoPeriodo])

  const tipoPeriodoOptions = [
    {
      label: 'Abierto',
      value: 'abierto',
    },
    {
      label: 'Cerrado',
      value: 'cerrado',
    },
  ]

  /**
   * Handles the changes of file input
   *
   * @param {React.ChangeEvent<HTMLInputElement>} ev The data of the event
   */
  function handleChangeReader(ev) {
    setFile(ev.target.files[0])

    setConfirmationModal(true)
  }

  /**
   * Uploads the file
   */
  async function sendFile() {
    const nombreArchivo = 'Carga_Manual_de_Resultados_' + getDate() + '.xlsx'

    setGenerado(true)
    setLoading(true)
    setConfirmationModal(false)

    try {
      setProcesandoReporte(true)
      let response = await postRequest('/usuarios/subir-documento', {
        nombre: nombreArchivo,
      })
      if (response.s === 'OK') {
        putRequest(response.d.presign_url, file, file.type).then(() => {
          progressSubiendo(response.d.url)
        })
      } else {
      }
      setLoading(false)
    } catch (e) {
      setProcesandoReporte(false)
      setLoading(false)
      console.error(e)
      toast.error('Error subiendo archivo')
    }
  }

  function progressSubiendo(url_carga, vez, time) {
    setTimeout(function () {
      postRequest('/reportes/procesar_carga_manual_resultados_dimension', {
        tipo: 'CRN',
        url_carga,
        accion: 'generar',
      }).then((response) => {
        if (response.s === 'OK') {
          if (response.d.url_result) {
            toast.success('Resultado cargado correctamente')
            setProcesandoReporte(false)
            setProgreso(0)
            setResultModal(true)
            setURLDownloadReport(response.d.url_result)
            setResultInfo(response.d)
          } else {
            progressGenerandoCarga(
              response.d.id_rastreo,
              '',
              0,
              100,
              1,
              0,
              url_carga
            )
          }
        }
      })
      document.getElementById('input-carga-resultados').value = null
    }, time)
  }

  function progressGenerandoCarga(
    id,
    id_job,
    _myProgreso,
    _timer,
    vez,
    time,
    url_carga
  ) {
    setTimeout(function () {
      postRequest('/reportes/procesar_carga_manual_resultados_dimension', {
        tipo: 'CRN',
        url_carga,
        accion: 'verificar',
        calendario: calendario.value,
        id_location: parseInt(id),
        id_job: parseInt(id_job === '' ? null : parseInt(id_job)),
      }).then((response) => {
        if (response.s === 'OK') {
          if (response.d.result === 'SUCCESS') {
            toast.success('Reporte obtenido correctamente')
            setResultInfo(response.d)
            setURLDownloadReport(response.d.url)
            setResultModal(true)
            setProcesandoReporte(false)
            setProgreso(0)
          } else if (response.d.result === 'FAILURE') {
            setProcesandoReporte(false)
            toast.error('Error al cargar resultados')
            setProgreso(0)
            return
          } else {
            let duracion = 10000
            if (isNaN(response.d.estimatedDuration)) {
              vez = 0
              duracion = 2000
            }
            let actualProgreso =
              vez < 1 ? 0 : ((10 * vez) / response.d.estimatedDuration) * 100

            setProgreso(actualProgreso < 100 ? actualProgreso : 100)
            progressGenerandoCarga(
              id,
              response.d.id_job,
              actualProgreso,
              response.d.estimatedDuration,
              vez + 1,
              duracion,
              url_carga
            )
          }
        } else {
        }
      })
    }, time)
  }

  return (
    <Contenedor title='Carga por CRN'>
      <div style={{ left: '40px', top: '57px', position: 'absolute' }}>
        <p>FILTROS</p>
      </div>
      <div
        className='filtros-tabla-completa'
        style={{ float: 'left', left: '95px' }}
      >
        <div className='sub-filtro'>
          <p>Campus(*):</p>
          <MultiSelect
            fromUser={false}
            loading={false}
            options={campusOptions}
            setCantidad={setQuantityCampus}
            cantidad={quantityCampus}
            onSet={setValueCampus}
            selecteds={valueCampus}
            value={campus}
          />
        </div>
        <>
          <div className='sub-filtro'>
            <p>Año(*):</p>
            <Select
              options={years}
              className='select-periodo small-bar'
              classNamePrefix='select-search'
              value={year}
              onChange={setYear}
            />
          </div>
          <div className='sub-filtro'>
            <p>Ciclo(*):</p>
            <Select
              options={cicloOptions}
              className='select-periodo'
              classNamePrefix='select-search'
              value={ciclo}
              onChange={setCiclo}
            />
          </div>
        </>
        <div className='sub-filtro'>
          <p>Tipo Periodo:</p>
          <Select
            options={tipoPeriodoOptions}
            className='select-periodo'
            classNamePrefix='select-search'
            value={tipoPeriodo}
            onChange={setTipoPeriodo}
          />
        </div>
        <div className='sub-filtro'>
          <p>Periodo:</p>
          <Select
            options={periodoOptions}
            className='select-periodo'
            classNamePrefix='select-search'
            value={periodo}
            onChange={setPeriodo}
          />
        </div>
        <div className='sub-filtro'>
          <p>Calendario:</p>
          {loadingCalendarios ? (
            <CircularProgress />
          ) : (
            <Select
              options={calendarios}
              className='select-periodo'
              classNamePrefix='select-search'
              value={calendario}
              onChange={setCalendario}
            />
          )}
        </div>
        <div className='sub-filtro'>
          <button
            className='NewButton'
            onClick={loading ? () => {} : getReporte}
          >
            Generar
          </button>
        </div>
        <input
          onChange={handleChangeReader}
          type='file'
          id='input-carga-resultados'
          style={{ display: 'none' }}
          accept='.xlsx'
        />
        <div className='sub-filtro'>
          <button
            onClick={() =>
              document.getElementById('input-carga-resultados').click()
            }
            className='NewButton'
          >
            Cargar Resultados
          </button>
        </div>
      </div>
      <div className='opciones-bar' style={{ marginTop: '120px' }}></div>
      <div style={{ height: 'calc(100% - 202px)' }}>
        {generado ? (
          <div style={{ height: '100%' }}>
            {loading ? (
              <div style={{ marginLeft: '50%', marginTop: '90px' }}>
                <CircularProgress />
              </div>
            ) : procesandoReporte ? (
              <>
                <div style={{ textAlign: 'center' }}>
                  <p style={{ textAlign: 'center', fontSize: '22px' }}>
                    Generando reporte
                  </p>
                  <CircularProgress />
                </div>
                <div id='barra-progreso'>
                  <LinearProgress variant='determinate' value={progreso} />
                </div>
              </>
            ) : (
              <>
                {urlDownload === '' ? (
                  <>
                    <p style={{ textAlign: 'center', fontSize: '22px' }}>
                      No se ha generado reporte
                    </p>
                  </>
                ) : (
                  <button
                    onClick={() => {
                      window.open(urlDownload)
                    }}
                    style={{ margin: 'auto' }}
                    className='NewButton'
                  >
                    Descargar excel
                  </button>
                )}
              </>
            )}
          </div>
        ) : (
          <p style={{ textAlign: 'center', fontSize: '22px' }}>
            Aplica los filtros para generar el Reporte
          </p>
        )}
      </div>
      <NewModal
        title='Carga Manual de Resultados'
        open={confirmationModal}
        handleClose={() => {
          setFile({})
          document.getElementById('input-carga-resultados').value = null
          setConfirmationModal(false)
        }}
        height={100}
        width={500}
        handleSubmit={sendFile}
      >
        <h4 style={{ textAlign: 'center' }}>
          ¿Está seguro que desea proceder con la carga manual {file.name}?
        </h4>
      </NewModal>
      <NewModal
        title='Resultado Carga Manual'
        open={resultModal}
        handleClose={() => setResultModal(false)}
        handleSubmit={() => setResultModal(false)}
        height={500}
        width={700}
        noCancel
      >
        <div
          style={{
            border: '4px solid #000',
            margin: '40px',
            padding: '0 20px',
          }}
        >
          <h4 style={{ textAlign: 'center' }}>Detalle</h4>

          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              marginBottom: 40,
            }}
          >
            <p style={{ fontWeight: 600, margin: 5 }}>Total elementos:</p>
            <p style={{ fontWeight: 600, margin: 5 }}>
              {resultInfo.total_elementos}
            </p>
            <p style={{ fontWeight: 600, margin: 5 }}>
              Total elementos correctas:
            </p>
            <p style={{ fontWeight: 600, margin: 5 }}>
              {resultInfo.total_elementos_correctos}
            </p>
            <p style={{ fontWeight: 600, margin: 5 }}>
              Total elementos descartados:
            </p>
            <p style={{ fontWeight: 600, margin: 5 }}>
              {resultInfo.total_elementos_descartados}
            </p>
            <p style={{ fontWeight: 600, margin: 5 }}>Total elementos error:</p>
            <p style={{ fontWeight: 600, margin: 5 }}>
              {resultInfo.total_elementos_error}
            </p>
          </div>
        </div>
        <button
          onClick={() => {
            window.open(urlDownloadReport)
          }}
          style={{ margin: 'auto' }}
          className='NewButton'
        >
          Descargar reporte
        </button>
      </NewModal>
    </Contenedor>
  )
}

const mapStateToProps = (state) => ({
  listaCampus: state.listaCampus,
})

export default connect(mapStateToProps)(CargaManualCRN)
