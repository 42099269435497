/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'

import Logo from '../../images/logo_sieda.png'
import Pregunta from '../../images/pregunta.png'
import BotonSafeKey from '../../images/boton_safekey.png'
import Footer from '../../Components/Footer/Footer'

import './styles.css'

function LoginPage({ loading, doLogin }) {
  return <UserForm doLogin={doLogin} loading={loading} />
}

const UserForm = ({ loading, doLogin }) => {
  const handleSubmit = (e) => {
    e.preventDefault()
    doLogin('enviar login')
  }

  return (
    <div className='form-page'>
      <div className='form-container'>
        <img className='image' src={Logo} alt='logo-uvm' />
        <form className='from' onSubmit={handleSubmit}>
          <div className='button-container'>
            {loading ? (
              <div className='loader-container'>
                <CircularProgress size={40} color='inherit' />
              </div>
            ) : (
              <button className='button' type='submit' disabled={loading}>
                <img
                  src={BotonSafeKey}
                  alt='boton_safekey'
                  style={{ height: '130%' }}
                />
              </button>
            )}
          </div>
        </form>
        <a
          href='#'
          target='_blank'
          style={{ marginTop: '90px', marginLeft: '36px', color: 'white' }}
          className='link-ayuda'
        >
          <p>
            <img src={Pregunta} alt='img-pregunta' />
            Mesa de ayuda Vicerrectoría
          </p>
        </a>
      </div>
      <Footer version='0.0.1' />
    </div>
  )
}

LoginPage.propTypes = {
  loading: PropTypes.bool,
  doLogin: PropTypes.func,
}

export default LoginPage
