/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { toast } from 'react-toastify'

import Contenedor from '../../Components/Contenedor/Contenedor'
import NewButton from '../../Components/Botones/NewButton'
import EditButton from '../../Components/Botones/EditButton'
import DataTable from '../../Components/DataTables'
import NewModal from '../../Components/Modal/NewModal'
import { getRequest, postRequest } from '../../utils/requester'
import './style.css'

function Etiquetas() {
  const [dataInicial, setDataInicial] = useState([])
  const [dataFiltradas, setDataFiltradas] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [loadingModalEditar, setLoadingModalEditar] = useState(false)
  const [modalEditarIsOpen, setModalEditarIsOpen] = useState(false)
  const [modalNuevaIsOpen, setModalNuevaIsOpen] = useState(false)
  const [nueva, setNueva] = useState({
    nombre: '',
  })
  const [editar, setEditar] = useState({
    id: '',
    nombre: '',
  })
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteModalLoading, setDeleteModalLoading] = useState(false)

  useEffect(() => {
    reloadList()
  }, [])

  const openEditModal = async ({ id, nombre }) => {
    const reg = { id, nombre }
    setEditar(reg)
    setModalEditarIsOpen(true)
  }

  const handleEditar = async (data) => {
    data.status = 1

    if (validar(data)) {
      setLoadingModalEditar(true)
      try {
        const editarResponse = await postRequest(
          `/instrumento/etiqueta/editar`,
          data
        )
        if (editarResponse.s === 'OK') {
          toast.success(editarResponse.m)
          setModalEditarIsOpen(false)
          setLoadingModalEditar(false)
          reloadList()
        }
        setLoadingModalEditar(false)
      } catch (error) {
        console.error(error)
        setLoadingModalEditar(false)
      }
    }
  }

  const handleNueva = async (data) => {
    if (validar(data)) {
      try {
        setLoadingModal(true)
        console.log(data)
        const nuevaResponse = await postRequest(
          `/instrumento/etiqueta/nueva`,
          data
        )

        if (nuevaResponse.s === 'OK') {
          setLoadingModal(false)
          setModalNuevaIsOpen(false)
          reloadList()
        }
        setLoadingModal(false)
      } catch (error) {
        console.error(error)
        setLoadingModal(false)
      }
    }
  }

  const validar = (body) => {
    let valido = true
    if (body.nombre === '') {
      document.getElementById('tag-nombre').classList.add('error')
      valido = false
    } else {
      document.getElementById('tag-nombre').classList.remove('error')
    }

    return valido
  }

  const handleDelete = async () => {
    console.log(editar)
    const body = { ...editar }
    body.status = 0
    setDeleteModalLoading(true)
    try {
      const eliminarResponse = await postRequest(
        `/instrumento/etiqueta/editar`,
        body
      )
      if (eliminarResponse.s === 'OK') {
        setModalEditarIsOpen(false)
        setDeleteModalLoading(false)
        reloadList()
        setDeleteModal(false)
      }
      setDeleteModal(false)
      setDeleteModalLoading(false)
    } catch (error) {
      console.error(error)
      setDeleteModal(false)
      setDeleteModalLoading(false)
    }
  }

  const testHeader = ['Folio', 'Nombre', 'Acciones']

  ////////// Filtrar resultados segun el input de busqueda //////////
  const search = (e) => {
    const text = e.target.value.toLowerCase()

    const result = dataInicial.filter((row) => {
      if (row[0].toString().toLowerCase().includes(text)) return true
      else if (row[1].toLowerCase().includes(text)) return true
      return false
    })
    setDataFiltradas(result)
  }

  const reloadList = async () => {
    setLoading(true)
    try {
      const response = await getRequest('/instrumento/etiqueta')

      if (response.s === 'OK') {
        const data = []
        response.d.forEach((reg) => {
          data.push([
            reg.id,
            reg.nombre,
            <EditButton onClick={(e) => openEditModal(reg)}>Editar</EditButton>,
          ])
        })
        setDataInicial(data)
        setDataFiltradas(data)
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  return (
    <Contenedor title='Etiquetas'>
      <div className='opciones-bar'>
        <div className='sub-filtro'>
          <NewButton
            onClick={() => {
              setModalNuevaIsOpen(true)
              setNueva({
                nombre: '',
              })
            }}
          />
        </div>
        <div style={{ float: 'right' }}>
          <input
            type='search'
            placeholder='Buscar'
            className='SearchInput-tabla-completa SearchInput-Usuarios'
            onChange={search}
          />
        </div>
      </div>
      <div style={{ height: 'calc(100% - 70px)' }}>
        <DataTable
          headers={testHeader}
          data={dataFiltradas}
          loading={loading}
          paginate
        />
      </div>
      <NewModal
        title='Editar Etiqueta'
        canDelete
        open={modalEditarIsOpen}
        handleClose={() => setModalEditarIsOpen(false)}
        height={180}
        loading={loadingModalEditar}
        handleSubmit={() => handleEditar(editar)}
        onDelete={() => setDeleteModal(true)}
        permiso='objetos_instrumento_etiquetas'
      >
        <div className='nuevoEtiquetaForm'>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <label id='tag-nombre'>Nombre (*)</label>
              <input
                placeholder='Ingresa Nombre'
                value={editar.nombre}
                onChange={(e) =>
                  setEditar({
                    ...editar,
                    nombre: e.target.value,
                  })
                }
              />
            </Grid>
          </Grid>
        </div>
      </NewModal>
      <NewModal
        title='Nueva Etiqueta'
        open={modalNuevaIsOpen}
        handleClose={() => {
          setModalNuevaIsOpen(false)
          setNueva({
            nombre: '',
            descripcion: '',
            status: 'activa',
          })
        }}
        height={180}
        loading={loadingModal}
        handleSubmit={() => handleNueva(nueva)}
      >
        <div className='nuevoEtiquetaForm'>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <label id='tag-nombre'>Nombre (*)</label>
              <input
                placeholder='Ingresa Nombre'
                value={nueva.nombre}
                onChange={(e) =>
                  setNueva({
                    ...nueva,
                    nombre: e.target.value,
                  })
                }
              />
            </Grid>
          </Grid>
        </div>
      </NewModal>
      <NewModal
        open={deleteModal}
        height={120}
        title='Confirmar'
        loading={deleteModalLoading}
        handleClose={() => setDeleteModal(false)}
        handleSubmit={handleDelete}
      >
        <div class='delete-confirm'>
          <h2 style={{ textAlign: 'center' }}>
            ¿Está seguro de que desea eliminar esta etiqueta?
          </h2>
        </div>
      </NewModal>
    </Contenedor>
  )
}

export default Etiquetas
