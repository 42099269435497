import React from "react";

const EstructuraIcon = () => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 172 175" enable-background="new 0 0 172 175" space="preserve">
            <image id="image0" width="172" height="175" x="0" y="0"
            href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKwAAACvCAQAAADKrP39AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
            AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZ
            cwAACxIAAAsSAdLdfvwAAAAHdElNRQflAgMWMi7LJVpjAAAKFElEQVR42u2dX4hcVx3HP2m2yZak
            9koVo23sCIJ9kGSCgawo9vogSqsy+IfGBsxEROpSQh6zotgniVhlmwcjRta00IAouqxt3UIkY0kx
            KRRmIQ9RWrPZVdmCpJM0IftnJseHbXY3c+ece87M/M6ZzJ7vPO3+7j2/ez5z5tx7zvmd392giJLQ
            XaEvoF8VwQopghVSBCukCFZIEayQIlghRbBCimCFFMEKKYIV0kDoC8hRSsIQ2ygCgwxSA+aYY5oK
            00yHvjy9NvTkJExCyjdJ+QiLbNIcUwcaTDHOX6iGvuAWUr31SVRZnVNK1ZW96uqyGlOF4Nd+2yf4
            Baz5FNSYWnBCulZL6oIqB69Dz4EtqMk2gd4Ot6aGg9cFheqJPjbhN3y9a6Xd5B2+QSV0pcI/bg0z
            10WscBf3c4ozJGGrFbbFJrzIHqFHvgX2Mh6uaiHBpkyyWbD8Br9jX6jKhQM7zLPiw5MGs+yiFqJ6
            ocBO8CgbvXia5XMhRmhhwPrDCrDAkP+xWYinAr9YYTNnKfqupP8WO8a3HbAusom3eJML1KgABQoU
            2M2D3OfkdZYdnvtazyOSYesh6/IMQGoYqy3PKtjqYj8PaYvWGCZV0arERI2ommpYfVET/Qo2UTUr
            qBPOM1XDat4K7b7+BHvaouoXLVtq9ksbU0u55c/7m1z0h7WU+4Otq9GOPKQW7fZMv4HN7wYWDDcq
            ey8Xc26ODVXqL7CjOT/UhTa7gOxnIgdtrZ/AJmqhA6yJOqQqK8dWctcJzGjrfqbC/YAd7QBrqmYy
            Z0zntO+L4dts+PZaN/Z6Ze15ZaNHU4/e8LE25gPsiBHrmLG16nXV2GrNQ5GZ/gB72VDF/xjPnDbi
            qRjPNd0u6127VQYEWzTcSurGR6ySypMJj7k7GOtqHVt85KcNDxrmss4aV1NLuWWbjqjxA+odlN2h
            5MHqq1DnKeOZhdyyU6P1l1zX2u61KL0jSYMt8H6t7S3xeX19mx3gW7KupcGmWkuDI8K+4aRhufIx
            WdfSYL+gtWzMXfWfzi29mmOv8brW9knZikuD/ZTW8nruUkklt/T8I45pLW5LO86S72N1Gs899wSz
            RvslizIq2l72Rs6tr0NJg9VFutzg7xZnHzRayxYlTNPQWO6Rje6SBZtyTVutisX54zyrtR2wjCic
            1lqKklWXbrGDmv8vWJ5/iAO8m/nvVQ5wwrKE/2kt2yQrLg1W97gzZ11Che8xteaLuMI59jnEv76p
            tQxal9HFiktr3uKYhEOUeajpv/exhz8Dl3jautUGUKjA43ywRar8OIN1VQ/xW6r+Q4dsFQpskmMv
            8aoB6i3t5G+9ijYUWHP/VuQk91qV8z7O5UynfFxrsemO2pY0WN3j1oeMZ41yj7WHTTk97Qe0Fvsb
            aBuSBVsztMyC1pLyiJOXR4zdgd5PVbLqsmCr2qeOugFG2dmP/oyCdpr9hmxYp3RXcEXz/wG+qj2n
            5OwlNVh0X63d2K9tSYP9t9byRa3Ffd5pp9byfa3lCqKSBntKa/mg9OIIkBg6nPOyrqXBjrOosdzN
            IWHf8IRhIfMlWdfyexD0Dq5ohgntXNKGlv+dYbvm+Aa77+SnAsCwOLKFYVHPKR/W2q5KL2TKg32O
            JY1lgJ+ITjY/b5hiGpeutjzYk9yttW3haTG/IzygtTU4Kl1tH/u8Jg2PVrAr86PsRh9b4J+GL3SW
            j0pX2sckzBFDqA+8JNIdvGrA6iOiwVPg8YwxtO1009HjucFwzRptKsEc013zUWc/Wz7LHDfcSBq8
            3DTALTsNHqab5rdO8rjhl1jn5xz2UGcvLRZ1wdjibnZx1+Cx9bS5A5XmVLdbGzIncnaTLfnKcuQL
            LGoip5+sq4sd7htM1PncLdDnfdXXH9jEYt/gfAe7XfdZlL/Yj1s+USWLLfVLqtrG/oCCqlrspF1S
            I/5q6xMs6gWrbAVLatJh+2dRTVpAVSr7WNdHYPO2tq2qrmbUSM4Pt6BG1Ix1YokZlfisqe/UJQn/
            MgTPZ585r3OWSaqsxsKmQMoQQ2xxiONZ4GG/uYz854Qpck6bE1anOvWV9d5rDDoHRi2yx3ceoxDp
            odpB24kCYA0TCVNlj3UYZ+cKgjVUiFGVbTlh8N3SbBis4WK3auygwk1RHw2m2BEqf3e4/LE1Ps8P
            RT08QzFMwkgInT8WivyJ7V1PyNfgbR4Lm2s+dMbjKh/jR12+lS3yDA8ET+HveeTV+pPkJDdx0Zjf
            EVavjLz0KnCIJxlou1uoc5NjjPbK2zx6ByxAQonDfKKNM//BUU6Gu1Vl1Vtgl1XgUfazE5W7YWie
            DUzxHC/3SjtdVS+CvaUiRb7GVzTWdzlINfgtSqteBguQclpjueQhDLQDdbaBLiElFY2/0m/LvF90
            +1yVSme/hvZbbMov2EW9598I1q4Wuc6vOdLuDbE9sAkv8unggwt51Wm0+/6PduAUmeMz6wArDLCZ
            P7QX6eXeYn1PU4eX4gTfcT3JFazbmlW/qMF+XnA7xRXsGAdC1zKIFtjmdhtz6ykL6xQrbHaNPXdr
            seu1vQIsuGXkcGuxpdC1C6gNbumkXMAm6/C2tapNbs3KBWxRNnVCz2u3y8FuXYFo3p+e11aXg7sz
            0n+bXwlVpsB+jeUKo549uslhHUefirwqtnak9znt3aNTLdfDiD+IIlghdQdsv87JdqDugHW6X64P
            mdpac+KPovbIQatRScXaU6c+2/GU73FrC49aT7q5ghJH2Z6Zz0k0pcxbDB0G2MprPJVZSWrtqROf
            Ok/DHDZ6yvN4LbPZeoCtvMKTLRffWz4s5O3v60TDwTzZ7a3p3BOK1rtm7DYNtau1r4TMe6nZneOp
            lB+7lXJK+D3HtyaNfXr6q/CDZSZxUNbdT8VfH72RJ7x5Kr3nSfp5fWtzGsBsi53XZoLvnl7js7SX
            oqR3Pb3Cl9b+mf0m5bHCgx58LEs0wbmpTv0+pPU3JmwaJPU72GCKYIUUwQopghWSS+d+ybHswZwU
            533tyQVswfEi9NHY68BT7AqEFMEKKYIVUgQrpAhWSBGskCJYIUWwQopghRTBCimCFVIEK6QIVkgR
            rJAiWCG5zMemjmUX276qPvCUDdjwEdywnHakHz2tKHYFQopghRTBCimCFVIEK6QwYP1tdr7WeRGW
            qt3+Zxbsfz1cxBsAvOPB0xQg/tZkILN/Jgv29+KXcJPjADwv7qnBzwD4o7inenPeuuwAIWFOOPh4
            6r3xi7yn5Xhun55WlG2xNYa0r5XuhmZXhpG124PLBTx9ecXTXk+eVtVyn1dRXRbZf9VQ1aZEz0VV
            E9kolPWUink60yp5tT6LUZm9PNzFb3WeNzjecotkme92dVdCL3jq+fyxd6ziAEFIEayQIlghRbBC
            imCFFMEKKYIVUgQrpAhWSBGskCJYIUWwQopghRTBCimCFVIEK6QIVkgRrJAiWCFFsEL6PyoYk8Nv
            33YBAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTAyLTAzVDIyOjUwOjQ2KzAzOjAwQ4L5uwAAACV0
            RVh0ZGF0ZTptb2RpZnkAMjAyMS0wMi0wM1QyMjo1MDo0NiswMzowMDLfQQcAAAAASUVORK5CYII=" />
        </svg>
    )
}

export default EstructuraIcon;