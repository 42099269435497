import React from 'react';
import styled from 'styled-components';
import AbiertaMobile from '../components/AbiertaMobile';
import Progress from '../components/Progress';
import Button from '../components/Button';

const PreguntaContainer = styled.div`
  margin: 1rem 3rem 0;
  @media screen and (max-width: 480px) {
    margin: 1rem 1rem 0;
  }
`;

const InputContainer = styled.div`
  margin: 38px;
  display: flex;
  align-items: center;
  margin-bottom: 8em;

  @media screen and (max-width: 1366px) {
    margin-bottom: 6em;
  }

  @media screen and (max-width: 768px) {
    margin: 38px 0 100px;
    flex-wrap: wrap;
  }
`;

const Wrapper = styled.div`
  @media screen and (min-width: 1440px) {
    display: grid;
    grid-template-rows: 2fr 1fr;
  }

  @media screen and (max-width: 1366px) {
    grid-template-rows: 1fr 1fr;
  }
`;

const Controls = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;

  @media screen and (max-width: 480px) {
    position: fixed;
    width: 91%;
    margin: 0 1rem;
  }
`;
const Name = styled.p`
  font-size: 24px;
  text-align: center;
`;
const Text = styled.p`
  font-size: 21px;
  margin: 0 10px 0 0;
  @media screen and (max-width: 768px) {
    margin: 0 10px 10px 0;
  }
`;

const Input = styled.input`
  border: none;
  background-color: #e2e2e2;
  width: 100%;
  font-size: 21px;
  height: 33px;

  &:placeholder-shown {
    font-size: 21px;
  }
`;

const H2 = styled.h2`
  font-weight: 400;
  font-size: 20px;
`;

export default function Abierta({
  loading,
  agrupadores,
  asignaciones,
  questionIndex,
  setQuestionIndex,
  reactivos,
  setSelectedOptionsAbiertaDesktop,
  setMultipleOptions,
  abiertaOptionsSelected,
  abiertaOptions,
  abiertaOptionsSelectedDesktop,
  answerData,
  setAnswerData,
  clearAnswers,
  type,
  setTipoActual,
  salir,
  tipoEvaluacion
}){

  const handleChange = (e) => {
    setSelectedOptionsAbiertaDesktop(e.target.value);
  };
  let name;
  let orden;
  agrupadores.forEach((agrupador) => {
    if (agrupador.reactivos.includes(reactivos[questionIndex])) {
      name = agrupador.nombre;
      orden = agrupador.orden;
    }
  });
  const question = reactivos[questionIndex].pregunta;

  return (
    <>
      <PreguntaContainer>
          <Name>{`${orden}.${name}`}</Name>
          <H2>{`${orden}.${reactivos[questionIndex].orden} ${question}`}</H2>
        <Wrapper>
            <AbiertaMobile 
            abiertaOptions={abiertaOptions}
            />
        </Wrapper>
      </PreguntaContainer>
      <Controls>
        <Progress
          agrupadores={agrupadores}
          reactivos={reactivos}
          canContinue={true}
          step={questionIndex + 1}
          questionIndex={questionIndex}
          setQuestionIndex={setQuestionIndex}
          clearAnswers={clearAnswers}
          salir={salir}
          answerData={answerData}
          setTipoActual={setTipoActual}
        />
        <Button onClick={salir}/>
      </Controls>
    </>
  );
};
