import { Ability, AbilityBuilder } from '@casl/ability'

export const ability = new Ability([])

export function setPermisos(permisos){
    const { can, rules } = new AbilityBuilder()
    permisos.forEach(permiso => {
        if(permiso.leer){
            can('read', permiso.funcionalidad)
        }
        if(permiso.modificar){
            can('edit', permiso.funcionalidad)
        }
        if(permiso.escribir){
            can('create', permiso.funcionalidad)
        }
        if(permiso.eliminar){
            can('delete', permiso.funcionalidad)
        }
    })
    return rules
}