import React from 'react'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons/'
import PropTypes from 'prop-types'

import './style.css'

function Despliegue(props) {
  return (
    <>
      <div
        className={
          props.size === 'medio'
            ? 'desplegador desplegador-medio'
            : 'desplegador'
        }
        onClick={props.open}
        style={props.style ? props.style : {}}
      >
        <span>{props.nombre}</span>{' '}
        {props.desplegada ? (
          <ArrowDropUp fontSize='large' />
        ) : (
          <ArrowDropDown fontSize='large' />
        )}
      </div>
      {props.desplegada ? (
        <div
          className='contenido-desplegador'
          style={
            props.height
              ? {
                  maxHeight: props.height,
                  height: props.height ? props.height : 'auto',
                  marginTop: props.marginTop ? props.marginTop : 'auto',
                }
              : { marginTop: props.marginTop ? props.marginTop : 'auto' }
          }
        >
          {props.children}
        </div>
      ) : null}
    </>
  )
}

Despliegue.propTypes = {
  size: PropTypes.string,
  open: PropTypes.func,
  nombre: PropTypes.string,
  desplegada: PropTypes.bool,
  height: PropTypes.number,
  children: PropTypes.element,
  style: PropTypes.any,
}

export default Despliegue
