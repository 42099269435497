import React from "react";

function SearchIcon(props) {
  return (
    <svg viewBox="0 0 22.77 22.76" {...props}>
      <defs>
        <style>{".SearchIcon_svg__cls-1{fill:#7e7d82}"}</style>
      </defs>
      <g id="SearchIcon_svg__Layer_2" data-name="Layer 2">
        <g id="SearchIcon_svg__Capa_1" data-name="Capa 1">
          <path
            className="SearchIcon_svg__cls-1"
            d="M9.36 18.72A9.36 9.36 0 1116 2.74a9.36 9.36 0 01-6.62 16zm0-16.18a6.82 6.82 0 104.83 2 6.81 6.81 0 00-4.83-2z"
          />
          <path
            className="SearchIcon_svg__cls-1"
            d="M21.5 22.76a1.26 1.26 0 01-.9-.37L14.19 16A1.27 1.27 0 0116 14.19l6.41 6.41a1.27 1.27 0 01-.9 2.17z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SearchIcon;
