import React, { useState, useEffect } from 'react'
import moment from 'moment'
import 'moment/locale/es'

import { getRequest } from '../../utils/requester'
import './style.css'

const Aside = ({ verMas }) => {
  const [vacantes, setVacantes] = useState(0)
  const [vacantesOcupadas, setVacantesOcupadas] = useState(0)
  const [alertas, setAlertas] = useState([])
  useEffect(() => {
    getAlertas()
  }, [])

  const getAlertas = async () => {
    try {
      const data = await getRequest('/reportes/alertas')
      setVacantes(data.d.vacantes)
      setVacantesOcupadas(data.d['vacantes ocupadas'])
      setAlertas(data.d.alertas.slice(0, 10))
      console.log(data)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <h3 className='aside-title'>Alertas</h3>
      <div className='sub-aside'>
        <h4 className='title-sub-aside'>{vacantes ? vacantes : ''} Vacantes</h4>
        <button onClick={() => verMas('Vacantes')} className='ver-mas-aside'>
          Ver más
        </button>
        <h4 className='title-sub-aside'>
          {vacantesOcupadas ? vacantesOcupadas : ''} Vacantes Ocupadas
        </h4>
        <button
          onClick={() => verMas('Vacantes Ocupadas')}
          className='ver-mas-aside'
        >
          Ver más
        </button>
        <h4 className='title-sub-aside'>Alertas</h4>
        <div style={{ paddingLeft: '30px' }}>
          {alertas.map((alerta) => (
            <p style={{ textAlign: 'left' }}>
              {alerta.tipo} {alerta.clave_presupuestal}{' '}
              {moment(alerta.fecha_hora).fromNow()}
            </p>
          ))}
        </div>
        <button onClick={() => verMas('Alertas')} className='ver-mas-aside'>
          Ver más
        </button>
      </div>
    </>
  )
}

export default Aside
