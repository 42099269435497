/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { CircularProgress } from '@material-ui/core'

import { getRequest } from '../../utils/requester'
import UserInfoComponent from '../../Components/Visualizar/components/UserInfo'
import Instrucciones from '../../Components/Visualizar/pages/Instrucciones'
import Seleccion from '../../Components/Visualizar/pages/Seleccion'
import OpcionMultiple from '../../Components/Visualizar/pages/OpcionMultiple'
import Intervalo from '../../Components/Visualizar/pages/Intervalo'
import EnvioResultados from '../../Components/Visualizar/pages/EnvioResultados'
import Abierta from '../../Components/Visualizar/pages/Abierta'
import Presentacion from '../../Components/Visualizar/pages/Presentacion'
import './style.css'

export default function Visualizar({ salir, detalle }) {
  const [loading, setLoading] = useState(false)
  const [loadingQuestion, setLoadingQuestion] = useState(false)
  const [infoEvaluacion, setInfoEvaluacion] = useState({})
  const [tipoEvaluacion, setTipoEvaluacion] = useState('')
  const [asignaciones, setAsignaciones] = useState([])
  const [agrupadores, setAgrupadores] = useState([])
  const [reactivos, setReactivos] = useState([])
  const [instruccionesAceptadas, setInstruccionesAceptadas] = useState(false)

  const [tipoActual, setTipoActual] = useState('')
  const [questionIndex, setQuestionIndex] = useState(-1)

  const [selectionOptionsSelected, setSelectionOptionsSelected] = useState([])
  const [rangeSelection, setRangeSelection] = useState([])
  const [rangeSelectionDesktop, setRangeSelectionDesktop] = useState('')
  const [multipleOptionsSelected, setMultipleOptionsSelected] = useState([])
  const [, setSelectedOptionsMultipleDesktop] = useState([])

  const [abiertaOptions, setAbiertaOptions] = useState([])

  const [nombreAgrupador, setNombreAgrupador] = useState('')
  const [answerData, setAnswerData] = useState({ respuestas: [] })

  useEffect(() => {
    let name
    agrupadores.forEach((agrupador) => {
      if (agrupador.reactivos.includes(reactivos[questionIndex])) {
        name = agrupador.nombre
      }
    })

    if (name !== nombreAgrupador) {
      setNombreAgrupador(name)
    }
    if (questionIndex >= 0 && questionIndex <= reactivos.length - 1) {
      if (reactivos[questionIndex].opciones.length === 0) {
        let myReactivo = reactivos[questionIndex]
        setLoadingQuestion(true)
        getRequest('/reactivo/detalle?id=' + reactivos[questionIndex].id).then(
          (response) => {
            if (response.s === 'OK') {
              if (response.d.tipo_pregunta === 'intervalo') {
                let i = parseInt(response.d.opciones[0].valor)
                response.d.opciones.forEach((option) => {
                  myReactivo.opciones.push({
                    id: option.id,
                    nombre: option.nombre,
                    valor: i,
                  })
                  i++
                })
              } else {
                response.d.opciones.forEach((option) => {
                  myReactivo.opciones.push({
                    id: option.id,
                    nombre: option.nombre,
                    valor: option.valor,
                  })
                })
              }
              let misReactivos = []
              let i = 0
              reactivos.forEach((react) => {
                if (i != questionIndex) {
                  misReactivos.push(react)
                } else {
                  misReactivos.push(myReactivo)
                }
                i++
              })
              i = 0
              setReactivos(misReactivos)
              setLoadingQuestion(false)
            } else {
              setLoadingQuestion(false)
              salir()
            }
          }
        )
      }
    }
  }, [questionIndex])

  useEffect(() => {
    let myEvaluacion = {
      clave: detalle.clave,
      fecha_inicio: '',
      fecha_fin: '',
      folio: '',
      tipo_evaluacion: detalle.tipo_evaluacion,
    }
    let myAgrupadores = []

    detalle.agrupadores.forEach((myAgrupador) => {
      let auxAgrupador = {
        id: myAgrupador.id,
        nombre: myAgrupador.nombre,
        orden: myAgrupador.numero,
      }
      let myReactivos = []
      myAgrupador.reactivos.forEach((myReactivo) => {
        let elReactivo = myReactivo
        if (myReactivo.tipo === 'intervalo') {
          let myNewOpcions = []
          let i = parseInt(myReactivo.opcions[0].valor)
          myReactivo.opcions.forEach((option) => {
            myNewOpcions.push({
              id: option.id,
              nombre: option.nombre,
              valor: i,
            })
            i++
          })
          elReactivo.opcions = myNewOpcions
        } else {
          let myNewOpcions = []
          myReactivo.opcions.forEach((option) => {
            myNewOpcions.push({
              id: option.id,
              nombre: option.nombre,
              valor: option.valor,
            })
          })
          elReactivo.opcions = myNewOpcions
        }
        myReactivos.push({
          id: myReactivo.id,
          orden: myReactivo.numero,
          pregunta: myReactivo.pregunta,
          tipo: myReactivo.tipo,
          opciones: elReactivo.opcions,
        })
      })
      auxAgrupador.reactivos = myReactivos
      myAgrupadores.push(auxAgrupador)
    })
    myEvaluacion.agrupadores = myAgrupadores
    getEvaluacion('13', myEvaluacion)
  }, [])

  function getEvaluacion(_id, myEvaluacion) {
    setLoading(true)
    let data = {}
    data.d = myEvaluacion
    setInfoEvaluacion({
      folio: '0000',
      clave: data.d.clave,
      tipo_evaluacion: data.d.tipo_evaluacion,
    })
    //setAsignaciones(data.d.asignaciones);
    setAgrupadores(data.d.agrupadores)
    setTipoEvaluacion(getType(data.d.tipo_evaluacion))
    setAsignaciones([
      { id: 0, nombre: '0', clave: '0', docente: '0', clave_docente: '0' },
    ])
    let myReactivos = []
    data.d.agrupadores.forEach((agrupador) => {
      myReactivos.push(...agrupador.reactivos)
    })
    setReactivos(myReactivos)
    setAnswerData({
      ...answerData,
      clave: data.d.clave,
      folio: data.d.folio,
      id_evaluacion: data.d.id,
    })
    setTipoActual(myReactivos[0].tipo)
    setLoading(false)
  }

  function getType(tipo) {
    switch (tipo) {
      case 'Taller/Laboratorio':
        return 'Taller_Laboratorio'

      case 'Opinion Estudiantil':
        return 'Opinion_Estudiantil'

      case 'Tipo ENE':
        return 'Tipo_ENE'

      case 'Encuestas':
        return 'Encuestas'

      case 'Evaluaciones':
        return 'Evaluaciones'
      default:
        return
    }
  }

  function handleAceptarInstrucciones() {
    setInstruccionesAceptadas(true)
    setTipoActual('presentacion')
    setQuestionIndex(0)
  }

  function clearAnswers() {
    setSelectionOptionsSelected([])
    setRangeSelection([])
    setRangeSelectionDesktop('')
    setSelectedOptionsMultipleDesktop([])
    setMultipleOptionsSelected([])
  }
  return (
    <>
      {loading ? (
        <div style={{ textAlign: 'center', paddingTop: 30 }}>
          <CircularProgress size={50} />
        </div>
      ) : (
        <div id='contenedor-previsualizacion'>
          <UserInfoComponent evalInfo={infoEvaluacion} />
          {!instruccionesAceptadas ? (
            <Instrucciones
              aceptarInstrucciones={handleAceptarInstrucciones}
              salir={salir}
              match={tipoEvaluacion}
            />
          ) : tipoActual === 'seleccion simple' ? (
            <Seleccion
              loading={loadingQuestion}
              tipoEvaluacion={tipoEvaluacion}
              clearAnswers={clearAnswers}
              setAnswerData={setAnswerData}
              answerData={answerData}
              selectionOptionsSelected={selectionOptionsSelected}
              setSelectionOptionsSelected={setSelectionOptionsSelected}
              agrupadores={agrupadores}
              asignaciones={asignaciones}
              questionIndex={questionIndex}
              setQuestionIndex={setQuestionIndex}
              reactivos={reactivos}
              salir={salir}
              setTipoActual={setTipoActual}
            />
          ) : tipoActual === 'seleccion multiple' ? (
            <OpcionMultiple
              loading={loadingQuestion}
              tipoEvaluacion={tipoEvaluacion}
              clearAnswers={clearAnswers}
              setAnswerData={setAnswerData}
              answerData={answerData}
              multipleOptionsSelected={multipleOptionsSelected}
              setMultipleOptionsSelected={setMultipleOptionsSelected}
              agrupadores={agrupadores}
              asignaciones={asignaciones}
              questionIndex={questionIndex}
              setQuestionIndex={setQuestionIndex}
              reactivos={reactivos}
              salir={salir}
              setTipoActual={setTipoActual}
            />
          ) : tipoActual === 'intervalo' ? (
            <Intervalo
              loading={loadingQuestion}
              tipoEvaluacion={tipoEvaluacion}
              clearAnswers={clearAnswers}
              setAnswerData={setAnswerData}
              answerData={answerData}
              rangeSelection={rangeSelection}
              setRangeSelection={setRangeSelection}
              rangeSelectionDesktop={rangeSelectionDesktop}
              setRangeSelectionDesktop={setRangeSelectionDesktop}
              agrupadores={agrupadores}
              asignaciones={asignaciones}
              questionIndex={questionIndex}
              setQuestionIndex={setQuestionIndex}
              reactivos={reactivos}
              salir={salir}
              setTipoActual={setTipoActual}
            />
          ) : tipoActual === 'abierta' ? (
            <Abierta
              loading={loadingQuestion}
              tipoEvaluacion={tipoEvaluacion}
              clearAnswers={clearAnswers}
              setAnswerData={setAnswerData}
              answerData={answerData}
              abiertaOptions={abiertaOptions}
              setAbiertaOptions={setAbiertaOptions}
              agrupadores={agrupadores}
              asignaciones={asignaciones}
              questionIndex={questionIndex}
              setQuestionIndex={setQuestionIndex}
              reactivos={reactivos}
              salir={salir}
              setTipoActual={setTipoActual}
            />
          ) : tipoActual === 'fin' ? (
            <EnvioResultados
              agrupadores={agrupadores}
              reactivos={reactivos}
              questionIndex={questionIndex}
              setQuestionIndex={setQuestionIndex}
              clearAnswers={clearAnswers}
              salir={salir}
              setTipoActual={setTipoActual}
            />
          ) : tipoActual === 'presentacion' ? (
            <Presentacion
              agrupadores={agrupadores}
              reactivos={reactivos}
              canContinue={true}
              step={questionIndex + 1}
              questionIndex={questionIndex}
              setQuestionIndex={setQuestionIndex}
              clearAnswers={clearAnswers}
              salir={salir}
              answerData={answerData}
              setTipoActual={setTipoActual}
            />
          ) : null}
        </div>
      )}
    </>
  )
}
