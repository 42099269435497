import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'

import Logo from '../../images/logo_uvm.png'
import pnd from '../../images/pnd.png'
import NewModal from '../Modal/NewModal'
import './styles.css'

function Header({ image, user }) {
  const [modalSesion, setModalSesion] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)

  async function logout() {
    setModalLoading(true)
    localStorage.removeItem('token_sieda')
    window.location.reload()
  }

  return (
    <header>
      <div className='logo'>
        <img src={Logo} alt='Logo UVM' />
      </div>
      <div className='page-title'>
        <h1>Evaluación integral docente</h1>
      </div>
      <div className='user-info'>
        <div>
          <p className='user-name'>{user.username}</p>
          {/*<p className='user-rol'>{user.rol}</p>*/}
        </div>
        {image ? (
          <img alt='avatar' onClick={() => setModalSesion(true)} src={image} />
        ) : (
          <img alt='avatar' src={pnd} onClick={() => setModalSesion(true)} />
        )}
      </div>
      {
        <NewModal
          title='Cerrar sesión'
          open={modalSesion}
          handleClose={() => setModalSesion(false)}
          handleSubmit={logout}
        >
          <div
            style={{ height: '80px', textAlign: 'center', paddingTop: '20px' }}
          >
            {modalLoading ? (
              <CircularProgress />
            ) : (
              <p style={{ textAlign: 'center' }}>
                ¿Está seguro de que desea cerrar sesión?
              </p>
            )}
          </div>
        </NewModal>
      }
    </header>
  )
}
const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

Header.propTypes = {
  images: PropTypes.string,
  user: PropTypes.shape({
    username: PropTypes.string,
    rol: PropTypes.string,
  }),
}

export default connect(mapStateToProps)(Header)
