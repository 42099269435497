/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import Grid from '@material-ui/core/Grid'
import MultiSelect from '../../Components/MultiSelect'

export default function NuevoUsuario({
  usuarioEditar,
  setUsuarioEditar,
  opcionesCampus,
  setQuantityCampus,
  quantityCampus,
  setValueCampus,
  valueCampus,
  campus,
}) {
  return (
    <div className='nuevoUsuarioForm'>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <label>Clave (*)</label>
          <input
            id='nuevo-clave'
            placeholder='Ingresa Clave'
            value={usuarioEditar.clave}
            onChange={(e) =>{
              if(!e.target.value.includes(' ')){
                setUsuarioEditar({ ...usuarioEditar, clave: e.target.value })
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <label>Nombre (*)</label>
          <input
            id='nuevo-nombre'
            placeholder='Ingresa Nombre'
            value={usuarioEditar.nombre}
            onChange={(e) =>
              setUsuarioEditar({ ...usuarioEditar, nombre: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <label>Correo electrónico</label>
          <input
            id='nuevo-correo'
            placeholder='Ingresa Correo'
            value={usuarioEditar.usuario}
            onChange={(e) =>{
              if(!e.target.value.includes(' ')){
                setUsuarioEditar({ ...usuarioEditar, usuario: e.target.value })
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <label>Campus (*)</label>
          <MultiSelect
            fromUser={true}
            loading={false}
            options={opcionesCampus}
            setCantidad={setQuantityCampus}
            cantidad={quantityCampus}
            onSet={setValueCampus}
            selecteds={valueCampus}
            value={campus}
          />
        </Grid>
      </Grid>
    </div>
  )
}
