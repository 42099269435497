import React from 'react'

function InfoIcon({ width, onClick }) {
  return (
    <svg
      onClick={onClick}
      width={width}
      style={{ cursor: 'pointer' }}
      xmlns='http://www.w3.org/2000/svg'
      x='0'
      y='0'
      enableBackground='new 0 0 512 512'
      version='1.1'
      viewBox='0 0 512 512'
      xmlSpace='preserve'
    >
      <path d='M257 0C116.39 0 0 114.39 0 255s116.39 257 257 257 255-116.39 255-257S397.61 0 257 0zm30 392c0 16.54-13.47 30-30 30-16.54 0-30-13.46-30-30V240c0-16.54 13.46-30 30-30 16.53 0 30 13.46 30 30v152zm-30-242c-16.54 0-30-13.46-30-30s13.46-30 30-30c16.53 0 30 13.46 30 30s-13.47 30-30 30z'></path>
    </svg>
  )
}

export default InfoIcon
