/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Select from 'react-select'
import { toast } from 'react-toastify'

import Contenedor from '../../Components/Contenedor/Contenedor'
import NewButton from '../../Components/Botones/NewButton'
import EditButton from '../../Components/Botones/EditButton'
import DataTable from '../../Components/DataTables'
import NewModal from '../../Components/Modal/NewModal'
import { getRequest, postRequest } from '../../utils/requester'

import './styles.css'

function CreadoPlantillaEvaluaciones() {
  const [alreadyHasBeenRendered, setAlreadyHasBeenRendered] = useState(false)
  const [dataInicial, setDataInicial] = useState([])
  const [dataFiltradas, setDataFiltradas] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [loadingModalEditar, setLoadingModalEditar] = useState(false)
  const [modalEditarIsOpen, setModalEditarIsOpen] = useState(false)
  const [modalNuevaIsOpen, setModalNuevaIsOpen] = useState(false)
  const [nombreEvaluacion, setNombreEvaluacion] = useState('')
  const [nombreEvaluacionExtension, setNombreEvaluacionExtension] = useState('')
  const [, setEditar] = useState({
    id: '',
    nombre: '',
    descripcion: '',
  })
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteModalLoading, setDeleteModalLoading] = useState(false)
  const [cicloPeriodoValue, setCicloPeriodoValue] = useState('')
  const [nivelValue, setNivelValue] = useState('')
  const [partePeriodoValue, setPartePeriodoValue] = useState([])
  const [modalidadValue, setModalidadValue] = useState('')
  const [opcionesNivel, setOpcionesNivel] = useState([])
  const [opcionesPartePeriodo, setOpcionesPartePeriodo] = useState([])
  const [opcionesCicloPeriodo, setOpcionesCicloPeriodo] = useState([])
  const [modalId, setModalId] = useState('')

  const opcionesModalidad = [
    {
      label: 'ONLN',
      value: 'ONLN',
    },
    {
      label: 'TR',
      value: 'TR',
    },
  ]

  const openNewModal = () => {
    setCicloPeriodoValue('')
    setNivelValue('')
    setPartePeriodoValue('')
    setModalidadValue('')
    setNombreEvaluacionExtension('')
    setModalNuevaIsOpen(true)
  }

  const openEditModal = async (
    ciclo,
    periodo,
    nivel,
    partePeriodo,
    modalidad,
    id,
    nombre_evaluacion
  ) => {
    setEditar({
      ciclo,
      periodo,
    })
    setCicloPeriodoValue({
      label: ciclo + '-' + periodo,
      value: ciclo + '-' + periodo,
    })
    setNivelValue({
      label: nivel,
      value: nivel,
    })

    const newPartePeriodo = partePeriodo.split(',').map((item) => ({
      label: item,
      value: item,
    }))
    setPartePeriodoValue(newPartePeriodo)
    setModalidadValue({
      label: modalidad,
      value: modalidad,
    })
    setModalEditarIsOpen(true)
    setModalId(id)

    const newNombreEvaluacionExtension = nombre_evaluacion
      .split(' ')
      .slice(1)
      .join(' ')
    setNombreEvaluacionExtension(newNombreEvaluacionExtension)
  }

  const handleEditar = async (action) => {
    setLoadingModalEditar(true)

    const cicloPeriodo = opcionesCicloPeriodo.find(
      (item) => item.value === cicloPeriodoValue.value
    )
    const level = opcionesNivel.find((item) => item.value === nivelValue.value)

    const idCicloPeriodo = cicloPeriodo?.id
    const idLevel = level?.id

    const partePeriodo = partePeriodoValue.map((item) => item.value).join(',')

    try {
      const response = await postRequest(
        `/calendario/plantilla_evaluaciones/editar`,
        {
          id: modalId,
          id_ciclo_periodo: idCicloPeriodo,
          id_level: idLevel,
          parte_periodo: partePeriodo,
          nombre_evaluacion: nombreEvaluacion + ' ' + nombreEvaluacionExtension,
          modalidad: modalidadValue.value,
          status: action,
        }
      )

      if (response.s === 'OK') {
        toast.success(response.m)
        setModalEditarIsOpen(false)
        setLoadingModalEditar(false)
        reloadList()
      }
      setLoadingModalEditar(false)
    } catch (error) {
      console.error(error)
      setLoadingModalEditar(false)
    }
  }

  const handleDelete = async () => {
    setDeleteModalLoading(true)
    try {
      /*
      const eliminarEtiquetaResponse = await postRequest(`/etiqueta/eliminar`, {
        id: etiquetaEditar.id,
      });
      */
      const eliminarResponse = eliminar()
      if (eliminarResponse.s === 'OK') {
        setModalEditarIsOpen(false)
        setDeleteModalLoading(false)
        reloadList()
        setDeleteModal(false)
      }
      setDeleteModal(false)
      setDeleteModalLoading(false)
    } catch (error) {
      console.error(error)
      setDeleteModal(false)
      setDeleteModalLoading(false)
    }
  }

  const testHeader = [
    'Ciclo',
    'Periodo',
    'Nivel',
    'Parte Periodo',
    'Nombre Evaluación',
    'Modalidad',
    'Acciones',
  ]

  ////////// Filtrar resultados segun el input de busqueda //////////
  const search = (e) => {
    let text = e.target.value.toLowerCase()

    let result = dataInicial.filter((row) => {
      if (JSON.stringify(row).toLowerCase().includes(text)) {
        return true
      }
      return false
    })
    setDataFiltradas(result)
  }

  const reloadList = async () => {
    setLoading(true)
    try {
      const response = await getRequest('/calendario/plantilla_evaluaciones')
      if (response.s === 'OK') {
        let data = []
        response.d.forEach((reg) => {
          data.push([
            reg.cycle,
            reg.period,
            reg.level,
            reg.parte_periodo,
            reg.nombre_evaluacion,
            reg.modalidad,
            <EditButton
              onClick={() =>
                openEditModal(
                  reg.cycle,
                  reg.period,
                  reg.level,
                  reg.parte_periodo,
                  reg.modalidad,
                  reg.id,
                  reg.nombre_evaluacion
                )
              }
            >
              Editar
            </EditButton>,
          ])
        })
        setDataInicial(data)
        setDataFiltradas(data)
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const getOpcionesCicloPeriodo = async () => {
    try {
      const response = await getRequest('/calendario/ciclo_periodo')
      if (response.s === 'OK') {
        let options = []
        response.d.length > 0 &&
          response.d.forEach((option) => {
            options = [
              ...options,
              {
                label: option.ciclo + '-' + option.periodo,
                value: option.ciclo + '-' + option.periodo,
                id: option.id,
              },
            ]
          })
        setOpcionesCicloPeriodo(options)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getNiveles = async () => {
    try {
      const response = await getRequest('/calendario/nivel')
      if (response.s === 'OK') {
        let niveles = []
        response.d.length > 0 &&
          response.d.forEach((item) => {
            niveles = [
              ...niveles,
              { label: item.nombre, value: item.nombre, id: item.id },
            ]
          })
        setOpcionesNivel(niveles)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getOpcionesPartePeriodo = async (nivel) => {
    try {
      const response = await getRequest(
        `/calendario/parte_periodo?nivel=${nivel}`
      )
      if (response.s === 'OK') {
        let options = []
        response.d.length > 0 &&
          response.d.forEach((option) => {
            options = [...options, { label: option, value: option }]
          })
        setOpcionesPartePeriodo(options)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleChangeCiclo = ({ value }) => {
    setCicloPeriodoValue({
      label: value,
      value,
    })
  }

  const handleChangeNivel = ({ value }) => {
    setNivelValue({
      label: value,
      value,
    })
    getOpcionesPartePeriodo(value)
    setPartePeriodoValue([])
  }

  const handleChangePartePeriodo = (items) => {
    setPartePeriodoValue(items)
  }

  const handleChangeModalidad = ({ value }) => {
    setModalidadValue({
      label: value,
      value,
    })
  }

  const handleSubmit = async () => {
    setLoadingModal(true)

    const cicloPeriodo = opcionesCicloPeriodo.find(
      (item) => item.value === cicloPeriodoValue.value
    )
    const level = opcionesNivel.find((item) => item.value === nivelValue.value)

    const idCicloPeriodo = cicloPeriodo?.id
    const idLevel = level?.id

    const partePeriodo = partePeriodoValue.map((item) => item.value).join(',')

    try {
      const response = await postRequest(
        `/calendario/plantilla_evaluaciones/nueva`,
        {
          id_ciclo_periodo: idCicloPeriodo,
          id_level: idLevel,
          parte_periodo: partePeriodo,
          nombre_evaluacion: nombreEvaluacion + ' ' + nombreEvaluacionExtension,
          modalidad: modalidadValue.value,
        }
      )
      if (response.s === 'OK') {
        setModalNuevaIsOpen(false)
        setLoadingModal(false)
        reloadList()
      }
      setLoadingModal(false)
    } catch (error) {
      console.error(error)
      setLoadingModal(false)
    }
  }

  useEffect(() => {
    // Solo se ejecuta en el primer render
    if (!alreadyHasBeenRendered) {
      reloadList()
    } else {
      setAlreadyHasBeenRendered(true)
    }
    getNiveles()
  }, [])

  useEffect(() => {
    getNiveles()
    getOpcionesCicloPeriodo()
  }, [modalEditarIsOpen, modalEditarIsOpen])

  useEffect(() => {
    try {
      if (cicloPeriodoValue.value && nivelValue.value && modalidadValue.value) {
        const cicloPeriodo = cicloPeriodoValue.value.split('-')

        const newNombreEvaluacion = `${cicloPeriodo[0]}-[año]-${cicloPeriodo[1]}-${nivelValue.value}-${modalidadValue.value}`

        setNombreEvaluacion(newNombreEvaluacion)
      } else {
        setNombreEvaluacion('')
      }
    } catch {
      setNombreEvaluacion('')
    }
  }, [cicloPeriodoValue, nivelValue, modalidadValue])

  const customStyles = {
    option: (provided, _state) => ({
      ...provided,
      color: '#616b74',
      fontSize: 14,
      fontWeight: 400,
      padding: '5px 25px',
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      userSelect: 'none',
      backgroundColor: '#fff',
      borderTop: '1px solid #ccc',
    }),
    control: (provided, _state) => ({
      ...provided,
      minHeight: 0,
      height: '25px',
      borderRadius: 15,
      fontSize: 11,
      backgroundColor: '#f9f9f9',
      padding: 0,
    }),
    placeholder: (provided, _state) => ({
      ...provided,
      padding: 0,
      fontSize: 11,
      fontWeight: 400,
    }),
    menu: (provided, _state) => ({
      ...provided,
      borderRadius: 0,
    }),
    input: (provided, _state) => ({
      ...provided,
      padding: 0,
      fontSize: 11,
      fontWeight: 400,
      borderRadius: 0,
    }),
    singleValue: (provided, _state) => ({
      ...provided,
      top: '50%',
    }),
  }

  return (
    <Contenedor title='Creado de Plantilla Evaluaciones'>
      <div className='opciones-bar'>
        <div className='sub-filtro'>
          <NewButton
            onClick={() => {
              openNewModal()
            }}
          />
        </div>
        <div style={{ float: 'right' }}>
          <input
            type='search'
            placeholder='Buscar'
            className='SearchInput-tabla-completa SearchInput-Usuarios'
            onChange={search}
          />
        </div>
      </div>
      <div style={{ height: 'calc(100% - 70px)' }}>
        <DataTable
          headers={testHeader}
          data={dataFiltradas}
          loading={loading}
          paginate
        />
      </div>
      <NewModal
        title='Editar Plantilla Evaluaciones'
        canDelete
        open={modalEditarIsOpen}
        handleClose={() => setModalEditarIsOpen(false)}
        height={500}
        loading={loadingModalEditar}
        handleSubmit={() => handleEditar(1)}
        onDelete={() => handleEditar(0)}
        permiso='calendario_plantilla-evaluaciones'
      >
        <div className='nuevoEtiquetaForm'>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <label id='tag-nombre'>Ciclo-Periodo (*)</label>
              <Select
                options={opcionesCicloPeriodo}
                value={cicloPeriodoValue}
                styles={customStyles}
                name='etiqueta'
                placeholder='Selección...'
                className='select-search-box'
                onChange={handleChangeCiclo}
              />
            </Grid>
            <Grid item xs={12}>
              <label id='tag-nombre'>Nivel (*)</label>
              <Select
                options={opcionesNivel}
                value={nivelValue}
                styles={customStyles}
                name='etiqueta'
                placeholder='Selección...'
                className='select-search-box'
                onChange={handleChangeNivel}
              />
            </Grid>
            <Grid item xs={12}>
              <label id='tag-nombre'>Parte Periodo (*)</label>
              <Select
                options={opcionesPartePeriodo}
                isMulti
                value={partePeriodoValue}
                styles={customStyles}
                name='etiqueta'
                placeholder='Selección...'
                className='select-search-box'
                onChange={handleChangePartePeriodo}
              />
            </Grid>
            <Grid item xs={12}>
              <label id='tag-nombre'>Modalidad (*)</label>
              <Select
                options={opcionesModalidad}
                value={modalidadValue}
                styles={customStyles}
                name='etiqueta'
                placeholder='Selección...'
                className='select-search-box'
                onChange={handleChangeModalidad}
              />
            </Grid>
            <Grid item xs={12}>
              <label id='tag-descripcion'>Nombre evaluación (*)</label>
              <div className='inputs-container'>
                <input
                  className='input-disabled'
                  disabled
                  placeholder=''
                  value={nombreEvaluacion}
                />
                <input
                  placeholder=''
                  value={nombreEvaluacionExtension}
                  onChange={(ev) => {
                    setNombreEvaluacionExtension(ev.target.value)
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </NewModal>
      <NewModal
        title='Nueva Plantilla Evaluaciones'
        open={modalNuevaIsOpen}
        handleClose={() => {
          setModalNuevaIsOpen(false)
        }}
        handleSubmit={handleSubmit}
        height={500}
        loading={loadingModal}
      >
        <div className='nuevoEtiquetaForm'>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <label id='tag-nombre'>Ciclo-Periodo (*)</label>
              <Select
                options={opcionesCicloPeriodo}
                value={cicloPeriodoValue}
                styles={customStyles}
                name='etiqueta'
                placeholder='Selección...'
                className='select-search-box'
                onChange={handleChangeCiclo}
              />
            </Grid>
            <Grid item xs={12}>
              <label id='tag-nombre'>Nivel (*)</label>
              <Select
                options={opcionesNivel}
                value={nivelValue}
                styles={customStyles}
                name='etiqueta'
                placeholder='Selección...'
                className='select-search-box'
                onChange={handleChangeNivel}
              />
            </Grid>
            <Grid item xs={12}>
              <label id='tag-nombre'>Parte Periodo (*)</label>
              <Select
                options={opcionesPartePeriodo}
                isMulti
                value={partePeriodoValue}
                styles={customStyles}
                name='etiqueta'
                placeholder='Selección...'
                className='select-search-box'
                onChange={handleChangePartePeriodo}
              />
            </Grid>
            <Grid item xs={12}>
              <label id='tag-nombre'>Modalidad (*)</label>
              <Select
                options={opcionesModalidad}
                value={modalidadValue}
                styles={customStyles}
                name='etiqueta'
                placeholder='Selección...'
                className='select-search-box'
                onChange={handleChangeModalidad}
              />
            </Grid>
            <Grid item xs={12}>
              <label id='tag-descripcion'>Nombre evaluación (*)</label>
              <div className='inputs-container'>
                <input
                  className='input-disabled'
                  disabled
                  placeholder=''
                  value={nombreEvaluacion}
                />
                <input
                  placeholder=''
                  value={nombreEvaluacionExtension}
                  onChange={(ev) => {
                    setNombreEvaluacionExtension(ev.target.value)
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </NewModal>
      <NewModal
        open={deleteModal}
        height={120}
        title='Confirmar'
        loading={deleteModalLoading}
        handleClose={() => setDeleteModal(false)}
        handleSubmit={handleDelete}
      >
        <div class='delete-confirm'>
          <h2 style={{ textAlign: 'center' }}>
            ¿Está seguro de que desea eliminar este Ciclo-Periodo?
          </h2>
        </div>
      </NewModal>
    </Contenedor>
  )
}

export default CreadoPlantillaEvaluaciones

function eliminar() {
  return { s: 'OK', m: 'etiqueta eliminada correctamente', d: '' }
}
