import React from 'react';
import styled from 'styled-components';
import Progress from '../components/Progress';
import Button from '../components/Button';

const Container = styled.div`
  margin: 1rem 3rem 0;
  @media screen and (max-width: 480px) {
    margin: 1rem 1rem 0;
  }
`;

const Controls = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;

  @media screen and (max-width: 480px) {
    position: fixed;
    width: 91%;
    margin: 0 1rem;
  }
`;

const Text = styled.h1`
  text-align: center;
  font-weight: 400;
  font-size: 25px;
  margin: 3em 5em 4em;

  @media screen and (max-width: 768px) {
    margin: 1em 0em 4em;
  }
`;

export default function EnvioResultados(
    {
      agrupadores,
      reactivos,
      questionIndex,
      setQuestionIndex,
      clearAnswers,
      setTipoActual,
      salir
    }
  ){
  return (
    <>
      <Container>
        <Text>
          Has llegado al final, para concluir deberás haber constestado todas
          las preguntas, de lo contrario no se podrá enviar la evaluación
        </Text>
      </Container>
      <Controls>
        <Progress

          agrupadores={agrupadores}
          reactivos={reactivos}
          canContinue={true}
          step={questionIndex + 1}
          questionIndex={questionIndex}
          setQuestionIndex={setQuestionIndex}
          clearAnswers={clearAnswers}
          setTipoActual={setTipoActual}
          salir={salir}
          enviar={true}
        />
        <Button onClick={salir}/>
      </Controls>
    </>
  );
};
